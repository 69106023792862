import React, {useEffect, useState} from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import HomeSearchingModal from '../Modals/HomeSearchingModal'
import {setUpdatedItem, getAllSearchData} from '../../store/slice/home-searching-slice'
import {getSearches} from '../../helper-functions/CreateIndexDb'
// import '../../styles/HomeSearchModal.css'
import '../../styles/homescreen.css'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {useTheme} from '../../context/ThemeContext'
import '../../styles/customSearchBar.css'
interface GeneralSearchProps {
  isListVisible: boolean
  setIsListVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const GeneralSearch: React.FC<GeneralSearchProps> = ({
  isListVisible,
  setIsListVisible,
}): React.JSX.Element => {
  const {theme} = useTheme()
  const dispatch: Dispatch<any> = useDispatch()
  const homeSearchingData = useSelector((state: any) => state.homeSearchingReducer)
  const [searchInput, setSearchInput] = useState('')

  const [showSearchingModal, setShowSearchingModal] = useState(false)
  const [lastSearches, setLastSearches] = useState<string[]>([])

  const apiFetchCall = async () => {
    try {
      const getSearch = await getSearches('Home')
      setLastSearches([...getSearch.data])
    } catch (error) {
      return error
    }
  }
  async function getSearchData() {
    await dispatch(getAllSearchData({query: searchInput}))
    dispatch(setUpdatedItem([]))
    setShowSearchingModal(!showSearchingModal)
  }
  useEffect(() => {
    if (showSearchingModal === false) {
      setSearchInput('')
    }
  }, [showSearchingModal])

  useEffect(() => {
    apiFetchCall()
  }, [searchInput])

  const isWhitespaceString = (str: string) => !/\S/.test(str)
  return (
    <>
      <div className='d-flex align-items-end' style={{width: '100%'}}>
        <InputGroup className='mb-3'>
          <input
            type='text'
            value={searchInput}
            id='searchInput'
            onChange={(e) => {
              setSearchInput(e.target.value)
            }}
            placeholder='Search'
            className={`form-control form-control-sm form-control-solid italics-placeholder px-3 ${
              theme === 'dark' ? 'dark__theme__input' : 'bg-white top_custom_search'
            }`}
            style={{position: 'relative'}}
            onClick={() => {
              // setIsDatePickerVisible(false)
              setIsListVisible(!isListVisible)
            }}
            autoComplete='off'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (searchInput.length > 0) {
                  getSearchData()
                }
              }
            }}
          />

          {searchInput !== '' && searchInput?.length > 0 ? (
            <i
              style={{
                right: '45px',
                bottom: '0px',
              }}
              className={`bi bi-x cursor-pointer fs-3 position-absolute  translate-middle-y  rounded-circle  ${theme === 'dark' ? 'theme__div' : 'bg-secondary text-light'}`}
              onClick={() => {
                setSearchInput('')
              }}
            ></i>
          ) : null}

          {searchInput.length > 0 &&
          homeSearchingData.mainSearchLoading === true &&
          isWhitespaceString(searchInput) === false ? (
            <span className={`input-group-text home-search-btn ${theme === 'dark' ? '' : 'top_custom_search'}`}>
              <div
                style={{
                  color: '#1d1e2c',
                }}
                className='spinner-border  spinner-border-sm'
                role='status'
              >
                <span className='sr-only'>Loading...</span>
              </div>
            </span>
          ) : (
            <span
              onClick={async () => {
                if (searchInput.length > 0 && isWhitespaceString(searchInput) === false) {
                  getSearchData()
                }
              }}
              className={`input-group-text home-search-btn ${theme === 'dark' ? '' : 'top_custom_search'}`}
            >
              <i className='bi bi-search'></i>
            </span>
          )}

          <>
            {isListVisible && lastSearches?.length > 0 ? (
              <ul className={`custom-list-home ${theme === 'dark' ? 'theme__div' : 'bg-white'} `}>
                {(lastSearches || [])?.length > 0
                  ? (lastSearches || [])?.map((search: string, index: number) => (
                      <li
                        className='text-nowrap'
                        onClick={() => {
                          setSearchInput(search)
                          setIsListVisible(false)
                        }}
                        key={index}
                      >
                        {search}
                      </li>
                    ))
                  : null}
              </ul>
            ) : null}
          </>
        </InputGroup>
      </div>
      <div>
        {showSearchingModal && (
          <HomeSearchingModal setShow={setShowSearchingModal} show={showSearchingModal} />
        )}
      </div>
    </>
  )
}
export default GeneralSearch

import Modal from 'react-bootstrap/Modal'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import {useTheme} from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DialogueModal({show, handleClose}: any) {
  const {redirectUrl} = useSelector((state: any) => state.RedirectionReducer)
  const {theme} = useTheme()

  const paragraph1 =
    "Thank you for signing up to b4igo and welcome to our platform! A verification email has been sent to the email address you created your account with. Please click on the Verification button within that email within the next 24 hours. After 24 hours, we will lock your account until you have verified, but if you can't find the email or just want us to send it again, click on the Resend Verification Email button you will see on the login page. This message will close after a few seconds and you will be taken to your b4igo Manage Account Screen, where you can choose a subscription plan and add as people to your account as you need."

  const paragraph2 =
    "Thank you for signing up to b4igo and welcome to our platform! A verification email has been sent to the email address you created your account with. Please click on the Verification button within that email within the next 24 hours. After 24 hours, we will lock your account until you have verified, but if you can't find the email or just want us to send it again, click on the Resend Verification Email button you will see on the login page. This message will close after a few seconds and you will be taken to your b4igo Home Screen."

  return (
    <>
      <Modal
        contentClassName={theme === 'dark' ? 'theme__div' : ''}
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Body>
          <div
            className={`${
              theme === 'dark' ? 'theme__div' : 'bg-white'
            } rounded shadow-sm py-5 px-3 mx-auto text-center`}
          >
            <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
                className='h-50px my-5 me-15'
              />
            </a>
            <p className={`p-3 fs-5 ${theme === 'dark' ? 'white__text' : ''}`}>
              {redirectUrl === '/home/board' ? paragraph2 : paragraph1}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DialogueModal

import React  from 'react'
import {Dropdown} from 'react-bootstrap'
import {useTheme} from '../../context/ThemeContext'
import { useAuth } from '../../modules/auth'

const ThemeSwitch: React.FC = () => {
  const {theme, toggleTheme} = useTheme()
  const {currentUser} = useAuth()
  const { isVerified, isInvitedByOrganization } =
  currentUser?.data.user

  return (
    <Dropdown>
      <Dropdown.Toggle
        as='button'
        id='dropdown-basic'
        className={`btn btn-sm btn-primary d-flex align-items-center no-arrow my-2 p-4 ${(!isVerified && !isInvitedByOrganization) ? 'my-2' : 'mt-4'}`}
      >
        <i className='bi bi-brightness-high white__text' style={{marginLeft: 5}}></i>
      </Dropdown.Toggle>

      <Dropdown.Menu className={theme === 'dark' ? 'theme__div' : ''}>
        <Dropdown.Item
          onClick={() => toggleTheme('light')}
          className={theme === 'light' ? 'active' : ' text-white'}
        >
          <i className={`bi bi-sun-fill menu-icon mx-2 ${theme=== 'dark'?'white__text':''}`}></i> Light
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => toggleTheme('dark')}
          className={theme === 'dark' ? 'active text-white' : ''}
        >
          <i className={`bi bi-moon-fill menu-icon mx-2  ${theme=== 'dark'?'white__text':''}`}></i> Dark
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ThemeSwitch

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllCardByUserId} from '../../services/cards'
// import {toast} from 'react-toastify'

// First, create the thunk
export const fetchAllHomeCardByUserId = createAsyncThunk(
  'card/fetchAllHomeCardByUserId',
  async (data: any) => {
    let response
    const dateFilter:any={days:null,fromDate:null,toDate:null} 
    try {
      response = await getAllCardByUserId(data.userId, data.boardStatus,dateFilter)
      return response.data
    } catch (error: any) {
      // if (error.response.status === 404) {
      //   toast.warning('No cards available.')
      // }
    }
  }
)

const initialState: any = {
  entities: {},
  loading: false,
  errors: {},
}

export const homeCardSlice = createSlice({
  name: 'fetchAllHomeCardByUserId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllHomeCardByUserId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllHomeCardByUserId.fulfilled, (state, action) => {
      state.entities = {...action.payload}
      state.loading = false
    })
    builder.addCase(fetchAllHomeCardByUserId.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export default homeCardSlice.reducer

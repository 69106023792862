import React, {useEffect, useState} from 'react'
import {Tab} from 'react-bootstrap'
import {useFormik} from 'formik'
import useTogglePassword from '../../hooks/useTogglePassword'
import Pricing from '../Pricing/Pricing'
import clsx from 'clsx'
import * as Yup from 'yup'
import '../../styles/inputStyle.css'
import {setRedirectTo} from '../../store/slice/redirect-slice'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {
  createOrganization,
  // createOrganizationLicense,
  // createOrganizationMemberInvite,
} from '../../services/company'
import {useEmailVerification} from '../../hooks'
import {useLocation, useSearchParams} from 'react-router-dom'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'

// interface Roles {
//   id: string
//   name: string
//   description: string
//   isSuperAdmin: boolean
//   isAdmin: boolean
//   createdAt: string
//   updatedAt: string
// }

//eslint-disable-next-line
const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/
// const nameReg = /^[a-zA-Z ._-]*$/
const registrationCompanySchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'Maximum 100 characters are allowed.')
    .min(3, 'Minimum 3 characters are required.')
    .required('Name is required')
    .trim(),
    boardName: Yup.string()
    .required('Board Name is required')
    .max(40, 'Maximum 40 characters are allowed.')
    .min(3, 'Minimum 3 characters are required.')
    .trim(),
    pageTitle: Yup.string()
    .required('Page Name is required')
    .max(40, 'Maximum 40 characters are allowed.')
    .min(3, 'Minimum 3 characters are required.')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .trim()
    .matches(
      passwordReg,
      'Please use 12 or more characters with a mix of letters, numbers and any of the following special characters: !,@,~,$,%,^ or *'
    ),
  companyName: Yup.string()
    .max(40, 'Maximum 40 characters are allowed.')
    .required('Company/Organisation name is required.')
    .trim(),
  companyEmail: Yup.string()
    .email('Invalid email address')
    .required('Company/Organisation email is required.'),
  licenseEmail1: Yup.string().email('Invalid email address'),
  licenseEmail2: Yup.string().email('Invalid email address'),
  licenseEmail3: Yup.string().email('Invalid email address'),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CompanyForm = ({loginHandler, handleClose, handleShow}: any): React.JSX.Element => {
  const {theme} = useTheme()
  const [isAlreadyMember, setIsAlreadyMember] = useState([
    {invite: '', index: 1},
    {invite: '', index: 2},
    {invite: '', index: 3},
  ])

  const [searchParams] = useSearchParams()

  const [popUpMsg, setPopUpMsg] = useState('')
  const [tabName, setTabName] = useState('info')
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')

  const [signUpLoading, setSignUpLoading] = useState<boolean>(false)
  const [licenseLoading, setLicenseLoading] = useState<boolean>(false)

  const [activeCard, setActiveCard] = useState<any>(null)
  const {passwordType, togglePassword} = useTogglePassword()
  const dispatch: Dispatch<any> = useDispatch()
  const {emailVerificationHandler} = useEmailVerification()
  const location: any = useLocation()
  const decodedEmail = location?.state?.decodedDataEmail || false
  const currentTier = searchParams.get('tier') || 'Introductory'
  const handleNextBtn = (name: string) => {
    setTabName(name)
  }
  const initialValues = {
    name: '',
    email: decodedEmail || '',
    password: '',
    image: null,
    roleId: '',
    boardName: 'b4igo',
    pageTitle: 'My Fantastic Idea',
    companyName: '',
    companyEmail: '',
    licenseEmail1: '',
    licenseEmail2: '',
    licenseEmail3: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationCompanySchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      const {
        boardName,
        pageTitle,
        companyName,
        companyEmail,
        password,
        email,
        name,
        licenseEmail1,
        licenseEmail2,
        licenseEmail3,
      } = values

      if (!values.companyName) {
        return setErrorMsgRegistration('Company name required')
      }
      // if(licenseEmail1===licenseEmail2|| licenseEmail3===licenseEmail1|| licenseEmail3===licenseEmail2){
      //   setPopUpMsg("Emails should be unique!")
      //   setLoading(false)
      //   return
      // }
      // const role = roleList.find((roles: Roles) => roles.name === 'User')?.id

      const organizationDetails = {
        boardName,
        pageNumber:1,
        pageTitle,
        organizationName: companyName,
        organizationEmail: companyEmail,
        logo: null,
        usedLicense: 0,
        unusedLicense: 0,
        totalLicense: 3,
        password: password,
        personalEmail: email,
        fullName: name,
        chosenSubscriptionPlan:
          activeCard === 0 ? 'Monthly' : activeCard === 1 ? 'Yearly' : 'Introductory',
      }
      const freeTrialLicenseSubscription = {
        organizationId: '',
        organizationName: '',
        type: 'FreeTrial',
        organizationEmail: companyEmail,
        totalLicense: 0,
        fullName: name,
      }
      
      const licenseEmails = [licenseEmail1, licenseEmail2, licenseEmail3].filter(email => email.trim() !== '');
      // const memberDetails = {
        //   organizationName: companyName,
        //   organizationLicenseId: '',
        //   organizationId: '',
        //   email: '',
        //   roleId: role,
        // }
        
        try {
          setLicenseLoading(true)
          const orgRes = await createOrganization(organizationDetails)
        freeTrialLicenseSubscription.organizationId = orgRes.data.data.id
        freeTrialLicenseSubscription.organizationName = orgRes.data.data.organizationName

        for (let index = 0; index < licenseEmails?.length; index++) {
          if (licenseEmails[index].length > 0) {
            freeTrialLicenseSubscription.totalLicense++
          }
        }
        const timeoutDuration = 3000
        // if (freeTrialLicenseSubscription.totalLicense > 0) {
        //   // const licenseResponse = await createOrganizationLicense(freeTrialLicenseSubscription)
        //   // memberDetails.organizationId = orgRes.data.data.id
        //   // for (let index = 0; index < licenseEmails.length; index++) {
        //     // if (licenseEmails[index].length > 0) {
        //       // memberDetails.email = licenseEmails[index]
        //       // memberDetails.organizationLicenseId = licenseResponse.data.data[index].id
        //       // const inviteRes = await createOrganizationMemberInvite(memberDetails)
        //       // if (inviteRes.data.message.includes('already registered on b4igo')) {
        //       //   timeoutDuration = 8000
        //       //   setIsAlreadyMember((prevStatus) => {
        //       //     const newState = [...prevStatus]
        //       //     newState[index] = {invite: inviteRes.data.message, index: index + 1}
        //       //     return newState
        //         })
        //       }
        //     }
        //   }
        // }
        setTimeout(() => {
          emailVerificationHandler({
            userId: orgRes.data.data.userId,
            fullName: name,
            email: email,
            isOrgSignup:true,
            chosenSubscriptionPlan:
              activeCard === 0 ? 'Monthly' : activeCard === 1 ? 'Yearly' : 'Introductory',
              license: {
                "totalLicense": freeTrialLicenseSubscription.totalLicense,
                "organizationId": freeTrialLicenseSubscription.organizationId,
                "organizationName":freeTrialLicenseSubscription.organizationName,
                "licenseEmails":licenseEmails
              }
          })
          handleShow()
          setTimeout(() => {
            handleClose()
            loginHandler(email, password,orgRes.data.data.boardId)
          }, 5000)
        }, timeoutDuration)
      } catch (error: any) {
        const msg = error.response.data.message
        setErrorMsgRegistration(msg)
        setLicenseLoading(false)
      } finally {
        setSubmitting(false)
        setSignUpLoading(false)

        // formik.resetForm()
      }
    },
  })

  useEffect(() => {
    if (currentTier && currentTier !== 'Introductory') {
      if (currentTier === 'Monthly') {
        setActiveCard(0)
      }
      if (currentTier === 'Yearly') {
        setActiveCard(1)
      }
    }
  }, [])

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework  ${theme === 'dark' ? 'theme__div' : ''}  mt-7`}
    >
      <Tab.Container id='left-tabs-example' activeKey={tabName}>
        <div className='d-flex flex-column justify-content-center pb-2 '>
          {/* <h5 className='mt-2'>Personal Details</h5> */}
          <div className='d-flex justify-content-center'>
            <div
              className={`d-inline-block me-1 border-bottom border-5 w-25 ${
                tabName === 'info' ? 'border-primary' : 'border-secondary'
              } rounded-pill`}
            ></div>
            <div
              className={`d-inline-block me-1 border-bottom border-5 w-25  ${
                tabName === 'company' ? 'border-primary' : 'border-secondary'
              } rounded-pill`}
            ></div>
          </div>
        </div>

        <Tab.Content>
          <Tab.Pane className={`${theme === 'dark' ? 'theme__div' : 'bg-white'}`} eventKey='info'>
            <div className='col-xl-12 mt-3 mb-7'>
              <label
                className={`form-label fw-bold ${
                  theme === 'dark' ? 'white__text' : 'text-dark'
                } fs-6`}
              >
                Please enter your name
              </label>
              <input
                placeholder='Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('name')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder`,
                  {
                    'is-invalid': formik.touched.name && formik.errors.name,
                  },
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.name}
                    </span>
                  </div>
                </div>
              )}
              
            </div>
            <div className='col-xl-12 my-7'>
              <label
                className={`form-label fw-bold ${
                  theme === 'dark' ? 'white__text' : 'text-dark'
                } fs-6 mb-0`}
              >
                Enter your email address
              </label>
              <input
                disabled={decodedEmail ? true : false}
                placeholder='Email Address'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder`,
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.email as any}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-xl-12 my-7'>
              <label
                className={`form-label fw-bold ${
                  theme === 'dark' ? 'white__text' : 'text-dark'
                } fs-6 mb-0`}
              >
                Create a password
              </label>
              <div className='position-relative mb-3'>
                <div className='input-group'>
                  <input
                    type={passwordType}
                    placeholder='Password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      `form-control form-control-lg form-control-solid  ${
                        theme === 'dark' ? 'dark__theme__input' : ''
                      }  italics-placeholder`,
                      {'is-invalid': formik.touched.password && formik.errors.password},
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />

                  <div className='input-group-btn'>
                    <button
                      className={`${
                        theme === 'dark' ? 'theme__div' : ''
                      } btn border-end border-top border-bottom`}
                      style={{borderRadius: '0px 10px 10px 0px'}}
                      type='button'
                      onClick={togglePassword}
                    >
                      {passwordType === 'password' ? (
                        <i
                          className={`  bi bi-eye-slash  ${theme === 'dark' ? 'white__text' : ''}`}
                        ></i>
                      ) : (
                        <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : ''}`}></i>
                      )}
                    </button>
                  </div>
                </div>

                {formik.touched.password && formik.errors.password ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.password}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='font-italic' style={{color: 'gray'}}>
                        Please use 12 or more characters with a mix of letters, numbers and any of
                        the following special characters: !,@,~,$,%,^ or *
                      </span>
                    </div>
                  </div>
                )}
              </div>
                  <div className='col-xl-12 my-7'>
              <label
                className={`class="form-label fw-bold ${
                  theme === 'dark' ? 'white__text' : 'text-dark'
                } fs-6`}
              >
                Name your first board
              </label>
              <input
              placeholder='Enter Board Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('boardName')}
              className={clsx(
                `form-control form-control-lg form-control-solid  ${
                  theme === 'dark' ? 'dark__theme__input' : ''
                }  italics-placeholder`,
                {
                  'is-invalid': formik.touched.boardName && formik.errors.boardName,
                },
                {
                  'is-valid': formik.touched.boardName && !formik.errors.boardName,
                }
              )}
            />
            {formik.touched.boardName && formik.errors.boardName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='text-danger'>
                    {formik.errors.boardName}
                  </span>
                </div>
              </div>
            )}
             
                  </div>
                  <div className='col-xl-12 my-3'>
            <label
              className={`class="form-label fw-bold ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } fs-6`}
            >
              Name your first page
            </label>
          <input
            placeholder='Enter Page Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('pageTitle')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              }  italics-placeholder`,
              {
                'is-invalid': formik.touched.pageTitle && formik.errors.pageTitle,
              },
              {
                'is-valid': formik.touched.pageTitle && !formik.errors.pageTitle,
              }
            )}
          />
          {formik.touched.pageTitle && formik.errors.pageTitle && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.pageTitle}
                </span>
              </div>
            </div>
          )}
           <div className='fv-plugins-message-container py-2'>
            <div className='fv-help-block'>
              <span role='alert' className='font-italic' style={{color: 'gray'}}>
              To help you get started quickly, we&lsquo;ve created your first board upon sign-up.
              You can keep the default name &quot;b4igo&quot; or change it to something that reflects
              your current idea or project. If you&lsquo;re unsure of what to name your first board,
              you can always rename it later.
              <br/>
              <br/>
              Additionally, we&lsquo;ve set up the first two columns in the tasks view as a template
              for column names and colors. Feel free to customize these column names to
              better fit your style and project needs.
              <br/>
              <br/>
              This board will be set as your default. While Adding notes or tasks from the Home Screen, it will automatically appear in Board field. You can switch to a different board while creating the note or task, or move it later if needed.
              <br />
              To change your default board, go to your User Profile Settings via the Profile icon in the top right corner after logging in.
              </span>

            </div>
        </div>
            </div>
              <div className='text-end mt-3'>
                <span
                  className={`${
                    !formik.errors.name && !formik.errors.password && !formik.errors.email &&!formik.errors.boardName
                      ? 'cursor-pointer border-primary text-primary'
                      : 'border-secondary text-secondary'
                  } border-bottom fs-3`}
                  onClick={() => {
                    if (formik.getFieldProps('companyEmail').value === '') {
                      formik.setFieldValue('companyEmail', formik.getFieldProps('email').value)
                    }
                    // formik.setFieldValue("companyEmail", formik.getFieldProps('email').value)
                    if (!formik.errors.name && !formik.errors.boardName && !formik.errors.password && !formik.errors.email)
                      handleNextBtn('company')
                  }}
                >
                  Next
                </span>
              </div>
            </div>
          </Tab.Pane>

          <Tab.Pane className='bg-white' eventKey='company'>
            <div className={`row fv-row mt-5   ${theme === 'dark' ? 'theme__div' : ''} `}>
              <div className='col-xl-12 my-3'>
                <label
                  className={`form-label fw-bold ${
                    theme === 'dark' ? 'white__text' : 'text-dark'
                  } fs-6`}
                >
                  The name of your company / organisation
                </label>
                <input
                  placeholder='Company / Organisation name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('companyName')}
                  className={clsx(
                    `form-control form-control-lg form-control-solid  ${
                      theme === 'dark' ? 'dark__theme__input' : ''
                    }  italics-placeholder`,
                    {
                      'is-invalid': formik.touched.companyName && formik.errors.companyName,
                    },
                    {
                      'is-valid': formik.touched.companyName && !formik.errors.companyName,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.companyName}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-xl-12 my-3'>
                <label
                  className={`form-label fw-bold ${
                    theme === 'dark' ? 'white__text' : 'text-dark'
                  } fs-6 mb-0`}
                >
                  Company / Organisation Email
                </label>
                <input
                  placeholder='Company / Organisation email'
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('companyEmail')}
                  className={clsx(
                    `form-control form-control-lg form-control-solid  ${
                      theme === 'dark' ? 'dark__theme__input' : ''
                    }  italics-placeholder`,
                    {'is-invalid': formik.touched.companyEmail && formik.errors.companyEmail},
                    {
                      'is-valid': formik.touched.companyEmail && !formik.errors.companyEmail,
                    }
                  )}
                />
                {formik.touched.companyEmail && formik.errors.companyEmail && (
                  <div className='fv-plugins-message-container mt-4'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.companyEmail}
                      </span>
                    </div>
                  </div>
                )}

                {currentTier && currentTier === 'Introductory' ? (
                  <React.Fragment>
                    <div className='fv-plugins-message-container mt-2'>
                      <div className='fv-help-block'>
                        <span role='alert' className='font-italic' style={{color: 'gray'}}>
                          Already have a team, or people that you would like to create a team with
                          and get started right away? Invite three of them to join you right from
                          the start? If not, or wish to add more people you can do so in the Manage
                          Account section once you have logged in and selected a plan.
                        </span>
                      </div>
                    </div>
                    <div className='col-xl-12 my-3' style={{marginTop: '10px'}}>
                      <input
                        placeholder='Email address'
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('licenseEmail1')}
                        className={clsx(
                          `form-control form-control-lg form-control-solid  ${
                            theme === 'dark' ? 'dark__theme__input' : ''
                          } mb-2 italics-placeholder`
                        )}
                      />
                      {formik.touched.licenseEmail1 && formik.errors.licenseEmail1 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.licenseEmail1}
                            </span>
                          </div>
                        </div>
                      )}
                      <input
                        placeholder='Email address'
                        disabled={!formik.errors.licenseEmail1 ? false : true}
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('licenseEmail2')}
                        className={clsx(
                          `form-control form-control-lg form-control-solid  ${
                            theme === 'dark' ? 'dark__theme__input' : ''
                          } mb-2 italics-placeholder`
                        )}
                      />
                      {formik.touched.licenseEmail2 && formik.errors.licenseEmail2 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.licenseEmail2}
                            </span>
                          </div>
                        </div>
                      )}
                      <input
                        placeholder='Email address'
                        type='email'
                        autoComplete='off'
                        disabled={
                          !formik.errors.licenseEmail1 && !formik.errors.licenseEmail2
                            ? false
                            : true
                        }
                        {...formik.getFieldProps('licenseEmail3')}
                        className={clsx(
                          `form-control form-control-lg form-control-solid  ${
                            theme === 'dark' ? 'dark__theme__input' : ''
                          } mb-2 italics-placeholder`
                        )}
                      />
                      {formik.touched.licenseEmail3 && formik.errors.licenseEmail3 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.licenseEmail3}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='text-center ' style={{marginTop: '20px'}}>
                      <button
                        onClick={() => {
                          setSignUpLoading(true)
                          formik.handleSubmit()
                        }}
                        type='button'
                        //  id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 my-2'
                        disabled={formik.isSubmitting || !formik.isValid || licenseLoading}
                      >
                        {!signUpLoading && (
                          <span className='indicator-label'>Signup for Introductory Offer</span>
                        )}
                        {signUpLoading && <span className='indicator-label'>Loading...</span>}
                      </button>
                    </div>
                  </React.Fragment>
                ) : (
                  <div>
                    <div className='fv-plugins-message-container my-2'>
                      <div className='fv-help-block'>
                        <span role='alert' className='font-italic' style={{color: 'gray'}}>
                          {/* Or have more than 3 people,  */}
                          Select a plan that works for you
                          {/* and click on the
                        invite more people button below */}
                        </span>
                      </div>
                    </div>
                    <Pricing setActiveCard={setActiveCard} activeCard={activeCard} />

                    <div className='text-center'>
                      <button
                        type='button'
                        onClick={() => {
                          dispatch(setRedirectTo('/manage-account/licenses'))

                          formik.handleSubmit()
                        }}
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={
                          (formik.isSubmitting || !formik.isValid) &&
                          (activeCard !== 1 || activeCard !== 0)
                        }

                        // disabled={formik.isSubmitting || !formik.isValid && activeCard === null}
                        // disabled={activeCard === 0 || activeCard === 1 ? false : true }
                      >
                        {!licenseLoading && <span className='indicator-label'>Signup</span>}
                        {licenseLoading && <span className='indicator-label'>Loading...</span>}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <CustomAlert
                  variant={'danger'}
                  alertMessage={errorMsgRegistration}
                  setAlertMessage={setErrorMsgRegistration}
                  time={5000}
                />
              </div>

              <div className='my-3'>
                <CustomAlert
                  variant={'info'}
                  alertMessage={popUpMsg}
                  setAlertMessage={setPopUpMsg}
                  time={5000}
                />
              </div>

              <div className='my-3'>
                {isAlreadyMember.map((item, index) => (
                  <CustomAlert
                    key={index}
                    variant={'danger'}
                    alertMessage={item.invite}
                    setAlertMessage={(newMessage) => {
                      setIsAlreadyMember((prevStatus) => {
                        const newState: any = [...prevStatus]
                        newState[index].invite = newMessage
                        return newState
                      })
                    }}
                    time={8000}
                  />
                ))}
              </div>

              <div className='d-flex justify-content-between'>
                <span
                  className='cursor-pointer text-primary border-bottom border-primary fs-4'
                  onClick={() => setTabName('info')}
                >
                  Back
                </span>
              </div>
            </div>
          </Tab.Pane>
          {/* <Tab.Pane className='bg-white' eventKey='plan'>
            
          </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </form>
  )
}

export default CompanyForm

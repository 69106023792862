import {useState} from 'react'

const useFileUploader = ():any => {
  const [selectedFile, setSelectedFile] = useState<any>([])
  const [fileErrorMsg, setFileErrorMsg] = useState('')
  const selectFileHandler = (fileList: any) => {
    const files = Array.from(fileList);
    const validFiles = files.filter(
      (file: any) =>
        file.size <= 25000000 &&
        !file.name.toLowerCase().endsWith('.html') &&
        !file.name.toLowerCase().endsWith('.mhtml')
    );

    const invalidFiles = files.filter((file: any) => 
      file.size > 25000000
    );
    const invalidFilesExt = files.filter(
      (file: any) =>
        file.name.toLowerCase().endsWith('.html') ||
        file.name.toLowerCase().endsWith('.mhtml')
    );
    if (invalidFilesExt.length > 0) {
      setFileErrorMsg('Does not support this extension');
    }
    if (invalidFiles.length > 0) {
      setFileErrorMsg('Please attach files less than 25MB!');
    }
    setSelectedFile((prevFiles:any) => [...prevFiles, ...validFiles]);
  }

  const deleteFileHandler = (name: string) => {
    const files = selectedFile.filter((item: any) => item.name !== name)
    setSelectedFile(files)
  }

  return {selectedFile, setSelectedFile, fileErrorMsg, selectFileHandler, deleteFileHandler,setFileErrorMsg}
}

export default useFileUploader

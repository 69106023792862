import Modal from 'react-bootstrap/Modal'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Button} from 'react-bootstrap'
import {useEmailVerification} from '../../hooks'
import {useTheme} from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function AccountLockModal({accountLock, handleClose, userData}: any) {
  const {theme} = useTheme()
  //eslint-disable-next-line
  const {emailVerificationHandler, isEmailSent, debounce, isLoading} = useEmailVerification()
  return (
    <>
      <Modal
        contentClassName={theme === 'dark' ? 'theme__div' : ''}
        show={accountLock}
        onHide={handleClose}
        size='lg'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'theme__div' : ''}>
          <div className='py-5 px-3 mx-auto text-center'>
            <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
                className='h-50px my-5 me-15'
              />
            </a>
            <p className={`p-3 fs-5 ${theme === 'dark' ? 'white__text' : ''}`}>
              It&apos;s been 24hrs! Sorry to do this to you, but we need you to verify your email
              Once you have done this, you can access your account again. If you don&apos;t see the
              email in your inbox (or spam!), please click on the Resend Verification Email button
              below. Thanks and again, sorry to do this to you!
            </p>
          </div>

          <Modal.Footer className='d-flex justify-content-center'>
            <Button
              variant='primary'
              disabled={isEmailSent.length > 0 || isLoading}
              onClick={() => {
                if (!debounce)
                  emailVerificationHandler({
                    email: userData.user.email,
                    userId: userData.user.id,
                    fullName: userData.user.fullName,
                    chosenSubscriptionPlan: userData.user.chosenSubscriptionPlan || 'Introductory',
                  })
              }}
            >
              {isEmailSent.length > 0 ? isEmailSent : 'Resend Verification Email'}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AccountLockModal

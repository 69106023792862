import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
// type Props = 
// {
//   className?: string
//   path: string
//   svgClassName?: string
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const KTSVG= ({className = '', path, svgClassName = 'mh-50px'}:any) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export {KTSVG}

import {FC} from 'react'

const NoInternet: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Network Not Found</h1>

      <div className='tablet_font fw-bold fs-3 text-gray-400 mb-15'>
        It looks like you&apos;re not connected to the internet. Please check your connection and
        try again.
        <br />
      </div>
    </>
  )
}

export {NoInternet}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const createBoard = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/board`, data)
}

export const getBoardDescriptionById = async (boardId: string, userId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL}/board/${boardId}?userId=${userId}`)
}

export const updateBoardById = async (data: any, userId: string, boardId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/board/${boardId}?userId=${userId}`,
    data
  )
}

export const updateBoardName = async (data: any, boardId: string, userId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/board/${boardId}?userId=${userId}`,
    data
  )
}

export const getBoard = async (boardId: string, userId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL}/board/${boardId}?userId=${userId}`)
}

export const getAllBoardByUserId = async (userId: string,orgId?:any, boardFilter = true , limit?: number | string , page?:any) => {
  return await apiService.get(
   `${process.env.REACT_APP_BACKEND_URL_V3}/board/owner/${userId}?${orgId ? `organizationId=${orgId}&` : ''}isMyBoard=${boardFilter}&limit=${limit ||9}&page=${page}`
  )
}

export const changeBoardStatus = async (status: string, boardId: string) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL}/board/change/status/${boardId}`, {
    status,
  })
}

export const getAllArchivedBoardByUserId = async (userId: string ,orgId:string, limit?: number | string , page = 1 ) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/board/archived/${userId}?${orgId ? `organizationId=${orgId}&` : ''}limit=${limit || 9}&page=${page}`)
}

export const deleteBoard = async (boardId: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL}/board/${boardId}`)
}

export const getBoardPersonById = async (boardId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/board/person/${boardId}`)
}

export const deletePersonFromBoard = async (boardId: any, adminId: string, email: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V2}/board/person/${boardId}?adminId=${adminId}&email=${email}`
  )
}

export const changeBoardPersonRoleById = async (data: any, boardPersonId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/board/person/change/role/${boardPersonId}`,
    data
  )
}

export const getBoardPersonInvitees = async (boardId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/board/person/invitees/${boardId}`)
}

export const getAllBoards = async (userId: string,orgId:any) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/board/allBoard/${userId}${orgId?"?organizationId="+orgId:""}`)
}


export const updateBoardStatusSwitch = async (data:any,userId: string) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V3}/user/change/home/active/${userId}`,data)
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cryptoEncrypt } from '../helper-functions/encryption'
import apiService from './index'


export const deleteProfile = async (data: any, userId: string) => {
 
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/user/delete/profile/${userId}`,
    { data: cryptoEncrypt(data) }
  )
}
export const profileRecoveryVerification = async (data: any) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/user/profile-recovery/verification?requested=true`,
    data
  )
}
export const profileRecovery = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL}/user/profile-recovery`,  { data: cryptoEncrypt(data) })
}

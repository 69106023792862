import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { UserEditModalFormWrapper } from './UserEditModalFormWrapper'
import { UserEditModalHeader } from './UserEditModalHeader'
import { useTheme } from '../../../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UserEditModal = ({ membersData, getMembersHandler, sendWebSocket, isPage = false,show,setShow }: any) => {
  const { theme } = useTheme()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleClose = () => {
      setShow(!show) 
  }

  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
      className=' '
      size={`xl`}
    >
      <UserEditModalHeader handleClose={handleClose} />
      <Modal.Body
        className={`mx-5 mx-xl-15 my-7  ${'overflow-y-scroll'}`}
        style={{ overflowY: 'scroll', maxHeight: '550px' }}
      >
        <UserEditModalFormWrapper
          membersData={membersData}
          getMembersHandler={getMembersHandler}
          sendWebSocket={sendWebSocket}
          handleClose={handleClose}
          isPage={isPage}
        />
      </Modal.Body>
    </Modal>
  )
}

export default UserEditModal

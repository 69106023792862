import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllPagesByBoardId, getPagesByUserId } from '../../services/pages'

const initialState: any = {
    pagesByBoardId: [],
    pagesByLoggedInUser: [],
    loading: false,
}

export const fetchAllPagesByBoardId: any = createAsyncThunk(
    'pageSlice/fetchAllPagesByBoardId',
    async ({ boardId }: { boardId: string }) => {
        try {
            const response = await getAllPagesByBoardId(boardId)
            return response.data.data.pages
        } catch (error) {
            return error
        }
    }
)
export const fetchAllPagesByLoggedInUser: any = createAsyncThunk(
    'pageSlice/fetchAllPagesByLoggedInUser',
    async ({ id }: { id: string }) => {
        try {
            const response = await getPagesByUserId(id)
            return response.data.data.pages.map((item: any) => item.page);
        } catch (error) {
            return error
        }
    }
)
export const pageSlice = createSlice({
    name: 'pageSlice',
    initialState,
    reducers: {
        addPageInLoggedInUser: (state, action) => {
            state.pagesByLoggedInUser.push(action.payload);
        },
        editPageInLoggedInUser: (state, action) => {
            const existingPageInBoard = state.pagesByBoardId.find((page: any) => page.id === action.payload.id);
            const existingPageInLoggedInuser = state.pagesByLoggedInUser.find((page: any) => page.id === action.payload.id);
            if (existingPageInBoard) {
                Object.assign(existingPageInBoard, action.payload);
            }
            if (existingPageInLoggedInuser) {
                Object.assign(existingPageInLoggedInuser, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllPagesByBoardId.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllPagesByBoardId.fulfilled, (state: any, action) => {
            state.pagesByBoardId = action.payload
            state.loading = false
        })
        builder.addCase(fetchAllPagesByBoardId.rejected, (state) => {
            state.loading = false
            // state.errors = action.payload
        })
        builder.addCase(fetchAllPagesByLoggedInUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllPagesByLoggedInUser.fulfilled, (state: any, action) => {
            state.pagesByLoggedInUser = action.payload
            state.loading = false
        })
        builder.addCase(fetchAllPagesByLoggedInUser.rejected, (state) => {
            state.loading = false
            // state.errors = action.payload
        })
    }
})
export const { addPageInLoggedInUser, editPageInLoggedInUser } = pageSlice.actions
export default pageSlice.reducer
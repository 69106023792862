import React, {useEffect, useState} from 'react'
import {getCardDescriptionById} from '../services/cards'

const useGetDescription = (
  id: string
): {
  fetchDescription: () => void
  cardDescription: null | string
  setCardDescription: React.Dispatch<React.SetStateAction<null | string>>
  isCardDescriptionLoading: boolean
  cardIdForDesc:null | string
} => {
  const [cardDescription, setCardDescription] = useState<null | string>(null)
  const [cardIdForDesc, setCardIdForDesc] = useState<null | string>(null)
  const [isCardDescriptionLoading, setCardDescriptionLoading] = useState<boolean>(false)

  const fetchDescription = async () => {
    try {
      setCardDescriptionLoading(true)
      const res = await getCardDescriptionById(id)
      const descData = res.data.data.card
      if (descData) {
        setCardDescription(descData.description)
        setCardIdForDesc(descData.id)
      }
    } catch (error: any) {
      return error
    } finally {
      setCardDescriptionLoading(false)
    }
  }

  useEffect(() => {
    fetchDescription()
    //eslint-disable-next-line
  }, [id])

  return {cardDescription, isCardDescriptionLoading, setCardDescription,fetchDescription,cardIdForDesc}
}

export default useGetDescription

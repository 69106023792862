import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  isInviteAccepted: false,
}

export const inviteSlice = createSlice({
  name: 'isInviteAccepted',
  initialState,
  reducers: {
    setIsInviteAccepted: (state, action) => {
      state.isInviteAccepted = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setIsInviteAccepted} = inviteSlice.actions
export default inviteSlice.reducer

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'

import RecoverAccountForm from '../../../components/Forms/RecoverAccountForm'
import { resetPassword } from '../../../services/password'
import { decodeInvitation } from '../../../services/invitation'
import * as Yup from 'yup'
//eslint-disable-next-line
const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/
const passValidations = Yup.object().shape({
  password: Yup.string()
      .required('Password is required.')
      .trim()
      .matches(passwordReg, 'Please use 12 or more characters with a mix of letters, numbers and any of the following special characters: !,@,~,$,%,^ or *'),
  confirmPassword: Yup.string()
      .required('Confirm password is required.')
      .trim().oneOf([Yup.ref("password"), null], "Confirm password must match with password"),
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

export function ResetPassword(): React.JSX.Element {
  const [loading, setLoading] = useState(false)
  const [decodedData, setDecodedData] = useState('')
  const [popUpMsg, setPopUpMsg] = useState('')
  const navigate = useNavigate()
  const urlAddress = window.location.search
  const token = urlAddress.slice(7)


  const decodeEmail = async () => {
    try {
      const decodedResponse=await decodeInvitation({ token: token, usage: 'resetPassword' })
      setDecodedData(decodedResponse.data.decodeToken.resetType)
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg === "jwt expired") {
        navigate('/error/404')
      }
    }
  }
  useEffect(() => {
      decodeEmail()
    //eslint-disable-next-line
  }, [])


  const formik = useFormik({
    initialValues,
    validationSchema:passValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true)
      const updatedValues = {
        ...values,
        resetType: decodedData,
        ...(decodedData === 'forced' ? { isAccountLocked: false } : {}),
      };
      try {
        const res=await resetPassword(updatedValues, token)
        setPopUpMsg(res.data.message)
        setTimeout(() => {
          navigate('/auth')
        }, 4000)
      } catch (error: any) {
        const msg = error.response.data.message
        if (msg === "jwt expired") {
          navigate('/*')
        }
      } finally {
        setLoading(false)

      }
    },
  })

  return (
    <RecoverAccountForm
      formik={formik}
      handleSubmit={formik.handleSubmit}
      title='Reset Password'
      bodyText='Enter and confirm new password to reset your old password.'
      isEmail={false}
      popUpMsg={popUpMsg}
      setPopUpMsg={setPopUpMsg}
      loading={loading}
    />
  )
}

export const priorityColors: { [key: string]: string } = {
    Critical: '#F90707',
    High: '#EC6363',
    Medium: '#F2900F',
    Low: '#13A113',
    Future: '#1334DB',
  };
  
  export const defaultColor: string = 'black';
  export const defaultWhiteColor: string = '#FFFFFF';
  
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import clsx from 'clsx'
import {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useEmailVerification, useWebSocketHook} from '../../../../app/hooks'
import {useAuth} from '../../../../app/modules/auth'
import ToastComponent from '../../../../app/components/ToastComponent'
import '../../../../app/styles/headerwrapper.css'
import {useTheme} from '../../../../app/context/ThemeContext'
import GeneralSearch from '../../../../app/components/GeneralSearch'
import { Alert } from 'react-bootstrap'
// import {useNavigate} from 'react-router-dom'
// import { createSubscription } from '../../../../app/services/subscription'
// import { webSocketOperations } from '../../../../app/constants'
// import { decodeInvitation } from '../../../../app/services/invitation'

export function HeaderWrapper(): React.JSX.Element {
  const {config, classes, attributes} = useLayout()
  const {theme} = useTheme()
  // const [data,setData] = useState<any>({})
  const {currentUser, setCurrentUser} = useAuth()
  const [isListVisible, setIsListVisible] = useState<boolean>(false)

  const { isVerified, isInvitedByOrganization,chosenSubscriptionPlan,email, id, fullName, } =
    currentUser?.data.user
  const { isEmailSent, setIsEmailSent,emailVerificationHandler, debounce } = useEmailVerification()

  const {header, aside} = config
  const {lastJsonMessage, sendJsonMessage, readyState}: any = useWebSocketHook()
  // const navigate = useNavigate()

  // let emailHref = window.location.href
  // let isEmailVerification:any=emailHref.includes('email-verification')
  // const urlAddress = window.location.search
  // const token = urlAddress.slice(7)

  // const decodeTokenHandler = async () => {
  //   try {
  //   const res = await decodeInvitation({token, usage: 'signup'})
  //   const resData = res.data.decodeToken
  //     const data = {
  //         email:resData.email,
  //         userId:resData.userId,
  //         name:resData.fullName,
  //         type: 'FreeTrial',

  //       }
  //       const resEmail = resData.email;
  //       if(resEmail !== email){

  //         // setTimeout(() => {
  //           setTimeout(() => {
  //             navigate('/error/404')

  //           }, 2000);

  //         // }, 2000);
  //           // let auth: any = localStorage.getItem('auth')
  //           // let authData = JSON.parse(auth)
  //           // if (authData) {
  //             // logout()
  //             // localStorage.clear()
  //             // window.location.reload()
  //             // dispatch(setRedirectTo('/login'))
  //       }else{
  //         setData(data)

  //       }

  // } catch (error: any) {
  //   const msg = error.response.data.message
  //   if (msg === 'jwt expired'|| msg ==="jwt must be provided") {
  //     setTimeout(() => {
  //       navigate('/error/404')

  //     }, 2000);
  //   }
  // }
  // }
  // const verificationHandler=async()=>{
  //   try {
  //       await createSubscription(data)
  //       sendJsonMessage({
  //         event: "publish",
  //         channel: id,
  //         userId: id,
  //         command: "UPDATE",
  //         operation: webSocketOperations.isUserVerified
  //       })
  //       const updateCurrentUser = {...currentUser}
  //       updateCurrentUser.data.user.isVerified = true
  //       localStorage.setItem('auth', JSON.stringify(updateCurrentUser))
  //       setCurrentUser(updateCurrentUser)

  //   } catch (error) {
  //     // navigate('/error/404')
  //     return error
  //     // return error
  //   }
  // }

  useEffect(() => {
    if (lastJsonMessage) {
      const isVerified: any = lastJsonMessage.response?.payload?.isVerified
      if (isVerified) {
        const updateCurrentUser = {...currentUser}
        updateCurrentUser.data.user.isVerified = true
        localStorage.setItem('auth', JSON.stringify(updateCurrentUser))
        setCurrentUser(updateCurrentUser)
      }
    }
    //eslint-disable-next-line
  }, [lastJsonMessage])

  // useEffect(() => {
  //   if(token.length>0){
  //     decodeTokenHandler()
  //   }
  // }, [token])

  useEffect(() => {
    if (readyState === 1) {
      sendJsonMessage({event: 'subscribe', channel: id})

      // setTimeout(() => {
      // if(data.email){
      //   verificationHandler()

      // }
      // }, 3000);
    }
  }, [readyState])
  function ResendEmailCLickHandler() {
    return (
      <span
        className='resend_link'
        onClick={() => {
          if (!debounce)
            emailVerificationHandler({
              email,
              fullName,
              userId: id,
              chosenSubscriptionPlan: chosenSubscriptionPlan,
            })
        }}
      >
        Resend Verification Email
      </span>
    )
  }
  

  return (
    <>
    <div
      id='kt_header'
      className={clsx(
        'header',
        classes.header.join(' '),
        'align-items-stretch flex-wrap',
        theme === 'dark' ? 'dark__header__div' : ''
      )}
      {...attributes.headerMenu}
    >
      {!isVerified && !isInvitedByOrganization ? (
          <Alert variant='danger' className='mb-0 w-100'>
            <span className='alter_email_verification_desktop'>
              Thank you for signing up to b4igo and welcome to our platform! A verification email
              has been sent to the email address you created your account with. After 24 hours, we
              will lock your account until you have verified, but if you can&apos;t find the email
              <ResendEmailCLickHandler />
              again.
            </span>
            <p className='alter_email_verification_tablet'>
              Verify your account! Your account will be locked after 24 hours{' '}
              <ResendEmailCLickHandler /> again.
            </p>
            <span className='alter_email_verification_mobile'>
              Verify your account! <ResendEmailCLickHandler /> again.
            </span>
          </Alert>
        ) : null}
      <div
        className={clsx(
          classes.headerContainer.join(' '),
        theme === 'dark' ? 'dark__header__div' : 'bg-white','d-flex'
        )}
      >

        {isEmailSent?.length > 0 && (
          <ToastComponent alertMessage={isEmailSent} setAlertMessage={setIsEmailSent} />
        )}
        {/* begin::Aside mobile toggle */}

        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/home' className='d-lg-none'>
              <img
                alt='Logo'
                src={theme === 'dark' ? toAbsoluteUrl('/media/logos/b4igo-logo-white.png'):toAbsoluteUrl('/media/logos/b4igo-logo.png')}

                className='h-25px'
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                src={theme === 'dark' ? toAbsoluteUrl('/media/logos/b4igo-logo-white.png'):toAbsoluteUrl('/media/logos/b4igo-logo.png')}
                className='h-25px'
              />
            </Link>
          </div>
        )}

        {/* begin::Wrapper */}
    
        <div className={`d-flex align-items-stretch justify-content-between flex-lg-grow-1 w-100 ${theme=== 'dark'?'dark__header__div':''} `}>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch w-100' id='kt_header_nav'>
              <Header />
            </div>
          )}
           
          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0 justify-content-between w-100'>
            <div></div>
          <div
            className='d-md-flex d-none align-items-stretch flex-shrink-0'
            style={{
              width: '40%',
            }}
            onMouseLeave={() => {
              //search dropdown list visible
              setIsListVisible(false)
            }}
          >
            <GeneralSearch isListVisible={isListVisible} setIsListVisible={setIsListVisible} />
          </div>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
    </>
  )
}

import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import '../../styles/HomeSearchModal.css'

import { setUpdatedItem, updateSearchData } from '../../store/slice/home-searching-slice'

import { useAuth } from '../../modules/auth'
import { useManageContacts, useUImageVideoPreview } from '../../hooks'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import EditContacts from '../../modules/user-management/users-list/user-edit-modal/EditContacts'
import ContactNotes from './ContactNotes'
import { KTSVG } from '../../../_metronic/helpers'
import EditCardForSearching from '../UpdateCard/UpdateCardForSearch'
import { BoardsList } from '../Board/BoardsList/BoardsList'
import { useWebSocketHook } from '../../hooks'
import { webSocketOperations } from '../../constants'
import ToastComponent from '../ToastComponent'
import TemplatesTable from '../Tables/TemplatesTable'
import HomeSearchInputCard from '../Filter/HomeSearchInputCardFilter'
import { TextEditor } from '../TextEditor'
import ImageModal from '../Comments/ImageModal'
import { createDesignBoard } from '../../services/design-board'
import { capitalizeEachWord, capitalizeName } from '../../helper-functions/CapitalizeName'
import CustomAlert from '../CustomAlert'
import { status } from '../../constants'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { downloadfile, getFileName } from '../../helper-functions/CapitalizeName'
import { convertDateTimeToLocal } from '../../helper-functions/TimeFormate'
import { useTheme } from '../../context/ThemeContext'
import useGetBoardDescription from '../../hooks/useGetBoardDescription'
import EditPageForSearching from '../UpdateCard/UpdatePageForSearch'

// import print from 'print-js'

function HomeSearchingModal({
  setShow,
  show,
}: {
  setShow: (val: boolean) => void
  show: boolean
}): React.JSX.Element {
  const { theme } = useTheme()
  const inputRef: any = useRef('')
  const filterRef: any = useRef('')
  const { currentUser } = useAuth()
  const { id: userId, fullName: userName } = currentUser?.data.user

  // const companyId: string = currentUser?.organizationId

  const dispatch: Dispatch<any> = useDispatch()
  const homeSearchingData = useSelector((state: any) => state.homeSearchingReducer)


  const [searchText, setSearchText] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState<string>('Pages')
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [dateFilter, setDateFilter] = useState<string>('')
  const [timeFilter, setTimeFilter] = useState<string>('')

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)

  // const [startTime, setStartTime] = useState<any>(null)

  const memoizedTab = useMemo(() => {
    return [
      { name: 'Pages', label: 'Pages' },
      { name: 'Cards', label: 'Tasks' },
      { name: 'Documents', label: 'Documents' },
      { name: 'Boards', label: 'Boards' },
      { name: 'Templates', label: 'Templates' },
      { name: 'Contacts', label: 'Contacts' },
    ]
  }, [])

  const handleClose = () => setShow(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // filterRef.current?.resetFilters()
    setSearchText(e.target.value)
    const data = [
      ...homeSearchingData.allSearchData[selectedTab.toLowerCase()][selectedTab.toLowerCase()],
    ]
    if (data?.length > 0) {
      const result = filterSearchResultLocally(data, e.target.value)
      dispatch(setUpdatedItem([...result]))
    }
  }

  const clearSearch = () => {
    setSelectedItem(null)
    setSearchText('')
    inputRef.current.value = ''
  }

  const handleClick = (tab: string) => {
    setSelectedTab(tab)
    getAllSearches(tab)
  }

  const fetchDataForTab = () => {
    const data = [
      ...homeSearchingData.allSearchData[selectedTab.toLowerCase()][selectedTab.toLowerCase()],
    ]
    if (data?.length > 0) {
      let results = data.filter((item: any) => item.due_date == dateFilter)

      if (timeFilter.length > 0 && results?.length > 0) {
        results = results?.filter((item: any) => item.due_time == timeFilter)
      }

      dispatch(setUpdatedItem([...results]))
    }
  }

  // function stripTags(html: any) {
  //   return html.replace(/<[^>]*>/g, '');
  // }
  const getSortedData = (data: any) => {
    const duplicateData = [...data]
    const sortedData = duplicateData?.sort((a: any, b: any) => {
      let titleA: any = undefined
      let titleB: any = undefined

      if (selectedTab === 'Cards') {
        titleA = a.board_name?.trim()
        titleB = b.board_name?.trim()
      } else if (selectedTab === 'Pages') {
        titleA = a.title
        titleB = b.title
      } else if (selectedTab === 'Boards') {
        titleA = a.board.name?.trim()
        titleB = b.board.name?.trim()
      } else if (selectedTab === 'Documents') {
        titleA = a
        titleB = b
      } else if (selectedTab === 'Contacts') {
        titleA = a.fullName
        titleB = b.fullName
      } else if (selectedTab === 'Templates') {
        titleA = a.name
        titleB = b.name
      }

      const isANumberA = !isNaN(titleA?.[0])
      const isANumberB = !isNaN(titleB?.[0])

      if (isANumberA && isANumberB) {
        return parseInt(titleA) - parseInt(titleB)
      }

      if (isANumberA && !isANumberB) {
        return 1
      }

      if (!isANumberA && isANumberB) {
        return -1
      }

      return titleA?.localeCompare(titleB)
    })

    return sortedData || []
  }
  const renderedLeftSection = () => {
    switch (selectedTab) {
      case 'Cards':
        return (
          <div className='list-group'>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              (getSortedData(homeSearchingData?.currentSelectedTabData) || []).map(
                (data: any, index: number) => {
                  return (
                    <span
                      key={index}
                      className={`list-group-item list-group-item-action left-section-list ${theme === 'dark' ? 'dark__search__left__sec' : ''}
                        ${theme === 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_dark' : ''}
                        ${theme !== 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_light' : ''}`}
                      aria-current='true'
                      onClick={() => {
                        setSelectedItem(data)
                      }}
                    >
                      <div className='d-flex w-300 justify-content-between'>
                        <h5 className={`mb-1  ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{data?.key?.trim()}</h5>
                      </div>
                      <div className='d-flex w-300 justify-content-between'>
                        {data?.due_date || data?.due_time ? (
                          <small>{`Due Date:  ${convertDateTimeToLocal(data?.due_date, data?.due_time)?.split(',')[0]
                            }`}</small>
                        ) : (
                          <small>{`Due Date:  --`}</small>
                        )}
                        {data?.status ? (
                          <small>{`Status:  ${status[data?.status]}`}</small>
                        ) : (
                          <small>{`Status:  --`}</small>
                        )}
                      </div>
                    </span>
                  )
                }
              )
            ) : (
              <SearchResultMessage
                searchText={searchText}
                selectedTab={selectedTab}
                dateFilter={dateFilter}
                timeFilter={timeFilter}
              />
            )}
          </div>
        )
        case 'Pages':
          return (
            <div className='list-group'>
              {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
                (getSortedData(homeSearchingData?.currentSelectedTabData) || []).map(
                  (data: any, index: number) => {
                    return (
                      <span
                        key={index}
                        className={`list-group-item list-group-item-action left-section-list ${theme === 'dark' ? 'dark__search__left__sec' : ''} 
                          ${theme === 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_dark' : ''}
                          ${theme !== 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_light' : ''}`}
                        aria-current='true'
                        onClick={() => {
                          setSelectedItem(data)
                        }}
                      >
                        <div className='d-flex w-300 justify-content-between'>
                          <h5 className={`mb-1  ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{data?.title?.trim()}</h5>
                        </div>
                        <div className='d-flex w-300 justify-content-between'>
                          <p></p>
                            <small>{`Status:  ${capitalizeName(data.status)}`}</small>
                        </div>
                      </span>
                    )
                  }
                )
              ) : (
                <SearchResultMessage
                  searchText={searchText}
                  selectedTab={selectedTab}
                  dateFilter={dateFilter}
                  timeFilter={timeFilter}
                />
              )}
            </div>
          )

      case 'Documents':
        return (
          <div className='list-group'>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              (getSortedData(homeSearchingData.currentSelectedTabData) || []).map(
                (data: any, index: number) => {
                  return (
                    <span
                      key={index}
                      className={`list-group-item list-group-item-action left-section-list ${theme === 'dark' ? 'dark__search__left__sec' : ''}
                        ${theme === 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_dark' : ''}
                        ${theme !== 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_light' : ''}`}
                      aria-current='true'
                      onClick={() => {
                        // const docs = {
                        //   uri: data,
                        // }

                        setSelectedItem(data)
                      }}
                    >
                      <div className='d-flex w-300 justify-content-between'>
                        <p className={`mb-1  ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{`Doc: ${getFileName(data)?.fileName
                          }`}</p>
                      </div>
                      {/* <small>{`URL: ${getFileName(data)?.url}`}</small> */}
                    </span>
                  )
                }
              )
            ) : (
              <SearchResultMessage
                searchText={searchText}
                selectedTab={selectedTab}
                dateFilter={dateFilter}
                timeFilter={timeFilter}
              />
            )}
          </div>
        )

      case 'Boards':
        return (
          <div className='list-group'>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              (getSortedData(homeSearchingData.currentSelectedTabData) || []).map(
                (data: any, index: number) => {
                  return (
                    <span
                      key={index}
                      className={`list-group-item list-group-item-action left-section-list rounded ${theme === 'dark' ? 'dark__search__left__sec' : ''}
                        ${theme === 'dark' && selectedItem?.board?.id === data?.board?.id ? 'selected_item_bg_dark' : ''}
                        ${theme !== 'dark' && selectedItem?.board?.id === data?.board?.id ? 'selected_item_bg_light' : ''}`}
                      aria-current='true'
                      style={{
                        height: '50px',
                        maxHeight: '74px',
                        overflow: 'hidden',
                        // backgroundColor:
                        //   data?.board?.id === selectedItem?.board?.id ? '#f5f8fa' : '',
                      }}
                      onClick={() => {
                        setSelectedItem(data)
                      }}
                    >
                      <div
                        className={`d-flex w-300 justify-content-between align-items-center`}
                        style={{ height: '100%' }}
                      >
                        <h5 className={`mb-1  ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{data?.board?.name}</h5>
                      </div>
                      {/* <div className='d-flex w-300 justify-content-between'>
                      <small>{`Status: ${capitalize(data?.board?.status)}`}</small>
                      <small>{`Created On:    ${data?.board?.createdAt?.split('T')[0]}`}</small>
                    </div> */}

                      {/* {data?.isAdmin === true && (<small>
                      {data?.board?.description && stripTags(data?.board?.description).trim() !== '' ? (
                        data?.board?.description.replace(/<[^>]+>/g, '').length > 100 ? (
                          data?.board?.description.replace(/<[^>]+>/g, '').slice(0, 100) + '...'
                        ) : (
                          data?.board?.description.replace(/<[^>]+>/g, '')
                        )
                      ) : (
                        <span>No Description</span>
                      )}
                    </small>)} */}
                    </span>
                  )
                }
              )
            ) : (
              <SearchResultMessage
                searchText={searchText}
                selectedTab={selectedTab}
                dateFilter={dateFilter}
                timeFilter={timeFilter}
              />
            )}
          </div>
        )
      case 'Templates':
        return (
          <div className='list-group'>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              (getSortedData(homeSearchingData.currentSelectedTabData) || []).map(
                (data: any, index: number) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        setSelectedItem(data)
                      }}
                      className={`list-group-item list-group-item-action left-section-list ${theme === 'dark' ? 'dark__search__left__sec' : ''}
                        ${theme === 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_dark' : ''}
                        ${theme !== 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_light' : ''}`}
                      aria-current='true'
                      style={{
                        height: '72px',
                        maxHeight: '74px',
                        overflow: 'hidden',
                        // backgroundColor: data?.id === selectedItem?.id ? '#f5f8fa' : '',
                      }}
                    >
                      <div className='d-flex w-300 justify-content-between'>
                        <h6 className={`mb-1  ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{`${data?.name}`}</h6>
                        {/* <small>{`Created On:    ${data?.createdAt?.split('T')[0]}`}</small> */}
                      </div>

                      <small>
                        {data?.description ? (
                          data?.description.replace(/<[^>]+>/g, '').length > 100 ? (
                            data?.description.replace(/<[^>]+>/g, '').slice(0, 100) + '...'
                          ) : (
                            data?.description.replace(/<[^>]+>/g, '')
                          )
                        ) : (
                          <span>No Description</span>
                        )}
                      </small>
                    </span>
                  )
                }
              )
            ) : (
              <SearchResultMessage
                searchText={searchText}
                selectedTab={selectedTab}
                dateFilter={dateFilter}
                timeFilter={timeFilter}
              />
            )}
          </div>
        )
      case 'Contacts':
        return (
          <div className='list-group'>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              (getSortedData(homeSearchingData.currentSelectedTabData) || []).map(
                (data: any, index: number) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        setSelectedItem(data)
                      }}
                      className={`list-group-item list-group-item-action left-section-list ${theme === 'dark' ? 'dark__search__left__sec' : ''}
                        ${theme === 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_dark' : ''}
                        ${theme !== 'dark' && selectedItem?.id === data?.id ? 'selected_item_bg_light' : ''}`}
                      aria-current='true'
                    >
                      <div className='d-flex w-100 justify-content-between'>
                        <h5 className={`mb-1  ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{data?.fullName}</h5>

                        <small className='text-nowrap'>{`Created On: ${data?.createdAt?.split('T')[0]
                          }`}</small>
                      </div>

                      <small className={`ellipsis-text ${theme === 'dark' ? 'white__text' : ''}`}>{data?.fullName}</small>
                    </span>
                  )
                }
              )
            ) : (
              <SearchResultMessage
                searchText={searchText}
                selectedTab={selectedTab}
                dateFilter={dateFilter}
                timeFilter={timeFilter}
              />
            )}
          </div>
        )

      default:
        return null
    }
  }

  const renderedRightSection = () => {
    switch (selectedTab) {
      case 'Templates':
        return (
          <>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              <DisplayTemplated
                currentTemplateImage={selectedItem}
                setCurrentTemplateImage={setSelectedItem}
              />
            ) : (
              <RightSideMessage icon='bi-journals' selectedTab={selectedTab} />
            )}
          </>
        )
      case 'Contacts':
        return (
          <>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              <DisplayContacts
                currentContact={selectedItem}
                allContacts={homeSearchingData.currentSelectedTabData || []}
              />
            ) : (
              <RightSideMessage icon='bi-people' selectedTab={selectedTab} />
            )}
          </>
        )
      case 'Pages':
        return (
          <>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              <DisplayPage currentPage={selectedItem} setSelectedItem={setSelectedItem} />
            ) : (
              <RightSideMessage icon='bi-credit-card-2-front' selectedTab={selectedTab} />
            )}
          </>
        )
      case 'Cards':
        return (
          <>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              <DisplayCard handleClose={handleClose} currentCard={selectedItem} setSelectedItem={setSelectedItem} />
            ) : (
              <RightSideMessage icon='bi-credit-card-2-front' selectedTab={selectedTab} />
            )}
          </>
        )

      case 'Documents':
        return (
          <>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              <>
                {selectedItem && (
                  <>
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={[
                        {
                          uri: selectedItem,
                        },
                      ]}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: true,
                        },
                      }}
                      theme={{
                        primary: '#5296d8',

                        disableThemeScrollbar: false,
                      }}
                      style={{ height: 1000 }}
                    />
                  </>
                )}
              </>
            ) : (
              // <>{selectedItem && <FileViewer fileType='docx' filePath={selectedItem} />}</>
              <RightSideMessage icon='bi-credit-card-2-front' selectedTab={selectedTab} />
            )}
          </>
        )
      case 'Boards':
        return (
          <>
            {homeSearchingData?.currentSelectedTabData?.length > 0 ? (
              <DisplayBoards
                userName={userName}
                userId={userId}
                item={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            ) : (
              <RightSideMessage icon='bi-wallet2' selectedTab={selectedTab} />
            )}
          </>
        )

      default:
        break
    }
  }

  function getAllSearches(tab: string) {
    const data = homeSearchingData.allSearchData[tab.toLowerCase()][tab.toLowerCase()]
    if (data?.length > 0) {
      dispatch(setUpdatedItem([...data]))
    }
  }

  function filterSearchResultLocally(array: any[], searchTerm: string): any[] {
    const results = []
    const searchTermLower = searchTerm.toLowerCase().trim()
    for (const obj of array) {
      let found = false

      if (selectedTab === 'Documents') {
        const value = typeof obj === 'string' ? obj.toLowerCase().trim() : obj
        if (typeof value === 'string' && value.includes(searchTermLower)) {
          found = true
        }
      } else if (selectedTab === 'Boards') {
        for (const key in obj?.board) {
          const arrayValue = obj?.board[key]
          if (Array.isArray(arrayValue)) {
            for (const item of arrayValue) {
              const value = typeof item === 'string' ? item.toLowerCase().trim() : item
              if (typeof value === 'string' && value.includes(searchTermLower)) {
                found = true
                break
              }
            }
          }

          const value =
            typeof obj?.board[key] === 'string'
              ? obj?.board[key].toLowerCase().trim()
              : obj?.board[key]
          if (typeof value === 'string' && value.includes(searchTermLower)) {
            found = true
            break
          } else if (
            (typeof value === 'boolean' || typeof value === 'number') &&
            value.toString().includes(searchTermLower)
          ) {
            found = true
            break
          }
        }
      } else {
        for (const key in obj) {
          const arrayValue = obj[key]
          if (Array.isArray(arrayValue)) {
            for (const item of arrayValue) {
              const value = typeof item === 'string' ? item.toLowerCase().trim() : item
              if (typeof value === 'string' && value.includes(searchTermLower)) {
                found = true
                break
              }
            }
          }

          const value = typeof obj[key] === 'string' ? obj[key].toLowerCase().trim() : obj[key]
          if (typeof value === 'string' && value.includes(searchTermLower)) {
            found = true
            break
          } else if (
            (typeof value === 'boolean' || typeof value === 'number') &&
            value.toString().includes(searchTermLower)
          ) {
            found = true
            break
          }
        }
      }

      if (found) {
        results.push(obj)
      }
    }
    return results
  }

  useEffect(() => {
    if ((dateFilter || timeFilter) && selectedTab) {
      fetchDataForTab()
    }

    if (
      (searchText === '' || searchText.length === 0) &&
      (dateFilter === '' || dateFilter.length === 0) &&
      (timeFilter === '' || timeFilter.length === 0)
    ) {
      getAllSearches(selectedTab)
      // dispatch(setUpdatedItem([]))
      setSelectedItem(null)
    }
  }, [searchText, dateFilter, timeFilter])

  useEffect(() => {
    if (homeSearchingData.currentSelectedTabData?.length > 0) {
      setIsDatePickerVisible(false)
      if (selectedItem === null) {
        setSelectedItem(
          getSortedData(homeSearchingData.currentSelectedTabData)[0] ||
          homeSearchingData.currentSelectedTabData[0]
        )
      } else if (selectedTab !== 'Boards') {
        const modifiedData = homeSearchingData.currentSelectedTabData?.find(
          (data: any) => data?.id === selectedItem?.id
        )
        if (modifiedData) setSelectedItem(modifiedData)
      }
    } else {
      setSelectedItem(null)
    }
  }, [homeSearchingData.currentSelectedTabData])

  useEffect(() => {
    setSearchText('')
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
    getAllSearches(selectedTab)
  }, [])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='custom-modal-size'
      size='xl'
      contentClassName={`modal-height ${theme === 'dark' ? 'theme__div' : ''}`}
      scrollable={false}
    >
      <Modal.Header
        className='d-flex justify-content-between'
        style={{
          margin: '0px',
          paddingBottom: '0px',
          paddingLeft: '0px',
        }}
      >
        <Navbar expand='lg' variant='light'>
          <Container>
            <Navbar.Toggle aria-controls='navbarLeftAlignExample' />
            <Navbar.Collapse id='navbarLeftAlignExample' className='justify-content-end'>
              <Nav className='me-auto mt-3 mb-lg-0' variant='underline'>
                {memoizedTab?.map((tab) => (
                  <Nav.Item
                    key={tab.name}
                    onClick={() => {
                      dispatch(setUpdatedItem([]))
                      setSelectedItem(null)
                      inputRef.current.value = ''
                      setSearchText('')
                      setDateFilter('')
                      setTimeFilter('')

                      handleClick(tab.name)
                    }}
                    style={{
                      color:
                        selectedTab === tab.name
                          ? 'rgb(161, 161, 167)'
                          : theme === 'dark'
                            ? '#1b9ef7'
                            : 'rgb(21, 51, 246)',
                      marginInline: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <span>{`${tab.label} (${homeSearchingData?.allSearchData[tab?.name?.toLowerCase()].length
                      })`}</span>
                  </Nav.Item>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleClose}></i>
      </Modal.Header>
      <Modal.Body className='home-searching-modal-body' style={{ overflow: 'auto' }}>
        <Row
          style={{
            maxHeight: '100vh',
            height: '100%',
          }}
        >
          <Col xs={12} lg={4} className='border-right'>
            <div
              style={{
                paddingRight: '20px',
              }}
            >
              <div>
                <div className='d-flex justify-content-center align-items-center gap-4 position-relative'>
                  <InputGroup className='mb-3'>
                    <span className='input-group-text'>
                      <i className='bi bi-search'></i>
                    </span>
                    <input
                      type='text'
                      ref={inputRef}
                      onChange={onChange}
                      placeholder={`Filter ${selectedTab}`}
                      className={`form-control form-control-lg form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`}
                      style={{ background: '#ffffff', position: 'relative' }}
                      onClick={() => {
                        setIsDatePickerVisible(false)
                      }}
                    />

                    {searchText !== '' && searchText?.length > 0 ? (
                      <i
                        style={{
                          right: '10px',
                          bottom: '4px',
                        }}
                        className={`bi bi-x cursor-pointer fs-3 position-absolute  translate-middle-y  rounded-circle  ${theme === 'dark' ? 'theme__div' : 'bg-secondary text-light'}`}
                        onClick={clearSearch}
                      ></i>
                    ) : null}
                  </InputGroup>

                  <div>
                    {selectedTab === 'Cards' && (
                      <div
                        className='dropdown-toggle-btn'
                        onClick={() => {
                          setSearchText('')
                          inputRef.current.value = ''

                          setIsDatePickerVisible(!isDatePickerVisible)
                        }}
                      >
                        <HomeSearchInputCard
                          ref={filterRef}
                          setDateFilter={setDateFilter}
                          setTimeFilter={setTimeFilter}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {(searchText || dateFilter || timeFilter) && (
                  <small
                    style={{
                      marginLeft: 5,
                    }}
                  >{`${homeSearchingData.currentSelectedTabData?.length} Filtered Results Found`}</small>
                )}
              </div>

              <div
                className='left-section '
                style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}
              >
                {homeSearchingData?.loading === true ? (
                  <span>
                    <p>Searching...</p>
                    <Skeleton count={6} height={45} />
                  </span>
                ) : (
                  renderedLeftSection()
                )}
              </div>
            </div>
          </Col>
          <Col xs={12} lg={8}>
            <>
              {selectedTab === 'Documents' && selectedItem && (
                <div className='d-flex justify-content-between align-items-center gap-3 mb-3 mt-4'>
                  <p className='ellipsis-text'>{getFileName(selectedItem)?.fileName}</p>
                  <button
                    type='button'
                    onClick={() => {
                      downloadfile(selectedItem, getFileName(selectedItem)?.fileName)
                    }}
                    className='btn btn-primary btn-sm text-nowrap'
                  >
                    <i className='bi bi-download'></i>Export
                  </button>
                  {/* <button
                    type='button'
                    onClick={() => {
                      print({ printable: selectedItem, type: 'pdf', showModal: true })
                    }}
                    className='btn btn-secondary btn-sm'
                  >
                    <i className='bi bi-printer'></i>Print
                  </button> */}
                </div>
              )}
              <div
                className='right-section'
                style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}
              >
                <div className='general-search-right-section'>{renderedRightSection()}</div>
              </div>
            </>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default HomeSearchingModal

// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
interface DisplayTemplatedProps {
  currentTemplateImage: any
  setCurrentTemplateImage: any
}

const DisplayTemplated: React.FC<DisplayTemplatedProps> = React.memo(
  ({ currentTemplateImage, setCurrentTemplateImage }) => {
    return (
      <>
        {currentTemplateImage && (
          <TemplatesTable
            isHomeSearching={true}
            setSearchesTemplate={setCurrentTemplateImage}
            searchTemplates={currentTemplateImage}
          />
        )}
      </>
    )
  }
)

DisplayTemplated.displayName = 'DisplayTemplated'

interface DisplayContactsProps {
  currentContact: any
  allContacts: any
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
const DisplayContacts: React.FC<DisplayContactsProps> = React.memo(
  ({ currentContact, allContacts }: any) => {
    const [showNote, setShowNote] = useState<boolean>(false)
    const { updateOtherContactNoteHandler } = useManageContacts()
    const { theme } = useTheme()
    return (
      <div>
        {currentContact !== null && (
          <>
            <div className='d-flex align-items-center gap-3'>
              <h6 className={theme === 'dark' ? 'white__text' : ''}>Update Note:</h6>
              <button
                onClick={() => {
                  setShowNote(true)
                }}
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm p-1' // Added p-1 for smaller padding
              >
                <KTSVG path='/media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
              </button>
            </div>
            {showNote && (
              <ContactNotes
                otherContactList={allContacts}
                handleClose={() => {
                  setShowNote(false)
                }}
                show={showNote}
                contactDetails={currentContact || null}
                updateOtherContactNoteHandler={updateOtherContactNoteHandler}
                isHomeSearching={true}
              />
            )}

            <EditContacts
              contact={currentContact || null}
              buttonTitle='Save Contact'
              otherContactList={allContacts}
              isHomeSearching={true}
            />
          </>
        )}
      </div>
    )
  }
)
DisplayContacts.displayName = 'DisplayContacts'

interface DisplayPagesProps {
  currentPage: any
  setSelectedItem: any
}
const DisplayPage: React.FC<DisplayPagesProps> = React.memo(({ currentPage, setSelectedItem }) => {
  return (
    <div>
   {currentPage && (
        <EditPageForSearching
          page={currentPage}
          setSelectedItem={setSelectedItem}
        />
      )}
    </div>
  )
})
DisplayPage.displayName = 'DisplayPage'

interface DisplayCardsProps {
  currentCard: any
  setSelectedItem: any
  handleClose: any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
const DisplayCard: React.FC<DisplayCardsProps> = React.memo(({ currentCard, setSelectedItem,handleClose }) => {

  return (
    <div>
      {currentCard && (
        <EditCardForSearching
          isBoard={false}
          cardDetails={currentCard}
          isHomeSearching={true}
          setSelectedItem={setSelectedItem}
          handleClose={handleClose}
        />
      )}
    </div>
  )
})

DisplayCard.displayName = 'DisplayCard'
interface DisplayBoardsProps {
  userId: string
  userName: string
  item: any
  setSelectedItem: any
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
const DisplayBoards: React.FC<DisplayBoardsProps> = React.memo(
  ({ item, setSelectedItem, userId, userName }) => {
    const boardId = item?.board?.id
    const dispatch: Dispatch<any> = useDispatch()
    const { theme } = useTheme()
    const { boardDescription, setBoardDescription, isBoardDescriptionLoading } = useGetBoardDescription(boardId, userId)
    const [webSocketAlert, setWebSocketAlert] = useState('')
    const [saveDescLoading, setSaveDescLoading] = useState(false)
    const [descriptionMsg, setDescriptionMsg] = useState('')
    const { sendJsonMessage, lastJsonMessage, readyState }: any = useWebSocketHook()
    const { allSearchData } = useSelector((state: any) => state.homeSearchingReducer)
    const { imageSrc, show, setShow } = useUImageVideoPreview(boardDescription)
    const webSocketOperationHandler = (operation: string, payload: any) => {
      const updateNewBoardTitle = (payload: {
        newBoardTitle: string
        boardId: string
        alert: string
      }) => {
        const { alert } = payload

        setWebSocketAlert(alert)
      }
      switch (operation) {
        case webSocketOperations.boardNameUpdate:
          updateNewBoardTitle(payload)
          break
        default:
          break
      }
    }

    function getUpdatedTitle(title: string) {
      if (title) {
        const updateBoard = { ...item, board: { ...item.board, name: title } }
        setSelectedItem(updateBoard)
        addUpdatedBoardDataIntoRedux(updateBoard)
      }
    }

    function addUpdatedBoardDataIntoRedux(updateBoard: any) {
      const searchedBoardsData = allSearchData['Boards'.toLowerCase()]['Boards'.toLowerCase()]
      if (searchedBoardsData?.length > 0) {
        const data = [...searchedBoardsData]
        const index =
          data?.length > 0 &&
          (data?.findIndex((data: any) => data?.board?.id === item?.board?.id) as number)
        if (index !== -1) {
          data[index as number] = updateBoard
          dispatch(setUpdatedItem([...data]))

          dispatch(
            updateSearchData({
              tab: 'boards',
              data: [...data],
            })
          )
        }
      }
    }

    useEffect(() => {
      if (lastJsonMessage) {
        const operation: any = lastJsonMessage.response?.output.operation
        const payload: any = lastJsonMessage.response?.output.payload
        webSocketOperationHandler(operation, payload)
      }
      //eslint-disable-next-line
    }, [lastJsonMessage])

    useEffect(() => {
      if (readyState === 1 && item) {
        sendJsonMessage({ event: 'subscribe', channel: item.board.boardId })
      }
      setBoardDescription(item?.board?.description)
      //eslint-disable-next-line
    }, [readyState, item])

    function hasOnlySpaces(str: string | null) {
      if (str) {
        const regex = /<p class="spacer">\s*<\/p>/
        return !regex.test(str)
      }
    }
    const sendWebSocket = (operation: string) => {
      const webSocketPayload: any = {
        event: 'publish',
        channel: item?.board?.id,
        userId: userId,
        command: 'UPDATE',
        operation: operation,
        payload: {
          boardId: item?.board?.boardId,
          alert: `${capitalizeEachWord(userName)} did changes in board design.`,
        },
      }
      sendJsonMessage(webSocketPayload)
    }
    const saveDescriptionHandler = async () => {
      try {
        setSaveDescLoading(true)
        const hasSpacesOnly = hasOnlySpaces(boardDescription)

        if (hasSpacesOnly) {
          const reponse = await createDesignBoard(
            {
              description: boardDescription,
            },
            userId,
            item?.board.id
          )
          const updateBoard = { ...item, board: { ...reponse.data?.data?.board } }
          setSelectedItem(updateBoard)
          addUpdatedBoardDataIntoRedux(updateBoard)

          setDescriptionMsg('Description Saved.')
          sendWebSocket(webSocketOperations.boardDesignDescriptionUpdate)
        }
      } catch (error) {
        return error
      } finally {
        setTimeout(() => {
          setSaveDescLoading(false)
        }, 3000)
      }
    }
    return (
      <div>
        {webSocketAlert?.length > 0 && (
          <ToastComponent alertMessage={webSocketAlert} setAlertMessage={setWebSocketAlert} />
        )}
        {item && (
          // eslint-disable-next-line react/prop-types
          <BoardsList
            className='card-xl-stretch mb-xl-8'
            image={theme === 'dark' ? 'abstract-2-dark.svg' : 'abstract-2.svg'}
            title={item?.board?.name}
            time={item?.board?.go_live_date}
            boardId={item?.board.id}
            isAdmin={item?.isAdmin}
            sendJsonMessage={sendJsonMessage}
            backgroundImage={item?.board?.board_design_image_url}
            textColor={item?.board?.textColor}
            status={item?.board?.status}
            isOwner={item?.isOwner}
            isHomeSearching={true}
            getUpdatedTitle={getUpdatedTitle}
          />
        )}

        {item?.isAdmin === true && (
          <>
            <div className='col-md-12 col-lg-12 col-sm-12 mt-8'>
              <h5 className='text-muted'>Description</h5>
              {isBoardDescriptionLoading ?
                <React.Fragment>
                  <Skeleton count={1} height={35} />
                  <Skeleton count={1} height={155} />
                </React.Fragment> :
                <TextEditor boardId={boardId} setEditor={setBoardDescription} editor={boardDescription} placeholder={'Write something'} />}

              <div className='d-flex justify-content-between my-2'>
                <div className='w-100'>
                  <CustomAlert
                    variant={'info'}
                    className={'w-75'}
                    alertMessage={descriptionMsg}
                    setAlertMessage={setDescriptionMsg}
                  />
                </div>
                <div className='text-end mt-2 mb-6'>
                  <button
                    disabled={saveDescLoading}
                    className='btn btn-primary'
                    type='button'
                    onClick={saveDescriptionHandler}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>

            {imageSrc && <ImageModal imageSrc={imageSrc} setShow={setShow} show={show} />}
          </>
        )}
      </div>
    )
  }
)
// eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
function SearchResultMessage({ searchText, selectedTab, dateFilter, timeFilter }: any) {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '350px' }}>
      {searchText !== '' || dateFilter !== '' || timeFilter !== ''
        ? `No ${selectedTab} Found`
        : `Search ${selectedTab} Here`}
    </div>
  )
}
DisplayBoards.displayName = 'DisplayBoards'
function RightSideMessage({ icon, selectedTab }: any) {
  const { theme } = useTheme()
  return (
    <div className='right-message-contianer d-flex justify-content-center align-items-center flex-column'>
      <i className={`bi ${icon} icon ${theme === 'dark' ? 'text-gray-600' : ''}`}></i>
      <p className='tab'>{selectedTab}</p>
    </div>
  )
}

HomeSearchingModal.displayName = 'HomeSearchingModal'
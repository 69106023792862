import {useState} from 'react'
import useWebSocket, {ReadyState} from 'react-use-websocket'

import {useAuth} from '../modules/auth'

const useWebSocketHook = () :any=> {
  const {currentUser} = useAuth()
  const {id} = currentUser?.data.user

  const [socketUrl] = useState(
    `${process.env.REACT_APP_WEBSOCKET_URL}?userid=${id}&token=${currentUser.token}`
  )
  const [socketStatus, setSocketStatus] = useState(ReadyState.CONNECTING)

  const {sendJsonMessage, lastJsonMessage, closeWebSocket, readyState}: any = useWebSocket(
    socketUrl,
    {
      onOpen: () => setSocketStatus(ReadyState.OPEN),
      onClose: () => setSocketStatus(ReadyState.CLOSED),
      onError: () => setSocketStatus(ReadyState.CLOSED),
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  )

  return {sendJsonMessage, lastJsonMessage, closeWebSocket, ReadyState, readyState,socketStatus}
}

export default useWebSocketHook

import React, {useEffect, useState} from 'react'
import {getBoardPersonById} from '../services/boards'
import {Dispatch} from '@reduxjs/toolkit'
import {useDispatch} from 'react-redux'
import {useAuth} from '../modules/auth'
import {updateRole} from '../store/slice/role-slice'

const useGetMember = (
  boardId: string
): {
  boardPerson: any
  setBoardPerson: React.Dispatch<any>
  getMembersHandler: () => Promise<unknown>
} => {
  const dispatch: Dispatch<any> = useDispatch()
  const {currentUser} = useAuth()
  const {email} = currentUser?.data.user

  const [boardPerson, setBoardPerson] = useState<any>()
  const getMembersHandler = async () => {
    try {
      const res = await getBoardPersonById(boardId)
      const resObject = res.data.data?.map((item: any) => {
        return {
          id: item.id,
          name: item.user.fullName,
          email: item.user.email,
          role: item.role.name,
        }
      })
      const roleOfBoard = resObject?.find((role: any) => role.email === email)
      dispatch(updateRole(roleOfBoard))
      setBoardPerson(roleOfBoard)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getMembersHandler()
    //eslint-disable-next-line
  }, [])

  return {boardPerson, setBoardPerson, getMembersHandler}
}

export default useGetMember

import {useEffect} from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LogoutListener = ({onLogout}: any) => {
  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === 'auth' && !event.newValue) {
        onLogout()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [onLogout])

  return null
}

export default LogoutListener

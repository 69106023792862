import {useState} from 'react'
import {getAllRoles} from '../services/user-lookup'

const useGetRoles = () :any=> {
  const [roles, setRoles] = useState<any>([])

  const getRolesHandler = async () => {
    try {
      const roleRes = await getAllRoles()
      setRoles(roleRes.data.data)
    } catch (error) {
      return error
    }
  }

  //   useEffect(() => {
  //     getRolesHandler()
  //   }, [])

  return {roles, setRoles, getRolesHandler}
}

export default useGetRoles

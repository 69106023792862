import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import DatePickerTime from 'react-datepicker';
import { getTimeDateFormate } from '../../helper-functions/TimeFormate';
import '../../styles/homesearchinputcardfilter.css';
import { useTheme } from '../../context/ThemeContext';



const HomeSearchInputCardFilter: React.FC<any> = forwardRef(({ setDateFilter, setTimeFilter }, ref) => {
  const [selectDate, setSelectDate] = useState<string>('');
  const [selectTime, setSelectTime] = useState<Date | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);

  const handleButtonClick = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    let count = filterCount;
    if (!selectDate) {
      if (count > 0) count--;
    }
    if (!selectTime) {
      if (count > 0) count--;
    }
    setFilterCount(count);
  }, [selectDate, selectTime]);


  const filterReset = () => {
    setSelectDate('');
    setSelectTime(null);
    setDateFilter('');
    setTimeFilter('');
    setFilterCount(0);
  }
  useImperativeHandle(ref, () => ({
    resetFilters: filterReset
  }));
  const {theme} = useTheme()

  return (
    <Dropdown show={showDropdown} onToggle={handleButtonClick}>
      <Dropdown.Toggle
        as={Button}
        style={{
          height: 40,
        }}
        variant='secondary'
        className={`px-2 py-1 me-1 btn-custom btn-custom-secondary`}
        onClick={handleButtonClick}
      >
        <i
          className='bi bi-calendar4-week'
          style={{
            fontSize: 18,
          }}
        ></i>
        {filterCount !== 0 && <span className='notification-circle'>{filterCount}</span>}
      </Dropdown.Toggle>

      <Dropdown.Menu className={`p-3  ${theme === 'dark' ? 'theme__div' : ''}`}>
        <div className='w-100 d-flex justify-content-center align-items-center flex-column gap-4'>
          <div>
            <label className='form-check-label mt-2' htmlFor={`date`}>
              Select Date:
            </label>
            <DatePicker
              className={`mt-1 w-100 pr-2   ${theme === 'dark' ? 'react_date_picker_dark' : 'home-search-card-date'}`}
              onChange={(date: any) => {
                setSelectDate(date)
              }}
              value={selectDate}
              id='date'
            />
          </div>
          
          <div className={`time-wrapper  ${theme === 'dark' ? 'react_date_dark' : ''}`}>

            <div>
              <label className='form-check-label mt-2' htmlFor={`time`}>
                Select Time: {!selectDate && <span> (Date is Required)</span>}
              </label>
            </div>

            <DatePickerTime
              onChange={(date: any) => {
                setSelectTime(date)
              }}
              id='time'
              disabled={selectDate === ''}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              dateFormat='h:mm aa'
              className={`date-filter  ${theme === 'dark' ? 'react_date_dark' : ''}`}
              wrapperClassName='time-Picker-search'
              selected={selectTime}
              placeholderText='Search By Time'
              
            />
          </div>
        </div>

        <div className='d-flex justify-content-center w-100 mt-4'>
          <button
            className='btn btn-light p-2 px-2 ms-2 mb-1  btn-sm w-100'
            type='button'
            onClick={() => {
              setSelectDate('')
              setSelectTime(null)
              setDateFilter('')
              setTimeFilter('')
              setFilterCount(0)
            }}
          >
            Clear
          </button>
          <button
            className='btn btn-primary btn-sm p-2 px-2 ms-2 mb-1 w-100'
            type='button'
            onClick={() => {
              let count = 0
              if (selectDate) {
                const {utcDate}: any = getTimeDateFormate(selectDate)
                setDateFilter(utcDate)
                setFilterCount((prev: number) => (prev = prev + 1))
                count++
              }

              if (selectTime) {
                const {utcTime}: any = getTimeDateFormate(selectTime)
                setTimeFilter(utcTime)
                setFilterCount((prev: number) => (prev = prev + 1))
                count++
              }

              setShowDropdown(false)
              setFilterCount(count)
            }}
          >
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default HomeSearchInputCardFilter;
HomeSearchInputCardFilter.displayName = 'HomeSearchInputCardFilter';

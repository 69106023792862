import React, { useState } from 'react'
import TemplateModal from '../../Modals/TemplateModal'
import { useAuth } from '../../../modules/auth'
import { createTemplate } from '../../../services/templates'
import { useTemplates } from '../../../hooks'
import { getAllColumn } from '../../../services/column'
import { useParams } from 'react-router-dom'
import "../../../styles/createTemplateBtn.css"


const CreateTemplate = (): React.JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const [editor, setEditor] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [isTemplateExists, setIsTemplateExists] = useState(false)
  const [debounceBtn, setDebounceBtn] = useState(false)
  const [msg, setMsg] = useState('')
  const { currentUser } = useAuth()
  const { id: userId } = currentUser?.data.user
  const { existingTemplates, getTemplateHandler } = useTemplates()
  const { boardId } = useParams()
  const closeModal = () => {
    setShowModal(false)
    setEditor('')
    setTemplateName('')
    setMsg('')
  }
  const openModal = async () => {
    await getTemplateHandler()
    setShowModal(true)
  }

  const createTemplateHandler = async () => {
    if (templateName === '' || templateName.length < 2 || templateName.length > 100) return
    setDebounceBtn(true)
    let sanitizedColumns = [];
    try {
      const columnResponse = await getAllColumn(boardId, userId)
      const columns = columnResponse.data.data
      sanitizedColumns = columns?.map((item: any) => ({
        columnName: item.name.trim(),
        color: item.color.trim(),
        position: item.position,
      }))
    } catch (error) {
      return error
    }


    const editorText = editor.replace(/<[^>]+>/g, '')
    const payload: any = {
      userId,
      name: templateName,
      description: editorText === '' ? null : editor,
      boardColumn: sanitizedColumns,
      templateType: 'individual',
      organizationId: null,

    }

    try {
      const res: any = await createTemplate(payload)
      setMsg(res.data.message)

    } catch (error) {
      return error
    } finally {
      setEditor('')
      setTemplateName('')
      setIsTemplateExists(false)
      setTimeout(() => {
        getTemplateHandler()
        setDebounceBtn(false)
        closeModal()
      }, 1000)
    }
  }

  const onNameChangeHandler = (event: any) => {
    const inputValue = event.target.value
    setTemplateName(inputValue);

    let exists = false;
    for (let i = 0; i < existingTemplates.length; i++) {
      const tempName = existingTemplates[i].name.trim()
      if (tempName === inputValue) {
        exists = true;
        break;
      }
    }

    if (!exists) {
      setIsTemplateExists(false)
      setMsg('')
    } else {
      setMsg('This template name already exists! Do you want to overwrite?')
      setIsTemplateExists(exists);
    }

  }
  const onSaveHandler = () => {
    if (templateName === '' || templateName.length < 2 || templateName.length > 100) return
    createTemplateHandler()
  }

  return (
    <>

      <button className='btn create-template-button text-nowrap' style={{
        padding: '8.5px 12px',
      
      }} onClick={openModal}>
        Create Template
      </button>
      <TemplateModal
        modalTitle="Create Board Template"
        onNameChangeHandler={onNameChangeHandler}
        createTemplateHandler={createTemplateHandler}
        isTemplateExists={isTemplateExists}
        msg={msg}
        setMsg={setMsg}
        showModal={showModal}
        closeModal={closeModal}
        onSaveHandler={onSaveHandler}
        setTemplateName={setTemplateName}
        templateName={templateName}
        setEditor={setEditor}
        editor={editor}
        saveButtonTitle="Create"
        debounceBtn={debounceBtn}
      />
    </>
  )
}

export default CreateTemplate

import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {TextEditor} from '../TextEditor'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TemplateModal = ({
  onNameChangeHandler,
  createTemplateHandler,
  isTemplateExists,
  msg,
  showModal,
  closeModal,
  onSaveHandler,
  setEditor,
  editor,
  templateName,
  modalTitle,
  saveButtonTitle,
  debounceBtn,
  setMsg,
}: any): React.JSX.Element => {
  const {theme} = useTheme()
  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={showModal}
      onHide={closeModal}
      size='lg'
    >
      <Modal.Header>
        <Modal.Title className={theme === 'dark' ? 'white__text' : ''}>{modalTitle}</Modal.Title>
        <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={closeModal}></i>
      </Modal.Header>
      <Modal.Body>
        <label
          className={`form-label fs-6 fw-bolder  ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
        >
          Name
        </label>
        <input
          onChange={onNameChangeHandler}
          value={templateName}
          placeholder='Enter Template Name'
          autoFocus
          className={`form-control form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`}
          type='text'
          autoComplete='off'
          maxLength={100}
        />
        <h6 className={`mt-6  mb-2 ${theme === 'dark' ? 'white__text' : ''}`}>Description</h6>
        <TextEditor
          placeholder={'Enter template description'}
          setEditor={setEditor}
          editor={editor}
        />
        <CustomAlert
          variant={'info'}
          className='my-3'
          alertMessage={msg}
          setAlertMessage={setMsg}
          time={1000}
        />
      </Modal.Body>
      <Modal.Footer className='my-1'>
        <Button variant='secondary' onClick={closeModal}>
          Cancel
        </Button>
        {isTemplateExists ? (
          <Button
            variant='primary'
            onClick={createTemplateHandler}
            disabled={debounceBtn || templateName === '' || templateName?.length < 2 ? true : false}
          >
            Overwrite
          </Button>
        ) : (
          <Button
            variant='primary'
            onClick={onSaveHandler}
            disabled={debounceBtn || templateName === '' || templateName?.length < 2 ? true : false}
          >
            {saveButtonTitle}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default TemplateModal


// import { useIntl } from 'react-intl'
// import { KTSVG } from '../../../helpers'
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import React from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain(): React.JSX.Element {
  // const intl = useIntl()


  const { currentUser } = useAuth()
  const { isInvitedByOrganization , role} = currentUser?.data?.user

  return (
    <>
      <AsideMenuItem
        to='/home/board'
        icon='/media/icons/duotune/aside-icons/home.svg'
        title="Home"
      />

      {role?.isGuest === true ? (
        <AsideMenuItem to='/board/other-boards' title='Other Boards' icon='/media/icons/duotune/aside-icons/other-board.svg' />
      ) : (
        <>
          <AsideMenuItem to='/board/my-boards' title='My Boards' icon='/media/icons/duotune/aside-icons/my-board.svg' />
          <AsideMenuItem to='/board/other-boards' title='Other Boards' icon='/media/icons/duotune/aside-icons/other-board.svg' />
          <AsideMenuItem to='/board/archived-boards' title='Archived Boards' icon='/media/icons/duotune/aside-icons/archieve-board.svg' />
          <AsideMenuItem
            to='/templates'
            icon='/media/icons/duotune/aside-icons/templates.svg'
            title="Templates"
          />
          <AsideMenuItem
            to='/manage-contacts'
            icon='/media/icons/duotune/communication/com005.svg'
            title="Manage Contacts"
          />
          {!isInvitedByOrganization && (
            <AsideMenuItem
              to='/manage-account'
              icon='/media/icons/duotune/aside-icons/template-2.svg'
              title="Manage Account"
            />
          )}
        </>
      )}

    </>

  )
}

// src/ThemeContext.tsx
import React, {createContext, useState, ReactNode, useContext, useEffect} from 'react'

type Theme = 'light' | 'dark'

interface ThemeContextType {
  theme: Theme
  toggleTheme: (color: Theme) => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export const useTheme = (): any => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {
  const [theme, setTheme] = useState<Theme>('light')

  const color = localStorage.getItem('theme')

  useEffect(() => {
    if (color) {
      setTheme(color as Theme)
    }
    if (color === 'dark') {
      document.body.style.backgroundColor = '#141520'
    } else {
      document.body.style.backgroundColor = '#f5f8fa'
    }
  }, [color])

  const toggleTheme = (color: Theme) => {
    localStorage.setItem('theme', color)
    setTheme(color)
  }

  return <ThemeContext.Provider value={{theme, toggleTheme}}>{children}</ThemeContext.Provider>
}

import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
    isOrganizationAccepted : false,
}

export const organizationInvitationSlice = createSlice({
  name: 'isOrganizationAccepted',
  initialState,
  reducers: {
    setIsOrganizationAccepted: (state, action) => {
      state.isOrganizationAccepted  = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setIsOrganizationAccepted} = organizationInvitationSlice.actions

export default organizationInvitationSlice.reducer

import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'
import AssignTo from '../Select/AssignTo'
import { defaultColor, defaultWhiteColor, priorityColors } from '../../utils/priorities'
import { removeDuplication } from '../../helper-functions/CapitalizeName'
import { updateColumnById } from '../../services/column'
import { longWordSlicingWithCustomLength } from '../../helper-functions/CheckString'

type Props = any

const ColumnRulesModal: React.FC<Props> = ({ columnRuleModalShow, handleRuleModalClose, options, selected, setSelected, role, handleShow, title, columnResponse, columnId, userId, boardId, setColumnsResponse }: Props) => {

    const selectedColumn = columnResponse.find((col: any) => col.id === columnId)

    useEffect(() => {
        if (columnRuleModalShow) {
            const people = selectedColumn?.board_column_people
                ? selectedColumn?.board_column_people.map((p: any) => ({
                    value: p.board_person.id,
                    label: p.board_person.user.fullName
                }))
                : [];
            setSelected(people)
        }
    }, [columnRuleModalShow])

    const [status, setStatus] = useState<string | null>(selectedColumn?.status || null);
    const [priority, setPriority] = useState<string | null>(selectedColumn?.priority || null);
    const [disableBtn, setDisableBtn] = useState<boolean>(false);

    const getOptionColor = (value: string, theme: string) => {
        if (theme === 'dark') {
            return defaultWhiteColor
        } else {
            switch (value) {
                case 'Critical':
                    return priorityColors.Critical;
                case 'High':
                    return priorityColors.High;
                case 'Medium':
                    return priorityColors.Medium;
                case 'Low':
                    return priorityColors.Low;
                case 'Future':
                    return priorityColors.Future;
                default:
                    return defaultColor;
            }
        }
    }
    const { theme } = useTheme()

    const addRulesHandler = async () => {
        setDisableBtn(true)
        try {
            const data = {
                boardId,
                status: status || null,
                priority: priority || null,
                assignees: selected?.map((item: any) => {
                    return {
                        board_person_id: item.value,
                    }
                })
            }
            await updateColumnById(data, selectedColumn?.id, userId)
            const updatedColumns = columnResponse?.map((col: any) =>
                col.id === selectedColumn.id ? {
                    ...col,
                    priority: data.priority,
                    status: data.status,
                    board_column_people: data.assignees.map((item: any) => {
                        return { id: item.board_person_id,
                            board_person_id: item.board_person_id,
                            board_person: {
                            id: item.board_person_id,
                            user: { fullName: selected?.find((sel: any) => sel.value === item.board_person_id).label }
                            },
                        }
                    })
                } : col
            )
            setColumnsResponse(updatedColumns)
        } catch (error) {
            // console.log(error)   
        } finally {
            setDisableBtn(false)
            handleRuleModalClose()
        }
    }

    return (
        <Modal contentClassName={theme === 'dark' ? 'theme__div modal__bg' : ''} show={columnRuleModalShow} onHide={handleRuleModalClose}>
            <Modal.Header >
                <Modal.Title className={`text-truncate w-100 ${theme === 'dark' ? 'white__text' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='my-auto'>Create rules for &quot;{longWordSlicingWithCustomLength(title, 15)}&quot;</p>
                        <Button
                            className={`${disableBtn && 'btn-custom-secondary '} btn-custom-secondary btn-sm me-5`}
                            onClick={addRulesHandler}
                            disabled={disableBtn}
                        >Save
                        </Button>
                    </div>
                </Modal.Title>
                <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleRuleModalClose}></i>

            </Modal.Header>
            <Modal.Body className={` ${theme === 'dark' ? 'white__text' : ''}`}>
                <div>
                    <h6 className={theme === 'dark' ? 'white__text' : ''}>Assigned to:</h6>
                    <span className={` ${theme === 'dark' ? 'white__text' : ''}`}>When a card moves to this column,assign the card to:</span>

                    <AssignTo
                        role={role}
                        page={false}
                        options={options}
                        setSelected={setSelected}
                        selected={removeDuplication(selected)} />
                </div>
                <div className='mt-5'>
                    <h6 className={theme === 'dark' ? 'white__text' : ''}>Status:</h6>
                    <span className={` ${theme === 'dark' ? 'white__text' : ''}`}>When a card moves to this column,set the status of card to:</span>

                    <select
                        className={`form-control form-control-lg form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`}
                        value={status || ''}
                        onChange={(e) => setStatus(e.target.value || null)}
                    >
                        <option className={theme === 'dark' ? 'label-color' : ''} value={''}>Select</option>
                        <option value='todo'>To Do</option>
                        <option value='in-progress'>In Progress</option>
                        <option value='done'>Done</option>
                    </select>
                </div>

                <div className='mt-5'>
                    <h6 className={theme === 'dark' ? 'white__text' : ''}>Priority:</h6>
                    <span className={` ${theme === 'dark' ? 'white__text' : ''}`}>When a card moves to this column,set the priority of card to:</span>

                    <select
                        className={`form-control form-control-lg form-control-solid ${theme === 'dark' ? 'dark__theme__input__no__color' : ''}`}
                        value={priority || ''}
                        onChange={(e) => setPriority(e.target.value || null)}
                        style={{ color: getOptionColor(columnResponse.priority, theme) }}
                    >
                        <option className={theme === 'dark' ? 'label-color' : ''} value={''}>Select</option>
                        {['Critical', 'High', 'Medium', 'Low', 'Future'].map((option, index) => (
                            <option key={index} value={option} style={{ color: getOptionColor(option, theme) }}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

            </Modal.Body>

            <Modal.Footer className='d-flex justify-content-center'>
                <div className='d-flex align-items-center cursor-pointer fs-2 gap-2' onClick={() => {
                    handleRuleModalClose()
                    handleShow()
                }}>
                    Delete &quot;{longWordSlicingWithCustomLength(title, 15)}&quot;  <i className={` bi bi-trash fs-2 p-1 mx-1 ${theme === 'dark' ? 'white__text' : 'text-black'}`}></i>
                </div>

            </Modal.Footer>
        </Modal>

    )
}

export default ColumnRulesModal

import { FC } from 'react'

const AlreadyLogin: FC = () => {
  return (
    <>
      <h3 className='fw-bolder fs-3x text-gray-700 mb-10'>It looks like you are already logged in to b4igo!</h3>
      <div className='fw-bold fs-2 text-gray-400 mb-15'>
            If not though and you are encountering this message, please email us at support@b4igo.ai and we&apos;ll get to work resolving your issue immediately!<br />
      </div>
    </>
  )
}

export { AlreadyLogin }

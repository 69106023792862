import React, {useEffect, useState} from 'react'
import {getAllColumn} from '../services/column'
import {useAuth} from '../modules/auth'
import useWebSocketHook from './useWebSocketHook'
import {webSocketOperations} from '../constants'

const useGetColumns = (boardId?: string) : {
  columnResponse: any[];
  setColumnsResponse: React.Dispatch<React.SetStateAction<any[]>>;
  fetchColumnHandler: (boardId: string) => Promise<unknown>;
  isColumnLoading: boolean;
  isColumnLoadSkeleton: boolean;
  setIsColumnLoading: React.Dispatch<React.SetStateAction<boolean>>; // Update the type here
}=> {
  const [columnResponse, setColumnsResponse] = useState<any>([])
  const [isColumnLoading, setIsColumnLoading] = useState(true)
  const [isColumnLoadSkeleton, setIsColumnLoadSkeleton] = useState(true)
  const {currentUser} = useAuth()
  const {lastJsonMessage,sendJsonMessage,readyState}: any = useWebSocketHook()
  const {id} = currentUser?.data.user
  const fetchColumnHandler = async (boardId: string) => {
    setIsColumnLoadSkeleton(true)
    try {
      const columnResponse = await getAllColumn(boardId, id)
      const columns = columnResponse.data.data
      setColumnsResponse(columns)
    } catch (error) {
      return error
    } finally {
      setIsColumnLoading(false)
      setIsColumnLoadSkeleton(false)
    }
  }

  useEffect(() => {
    if (boardId) {
      fetchColumnHandler(boardId)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (lastJsonMessage) {
      const operation: any = lastJsonMessage.response?.output.operation
      if (
        operation === webSocketOperations.columnDelete ||
        operation === webSocketOperations.columnCreate
      ) {
         if(boardId){
          fetchColumnHandler(boardId)
         }
      }
    }
    //eslint-disable-next-line
  }, [lastJsonMessage])

  useEffect(() => {
    if(readyState===1 ){
    
        sendJsonMessage({event:"subscribe",channel:boardId})      
      }
      //eslint-disable-next-line
}, [readyState])

  return {
    columnResponse,
    setColumnsResponse,
    fetchColumnHandler,
    isColumnLoading,
    isColumnLoadSkeleton,
    setIsColumnLoading,
  
}
}
export default useGetColumns

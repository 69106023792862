// type Props = {
//     boardId: string
//     boardTitle: string
//     isEditable: boolean
//     newTitle: string
//     title: string
//     setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
//     setBoardIdState: React.Dispatch<React.SetStateAction<string>>
//     setBoardTitle: React.Dispatch<React.SetStateAction<string>>
//     updateBoardTitleHandler: () => void
//     isAdmin: any
// }
import React from 'react'
import { useAuth } from '../../modules/auth'
type Props = any

const UpdateBoardTitle: React.FC<Props> = ({ isAdmin,  setIsEditable, setBoardIdState, boardId, setBoardTitle, boardTitle,  textColor, updateBoardTitleHandler, isEditable
    // , newTitle, title
}: Props) => {
    const { currentUser } = useAuth()
    const {  role } = currentUser?.data.user
    return (
        <p className='card-title fw-bolder text-muted fs-4  text-truncate'
            onClick={() => {
                if (isAdmin) {

                    setIsEditable(true);
                    setBoardIdState(boardId);
                }
            }
            }
            onBlur={updateBoardTitleHandler}
            onKeyPress={(event: any) => event.key === 'Enter' && updateBoardTitleHandler()}>
            {
                isEditable &&   role?.isGuest !== true ?
                    <input type="text" maxLength={100} onChange={(e) => setBoardTitle(e.target.value)} value={boardTitle} placeholder={"Enter Title"} autoFocus className="css-input w-100" />
                    :
                    <span style={{ color: textColor ? textColor : "" }}>{boardTitle}</span>
            }

        </p>
    )
}

export default UpdateBoardTitle
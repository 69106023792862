import {getAllCardByBoardId} from '../services/cards'

export const countCardNumbers = async (boardId: string, userId: string):Promise<any> => {
  try {
    const res = await getAllCardByBoardId(boardId, userId)
    const cardLength: number = res.data.length
    const allCards: any = res.data.data.sort((a: any, b: any) => Number(a.number) - Number(b.number))
    const cardNumber: number = allCards[cardLength - 1].number

    return {cardLength, cardNumber}
  } catch (error: any) {
    return error
  }
}

export const findAndAddCardPosition = (cardLength: number, cardPositionStatus: string): number | undefined => {
  let position
  if (cardPositionStatus === 'top') {
    if (cardLength === undefined) {
      position = -1
    } else {
      position = -(cardLength + 1)
    }
  } else if (cardPositionStatus === 'down') {
    if (cardLength === undefined) {
      position = 1
    } else {
      position = cardLength + 1
    }
  }
  return position
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const  checkErrorTypeInStripeHandler=(error:any)=>{
switch (error.type) {
    case "invalid_request_error":
        return "You made a payment with the wrong card numbers, in the wrong state, or in an invalid way."
case "connection_error":
        return "There was a network problem between your server and Stripe."
case "authentication_error":
        return "Stripe cant authenticate you with the information provided."
case "payment_error":
        return "Payment blocked for suspected fraud or Payment declined by the issuer"
case "rate_limit_error":
        return "You made too many payment calls in too short a time."

    default:
        return error.massage;
}
}

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface EditModalContextProps {
  showEditModalQuill: boolean;
  setShowEditModalQuill: (value: boolean) => void;
  card: any;
  setCard : (value : any) => void
}

const EditModalContext = createContext<EditModalContextProps | undefined>(undefined);

export const EditModalProvider: React.FC<{ children: ReactNode }> = ({ children } : {children : React.ReactNode}) : any => {
  const [showEditModalQuill, setShowEditModalQuill] = useState(false);
  const [card, setCard] = useState({})

  useEffect(()=>{
    return ()=>  setCard({})
  },[])



  return (
    <EditModalContext.Provider value={{ showEditModalQuill, setShowEditModalQuill, card, setCard }}>
      {children}
    </EditModalContext.Provider>
  );
};

export const useEditModal = () : EditModalContextProps  => {
  const context  = useContext(EditModalContext);
  if (!context) {
    throw new Error('useEditModal must be used within an EditModalProvider');
  }
  return context;
};

/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, FC} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './sub-pages/Registration'
import {ForgotPassword} from './sub-pages/ForgotPassword'
import {Login} from './sub-pages/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Invitation} from './sub-pages/Invitation'
import {ProfileRecoveryComp} from './sub-pages/ProfileRecoveryComp'
import {ResetPassword} from './sub-pages/ResetPassword'
import {VerifyEmail} from './sub-pages/VerifyEmail'
import PricingPlan from './sub-pages/PricingPlan'
import {SignupError} from '../errors/components/SignupError'
import DialoguePage from './sub-pages/DialoguePage'
import Plans from './sub-pages/Plans'
import Payment from './sub-pages/Payment'
import {AcceptAndRegister} from './sub-pages/AcceptAndRegister'
import packageInfo from '../../../../package.json'
import {GuestRegistration} from './sub-pages/GuestRegisteration'
import {useTheme} from '../../context/ThemeContext'
const AuthLayout = () => {
  const {theme} = useTheme()

  //change-theme

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  return (
    <div
      className={`d-flex ${
        theme === 'dark' ? 'dark__div' : ''
      } flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed`}
      style={
        {
          // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }
      }
    >
      <div
        className={`${
          theme === 'dark' ? 'dark__div' : ''
        } d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-8`}
      >
        <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
            className='h-50px my-5 me-15'
          />
        </a>
        <div
          className={`${
            theme === 'dark' ? 'layout__  theme__div' : ''
          } w-lg-550px w-sm-550px  rounded shadow-sm  p-lg-15 p-md-10 p-sm-7 p-5 mx-auto`}
          style={{width: '100%'}}
        >
          <Outlet />
        </div>
      </div>
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a
            href={`${process.env.REACT_APP_B4IGO_LANDING_PAGE}/#about`}
            className='text-muted text-hover-primary px-2'
          >
            About
          </a>

          <a
            href={`${process.env.REACT_APP_B4IGO_LANDING_PAGE}/#footer-contact`}
            className='text-muted text-hover-primary px-2'
          >
            Contact
          </a>
          <span className='text-muted text-hover-primary px-2'>Version: {packageInfo.version}</span>
        </div>
      </div>
    </div>
  )
}

const AuthPage: FC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='pricing-plan/:planName' element={<PricingPlan />} />
        <Route path='payment' element={<Payment />} />
        <Route path='with-plan' element={<Registration />} />
        {/* <Route path={`registration`} element={<RenderPages /> */}
        {/* } /> */}

        <Route path='/registration' element={<Registration />} />
        <Route path='/:token/registration' element={<Registration />} />
        <Route path='invite' element={<Invitation />} />
        <Route path='organization-invitation' element={<AcceptAndRegister />} />
        <Route path='/:token/guest-invitation' element={<GuestRegistration />} />
        {/* <Route path='guest-invitation' element={<AcceptAndRegister />} /> */}
        <Route path='reset-password/*' element={<ResetPassword />} />
        <Route path='profile-recovery/*' element={<ProfileRecoveryComp />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='verify-email/*' element={<VerifyEmail />} />
        <Route path='/error/signup-error' element={<SignupError />} />
        <Route index element={<Login />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route path='/email-verification' element={<Plans />} />
      <Route path='dialogue' element={<DialoguePage />} />
    </Routes>
  )
}

export {AuthPage}

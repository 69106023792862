import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  isCardUpdated: false,
}

export const lastUpdateSlice = createSlice({
  name: 'last-update',
  initialState,
  reducers: {
    setIsCardUpdated: (state, action) => {
      state.isCardUpdated = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setIsCardUpdated} = lastUpdateSlice.actions

export default lastUpdateSlice.reducer

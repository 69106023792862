import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'
import { useNavigate } from 'react-router-dom'

interface Props {
    boardTitle: string
    defaultBoardError: boolean
    setDefaultBoardError: any
}
const DefaultBoardModal: React.FC<Props> = ({ boardTitle, defaultBoardError, setDefaultBoardError }: any) => {
    const { theme } = useTheme()
    const navigate = useNavigate()

    return (
        <Modal
            contentClassName={theme === 'dark' ? 'layout__theme__div' : ''}
            show={defaultBoardError}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            size={'lg'}
        >
            <Modal.Header>
                <Modal.Title>
                    <h1 className={theme === 'dark' ? 'white__text' : ''}>Default Board!</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-start align-items-center flex-column'>
                    <h4 style={{ color: 'gray' }}>  &quot;{boardTitle}&quot; has been set as your default board. You must change your default board to be another board before can be archived. To change your default board, go to your User Profile Settings via the Profile Photo icon in the top right corner of b4igo.</h4>
                    <div className='align-self-end' style={{ marginTop: '40px' }}>
                        <div className='d-flex gap-3'>
                            <Button variant='secondary' className='btn' onClick={() => setDefaultBoardError(false)}>
                                Close
                            </Button>
                            <Button variant='primary' className='btn' onClick={() => navigate('/profile/overview')}>
                                Go to Profile Settings
                            </Button>

                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DefaultBoardModal

import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  isFirstLogin: false,
  showTour:false
}

export const tourSlice = createSlice({
  name: 'tourSlice',
  initialState,
  reducers: {
    setIsFirstLogin: (state, action) => {
      state.isFirstLogin = action.payload
    },
    setShowTour: (state, action) => {
      state.showTour = action.payload
    },
  },
})

export const {setIsFirstLogin,setShowTour} = tourSlice.actions
export default tourSlice.reducer

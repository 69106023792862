import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllColumn} from '../../services/column'

// First, create the thunk
export const fetchColumnByBoardId = createAsyncThunk(
  'column/fetchColumnByBoardId',
  async (data: any) => {
    const {boardId, userId}: any = data
    try {
      const response = await getAllColumn(boardId, userId)
      return response.data
    } catch (error) {
      return error
    }
  }
)

const initialState: any = {
  entities: {},
  loading: false,
  errors: {},
}

export const columnSlice = createSlice({
  name: 'fetchColumnByBoardId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchColumnByBoardId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchColumnByBoardId.fulfilled, (state, action) => {
      state.entities = {...action.payload}
      state.loading = false
    })
    builder.addCase(fetchColumnByBoardId.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export default columnSlice.reducer

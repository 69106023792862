export const applyColumnRules: any = (postData: any, currentCol: any) => {
    if (!currentCol) return postData;
    postData.status = currentCol.status ? currentCol.status : postData.status;
    postData.priority = currentCol.priority ? currentCol.priority : postData.priority;
    postData.position

    const columnPeople = currentCol?.board_column_people &&
        currentCol?.board_column_people.length > 0 ? currentCol.board_column_people.map((item: any) => ({
            board_person_id: item.board_person_id,
        })) : postData?.assignees.map((item: any) => item)
        
    postData.assignees = columnPeople
    return postData;
};

import { Button } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useTheme } from '../context/ThemeContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ToastComponent({ alertMessage, setAlertMessage }: any) {
    const {theme} = useTheme()

    return (
        <>
            <ToastContainer
                className="p-3 mt-20 position-fixed"
                position="top-end"
                style={{ zIndex: 8000 }}
            >
                <Toast onClose={() => setAlertMessage("")} >
                    <Toast.Header closeButton={false} className={theme === 'dark' ? "theme__div" : ''}>
                        {/* <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        /> */}
                        <strong className="me-auto fs-5 text-primary fw-bold">Alert</strong>
                        {/* <small>11 mins ago</small> */}
                        <Button variant="close" className={theme === 'dark' ? "btn-close-white" : ''} onClick={() => setAlertMessage("")} />
                    </Toast.Header>
                    <Toast.Body className={` fs-6 fw-bold ${theme === 'dark' ? "theme__div" : 'bg-white'}`}>{alertMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default ToastComponent;
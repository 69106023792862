import {useEffect, useState} from 'react'

const useUImageVideoPreview = (boardDescription: string|null):any => {
  const [showVideo, setShowVideo] = useState(false)
  const [video, setVideo] = useState('')
  const [isVideoSrcGet, setIsVideoSrcGet] = useState(false)
  // const [editor, setEditor] = useState('')
  const [imageSrc, setImageSrc] = useState('')
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleChange = () => {
      const editImg: any = document.querySelectorAll(`div.ql-editor[contenteditable="true"] img`)
      const videoIframe: any = document.querySelectorAll(
        `div.ql-editor[contenteditable="true"] iframe`
      )
      if (videoIframe.length > 0) {
        setIsVideoSrcGet(true)
      }
      for (let index = 0; index < videoIframe.length; index++) {
        videoIframe[index].addEventListener('mouseover', () => {
          const videoSrc = videoIframe[index].getAttribute('src')
          setVideo(videoSrc)
          setShowVideo(true)
        })
      }
      for (let i = 0; i < editImg.length; i++) {
        editImg[i].style.maxWidth = '20%'
        editImg[i].classList.add('m-2')
        editImg[i].classList.add('cursor-pointer')
        editImg[i].addEventListener('click', () => {
          const imageSrc: any = editImg[i].getAttribute('src')
          setImageSrc(imageSrc)
          setShow(true)
        })
      }
    }

    handleChange()
  }, [boardDescription])

  return {
    showVideo,
    setShowVideo,
    video,
    setVideo,
    isVideoSrcGet,
    setIsVideoSrcGet,
    imageSrc,
    setImageSrc,
    show,
    setShow,
    // editor,
    // setEditor,
  }
}

export default useUImageVideoPreview

import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
    license:{
        totalLicense: 0,
        assignedLicense: 0,
        availableLicense: 0,}
   
}

export const TotalLicenseSlice = createSlice({
  name: 'TotalLicenseSlice',
  initialState,
  reducers: {
    setLicenseCount: (state, action) => {
      state.license = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setLicenseCount} = TotalLicenseSlice.actions

export default TotalLicenseSlice.reducer

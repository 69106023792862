import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { capitalizeEachWord } from '../../helper-functions/CapitalizeName'
import { useTheme } from '../../context/ThemeContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Reaction = ({ reactions, setShowEmojiOverlay, showEmojiOverlay }: any) => {
    const {theme} =useTheme() 
    return (
        <>
            {reactions?.map((item: any, index: number) =>

                <OverlayTrigger
                    key={index}
                    placement="right"
                    overlay={
                        <Tooltip id={`tooltip-right`}>
                            {capitalizeEachWord(item.reactedBy) + " reacted with " + item.emoji}
                        </Tooltip>
                    }
                >
                    <span className="fs-4 ms-3 pt-2">
                        {item.emoji}
                    </span>
                </OverlayTrigger>

            )}
            <span className="text-muted fs-1 ms-3 pt-2" style={{ cursor: "pointer" }} onClick={() => setShowEmojiOverlay(!showEmojiOverlay)}>
                <i className={`bi bi-emoji-smile fs-4 ${theme === 'dark' ? 'white__text' :''}`}></i>
                <sup className={theme === 'dark' ? 'white__text' :''}>+</sup>
            </span></>
    )
}

export default Reaction
import React, {Dispatch, SetStateAction} from 'react'
import DatePicker from 'react-datepicker'
import {isLocalTimeBeforeUtc} from '../helper-functions/TimeFormate'
import '../styles/datepicker.css'
import {useTheme} from '../context/ThemeContext'

type Props = {
  setStartDate: Dispatch<SetStateAction<any>>
  startDate: any
  cardDetails?: any
  role?: string
  isPlaceholder?: boolean
  className?: string
}

const CustomDatePicker: React.FC<Props> = ({
  startDate,
  setStartDate,
  cardDetails,
  role,
  className,
}: Props) => {
  const {theme} = useTheme()
  return (
    <>
      {startDate === null ? (
        <DatePicker
          disabled={role === 'Guest'}
          onChange={(date: any) => setStartDate(date)}
          showTimeSelect
          timeFormat='h:mm aa'
          wrapperClassName={`datePicker `}
          dateFormat='MM/dd/yyyy h:mm aa'
          value={startDate}
          placeholderText='Select Date & Time'
          className={`${className} ${theme === 'dark' ? 'react_date_dark' : 'light-theme'}`}
        />
      ) : (
        <DatePicker
          disabled={role === 'Guest'}
          className={
            cardDetails !== undefined &&
            isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true
              ? `react-date-picker__inputGroup    ${
                 theme === 'dark' ? 'react_date_dark' : 'light-theme'
                } w-100 ${className}`
              : className
          }
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          showTimeSelect
          timeFormat='h:mm aa'
          wrapperClassName={`datePicker `}
          dateFormat='MM/dd/yyyy h:mm aa'
          value={startDate}
          placeholderText='Select Date & Time'
        />
      )}
    </>
  )
}

export default CustomDatePicker

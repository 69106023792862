import React, { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { Registration } from '../modules/auth/sub-pages/Registration'
import { Board } from '../modules/boards/sub-pages/Board'
import { useSelector } from 'react-redux'
import { useAuth } from '../modules/auth'
import { Templates } from '../modules/templates/Templates'
import TopBarProgress from 'react-topbar-progress-indicator'
import UsersPage from '../modules/user-management/UsersPage'
import { CreateOrganization } from '../modules/organization/CreateOrganization'
// import Plan from '../modules/billing/sub-pages/Plan'
import Plans from '../modules/auth/sub-pages/Plans'
import FeedBack from '../pages/feedback'

const PrivateRoutes: React.FC = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UpgradePage = lazy(() => import('../modules/upgrade/UpgradePage'))
  const BillingPage = lazy(() => import('../modules/billing/BillingPage'))
  const BoardPage = lazy(() => import('../modules/boards/BoardPage'))
  const Pages = lazy(() => import('../modules/pages/Pages'))
  const HomePage = lazy(() => import('../modules/home/HomePage'))
  const ManageAccountPage = lazy(() => import('../modules/manage-account/ManageAccountPage'))
  const ManageContactsPage = lazy(() => import('../modules/manage-contacts/ManageContactsPage'))
  const subscriptionStore = useSelector((state: any) => state.subscriptionReducer)
  const { isSubscription } = subscriptionStore?.isSubscription
  const { currentUser } = useAuth()
 
  const { isVerified, isInvitedByOrganization , role } = currentUser?.data.user
  const { hasOrganization } = currentUser
  const urlAddress = window.location.search
  const token = urlAddress.slice(7)
  const NavigateToHome = (): React.JSX.Element => {
    if (token.length > 0) {
      return <Navigate to={`/home?token=${token}`} />;
    } else {
      return <Navigate to="/home" />;
    }
  };
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='auth/' element={<NavigateToHome />} />
        <Route path='auth/invite' element={<NavigateToHome />} />
        <Route path='auth/organization-invitation' element={<NavigateToHome />} />
        <Route path='auth/email-verification' element={<Plans />} />
        {/* <Route path='auth/email-verification' element={<NavigateToHome />}  /> */}

        <Route path='auth/verify-email/*' element={<Navigate to='/error/AlreadyLogin' />} />
        {isSubscription === 'active' || isSubscription === undefined ? (
          <>
            <Route path='auth/registration' element={<Registration />} />
            <Route path='/board-view/board/:boardId' element={<Board />} />

            <Route
              path='home/*'
              element={
                <SuspensedView>
                  <HomePage />
                </SuspensedView>
              }
            />

            <Route
              path='board/*'
              element={
                <SuspensedView>
                  <BoardPage />
                </SuspensedView>
              }
            />
            <Route
              path='notes/:boardId'
              element={
                <SuspensedView>
                  <Pages />
                </SuspensedView>
              }
            />
            <Route
              path='account/upgrade'
              element={
                <SuspensedView>
                  <UpgradePage/>
                </SuspensedView>
              }
            />
            {role?.isGuest !== true && <Route
              path='templates/*'
              element={
                <SuspensedView>
                  <Templates />
                </SuspensedView>
              }
            />}
            {!isInvitedByOrganization && role?.isGuest !== true &&
              <Route
                path='manage-account/*'
                element={
                  <SuspensedView>
                    <ManageAccountPage />
                  </SuspensedView>
                }
              />
            }

            {/* {
      currentAccount.isCompany&& */}
           {role?.isGuest !== true && <Route
              path='manage-contacts/*'
              element={
                <SuspensedView>
                  <ManageContactsPage />
                </SuspensedView>
              }
            />}
            {/* } */}
            {
              //eslint-disable-next-line
              !hasOrganization && !isInvitedByOrganization && role?.isGuest !== true && (
                <Route
                  path='create'
                  element={
                    <SuspensedView>
                      <CreateOrganization />
                    </SuspensedView>
                  }
                />
              )
            }

            <Route
              path='profile/*'
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />

           {role?.isGuest !== true && <Route
              path='/user-management/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        ) : (
          isVerified && role?.isGuest !== true && <Route path='*' element={<Navigate to='/billing/billing-details' />} />
        )}

        {isVerified && role?.isGuest !== true && (
          <Route
            path='/billing/*'
            element={
              <SuspensedView>
                <BillingPage />
              </SuspensedView>
            }
          />
          )}
          <Route
            path='/feedback'
            element={
              <SuspensedView>
                <FeedBack />
              </SuspensedView>
            }
          />
      </Route>
    </Routes>
  )
}

interface ComponentProps {
  children: React.ReactNode
}
const SuspensedView: FC<ComponentProps> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
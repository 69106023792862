import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { getAllBoards } from '../../services/boards'

export const fetchAllBoards = createAsyncThunk(
  'board/allBoards',
  async (data:any) => {
    try {
      const response = await getAllBoards(data.userId,data.organizationId)
      const boardData = response.data.data.boards
      if(boardData.length>0){
        const filterBoard = boardData.length && boardData.filter((board : any)=> board.isGuest === false )
        return filterBoard
      }
    } catch (error: any) {
      // console.log(error)
    }
  }
)
const initialState: any = {
  allBoards : null,
  defaultBoard : null,
  isBoardLoading: false,
  errors: {},
}

export const allBoardsSlice = createSlice({
  name: 'allBoards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllBoards.pending, (state) => {
      state.isBoardLoading = true
    })
    builder.addCase(fetchAllBoards.fulfilled, (state: any, action) => {
      state.allBoards = action.payload
      const defaultBoard:any=action.payload?.find((item: any) => item.board.default)
      state.defaultBoard=defaultBoard
      state.isBoardLoading = false
    })
    builder.addCase(fetchAllBoards.rejected, (state,) => {
      state.isBoardLoading = false
    })
  }
})

export default allBoardsSlice.reducer
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'
import useVersioning from '../../hooks/useVersioning'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const VersionUpdateModal: React.FC = () => {
  const { theme } = useTheme()
  const { reload, setReload } = useVersioning()

  return (
    <Modal
      contentClassName={theme === 'dark' ? 'layout__theme__div' : ''}
      show={reload}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h1 className={theme === 'dark' ? 'white__text' : ''}>New Update!</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start align-items-center flex-column'>
          <h2 className={theme === 'dark' ? 'white__text' : ''}> New updates of the app is available. Please refresh the page.</h2>
          <br />
          <div className='version__content' >
            <div className='tags__content'>
              <h5 className={theme === 'dark' ? 'white__text' : ''}>🏷️ Task Tags & Filtering</h5>
              <ul>
                <li>Add tags to tasks just like you do with pages</li>
                <li>Filter your task view for enhanced organization</li>
                <li>Better visibility across your workspace</li>
              </ul>
            </div>
            <div className='column__content'>
              <h5 className={theme === 'dark' ? 'white__text' : ''}>⚡Smart Column Rules</h5>
              <p className='ms-3'>Set it once, apply it automatically! Configure columns to:</p>
              <ul>
                <li>Auto-assign cards to one or more people</li>
                <li>Set default priorities</li>
                <li>Update status automatically</li>
              </ul>
              <p className='ms-3'>No more manual updates for each task - let automation do the heavy lifting!</p>
            </div>
            <div className='additional__content'>
              <h5 className={theme === 'dark' ? 'white__text' : ''}>✨ Additional Improvements:</h5>
              <ul>
                <li>Tags now appear at the top of page display areas for better visibility</li>
                <li>More distinctive Add Task button for improved UX</li>
                <li>Quick title copying via the orange dropdown</li>
                <li>Enhanced task management interface</li>
              </ul>
              <p className='ms-3'>Enjoy and let us know what you think or if you have any suggestions for us.</p>
            </div>

          </div>
          <div className='align-self-end w-25' style={{ marginTop: '40px' }}>
            <Button variant='secondary' className='btn-sm' onClick={() => {
              setReload(false)
              window.location.reload()
            }}>
              <img className="w-75" src={toAbsoluteUrl('/media/logos/b4igo-go.png')} />
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VersionUpdateModal

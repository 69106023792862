import React,{useState, useEffect} from 'react'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'

import OrganizationForm from '../../components/Forms/OrganizationForm'
import { useTheme } from '../../context/ThemeContext'
import { useSelector } from 'react-redux'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CreateOrganization({setIsNavigate}:any): React.JSX.Element {
  const [loading, setLoading] = useState(false)
  const {theme} = useTheme()
  const {allRoles:roleList}: any = useSelector((state: any) => state.PersonRoleReducer)

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    // getRolesHandler()
    //eslint-disable-next-line
  }, [])

  return (
    <div className={` w-lg-100 ${theme === 'dark' ? 'theme__div' : 'bg-white'}  rounded shadow-sm  p-lg-15 p-md-10 p-sm-7 p-5 mx-auto`}>
      <div className='mb-2 text-center '>
        <h1 className={`${theme === 'dark' ? 'theme__div' : ''} `} >
          Create organization in <span className='fw-semibold'>B4i</span>
          <span className='text-primary fw-semibold'>GO</span>
        </h1>
      </div>
      <p className={`${theme === 'dark' ? 'white__text' : ''} text-muted text-center my-7`}>
        Let&apos;s get underway! Road test b4igo and if the love is there, enter the card
        details later.
      </p>

    
          <OrganizationForm
          setLoading={setLoading}
          loading={loading}
          roleList={roleList}
          setIsNavigate={setIsNavigate}
          />
      

    </div>
  )
}

/* eslint no-use-before-define: 0 */  // --> OFF

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeNullFromReminder(reminders: any): any {
    const filteredReminders = reminders?.filter((reminder: any) => {
      if (reminder?.reminderType === 'days' && reminder?.number_of_days !== null) {
        return true;
      }
      if (reminder?.reminderType === 'hours' && reminder?.number_of_hours !== null) {
        return true;
      }
  
      if (reminder?.reminderType === 'date' && reminder?.date !==  null) {
        return true;
      }
  
      if (reminder?.reminderType === 'everyday') {
        return true;
      }
      return false;
    });
  
    return filteredReminders || [];
  }
  
/* eslint no-use-before-define: 2 */  // --> ON
  
import {Button, Modal} from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CanelInvitationModal = ({disableBtn, show, handleClose, cancelInviteFunc, email}: any) => {
  const {theme} = useTheme()
  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className={`${theme === 'dark' ? 'white__text' : ''}`}>Cancel Invitation</Modal.Title>
        <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleClose}></i>
      </Modal.Header>
      <Modal.Body>
        <p className={`text-center ${theme === 'dark' ? 'white__text' : ''}`}>
          Are you sure you want to cancel the invitation for &ldquo;{email}&ldquo; from this board ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          No
        </Button>

        <Button variant='primary' onClick={cancelInviteFunc} disabled={disableBtn}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CanelInvitationModal

import {colors} from '@atlaskit/theme'

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getHomeColumnPosition} from '../../services/home-column-position'
const todo = {
  id: '1',
  name: 'To Do',
  url: 'http://adventuretime.wikia.com/wiki/Jake',
  //   avatarUrl: jakeImg,
  colors: {
    soft: colors.Y200,
    hard: colors.Y200,
  },
}

const inProgress = {
  id: '2',
  name: 'In Progress',
  url: 'http://adventuretime.wikia.com/wiki/BMO',
  //   avatarUrl: bmoImg,
  colors: {
    soft: colors.B200,
    hard: colors.G200,
  },
}

const done = {
  id: '3',
  name: 'Done',
  url: 'http://adventuretime.wikia.com/wiki/Finn',
  //   avatarUrl: finnImg,
  colors: {
    soft: colors.G200,
    hard: colors.B200,
  },
}
// First, create the thunk
export const fetchColumnPositions = createAsyncThunk(
  'board/fetchColumnPositions',
  async (userId: string) => {
    try {
      const res = await getHomeColumnPosition(userId)
      const columns = res.data.data.columns
      const orderState = []
      for (let index = 0; index < columns.length; index++) {
        if (columns[index].columnName === 'todo') {
          orderState.push(todo)
        } else if (columns[index].columnName === 'in-progress') {
          orderState.push(inProgress)
        } else if (columns[index].columnName === 'done') {
          orderState.push(done)
        }
      }
      return orderState
    } catch (error: any) {
      return error
    }
  }
)

const initialState: any = {
  entities: {
    pos: [],
  },
  loading: false,
  errors: {},
}

export const ColumnPositionSlice = createSlice({
  name: 'fetchColumnPositions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchColumnPositions.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchColumnPositions.fulfilled, (state, action) => {
      state.entities.pos = [...action.payload]
      state.loading = false
    })
    builder.addCase(fetchColumnPositions.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export default ColumnPositionSlice.reducer

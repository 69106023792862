import {useEffect, useState} from 'react'
import {createPublishableKey} from '../services/stripe-services'

const useStripeApi = ():any => {
  const [pubKey, setPubKey] = useState('')
  
  const publishableKeyHandler = async () => {
    const payload = {
      b4igoSecret: process.env.REACT_APP_B4IGO_SECRET,
    }
    try {
      const res = await createPublishableKey(payload)
      setPubKey(res.data.data)
    } catch (error) {
      return error
    }
  }

 
  useEffect(() => {
    publishableKeyHandler()
    //eslint-disable-next-line
  }, [])

  return {pubKey}
}

export default useStripeApi

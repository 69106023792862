/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'
export const createSubscription = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/subscription`, data)
}

export const changeSubscription = async (data: any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V2}/change/subscription`, data)
}

export const updateSubscription = async (data: any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V2}/subscription`, data)
}

export const getSubscriptions = async (userId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V2}/subscription/dashboard/${userId}`)
}

export const cancelSubscriptions = async (userId: string) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V2}/delete/subscription/${userId}`)
}

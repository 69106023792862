import React, {useEffect, useState} from 'react'
import { getBoardDescriptionById } from '../services/boards'

const useGetBoardDescription = (
    boardId: string,
    userId: string
): {
  fetchDescription: () => void
  boardDescription: null | string
  setBoardDescription: React.Dispatch<React.SetStateAction<null | string>>
  isBoardDescriptionLoading: boolean
} => {
  const [boardDescription, setBoardDescription] = useState<null | string>(null)
  const [isBoardDescriptionLoading, setIsBoardDescriptionLoading] = useState<boolean>(false)

  const fetchDescription = async () => {
    if(boardId){
      try {
        setIsBoardDescriptionLoading(true)
        const res = await getBoardDescriptionById(boardId, userId)
        const descData = res.data.data.board.description
          setBoardDescription(descData)
      } catch (error: any) {
        return error
      } finally {
        setIsBoardDescriptionLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchDescription()
    //eslint-disable-next-line
  }, [boardId])

  return {boardDescription, isBoardDescriptionLoading, setBoardDescription,fetchDescription}
}

export default useGetBoardDescription

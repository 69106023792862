import React, {FC, createContext, useContext} from 'react'


const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
// console.log("I18N_CONFIG_KEY", I18N_CONFIG_KEY)

type Props = {
  selectedLang: 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh'
}


const initialState: Props = {
  selectedLang: 'en',
}
//eslint-disable-next-line
interface ComponetProps {
  children : React.ReactNode
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      // console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string):void {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () :string => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC<ComponetProps> = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
import { removeSpaces } from '../helper-functions/CapitalizeName'
import apiService from './index'

export const getHomeSearchingTemplateData = async (
  userId: string,
  limit: string | number,
  query: string,
  page = 1
): Promise<any> => {
  try {
    return await apiService.get(
      `${
        process.env.REACT_APP_BACKEND_URL_V3
      }/template/${userId}?limit=${limit}&page=${page}&name=${query}`
    )
  } catch (error) {
    return error
  }
}

export const getHomeSearchingContactData = async (userId: string, query: string): Promise<any> => {
  try {
    const apiResponse = await apiService.get(
      `${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/${userId}?page=${1}&name=${query}`
    )
    const responseData = apiResponse?.data.data?.otherContact
    const response = {
      data: {
        data: [...responseData],
        lastSearches: apiResponse?.data?.lastSearches ? [...apiResponse?.data?.lastSearches] : [],
      },
    }

    return response
  } catch (error) {
    return error
  }
}

export const getHomeSearchingCards = async (
  query: string,
  due_date?: string,
  due_time?: string
): Promise<any> => {
  try {
    const apiResponse = await apiService.get(
      `${process.env.REACT_APP_BACKEND_URL_V3}/card?query=${query}&due_date=${
        due_date || null
      }&due_time=${due_time || null}`
    )

    const responseData = apiResponse?.data.data?.cards
    const response = {
      data: {
        data: [...responseData],
        lastSearches: apiResponse?.data?.lastSearches ? [...apiResponse?.data?.lastSearches] : [],
      },
    }

    return response
  } catch (error) {
    return error
  }
}

export const getAllBoardByUserId = async (userId: string, query: string | number): Promise<any> => {
  try {
    const apiResponse = await apiService.get(
      `${process.env.REACT_APP_BACKEND_URL_V3}/board/allBoard/${userId}?name=${query}`
    )
    const responseData = apiResponse?.data?.data?.boards
    const response = {
      data: {
        data: [...responseData],
        lastSearches: apiResponse?.data?.lastSearches ? [...apiResponse?.data?.lastSearches] : [],
      },
    }
    return response
  } catch (error) {
    return error
  }
}

export const getAllDocumentsByUserId = async (query: string | number): Promise<any> => {
  try {
    const apiResponse = await apiService.get(
      `${
        process.env.REACT_APP_BACKEND_URL_V3
      }/user/search/all?query=${query}&due_date=${null}&due_time=${null}`
    )
    const responseData = [
      ...apiResponse.data?.data?.documents.boards.files,
      ...apiResponse.data?.data?.documents.cards.files,
    ]
    const response = {
      data: {
        data: [...responseData],
        lastSearches: apiResponse?.data?.lastSearches ? [...apiResponse?.data?.lastSearches] : [],
      },
    }
    return response
  } catch (error) {
    return error
  }
}

export const fetchAllUserSearch = async (
  query: string,
): Promise<any> => {
  try {
    const apiResponse = await apiService.get(
      `${
        process.env.REACT_APP_BACKEND_URL_V3
      }/user/search/all?query=${query}&formatted_query=${removeSpaces(query)}&due_date=${null}&due_time=${null}`
    )

    const responseData = {
      cards: apiResponse.data?.data?.cards,
      pages: apiResponse.data?.data?.pages,
      boards: apiResponse.data?.data?.boards,
      templates: apiResponse.data?.data?.templates,
      contacts: apiResponse.data?.data?.contacts,
      documents: {
        length:
          apiResponse.data?.data?.documents.boards.length +
          apiResponse.data?.data?.documents.cards.length,
        documents: [
          ...apiResponse.data?.data?.documents.boards.files,
          ...apiResponse.data?.data?.documents.cards.files,
        ],
      },
    }

    const response = {
      data: {
        data: {...responseData},
        lastSearches: apiResponse?.data?.lastSearches ? [...apiResponse?.data?.lastSearches] : [],
      },
    }

    return response
  } catch (error) {
    return error
  }
}

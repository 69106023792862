import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  isRender: false,
}

export const isRenderSlice = createSlice({
  name: 'isRender',
  initialState,
  reducers: {
    setIsRender: (state, action) => {
      state.isRender = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setIsRender} = isRenderSlice.actions
export default isRenderSlice.reducer

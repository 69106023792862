import React,{ useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { profileRecovery } from '../../../services/delete-profile'
import RecoverAccountForm from '../../../components/Forms/RecoverAccountForm'
import { decodeInvitation } from '../../../services/invitation'
import * as Yup from 'yup'
//eslint-disable-next-line
const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/
const passValidations = Yup.object().shape({
  password: Yup.string()
      .required('Password is required.')
      .trim()
      .matches(passwordReg, 'Please use 12 or more characters with a mix of letters, numbers and any of the following special characters: !,@,~,$,%,^ or *'),
  confirmPassword: Yup.string()
      .required('Confirm password is required.')
      .trim().oneOf([Yup.ref("password"), null], "Confirm password must match with password"),
})


export function ProfileRecoveryComp(): React.JSX.Element {
  const [loading, setLoading] = useState(false)
  const [emailState, setEmailState] = useState('')
  const [popUpMsg, setPopUpMsg] = useState('')

  // const [passwordState, setPasswordState] = useState('')
  // const [confirmPasswordState, setConfirmPasswordState] = useState('')


  const navigate = useNavigate()

  const urlAddress = window.location.search
  const token = urlAddress.slice(7)

  const initialValues = {
    email: emailState,
    password: '',
    confirmPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema:passValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true)
      const updatedValues = { ...values }
      updatedValues.email = emailState
      // updatedValues.password = passwordState
      // updatedValues.confirmPassword = confirmPasswordState
      const payload = {
        email: updatedValues.email,
        newPassword: updatedValues.password,
        confirmNewPassword: updatedValues.confirmPassword,
      }
      try {
       const response= await profileRecovery(payload)
       setPopUpMsg(response.data.message)
        setTimeout(() => {
          navigate('/auth')
        }, 4000)
      } catch (error:any) {
        setPopUpMsg(error.response?.data?.message)
        setLoading(false)
        return error
      }finally{
        setTimeout(() => {
          setLoading(false)
        }, 4000)
      }
    },
  })

  useEffect(() => {
    async function decodeHandler() {
      try {
        const decodedResponse = await decodeInvitation({ token, usage: 'recoverProfile' })
        const decodedData = decodedResponse.data.decodeToken.email
        setEmailState(decodedData)
      } catch (error: any) {
        const msg = error.response.data.message
        if (msg === "jwt expired") {
          navigate('/error/404')
        }
      }
    }

    decodeHandler()
    //eslint-disable-next-line
  }, [])

  return (
    <RecoverAccountForm
      formik={formik}
      handleSubmit={formik.handleSubmit}
      title='Recover Account'
      bodyText='Enter email and confirm new password to recover your account.'
      isEmail={true}
      // passwordState={passwordState}
      // setPasswordState={setPasswordState}
      // confirmPasswordState={confirmPasswordState}
      // setConfirmPasswordState={setConfirmPasswordState}
      popUpMsg={popUpMsg}
      setPopUpMsg={setPopUpMsg}
      loading={loading}
    />
  )
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const activate2fa = async (data: any, userId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V2}/user/2fa/activation/${userId}`,
    data
  )
}
export const verify2fa = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/twilio/verify/code`, data)
}

export const verify2faCodeForUpdateProfile = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/twilio/verify/code/update`, data)
}
export const verify2faCodeForDeleteProfile = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/twilio/verify/code/delete`, data)
}

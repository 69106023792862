import { useState } from 'react'
import { useAuth } from '../modules/auth'
import { createLoadTemplate, getTemplatesByTemplateId, getTemplatesByUserId } from '../services/templates'
import { useParams } from 'react-router-dom'
import { capitalizeEachWord } from '../helper-functions/CapitalizeName'
import { getAllColumn } from '../services/column'


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTemplates = (): any =>  {
  const [existingTemplates, setExistingTemplates] = useState<any>(null)
  const [page , setPage] = useState<any>(1)
  const [totalLength , setTotalLength] = useState({
    limit:0,
    length:0,
  })
  const { currentUser } = useAuth()
  const { id, fullName } = currentUser?.data.user

  const params: any = useParams()
  const getTemplateHandler = async (limit? : string | number) => {
    try {


      const res = await getTemplatesByUserId(id ,limit, page)
      const templates = res.data?.data
      setTotalLength({
        limit : res.data?.limit,
        length: res.data?.total_length
      })
      setExistingTemplates(templates)
    } catch (error) {
      return error
    }
  }
  const getTemplateHandlerByName = async ( name: string , templateId:string ,limit? : string | number ) => {
    try {
      
      const res = await getTemplatesByUserId(id ,limit, page , name?.trim())
      const templates = res.data?.data
     
      const getUpdateTemplate = templates?.find((template : any) => template.id === templateId)
      setExistingTemplates([getUpdateTemplate])
    } catch (error) {
      return error
    }
  }

  const getTemplateByIdHandler = async (templateId: string, fetchColumn: any, columnsFromState: any) => {
    try {
      const res = await getTemplatesByTemplateId(templateId)

      const columns = res.data.data.templateColumns
      if (columns.length) {
        const templateColumns = columns.map((item: any) => ({
          created_by: capitalizeEachWord(fullName),
          last_modified_by: capitalizeEachWord(fullName),
          color: item.color.trim(),
          position: (columnsFromState.length) + item.position,
          name: item.columnName.trim(),
          boardId: params.boardId,
          version: null,
          status: null
        }))
        await createLoadTemplate({ templateColumns })
        await fetchColumn(params.boardId)
      }

    } catch (error) {
      return error
    }
  }
  const getTemplateAndLoad = async (templateId: string, boardId: string) => {
    try {

      const res = await getTemplatesByTemplateId(templateId)
      const columnResponse = await getAllColumn(boardId, id)


      const columns = res.data.data.templateColumns
      if (columns.length) {
        const templateColumns = columns.map((item: any) => ({
          created_by: capitalizeEachWord(fullName),
          last_modified_by: capitalizeEachWord(fullName),
          color: item.color.trim(),
          position: columnResponse.data?.data && columnResponse.data?.data?.length + item.position,
          name: item.columnName.trim(),
          boardId: boardId,
          version: null,
          status: null
        }))
        const response = await createLoadTemplate({ templateColumns })
       
        return response.data

      }

    } catch (error) {
      return error
    }
  }


  return {page , setPage ,  totalLength, existingTemplates, getTemplateHandler, getTemplateByIdHandler, getTemplateAndLoad , setExistingTemplates , getTemplateHandlerByName}
}

export default useTemplates

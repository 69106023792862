export const webSocketOperations = {
  cardPositionUpdate: 'CARD_POSITION_UPDATES',
  cardUpdate: 'CARD_UPDATES',
  cardCreate: 'CARD_CREATE',
  columnPositionUpdate: 'BOARD_COLUMN_POSITION_UPDATES',
  columnColorUpdate: 'BOARD_COLUMN_COLOR_UPDATES',
  columnDelete: 'BOARD_COLUMN_DELETE',
  columnCreate: 'BOARD_COLUMN_CREATE',
  boardColumnNameUpdate: 'BOARD_COLUMN_NAME_UPDATES',
  boardNameUpdate: 'BOARD_NAME_UPDATES',
  boardStatusUpdate: 'BOARD_STATUS_UPDATES',
  boardCreate: 'BOARD_CREATE',
  boardDesignDescriptionUpdate: 'BOARD_DESIGN_DESCRIPTION_UPDATES',
  boardDesignStatusUpdate: 'BOARD_DESIGN_STATUS_UPDATES',
  boardDesignTeamUpdate: 'BOARD_DESIGN_TEAM_UPDATES',
  boardDesignInviteeUpdate: 'BOARD_DESIGN_INVITEE_UPDATES',
  boardDesignInviteeDelete: 'BOARD_DESIGN_INVITEE_DELETE',
  isUserVerified: 'USER_VERIFICATION',

}

export const status: any = {
  todo: 'To Do',
  'in-progress': 'In Progress',
  done: 'Done',
}

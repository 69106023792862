import React,{ FC, useState, createContext, useContext } from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
} from '../../../../../_metronic/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

interface ComponentProps{
  children : React.ReactNode
}
const QueryRequestProvider: FC<ComponentProps> = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = (): QueryRequestContextProps => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }

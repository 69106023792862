import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  auth: {},
  userSessionTimeout  : false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload
    },
    setSession : (state , action)=>{
      state.userSessionTimeout = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {setAuth , setSession} = authSlice.actions

export default authSlice.reducer

import React, {Dispatch, SetStateAction} from 'react'
import {useTheme} from '../../context/ThemeContext'
type Props = {
  columnId: string
  defaultColor: string
  role: string
  index?: any
  setCardModalShow: Dispatch<SetStateAction<boolean>>
  setCardPositionStatus: Dispatch<SetStateAction<string>>
  setBackgroundColorState: Dispatch<SetStateAction<string>>
  setShowColor: Dispatch<SetStateAction<boolean>>
  setColumnIdState: React.Dispatch<React.SetStateAction<string>>
  handleRuleModalShow: () => void
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ColumnHeaderButtons: React.FC<Props> = ({
  role,
  setCardModalShow,
  setCardPositionStatus,
  setShowColor,
  setColumnIdState,
  columnId,
  handleRuleModalShow,
  index,defaultColor,setBackgroundColorState
}: any) => {
  const {theme} = useTheme()
  return (
    <>
      <button 
        className={`btn-custom btn-custom-secondary ${index === 1 && 'top_task_btn'} ${
          theme === 'dark' ? 'layout__theme__div' : ''
        } py-1 px-1 ${role !== 'Admin' && 'me-3'}`}
        onClick={() => {
          setCardModalShow(true)
          setCardPositionStatus('top')
        }}
      >
        <i className={`p-1 mx-0 bi bi-plus ${theme === 'dark' ? 'white__text' : ''}`}></i>
      </button>
      {role === 'Admin' && (
        <>
          {/* Add color on column header button */}
          <button
            className={`btn-custom btn-custom-secondary ${
              theme === 'dark' ? 'layout__theme__div' : ''
            } px-2 py-1 mx-1 `}
            onClick={() => {
              setShowColor(true)
              setColumnIdState(columnId)
              setBackgroundColorState(defaultColor)
            }}
          >
            <i className={`bi bi-brush-fill ${theme === 'dark' ? 'white__text' : ''} pe-0`}></i>
          </button>
          {/* delete column button */}
          <button
            className={`btn-custom btn-custom-secondary ${
              theme === 'dark' ? 'layout__theme__div' : ''
            } px-0 py-1 me-1`}
            onClick={()=>{
              handleRuleModalShow()
              setColumnIdState(columnId)
            }}
          >
            <i className={`bi bi-three-dots-vertical p-1 mx-1 ${theme === 'dark' ? 'white__text' : 'text-black'}`}></i>
          </button>
        </>
      )}
    </>
  )
}

export default ColumnHeaderButtons

import React , {useState} from 'react'
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { Droppable, Draggable } from "react-beautiful-dnd";
import QuoteItem from "./CardItem";
import { grid } from "../constants";
// import { useAuth } from '../../../modules/auth'

import Title from "./title";


const getBackgroundColor = (isDraggingOver: any, isDraggingFrom: any) => {
  if (isDraggingOver) {
    return colors.R50;
  }
  if (isDraggingFrom) {
    return colors.T50;
  }
  return colors.N30;
};

// opacity: ${({ isDropDisabled }: any) => (isDropDisabled ? 0.9 : "inherit")};
const Wrapper = styled.div`
  background-color: ${(props: any) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 100%;
  // width: 250px;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */



const InnerQuoteList = ({ setCards, fetchSortedCardHandler,
  checked,handleCloneCard,cards,allCards,
  quotes , role }: any) => {
    const highestNumber = Math.max(...(cards || []).map((obj:any) => obj.number));



  // return quotes && getCookie("sortedCards") === 'false' ? quotes.sort((a: any, b: any) => b.position - a.position).map((quote: any, index: any) => (
  return quotes && checked ? quotes.map((quote: any, index: any) => (
    <Draggable
      key={quote.id}
      draggableId={quote.id}
      index={index}
      isDragDisabled ={role === "Guest" ? true : false}
    // shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <QuoteItem
          // sortStatus={sortStatus}
          fetchSortedCardHandler={fetchSortedCardHandler}
          // fetchCardHandler={fetchCardHandler}
          key={quote.id}
          quote={quote}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
          highestNumber={highestNumber}
          handleCloneCard={handleCloneCard}
          setCards={setCards}
          cards={cards}
          allCards={allCards}
          />
      )}
    </Draggable>
  )) : quotes ? quotes.sort((a: any, b: any) => a.position - b.position).map((quote: any, index: any) => (
    <Draggable
      key={quote.id}
      draggableId={quote.id}
      index={index}
      isDragDisabled ={role === "Guest" ? true : false}
    // shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <QuoteItem
          // sortStatus={sortStatus}
          fetchSortedCardHandler={fetchSortedCardHandler}
          // fetchCardHandler={fetchCardHandler}
          key={quote.id}
          quote={quote}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
          highestNumber={highestNumber}
          handleCloneCard={handleCloneCard}
          setCards={setCards}
          cards={cards}
          allCards={allCards}
        />
      )}
    </Draggable>
  )) : null
};



const InnerList = ({ setCards, fetchSortedCardHandler,
  checked,handleCloneCard,cards,allCards,
  quotes, dropProvided, title , role}: any) => {
  // const { quotes, dropProvided } = this.props;
  const customTitle = title ? <Title>{title}</Title> : null;
  return (
    <Container>
      {customTitle}
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteList setCards={setCards} quotes={quotes} allCards={allCards} cards={cards} handleCloneCard={handleCloneCard} checked={checked} fetchSortedCardHandler={fetchSortedCardHandler}
          // fetchCardHandler={fetchCardHandler} 
          role = {role}
          />
        {/* <InnerQuoteList quotes={quotes} sortStatus={sortStatus} /> */}
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CardList = ({
  handleCloneCard,
  // fetchCardHandler,
   fetchSortedCardHandler,
  ignoreContainerClipping,
  internalScroll,
  scrollContainerStyle,
  isCombineEnabled,
  listId,
  listType,
  style,
  quotes, checked,
  title,
  className,
  cards,allCards,
  role,
  setCards
}: any): React.JSX.Element => {
 

 
  const  [isDropDisabled] = useState(role === 'Guest'? true : false)
  
  


  return (
    <>
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
        
      >
        {(dropProvided, dropSnapshot) => (
          <Wrapper
            className={className}
            style={style}
            isDraggingOver={dropSnapshot.isDraggingOver}
            // isDropDisabled={isDropDisabled}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <ScrollContainer style={scrollContainerStyle}>
                <InnerList
                  role={role}
                  quotes={quotes}
                  title={title}
                  dropProvided={dropProvided}
                  fetchSortedCardHandler={fetchSortedCardHandler}
                  // fetchCardHandler={fetchCardHandler}
                  handleCloneCard={handleCloneCard}
                  cards={cards}
                  setCards={setCards}
                  allCards={allCards}
                />
              </ScrollContainer>
            ) : (
              <InnerList
              role={role}
                quotes={quotes}
                checked={checked}
                // sortStatus={sortStatus}
                title={title}
                dropProvided={dropProvided}
                fetchSortedCardHandler={fetchSortedCardHandler}
                // fetchCardHandler={fetchCardHandler}
                handleCloneCard={handleCloneCard}
                cards={cards}
                setCards={setCards}
                allCards={allCards}
              />
            )}
          </Wrapper>
        )}
      </Droppable>

    </>
  );
};

export default CardList;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const createRetainAlertInfo = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/card/retain-alert/info`, data)
}
export const createRetainAlertInfoForPage = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/page/retain-alert/info`, data)
}
export const getRetainAlertInfo = async (cardId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/retain-alert/info?type=description&cardId=${cardId}`
  )
}
export const getRetainAlertInfoForPage = async (pageId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL_V3}/page/retain-alert/info/${pageId}`
  )
}
// export const getRetainAlertInfoComment = async (commentId: string) => {
//   return await apiService.get(
//     `${process.env.REACT_APP_BACKEND_URL_V2}/card/retain-alert/info?type=comment&card_commentId=${commentId}`
//   )
// }

import React from 'react'
import { useQuery } from 'react-query'
import { UserEditModalForm } from './UserEditModalForm'
import { isNotEmpty, QUERIES } from '../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getUserById } from '../core/_requests'
import { useState } from 'react'

type Props = {
  getMembersHandler: () => void;
  membersData: any;
  sendWebSocket: any;
  handleClose: any;
  isPage: boolean;
};

const UserEditModalFormWrapper: React.FC<Props> = ({
  membersData,
  getMembersHandler,
  sendWebSocket,
  handleClose,
  isPage
}:Props) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const [errorMessage, setErrorMessage] = useState('')

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: () => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <UserEditModalForm handleClose={handleClose} setErrorMessage={setErrorMessage} errorMessage={errorMessage} membersData={membersData} isUserLoading={isLoading} user={{ id: undefined }} getMembersHandler={getMembersHandler} sendWebSocket={sendWebSocket} isPage={isPage} />
  }

  if (!isLoading && !error && user) {
    return <UserEditModalForm handleClose={handleClose} setErrorMessage={setErrorMessage} errorMessage={errorMessage} membersData={membersData} isUserLoading={isLoading} user={user} getMembersHandler={getMembersHandler} sendWebSocket={sendWebSocket} isPage={isPage} />
  }

  return null
}

export { UserEditModalFormWrapper }
import React from "react";
import CheckoutForm from "../../../components/Forms/CheckoutForm";
import {loadStripe} from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js';
import { useStripeApi } from "../../../hooks";



function Payment(): React.JSX.Element {
  const {pubKey} = useStripeApi()

  return (
<>
    {pubKey && (
      <Elements stripe={loadStripe(pubKey)} >
      <CheckoutForm />
      </Elements>
    )}

    </>
  );
}

export default Payment;

import {S3Client} from "@aws-sdk/client-s3"

export const awsFileUploadConfig: any = {
  bucketName: process.env.REACT_APP_FILE_UPLOAD_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  s3Url: 'https://b4igo-files.s3.amazonaws.com',
}

const credentials : any = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const config = {
  region : process.env.REACT_APP_REGION as string,
  credentials,
}
export const s3Client = new S3Client(config as any);

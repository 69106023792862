import React from 'react'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Alert} from 'react-bootstrap'
import '../../../styles/pricingStyle.css'
import {decodeInvitation} from '../../../services/invitation'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {setPlanDetails} from '../../../store/slice/payment-slice'
// import PlanConfirmationModal from '../../../components/Modals/PlanConfirmationModal'
// import { useAuth } from '../core/Auth'
import Skeleton from 'react-loading-skeleton'
import {createSubscription} from '../../../services/subscription'
import useWebSocket, {ReadyState} from 'react-use-websocket'
import {webSocketOperations} from '../../../constants'
import {useAuth} from '../core/Auth'
import { useTheme } from '../../../context/ThemeContext'

const Plans = (): React.JSX.Element => {
  const auth: any = localStorage.getItem('auth')
  const authData = JSON.parse(auth)
  const {logout} = useAuth()
  if (authData) {
    logout()
    localStorage.clear()
    window.location.reload()
  }

  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  const [socketUrl, setSocketUrl] = useState('')
  const [data, setData] = useState({})
  const [isFreeTrial, setIsFreeTrial] = useState('')
  const [popupMsg, setPopupMsg] = useState<any>('')
  const [popupAlertMsg, setPopupAlertMsg] = useState<any>('')
  const [socketStatus, setSocketStatus] = useState(ReadyState.CONNECTING)
  const {sendJsonMessage}: any = useWebSocket(socketUrl, {
    onOpen: () => setSocketStatus(ReadyState.OPEN),
    onClose: () => setSocketStatus(ReadyState.CLOSED),
    onError: () => setSocketStatus(ReadyState.CLOSED),
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  })

  // const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  // const handleClose = () => setShowConfirmationModal(false)
  // const handleShow = () => setShowConfirmationModal(true)
  const navigate = useNavigate()
  const url: any = window.location.href
  const token: any = url.split('token=')[1]
  const dispatch = useDispatch()
  const {theme} = useTheme()

  const decodeEmail = async () => {
    try {
      const res = await decodeInvitation({token, usage: 'signup'})
      const resData = res.data.decodeToken
      const subscriptionData = {
        email: resData.email,
        userId: resData.userId,
        name: resData.fullName,
        type: resData.chosenSubscriptionPlan,
        licenseEmails:resData.license,
        isOrgSignup:resData.isOrgSignup,
      }
      // if (resData?.chosenSubscriptionPlan !== 'FreeTrial'.toString()) {
        const data = {...subscriptionData, loginToken: resData.loginToken}
        dispatch(setPlanDetails(data))
        navigate(`/auth/pricing-plan/${resData?.chosenSubscriptionPlan}`)
        // return
      // }

      setUserId(resData.userId)
      setData(subscriptionData)
      setIsFreeTrial('freeTrial')
      setSocketUrl(
        `${process.env.REACT_APP_WEBSOCKET_URL}?userid=${resData.userId}&token=${resData.loginToken}`
      )
      sendJsonMessage({event: 'subscribe', channel: resData.userId})
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg === 'jwt expired' || msg === 'jwt must be provided') {
        navigate('/error/404')
      }
      navigate('/error/404')
    }
  }

  const verificationHandler = async () => {
    setLoading(true)
    try {
      const response = await createSubscription(data)
      sendJsonMessage({
        event: 'publish',
        channel: userId,
        userId: userId,
        command: 'UPDATE',
        operation: webSocketOperations.isUserVerified,
      })
      sendJsonMessage({event: 'subscribe', channel: userId})

      setPopupMsg(response.data.message)

      setTimeout(() => {
        navigate('/auth')
        setLoading(false)
      }, 3000)
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg.includes("You already have a subscription!")) {
        setPopupAlertMsg(msg)
        setTimeout(() => {
          navigate('/auth')
        }, 3000);
      }
      else{
        if (msg.includes("deletion")) {
          setPopupMsg(msg)
        } else {
  
          navigate('/error/404')
        }
      }
     
      setLoading(false)
      // return error
    }
  }
  const planSelectHandler = () => {
    verificationHandler()
  }

  useEffect(() => {
    if (!authData) {
      decodeEmail()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (socketStatus === 1 && userId.length > 0) {
      sendJsonMessage({event: 'subscribe', channel: userId})
    }
    //eslint-disable-next-line
  }, [socketStatus, userId])

  return (
    <>
      <div
        className={`d-flex flex-column justify-content-center align-items-center w-100`}
        style={{
          marginBlock: 'auto',
        }}
      >
        <div className='d-flex justify-content-center align-items-center'>
          <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
              className='h-50px my-5 me-15'
            />
          </a>
        </div>
        <div className='w-lg-550px w-md-400px  p-lg-15 p-md-10 p-sm-7 p-5 mx-auto selected_plan_card'>
          {isFreeTrial === 'freeTrial' ? (
            <React.Fragment>
              <h1 className={` mb-3 text-center ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Selected Plan</h1>
              <div
                onClick={() => {
                  if (loading === false) {
                    planSelectHandler()
                  }
                }}
                className='mx-auto'
                style={{maxWidth: '400px'}}
              >
                <div
                  className= {` ${theme === 'dark' ? 'theme__div' : ''} pricing-card text-center mt-30 wow fadeIn py-2 px-0`}
                  data-wow-duration='1s'
                  data-wow-delay='0.2s'
                >
                  <div className='pricing-content mt-30'>
                  <h3 className={`pricing-title wow fadeInUp  ${theme === 'dark' ? 'white__text' : ''}`}>14 Day free trial</h3>

                    <div className='wow fadeInUp mt-10'>
                      <span className={`mb-2 dollar ${theme === 'dark' ? 'white__text' : ''}`}>$</span> <span className='price'>0.00</span>{' '}
                      /user
                    </div>
                    <div className={`price-duration p-3 w-100 mt-3 ${theme === 'dark' ? 'white__text' : ''}`}>No Charge</div>

                    <span className={`btn btn-primary my-3 p-3 ${theme === 'dark' ? 'white__text' : ''}`}>Subscribe</span>
                  </div>
                </div>
              </div>
              {popupMsg && (
                <Alert variant='primary' className='mb-0 mt-4'>
                  {popupMsg}
                </Alert>
              )}
              {popupAlertMsg && (
                <Alert variant='danger' className='mb-0 mt-4'>
                  {popupAlertMsg}
                </Alert>
              )}
              {/* <CustomAlert variant={'primary'} className={'my-2'} alertMessage={popupMsg} setAlertMessage={setPopupMsg} /> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='text-muted my-5 text-center'>
                <Skeleton count={1} height={40} className='selected_plan_free_trial_skeleton' />
              </div>
              <div className='text-muted my-3 text-center'>
                <Skeleton count={1} height={300} className='selected_plan_free_trial_skeleton' />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>

      {/* <PlanConfirmationModal
        showConfirmationModal={showConfirmationModal}
        handleClose={handleClose}
      /> */}
    </>
  )
}

export default Plans

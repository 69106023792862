import {useEffect, useRef, useState} from 'react'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {capitalizeEachWord} from '../helper-functions/CapitalizeName'
import '../styles/excelexport.css'
import {getAllCardByBoardIdForExcelExport} from '../services/cards'
import {useAuth} from '../modules/auth'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ExcelExport({boardName, boardId}: any) {
  const [cardsList, setCardsList] = useState([])
  const [loading, setLoading] = useState(false)

  const {currentUser} = useAuth()
  const {id} = currentUser?.data.user
  const getCardsListForExport = async () => {
    setLoading(true)
    try {
      const res: any = await getAllCardByBoardIdForExcelExport(boardId, id)
      if (res.data.data) {
        setCardsList(res.data.data)
      }
    } catch (error) {
      return error
    }finally{
      setLoading(false)
    }
  }
  const tableRef = useRef(null)

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: boardName ? boardName.trim() : 'Board Details',
    sheet: boardName ? boardName.trim() : 'Board',
  })

  useEffect(() => {
    if(cardsList.length>0){

      onDownload()
    }
  }, [cardsList])

  return (
    <>
      {cardsList && (
        <div>
          <button
            className='btn btn-sm btn-info p-2 mx-3 export_btn text-nowrap'
            onClick={getCardsListForExport}
            disabled={loading}
          >
            {' '}
            Export to Excel{' '}
          </button>
          <table ref={tableRef} hidden>
            <tbody>
              <tr>
                <th>Card ID</th>
                <th>Title</th>
                <th>Column</th>
                <th>Description</th>
                <th>Due Date</th>
                <th>Status</th>
                <th>Priority</th>
                <th>Assignees</th>
              </tr>
              {cardsList?.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.key}</td>
                  <td>{item.card_name}</td>
                  <td>{item.board_column_name}</td>
                  <td>{item.description && item.description.replace(/<[^>]+>/g, '')}</td>
                  <td>{item.due_date}</td>
                  <td>
                    {item.status === 'todo'
                      ? 'To Do'
                      : item.status === 'done'
                      ? 'Done'
                      : 'In Progress'}
                  </td>
                  <td>{item.priority}</td>
                  <td>
                    {item.assignees
                      ? capitalizeEachWord(item.assignees.toString())
                      : capitalizeEachWord(item.assignees)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}



export const checkLongWordWithoutSpace = (str: string): boolean | undefined => {
  //eslint-disable-next-line
  // const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if(str){

    const words = str.split(' ')
    
    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      
      if (word.length > 30 && !/\s/.test(word)) {
        return true
        // if(regex.test(str)){
        //   return false 
        // }else{
        //   return true
        // }

      }
    }
    
    return false // No word found with more than 25 characters without a space
  }
  }

export const longWordSlicing = (str: string): string | undefined => {
  if (str) {
    const trimedString = str.trim()
    let newString = ""
    if(checkLongWordWithoutSpace(trimedString)){
           newString = trimedString.slice(0, 25) + '...'
    }else{
      newString = trimedString.length<25 ? trimedString:trimedString.slice(0, 25)+"..." 
    }
      
    return newString
  }
}
export const longWordSlicingWithCustomLength = (str: string | undefined, length: number): string|undefined => {
  if (str) {
    const trimedString = str.trim()
    let newString = ""
    if(checkLongWordWithoutSpace(trimedString)){
           newString = trimedString.slice(0, length) + '...'
    }else{
      newString = trimedString.length<length ? trimedString:trimedString.slice(0, length)+"..." 
    }
      
    return newString
  }
}
export const longWordSlicingWithGivenLength = (str: string | undefined, length: number): string|undefined => {
  if (str) {
    const trimedString = str.trim()
    let newString = ""
      newString = trimedString.length<length ? trimedString:trimedString.slice(0, length)+"..."  
      
    return newString
  }
}

// export const checkEmailValidation = (email : string)=>{
//   return 
// }

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const getAllRoles = async () => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL}/role`)
}

export const userLookup = async (email: string) => {
  const data = {
    email,
    b4igoSecret: process.env.REACT_APP_B4IGO_SECRET,
  }
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/user/lookup/email`, data)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userVerification = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/user/verification`, data)
}

import ReactDOM from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { getFirestore } from 'firebase/firestore'
import { initializeApp,getApps,getApp } from 'firebase/app'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import {EditModalProvider} from '../src/app/context/ShowEditModalQuill'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
//testing build
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './app/styles/button.css'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {store} from './app/store'
import {Provider} from 'react-redux'
// import 'react-toastify/dist/ReactToastify.css';
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist'
import {firebaseConfig} from './firebase'
import {ThemeProvider} from './app/context/ThemeContext'
// import './_metronic/assets/sass/style.dark.scss'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart?.register(...registerables)
const queryClient = new QueryClient()
const persistor : any   = persistStore(store as any)
const app: any = !getApps().length? initializeApp(firebaseConfig):getApp()
export const db = getFirestore(app)
ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <Provider store={store}>
        <ThemeProvider>
          <AuthProvider>
            <PersistGate persistor={persistor}>
              <EditModalProvider>
                <AppRoutes />
              </EditModalProvider>
            </PersistGate>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </MetronicI18nProvider>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
  // </React.StrictMode>,
)

import React,{ useEffect } from 'react'
import { colors } from '@atlaskit/theme'
import reorder, { reorderQuoteMap } from './Reorder'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'

import { updateCardById, updateCardColumnAndPosition } from '../../services/cards'
import { updateColumnPositions } from '../../services/column'
import ColumnButton from './ColumnButton'
import styled from '@emotion/styled'
import Column from './Column'
import '../../styles/ColumnButton.css'

import SpinnerContainer from '../../utils/SpinnerContainer'
import Skeleton from 'react-loading-skeleton'
import { setIsCardUpdated } from '../../store/slice/last-update-slice'
import { useAuth } from '../../modules/auth'
import { useBoardPersons, useWebSocketHook } from '../../hooks'
import { setIsRender } from '../../store/slice/isRender-slice'
import { webSocketOperations } from '../../constants'
import ToastComponent from '../ToastComponent'
import { capitalizeEachWord } from '../../helper-functions/CapitalizeName'
import { useNavigate } from 'react-router-dom'


type Props = any

const BoardDnD: React.FC<Props> = ({
    boardCardFetchLoading,
    isSorted,
    columnResponse,
    useClone,
    boardId,
    setColumnsResponse,
    containerHeight,
    withScrollableColumns,
    isCombineEnabled,
    role,
    isColumnLoading,
    cards,allCards, setCards,
    fetchCardHandler, fetchSortedCardHandler, fetchColumnHandler,getMembersHandler,isColumnLoadSkeleton,
}:Props) => {
    const [webSocketAlert, setWebSocketAlert] = useState("")
    const [columnsState, setColumnState] = useState([])
    const [columnsBg, setColumnsBg] = useState<any>([])
    const [orderedState, setOrderedState] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [addColumnOpen, setAddColumnOpen] = useState(false)
    const [authors, setAuthors] = useState<any>()
    const dispatch: Dispatch<any> = useDispatch()
    const { sendJsonMessage, lastJsonMessage,readyState }: any = useWebSocketHook()
    const { currentUser } = useAuth()
    const { id, fullName  } = currentUser?.data.user
    const {
        optionsOfAssignee,
        getBoardPersonHandler
      } = useBoardPersons()
    //guest -mode : hide add column and prevent move column
    const Container:any = styled.div`
    //   background-color: #f6f6f6;
    //   min-height: 100vh;
    /* like display:flex but will allow bleeding over the window width */
    //   min-width: 100vw;
    display : ${({isDraggingOver}: any) => (isDraggingOver  && 'grid' )};
    display: inline-flex
    grid-template-columns: ${`repeat(${orderedState?.length+1}, 1fr)`};
    `
 
    const isLastUpdated = useSelector((state: any) => state.lastUpdateReducer)
    const { isCardUpdated } = isLastUpdated.isCardUpdated
    const isRenderStore = useSelector((state: any) => state.isRenderReducer)
    const { isRender } = isRenderStore.isRender
    const navigate = useNavigate()
    const handleCloneCard = (newQuote: any) => {
        setCards((card:any) => [...card, newQuote]);
        const clonedCardPosition = newQuote.position;
    
        const updatedCards = allCards?.map((card: any) => {
            // If the card's position is >= the cloned card's position
            if (card.position >= clonedCardPosition) {
                return {
                    ...card,
                    position: card.position + 1, 
                    cardNumber: Number(card.cardNumber) + 1 
                };
            }
            return card;
        });
        const finalCards = [...updatedCards, newQuote];
        const payload = {
            properties: updatedCards.map((card: any) => ({
                id: card.id,
                board_column_id: card.board_column_id,
                position: card.position,
                cardNumber: card.number
            }))
        };
    
        updateCardColumnAndPosition(payload,id) 
        setCards(finalCards);   
    };
    const updateColumnPositionsState = (columnPos: any) => {
        const updatedColumns: any = []
        for (let i = 0; i < columnResponse.length; i++) {
            let newColumn: any
            for (let j = 0; j < columnPos.length; j++) {
                if (columnResponse[i].id === columnPos[j].id) {
                    columnResponse[i].position = columnPos[j].position
                    newColumn = columnResponse[i]
                } else {
                    newColumn = columnResponse[i]
                }
            }
            updatedColumns.push(newColumn)
        }
        setColumnsResponse(updatedColumns)

        const webSocketPayload: any = {
            event: 'publish',
            channel: boardId,
            userId:id,
            command: "UPDATE",
            operation: webSocketOperations.columnPositionUpdate,
            payload: { columns: updatedColumns, boardId, alert: `${capitalizeEachWord(fullName)} did changes in column position.` }
        }
        sendJsonMessage(webSocketPayload)
    }

    const updateCardPositions = (payload: any) => {
        const { properties: cardsIdsAndPositions } = payload
        const updatedCards: any = []

        let webSocketPayload: any = {
            event: 'publish',
          channel: boardId,
          userId:id,
            command: "UPDATE",
            operation: webSocketOperations.cardPositionUpdate,

        }
        for (let i = 0; i < cards.length; i++) {
            let newCard: any
            for (let j = 0; j < cardsIdsAndPositions.length; j++) {
                if (cards[i].id === cardsIdsAndPositions[j].id) {
                    cards[i].position = cardsIdsAndPositions[j].position
                    cards[i].board_column_id = cardsIdsAndPositions[j].board_column_id
                    newCard = cards[i]
                    webSocketPayload = {
                        ...webSocketPayload,
                        payload: {
                            boardId,
                            cardId: cardsIdsAndPositions[j].id,
                            cardNumber: cardsIdsAndPositions[j].cardNumber,
                            cardPosition: cardsIdsAndPositions[j].position,
                            boardColumnId: cardsIdsAndPositions[j].board_column_id,
                            alert: `${capitalizeEachWord(fullName)} did changes in ${cards[i].board_name}- ${cards[i].number}.`
                        }

                    }

                } else {
                    newCard = cards[i]
                }
            }
            updatedCards.push(newCard)
        }
        setCards(updatedCards)
        return webSocketPayload
    }
    const updateCardHandler = async (payload: any, column: any,cardId:string) => {
        setIsLoading(true)
        const webSocketPayload: any = updateCardPositions(payload)
       
        try {
            const cardToUpdate=payload.properties.find((card:any)=>cardId=== card.card_id)
            const postData = {
                created_by: fullName,
                last_modified_by: fullName,
                boardId:cardToUpdate.boardId,
                card_name:cardToUpdate.card_name,
                position:cardToUpdate.position,
                status: column.status ? column.status :cardToUpdate.status ,
                board_column_id:cardToUpdate.board_column_id,
                priority: column.priority ? column.priority :cardToUpdate.priority,
                assignees:column.board_column_people.length>0 ?column.board_column_people.map((item: any) => ({
                board_person_id: item.board_person_id,
                })):cardToUpdate.assignees.map((item:any)=> item)
              };          
            const response=await updateCardById(postData,cardToUpdate.id, id, boardId)
            const filteredCards = allCards?.filter((card : any)=> card.id !== cardToUpdate.id )
    
            setCards([ response.data.data.card,...filteredCards])
            await updateCardColumnAndPosition(payload, column?.id)
            sendJsonMessage(webSocketPayload)

            dispatch(setIsCardUpdated({ isCardUpdated: !isCardUpdated }))
        } catch (error) {
            return error
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 700)


    }

    const scroll = (scrollOffset: any) => {
        window.scrollBy(scrollOffset, 0)
    }

    const onDragEnd = async (result: any) => {
        if (orderedState !== undefined) {
            if (result.combine) {
                if (result.type === 'COLUMN') {
                    const shallow = [...orderedState]
                    shallow.splice(result.source.index, 1)
                    setOrderedState(shallow)
                    return
                }
                const column: any = columnsState[result.source.droppableId]
                const withQuoteRemoved = [...column]
                withQuoteRemoved.splice(result.source.index, 1)
                const columns: any = {
                    ...columnsState,
                    [result.source.droppableId]: withQuoteRemoved,
                }
                setColumnState(columns)
                return
            }

            if (!result.destination) {
                return
            }

            const source = result.source
            const destination = result.destination
            const destinationDroppableId = result.destination.droppableId
            const column = columnResponse.find((col: any) => col.id === destinationDroppableId)
            if (source.droppableId === destination.droppableId && source.index === destination.index) {
                return
            }

            if (result.type === 'COLUMN') {
                const ordered = reorder(orderedState, source.index, destination.index)
                setOrderedState(ordered)

                const columnsIdsAndPositions = ordered.map((col: any, index: number) => {
                    const getColumn = columnResponse.find((item: any) => item.id === col)
                    return {
                        position: index,
                        id: getColumn.id,
                    }
                })

                updateColumnPositionsState(columnsIdsAndPositions)

                await updateColumnPositions({ idsAndPositions: columnsIdsAndPositions }, id, boardId)
                return
            }

            const data = reorderQuoteMap({
                quoteMap: columnsState,
                source,
                destination,
            })

            let cardsInColumn
            for (const k in data.quoteMap) {
                if (destinationDroppableId === k) {
                    cardsInColumn = data.quoteMap[k]
                    break
                }
            }

            const idsAndPositionsOfCards = []
            const cardsPosition: any = []
            let lastUpdatedElement
            for (let i = 0; i < cardsInColumn.length; i++) {
                if (i !== destination.index) {
                    idsAndPositionsOfCards.push({
                        position: i,
                        id: cardsInColumn[i].id,
                        board_column_id: column?.id,
                        cardNumber: cardsInColumn[i].number,
                        boardId: cardsInColumn[i].boardId,
                        card_name: cardsInColumn[i].card_name,
                        card_id:cardsInColumn[i].id,
                        status:cardsInColumn[i].status,
                        assignees: cardsInColumn[i].assigneeBoardPersonIds.map((assignee: any) => ({
                            board_person_id: assignee.boardPersonId, 
                          })),
                    })
                    cardsInColumn[i].position = i
                    // cardsInColumn[i].board_column_id = columnId
                    cardsPosition.push(cardsInColumn[i])
                } else {
                    lastUpdatedElement = {
                        position: i,
                        id: cardsInColumn[i].id,
                        board_column_id: column?.id,
                        cardNumber: cardsInColumn[i].number,
                        boardId: cardsInColumn[i].boardId,
                        card_name: cardsInColumn[i].card_name,
                        card_id:cardsInColumn[i].id,
                        priority:cardsInColumn[i].priority,
                        status:cardsInColumn[i].status,
                        assignees: cardsInColumn[i].assigneeBoardPersonIds.map((assignee: any) => ({
                            board_person_id: assignee.boardPersonId,
                          })),
                    }
                    cardsInColumn[i].position = i
                    cardsInColumn[i].board_column_id = column?.id

                    cardsPosition.push(cardsInColumn[i])
                }
            }
            idsAndPositionsOfCards.push(lastUpdatedElement)


            setColumnState(data.quoteMap)

            updateCardHandler(
                {
                    properties: idsAndPositionsOfCards,
                },
                column,result.draggableId
                )
        }
    }

    const webSocketOperationHandler = (operation: string, payload: any) => {
        const createCardWebsocketHandler = (payload: { newCard:any, boardId: string, alert: string }) => {
            const { alert, boardId: boardIdFromWebsocket, newCard } = payload
            if (boardId === boardIdFromWebsocket) {
                if (!alert.includes(capitalizeEachWord(fullName))) {
                    setCards([...cards, newCard])
                    setWebSocketAlert(alert)
                }
            }
        }
        const updateCardWebsocketHandler = (payload: { alert: string }) => {
            const { alert } = payload
            dispatch(setIsRender({ isRender: !isRender }))
            if (!alert.includes(capitalizeEachWord(fullName))) {
                setWebSocketAlert(alert)
            }
        }

        const updateColumnTitleWebsocketHandler = (payload: any) => {
            const { alert, columnId, newName } = payload
            const websocketColumnUpdate = columnResponse.map((col: any) => {
                if (col.id === columnId && newName) {
                    return { ...col, name: newName }
                }
                return col
            })
            setColumnsResponse(websocketColumnUpdate)
            setWebSocketAlert(alert)
        }

        const updateColumnPositionWebsocketHandler = (payload: { columns: any, boardId: string, alert: string }) => {
            const { alert, boardId: boardIdFromWebsocket, columns } = payload
            if (boardId === boardIdFromWebsocket) {
                setColumnsResponse(columns)
                setWebSocketAlert(alert)
            }
            // setTimeout(() => {
            //     fetchColumnHandler(boardId)
            // }, 1000);
        }

        const updateColumnColorWebsocketHandler = (payload: { newColor: string, columnId: string, boardId: string, alert: string }) => {
            const { alert, newColor, boardId: boardIdFromWebsocket, columnId } = payload
            if (boardId === boardIdFromWebsocket) {
                const updatedColumns = columnResponse.map((col: any) => col.id === columnId ? { ...col, color: newColor } : col)
                setColumnsResponse(updatedColumns)
                setWebSocketAlert(alert)
            }
        }
        const deleteColumnWebsocketHandler = (payload: { columnId: string, boardId: string, alert: string }) => {
            const { alert, boardId: boardIdFromWebsocket } = payload
            if (boardId === boardIdFromWebsocket) {
                fetchColumnHandler(boardId)
                setWebSocketAlert(alert)
            }
        }
        const createColumnWebsocketHandler = (payload: { newColumn: any, boardId: string, alert: string }) => {
            const { alert, boardId: boardIdFromWebsocket, newColumn } = payload
            if (boardId === boardIdFromWebsocket) {
                setColumnsResponse([...columnResponse, newColumn])
                setWebSocketAlert(alert)
            }
        }
        const archivedBoardWebsocketHandler = (payload: { status: any, boardId: string, alert: string }) => {
            const { alert, boardId: boardIdFromWebsocket } = payload
            if (boardId === boardIdFromWebsocket) {
                navigate(-1)
                setWebSocketAlert(alert)
            }
        }

        const memberRoleWebsocketHandler=(payload: { role: string, boardId: string, alert: string })=>{
            const { alert } = payload
            if (!alert.includes(capitalizeEachWord(fullName))) {
                setWebSocketAlert(alert)
                getMembersHandler()
            }
        }

        const { alert } = payload

        switch (operation) {
            case webSocketOperations.cardPositionUpdate:
            case webSocketOperations.cardUpdate:
                updateCardWebsocketHandler(payload)
                break;
            case webSocketOperations.boardColumnNameUpdate:
                updateColumnTitleWebsocketHandler(payload)
                break;
            case webSocketOperations.columnPositionUpdate:
                updateColumnPositionWebsocketHandler(payload)
                break;
            case webSocketOperations.columnColorUpdate:
                updateColumnColorWebsocketHandler(payload)
                break;
            case webSocketOperations.columnDelete:
                deleteColumnWebsocketHandler(payload)
                break;
            case webSocketOperations.columnCreate:
                createColumnWebsocketHandler(payload)
                break;
            case webSocketOperations.cardCreate:
                createCardWebsocketHandler(payload)
                break;
            case webSocketOperations.boardStatusUpdate:
            case webSocketOperations.boardDesignInviteeDelete:
                archivedBoardWebsocketHandler(payload)
                break;
            case webSocketOperations.boardNameUpdate:
                if (!alert.includes(capitalizeEachWord(fullName))) {
                    setWebSocketAlert(alert)
                }
                break; 
        case webSocketOperations.boardDesignTeamUpdate:
            memberRoleWebsocketHandler(payload)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const authors = [...columnResponse]
            ?.sort((a: any, b: any) => a.position - b.position)
            .map((item: any) => {
                return {
                    ...item,
                    colors: {
                        soft: colors.Y50,
                        hard: colors.Y200,
                    },
                }
            })

        setAuthors(authors)
        const sanitizeCard = cards?.map((card: any) => {
            return { ...card, author: authors?.find((col: any) => card.board_column_id === col.id) }
        })

        const getByAuthor = (author: any, items: any) =>
            items?.filter((quote: any) => quote.author === author)

        const authorQuoteMap = authors?.reduce(
            (previous: any, author: any) => ({
                ...previous,
                [author.id]: getByAuthor(author, sanitizeCard),
            }),
            {}
        )
        const colBg:any = authors?.map((elem: any) => elem.color.trim())
        setColumnsBg(colBg)
        setColumnState(authorQuoteMap ? authorQuoteMap : undefined)
        setOrderedState(authorQuoteMap ? Object.keys(authorQuoteMap) : undefined)
        //eslint-disable-next-line
    }, [columnResponse, cards])

    useEffect(() => {
        if (lastJsonMessage) {
            const operation: any = lastJsonMessage.response?.output.operation
            const payload: any = lastJsonMessage.response?.output.payload
            webSocketOperationHandler(operation, payload)
            setTimeout(() => {
                setWebSocketAlert("")
            }, 8000);
        }
         //eslint-disable-next-line
    }, [lastJsonMessage]);

    useEffect(() => {
        if(readyState===1){
            sendJsonMessage({event:"subscribe",channel:boardId})   
          }
           //eslint-disable-next-line
    }, [readyState])
    
    useEffect(() => {
        getBoardPersonHandler(boardId)
      }, [boardId])
    
    return (
        <>
            {
                webSocketAlert?.length > 0 &&
                <ToastComponent alertMessage={webSocketAlert} setAlertMessage={setWebSocketAlert} />
            }
            <DragDropContext onDragEnd={onDragEnd} > 
                <Droppable
                    droppableId='board'
                    type='COLUMN'
                    direction='horizontal'
                    ignoreContainerClipping={Boolean(containerHeight)}
                    isCombineEnabled={isCombineEnabled}
                    isDropDisabled={role === 'Guest'? true : false}
                   
                >

                    {(provided:any,snapshot:any) => (
                        <Container ref={provided.innerRef} {...provided.droppableProps} isDragging={snapshot.isDragging} isDraggingOver={snapshot.isDraggingOver}>
                            {isColumnLoading || isColumnLoadSkeleton ? (
                                <div className='d-flex'>
                                    <div
                                        className=' mx-3 '
                                        style={{
                                            fontSize: '40px',
                                            width: '20vw',
                                        }}
                                    >
                                        <SpinnerContainer>
                                            <Skeleton count={8} />
                                        </SpinnerContainer>
                                    </div>
                                    <div
                                        className=' mx-3 '
                                        style={{
                                            fontSize: '40px',
                                            width: '20vw',
                                        }}
                                    >
                                        <SpinnerContainer>
                                            <Skeleton count={8} />
                                        </SpinnerContainer>
                                    </div>
                                    <div
                                        className=' mx-3 '
                                        style={{
                                            fontSize: '40px',
                                            width: '20vw',
                                        }}
                                    >
                                        <SpinnerContainer>
                                            <Skeleton count={8} />
                                        </SpinnerContainer>
                                    </div>

                                </div>
                            ) : (

                                orderedState?.map((key: any, index: number) => (
                                    <Column
                                        columnsBg={columnsBg}
                                        setColumnsBg={setColumnsBg}
                                        bgColor={authors?.find((elem: any) => elem.id === key)?.color}
                                        columnId={authors?.find((elem: any) => elem.id === key)?.id}
                                        key={key}
                                        index={index}
                                        title={authors?.find((elem: any) => elem.id === key)?.name}
                                        quotes={columnsState[key]}
                                        isScrollable={withScrollableColumns}
                                        isCombineEnabled={isCombineEnabled}
                                        useClone={useClone}
                                        setColumnsResponse={setColumnsResponse}
                                        columnResponse={columnResponse}
                                        role={role}
                                        isLoading={isLoading}
                                        checked={isSorted}
                                        cards={cards}
                                        allCards={allCards}
                                        setCards={setCards}
                                        fetchSortedCardHandler={fetchSortedCardHandler}
                                        fetchCardHandler={fetchCardHandler}
                                        sendJsonMessage={sendJsonMessage}
                                        handleCloneCard={handleCloneCard}
                                        boardCardFetchLoading={boardCardFetchLoading}
                                        optionsOfAssignee={optionsOfAssignee}
                                    />
                                ))
                            )
                            }

                            {provided.placeholder}
                            {role === 'Admin'  && (
                                <ColumnButton
                                    scroll={scroll}
                                    columnResponse={columnResponse}
                                    setColumnsResponse={setColumnsResponse}
                                    sendJsonMessage={sendJsonMessage}
                                    setAddColumnOpen={setAddColumnOpen}
                                    addColumnOpen={addColumnOpen}
                                />
                            )}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
}

BoardDnD.defaultProps = {
    isCombineEnabled: false,
}

export default BoardDnD

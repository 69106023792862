import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {

  fetchAllUserSearch,
  
} from '../../services/home-searching'
import {updateSearches} from '../../helper-functions/CreateIndexDb'

const name = 'seachingHome'



export const getAllSearchData = createAsyncThunk(
  `${name}/getAllSearchData`,
  async ({query}: {query: string}) => {
    try {
      const response = await fetchAllUserSearch(query)
      updateSearches('Home', response?.data.lastSearches)
      return response?.data.data || []
    } catch (error: any) {
      throw new error()
    }
  }
)

interface InitialStateProps {
  currentSelectedTabData: any
  currentSelectedTab: string
  allSearchData: any
  selectedTemplate: any
  selectedContact: any
  loading: boolean
  mainSearchLoading: boolean
  lastSearches: string[]
}

const initialState: InitialStateProps = {
  currentSelectedTabData: [],
  allSearchData: {
    cards: {
      length: 0,
      cards: [],
    },
    pages: {
      length: 0,
      pages: [],
    },
    boards: {
      length: 0,
      boards: [],
    },
    templates: {
      length: 0,
      templates: [],
    },
    contacts: {length: 0, contacts: []},
    documents: {
      length: 0,
      documents: [],
    },
  },
  currentSelectedTab: 'Cards',
  selectedTemplate: null,
  selectedContact: null,
  loading: false,
  mainSearchLoading: false,
  lastSearches: [],
}

export const homeSearchingSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.selectedTemplate = {...action.payload}
    },
    setContacts: (state, action) => {
      state.selectedTemplate = {...action.payload}
    },
    setUpdatedItem: (state, action) => {
      state.currentSelectedTabData = action.payload
      state.loading = false
    },
    updateSearchData: (state, action) => {
      state.allSearchData[action.payload.tab] = {
        length: action.payload.data?.length || 0,
        [action.payload.tab]: [...action.payload.data],
      }
    },
    setLastSearchedItem: (state, action) => {
      state.lastSearches = [...action.payload]
    },
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(getAllSearchData.pending, (state) => {
        state.mainSearchLoading = true
      })
      .addCase(getAllSearchData.fulfilled, (state, action) => {
        state.allSearchData = {...action.payload}
        state.mainSearchLoading = false
      })
      .addCase(getAllSearchData.rejected, (state) => {
        state.mainSearchLoading = false
      })
  },
})
export const {setContacts, setTemplates, setUpdatedItem, setLastSearchedItem, updateSearchData} =
  homeSearchingSlice.actions
export default homeSearchingSlice.reducer
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cryptoEncrypt } from '../helper-functions/encryption'
import apiService from './index'


export const createOrganization = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/organization`,  { data: cryptoEncrypt(data) })
}

export const getOrganizationByUserId = async (userId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/by/user/${userId}`)
}

export const createOrganizationLicense = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/license`, data)
}

export const createOrganizationLicenseInsert = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/license/insert`, data)
}

export const createOrganizationMember = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/member`,  { data: cryptoEncrypt(data) })
}

export const createOrganizationMemberInvite = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/member/invite`, data)
}

export const updateOrganization = async (data: any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V3}/organization`, data)
}

export const getOrganizationById = async (organizationid: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/${organizationid}`)
}

export const getOrganizationLicenseById = async (organizationid: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/license/${organizationid}`)
}

export const getOrganizationMemberById = async (organizationid: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/member/${organizationid}`)
}

export const deleteOrganization = async (organizationid: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/${organizationid}`)
}

export const removeOrganizationMember = async (organizationMemberId: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/member/${organizationMemberId}`)
}
//new created
export const getOrganizationforMembrs = async (token?:string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/organization/member/by/current/user`,{headers: {
    Authorization: `Bearer ${token}`,
  }})
}


import React from "react"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { useTheme } from "../../../context/ThemeContext"

const DialoguePage = (): React.JSX.Element => {
  const {theme} = useTheme()
  return (
    <div
    className={` ${theme === 'dark' ? 'layout__theme__div' : ''} d-flex flex-column  flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed`}
    style={{
      // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
    }}
  >

    <div className={` d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 bg-primary`}>

      <div className='w-lg-500px bg-white rounded shadow-sm  p-lg-15 p-md-10 p-sm-7 p-5 mx-auto text-center'>
      <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/b4igo-go.png')} className='h-50px my-5 me-15' />
      </a>
      <p className={`p-3 fs-5  ${theme === 'dark' ? 'white__text' : ''}`}>
        
      Thank you for signing up to b4igo and welcome to our platform! A verification email has been sent to the email address you created your account with. Please click on the Verification button within that email within the next 24 hours.
      After 24 hours, we will lock your account until you have verified, but if you can&apos;t find the email or just want us to send it again, click on the Resend Verification Email button you will see on the login page. This message will close after a few seconds and you will be taken to your b4igo Home Screen.
    </p>
      </div>
    </div>
    
  </div>
   
  )
}

export default DialoguePage
import  {forwardRef, useImperativeHandle, useState} from 'react'
import "../../styles/customImageModal.css"
import { useTheme } from '../../context/ThemeContext'

          //eslint-disable-next-line
const ImageModal = forwardRef((props: any, ref: any) => {
  const {theme} = useTheme()
  const [show, setShow] = useState<boolean>(false)
  const [imageSrc, setImageSrc] = useState<string>('')

  useImperativeHandle(ref, () => ({
    handleModal(src: string) {
      setShow(true)
      setImageSrc(src)
     
    },
  }))

  return (
    <div className="modal-overlay" style={{ display: show ? 'flex' : 'none' }}>
      <div className={`modal-content ${theme === 'dark' && 'theme__div'}`}>
        <div className="modal-header  p-6 ">
          <p className="modal-title">Image Preview</p>
          <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={() => setShow(false)}></i>
        </div>
        <div className="modal-body text-center">
          <img src={imageSrc} alt='test' className="modal-image" />
        </div>
      </div>
    </div>
  );
})

export default ImageModal

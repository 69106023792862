import React from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SpinnerContainer = ({ children }: any) => {
    return (
        <div

        >
            {children}
        </div >

    )
}

export default SpinnerContainer
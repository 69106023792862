import React, {useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
// import { useAuth } from '../../../auth'
import {isValidPhoneNumber} from 'libphonenumber-js'
import {useManageContacts} from '../../../../hooks'
// import { getAllRoles } from '../../../../services/user-lookup'
// import { useParams } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CustomAlert from '../../../../components/CustomAlert'
import {setUpdatedItem} from '../../../../store/slice/home-searching-slice'
import {useTheme} from '../../../../context/ThemeContext'

//eslint-disable-next-line
const cityRgx = /^[A-Za-z\s]+$/
const AddNewContactSchema = Yup.object().shape({
  fullName: Yup.string()
    .max(40, 'Maximum 100 characters are allowed.')
    .required('Name is required')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  contact: Yup.string().nullable(true),
  businessNumber: Yup.string().nullable(true),
  website: Yup.string().nullable(true),
  streetAddress: Yup.string().nullable(true),
  designation: Yup.string().nullable(true),
  city: Yup.string().nullable(true).matches(cityRgx, 'Please enter city name with alphabets only.'),
  zipCode: Yup.string().nullable(true),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const memoizedEditContacts: React.FC<any> = ({
  contact,
  buttonTitle,
  handleClose,
  otherContactList,
  setOtherContactList,
  isHomeSearching,
}) => {
  const dispatch: Dispatch<any> = useDispatch()
  const {theme} = useTheme()
  const [phoneState, setPhoneState] = useState<any>(contact?.contact || null)
  const [businessNumberState, setBusinessNumberState] = useState<any>(
    contact?.businessNumber || null
  )

  // const [editor, setEditor] = useState(contact.notes || null)
  const [loading, setLoading] = useState(false)
  const [isContactValid, setIsContactValid] = useState(true)
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')
  const [isBusinessNumberValid, setIsBusinessValid] = useState(true)
  const {
    isInvited,
    updateContactByContactIdHandler,
    setIsInvited,
    contactEditedMsg,
    setContactEditedMsg,
  } = useManageContacts()

  const valuesToEdit: any = {}
  let modifiedValues: any = {}

  const formik = useFormik({
    initialValues: contact,
    validationSchema: AddNewContactSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      let validContact: any
      let validBusinessNumber: any
      if (phoneState && phoneState.length !== '') {
        validContact = isValidPhoneNumber('+' + phoneState)
      } else {
        validContact = false
      }
      if (businessNumberState && businessNumberState.length !== '') {
        validBusinessNumber = isValidPhoneNumber('+' + businessNumberState)
      } else {
        validBusinessNumber = false
      }
      modifiedValues = {...values}

      if (phoneState ? phoneState && phoneState.length > 0 && validContact : true) {
        setIsContactValid(true)
        if (businessNumberState ? businessNumberState.length > 0 && validBusinessNumber : true) {
          setIsBusinessValid(true)

          if (validContact) modifiedValues.contact = phoneState
          if (validBusinessNumber) modifiedValues.businessNumber = businessNumberState
          if (modifiedValues.website === '') modifiedValues.website = null
          if (modifiedValues.streetAddress === '') modifiedValues.streetAddress = null
          if (modifiedValues.companyName === '') modifiedValues.companyName = null
          if (modifiedValues.city === '') modifiedValues.city = null
          if (modifiedValues.zipCode === '') modifiedValues.zipCode = null

          if (modifiedValues) {
            Object.entries(modifiedValues).forEach(([key, value]) => {
              if (value !== contact[key]) {
                valuesToEdit[key] = value
              }
            })
            valuesToEdit['fullName'] = modifiedValues['fullName'] ?? contact.fullName
          }

          try {
            await updateContactByContactIdHandler(contact.id, valuesToEdit)
            saveContactHandler()

            setTimeout(() => {
              handleClose()
            }, 3000)
          } catch (error: any) {
            const msg = error.response.data.message
            setErrorMsgRegistration(msg)
          }
        } else {
          setIsBusinessValid(false)
        }
      } else {
        setIsContactValid(false)
      }

      setTimeout(() => {
        setLoading(false)
        setSubmitting(false)
      }, 5000)
    },
  })

  const saveContactHandler = () => {
    const updatedContact =
      otherContactList?.length > 0 &&
      otherContactList?.map((item: any) =>
        item.id === contact?.id ? {...item, ...modifiedValues} : item
      )
    if (isHomeSearching) {
      dispatch(setUpdatedItem([...updatedContact]))
    } else {
      setOtherContactList(updatedContact)
    }
  }
  return (
    <div>
      <form
        className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework   ${
          theme === 'dark' ? 'theme__div' : ''
        } `}
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='w-100 min-h-350px '>
          <div className='row w-100 m-auto'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>
                Name<span className='text-danger'>*</span>
              </div>
              {/* </div> */}
              <input
                placeholder='Enter Name'
                autoComplete='off'
                type='text'
                {...formik.getFieldProps('fullName')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {
                    'is-invalid': formik.touched.fullName && formik.errors.fullName,
                  },
                  {
                    'is-valid': formik.touched.fullName && !formik.errors.fullName,
                  }
                )}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.fullName as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='row w-100 m-auto'> */}
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>
                Email Address<span className='text-danger'>*</span>
              </div>
              {/* </div> */}
              <input
                disabled
                placeholder='Enter Email Address'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.email as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-5'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Contact Number</div>
              {/* </div> */}
              {/* <input
                placeholder='Enter Contact Number'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('contact')}
                onBlur={validateNumber}
                className={clsx(
                  'form-control form-control-lg form-control-solid dark__theme__input italics-placeholder mt-5 mt-2',
                  { 'is-invalid': formik.touched.contact && formik.errors.contact },
                  {
                    'is-valid': formik.touched.email && !formik.errors.contact,
                  }
                )}
              /> */}
              <div className='mt-2'>
                <PhoneInput
                  placeholder='+44 7700 900077'
                  inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                  dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                  containerClass={theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'}
                  country={'44'}
                  value={phoneState}
                  onChange={(phone) => setPhoneState(phone)}
                />
              </div>

              {!isContactValid && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {'Please Enter Valid Contact Number!'}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* </div> */}
            {/* <div className='row w-100 m-auto'> */}
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Business Number</div>
              {/* </div> */}
              <div className='mt-2'>
                <PhoneInput
                  placeholder='+44 77 0090 0077'
                  inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                          dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                          containerClass={theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'}
                  // country={'44'}
                  masks={{ gb: '.. .... ....' }}
                  value={businessNumberState}
                  onChange={(phone) => setBusinessNumberState(phone)}
                />
              </div>

              {!isBusinessNumberValid && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {'Please Enter Valid Business Number!'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-2'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>City</div>
              {/* </div> */}
              <input
                placeholder='Enter City'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('city')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.city && formik.errors.city},
                  {
                    'is-valid': formik.touched.city && !formik.errors.city,
                  }
                )}
              />
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.city as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* </div> */}
            {/* <div className='row w-100 m-auto'> */}
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Post/Zip Code</div>
              {/* </div> */}
              <input
                maxLength={10}
                placeholder='Enter Zip Code'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('zipCode')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.zipCode && formik.errors.zipCode},
                  {
                    'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
                  }
                )}
              />
              {formik.touched.zipCode && formik.errors.zipCode && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.zipCode as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-5'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Website </div>
              {/* </div> */}
              <input
                placeholder='Enter Website Link'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('website')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.website && formik.errors.website},
                  {
                    'is-valid': formik.touched.website && !formik.errors.website,
                  }
                )}
              />
              {formik.touched.website && formik.errors.website && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.website as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* </div> */}
            {/* <div className='row w-100 m-auto'> */}
            {/* <div className='col-4 w-25 m-auto'> */}
            {/* </div> */}
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Street Address </div>
              <input
                placeholder='Enter Street Address'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('streetAddress')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.streetAddress && formik.errors.streetAddress},
                  {
                    'is-valid': formik.touched.streetAddress && !formik.errors.streetAddress,
                  }
                )}
              />
              {formik.touched.streetAddress && formik.errors.streetAddress && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.streetAddress as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mt-5 m-auto w-100   '>
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Company Name</div>
              {/* </div> */}
              <input
                placeholder='Enter Company Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('companyName')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.companyName && formik.errors.companyName},
                  {
                    'is-valid': formik.touched.companyName && !formik.errors.companyName,
                  }
                )}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.companyName as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className=' fs-5 fw-semibold mt-3'>Role</div>
              <input
                placeholder='Enter Contact Role'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('designation')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.designation && formik.errors.designation},
                  {
                    'is-valid': formik.touched.designation && !formik.errors.designation,
                  }
                )}
              />
              {formik.touched.designation && formik.errors.designation && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.designation as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='my-3 mx-2'>
            <CustomAlert
              variant={'info'}
              alertMessage={contactEditedMsg}
              setAlertMessage={setContactEditedMsg}
              time={5000}
            />
          </div>
          <div className='my-3'>
            <CustomAlert
              variant={'danger'}
              alertMessage={errorMsgRegistration}
              setAlertMessage={setErrorMsgRegistration}
              time={5000}
            />
          </div>

          <div className='my-3'>
            <CustomAlert
              variant={'info'}
              alertMessage={isInvited}
              setAlertMessage={setIsInvited}
              time={5000}
            />
          </div>
        </div>
        <div className='d-flex flex-column-reverse flex-sm-row justify-content-sm-center justify-content-lg-end justify-content-md-end gap-2 w-100'>
          <div className='d-flex justify-content-center justify-content-lg-end justify-content-md-end pt-4'>
            <button
              type='button'
              className='btn btn-secondary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                formik.resetForm()
                handleClose()
                // setFormValues({})
                // dispatch(setContactDetailSlice({currentDetail:{}}))
              }}
            >
              Cancel
            </button>
          </div>
          <div className=' d-flex justify-content-center justify-content-lg-end justify-content-md-end pt-4'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>{buttonTitle}</span>}
              {loading && <span className='indicator-label'>Loading...</span>}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const EditContacts = React.memo(memoizedEditContacts)
export default EditContacts

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const postCardComment = async (data: any, userId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/comment?userId=${userId}`,
    data
  )
}

export const createCardComment = async (data: any, userId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/comment?userId=${userId}`,
    data
  )
}

export const getCardComment = async (cardId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL}/card/comment/${cardId}`)
}

//onComments Update

export const updateComments = async (firebaseId: string, data: any) => {
  try {
    return await apiService.patch(
      `${process.env.REACT_APP_BACKEND_URL_V3}/card/comment/${firebaseId}`,
      data
    )
  } catch (error) {
    return error
  }
}
export const deleteComments = async (firebaseId: string, userId: string , boardId : string) => {
  try {
    return await apiService.delete(
      `${process.env.REACT_APP_BACKEND_URL_V3}/card/comment/${firebaseId}?userId=${userId}&boardId=${boardId}`
    )
  } catch (error) {
     return error
  }
}

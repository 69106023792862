import React, {useState, useEffect} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {longWordSlicingWithCustomLength} from '../../helper-functions/CheckString'
import {useTheme} from '../../context/ThemeContext'
import useGetDescription from '../../hooks/useGetCardDescription'
import * as Yup from 'yup';
import {useFormik} from 'formik'
import clsx from 'clsx'

const titleSchema = Yup.object({
  cardName: Yup.string()
  .min(3, 'Minimum 3 Characters')
  .max(300, 'Maximum 300 Characters')
  .required('Task Name is Required')
  .trim(),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CloneCardModal = ({
  showModal,
  closeModal,
  onCloneHandler,
  card,
  loading,
  isHomePage,cardDescription
}: any): React.JSX.Element => {
  const {theme} = useTheme()
  const initialValues = { cardName:card?.card_name }
  const [formData, setFormData] = useState<any>({
    card_name: true,
    description: true,
    due_date: true,
    assignees: true,
    file_attachments: true,
    priority: true,
    status: true,
    tags: true,
  })

  const formik = useFormik({
    initialValues,
    validationSchema: titleSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCloneHandler(card, selectedKeys,cardDescription || description,values.cardName)
    }})

  const [selectAll, setSelectAll] = useState<any>(true)
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const { cardDescription: description } = cardDescription ? { cardDescription } : useGetDescription(card?.id);


  const labelMapping: Record<string, string> = {
    card_name: 'Title',
    description: 'Description',
    due_date: 'Due Date',
    assignees: 'Assigned to:',
    file_attachments: 'Attachments',
    priority: 'Priority',
    status: 'Status',
    tags: 'Tags',
  }

  const handleCheckboxChange = (key: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [key]: !prevFormData[key],
    }))

    // setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]));
  }

  const handleSelectAllChange = () => {
    setSelectAll((prevSelectAll: any) => !prevSelectAll)
    const allChecked = !selectAll
    setFormData({
      card_name: true,
      description: allChecked,
      due_date: allChecked,
      assignees: isHomePage || allChecked,
      file_attachments: allChecked,
      priority: allChecked,
      status: allChecked,
      tags: allChecked,
    })

    // setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]));
  }

  useEffect(() => {
    setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]))
    const isAllTrue = Object.values(formData).every((value: any) => value === true)
    setSelectAll(isAllTrue)
  }, [formData])

  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={showModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className={`text-truncate ${theme === 'dark' ? 'white__text ' : 'text-truncate'}`}
        >
          Clone
          <span className='text-muted' style={{marginLeft: '8px'}}>
            {card.card_name}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Are you sure you want to clone this card? */}
        <div>
          <h3 className={theme === 'dark' ? 'white__text' : ''}>Select fields to duplicate:</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className='form-check mb-3 my-4'>
              <input
                type='checkbox'
                className={`form-check-input ${theme === 'dark' ? 'dark__theme__input' : ''} `}
                id='selectAll'
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
              <label
                className={`form-check-label ${theme === 'dark' ? 'white__text' : ''}`}
                htmlFor='selectAll'
              >
                Select All
              </label>
            </div>
            <hr />
            {Object.keys(formData).map((key: any) => (
              <div key={key} className='form-check mb-3 my-4'>
                <input
                  type='checkbox'
                  className={`form-check-input ${theme === 'dark' ? 'dark__theme__input' : ''} `}
                  id={key}
                  checked={formData[key]}
                  onChange={() => key !== 'card_name' && handleCheckboxChange(key)}
                  disabled={(key === 'assignees' && isHomePage) || key === 'card_name'}
                />
                <label className='form-check-label' htmlFor={key}>
                  {labelMapping[key]}
                </label>
                {key === 'card_name' && (
                  <div>
                     <input
                      type='text'
                      placeholder='Enter Task Name'
                      {...formik.getFieldProps('cardName')}
                      className={clsx(
                        `form-control form-control-lg form-control-solid  ${
                          theme === 'dark' ? 'dark__theme__input' : ''
                        }  italics-placeholder`,
                        {
                          'is-invalid': formik.touched.cardName && formik.errors.cardName,
                        },
                        {
                          'is-valid': formik.touched.cardName && !formik.errors.cardName,
                        }
                      )}
                    />
                        {formik.touched.cardName && formik.errors.cardName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.cardName as any}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>
            ))}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className={`${
            theme === 'dark' ? 'white__text' : ''
          } d-flex justify-content-between w-100 align-items-center`}
        >
          {loading ? (
            <div className='fs-5'>
              <Spinner
                animation='border'
                variant={theme === 'dark' ? 'light' : 'dark'}
                size='sm'
                className='mx-2'
              />
              Cloning{' '}
              <span
                className={theme === 'dark' ? 'white__text' : 'text-dark'}
                style={{wordBreak: 'break-all'}}
              >
                &quot;{longWordSlicingWithCustomLength(card.card_name, 15)}&quot;
              </span>
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <Button variant='secondary mx-1' onClick={closeModal}>
              Cancel
            </Button>

            <Button
              disabled={loading}
              variant='primary mx-1'
              onClick={()=>formik.handleSubmit()}
              // onClick={() => onCloneHandler(card, selectedKeys,cardDescription || description)}
            >
              {loading ? <Spinner animation='border' variant='light' size='sm' /> : 'Clone'}
            </Button>
          </div>
        </div>
        {/* <Button variant='primary' onClick={() => onCloneHandler(card , selectedKeys)}>
                    Clone
                </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default CloneCardModal

import React from 'react';
import {getFileIcon} from "../../helper-functions/GetFileIcons"
import { useTheme } from '../../context/ThemeContext';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

interface FileIconProps {
  fileName?: string;
  showName?: string;
}

const FileIcon: React.FC<FileIconProps> = ({ fileName, showName }) => {
  const {theme} = useTheme()  
  const fileExtension =
      (showName as string || fileName as string )?.toLowerCase().split('.')[
        (showName as string|| fileName as string )?.toLowerCase().split('.').length - 1
      ] 
  
    const iconClassName = getFileIcon(fileExtension,theme);
  
    return (
        // <i className={`bi ${iconClassName} ${theme === 'dark' ? 'white__text' : 'text-dark'}`}></i>
       <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/${iconClassName}`)} />
    );
  };

  
  export default FileIcon
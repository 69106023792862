/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const createTemplate = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/template`, data)
}



export const getTemplatesByUserId = async (
  userId: string,
  limit?: string | number,
  page = 1,
  name?: string
) => {
  let response
  if (name) {
    response = await apiService.get(
      `${
        process.env.REACT_APP_BACKEND_URL_V3
      }/template/${userId}?limit=${limit}&page=${page}&name=${name}`
    )
  } else {
    response = await apiService.get(
      `${process.env.REACT_APP_BACKEND_URL_V3}/template/${userId}?limit=${limit}&page=${page}`
    )
  }

  return response
}

export const getOrganizationTemplatesByUserId = async (userId: string, orgId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/template/${userId}/${orgId}`)
}

export const getTemplatesByTemplateId = async (templateId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL_V2}/fetch/template/${templateId}`
  )
}

export const createLoadTemplate = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/template/load`, data)
}

export const updateTemplate = async (data: any, templateId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V2}/template/${templateId}`,
    data
  )
}
export const deleteTemplate = async (templateId: string) => {
  return await apiService.delete(
    `${process.env.REACT_APP_BACKEND_URL_V3}/template/${templateId}`,
  )
}

import React from "react"
const Skeleton = () :React.JSX.Element=> {
    return (
        <div
            className='text-center d-flex justify-content-center align-items-center'
            style={{
                backgroundColor: 'white',
                width: '310px',
                height: '60vh',
                overflowY: 'auto',
                padding: '0',
            }}
        >
            <div className='spinner-grow text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
            <div className='spinner-grow text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
            <div className='spinner-grow text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
            </div>
        </div>
    )
}

export default Skeleton
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'
import {backgroundBoardImageTypes} from '../../app/models/designBoards'

export const createDesignBoard = async (data: any, userId: string, boardId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V2}/board/design/${boardId}?userId=${userId}`,
    data
  )
}
export const createDesignBoardCategory = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/board/category`, data)
}
export const getDesignBoardCategory = async (boardId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V2}/board/category/${boardId}`)
}

export const getBoardDesign = async (boardId: string, userId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL}/board/${boardId}?userId=${userId}`)
}

export const deleteDesignFileAttachment = async (boardId: string, fileKey: string) => {
  return await apiService.delete(
    `${process.env.REACT_APP_BACKEND_URL_V3}/board/design/file-attachments/${boardId}?fileId=${fileKey}`
  )
}

export const deleteBoardCategory = async (categoryId: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL_V2}/board/category/${categoryId}`)
}

export const updateBoardCategory = async (categoryId: string, data: any) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V2}/board/category/${categoryId}`,
    data
  )
}
export const updateBoardDesignColumnPositions = async (
  data: any,
  boardId: string,
  userId: string
) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V2}/board/design/${boardId}/column/${userId}`,
    data
  )
}

export const getBoardDesignColumnPositions = async (boardId: string, userId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL_V2}/board/design/${boardId}/column/${userId}`
  )
}

export const updateBackgroundBoardImage = async (boardId: string, payload: backgroundBoardImageTypes) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V3}/board/design/image/${boardId}`,
    payload
  )
}

import axios, { AxiosResponse } from 'axios'
import { cryptoDecrypt } from "../helper-functions/encryption"

const apiService = axios.create({
  baseURL: '',
  // You can add other Axios configurations here
})

function whitelisted(url: string, method: string, data?: any) {
  return (
    url.includes('auth/login') ||
    (url.includes('/api/v3/user') && typeof data === "string" && method === 'post') ||
    (url.includes('/api/v3/organization') && typeof data === "string" && method === 'post') ||
    (url.includes('/api/v1/user/profile-recovery') && typeof data === "string" && method === 'post') ||
    (url.includes('/api/v3/organization/member') && typeof data === "string" && typeof data === "string" && method === 'post') ||
    (url.includes('/api/v3/other/contact') && ['patch', 'post'].includes(method)) ||
    url.includes('user/delete/profile') ||
    url.includes('auth/change/password') ||
    url.includes('auth/reset/password') ||
    (url.includes('/other/contact/board') && method === 'get') ||
    (url.includes('/other/contact') && method === 'get')
  );
}


function handleErrorResponse(response: any) {
 
  if (whitelisted(response.config.url!, response.config.method!, response.data)) {
    const decryptData = JSON.parse(cryptoDecrypt(response.data))

    return { ...response, data: decryptData }
  }
  else {
    return { ...response }
  }

}

apiService.interceptors.request.use(
  (config: any) => {
    const authToken: any = localStorage.getItem('auth')
    if (authToken) {
      config.headers.Authorization = 'Bearer ' + JSON.parse(authToken).token
    }
    return config
  },
  (error: any) => {
    // if (response && response.status === 403) {
    // const authToken:any = localStorage.getItem('auth');
    // if (authToken) {
    //     handleForbiddenError()
    //   }
    //   }
    return Promise.reject(error)
  }
)

apiService.interceptors.response.use(
  (response: AxiosResponse) => {
    if (whitelisted(response.config.url!, response.config.method!, response.data)) {
      const decryptData = JSON.parse(cryptoDecrypt(response.data))
      return { ...response, data: decryptData }
    }
    else {
      return response
    }
  },

  (error: any) => {
    const response = handleErrorResponse(error.response)
    // debugger
    // console.log('response: ', response);


    if (
      response &&
      response.status === 403 &&
      response.data.message.includes('license has expired or it has been canceled')
    ) {
      const authToken: any = localStorage.getItem('auth')

      if (authToken) {
        handleForbiddenError()
      }
    }

    if (
      response &&
      response.status === 403 &&
      response.data.message
        ?.toLowerCase()
        .includes('Your subscription has been expired kindly renew it.'.toLowerCase())
    ) {
      window.location.href = '/billing/billing-details'
    }

    if (
      response &&
      response.status === 401 &&
      response.data.message?.toLowerCase().includes('TokenExpiredError'.toLowerCase())
    ) {
      window.sessionStorage.setItem('isTokenExpire', 'true')
    }

    return Promise.reject({
      response
    })
  }
)

const handleForbiddenError = () => {
  localStorage.removeItem('auth')
  localStorage.removeItem('persist:root')
  window.location.reload()
}

export default apiService

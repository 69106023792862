import { FC } from 'react'
import { useListView } from '../../core/ListViewProvider'
import { UsersListToolbar } from './UserListToolbar'
import { UsersListGrouping } from './UsersListGrouping'
import { UsersListSearchComponent } from './UsersListSearchComponent'
// import { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UsersListHeader:FC<any> = ({setShow}:any) => {
  const { selected } = useListView()
  // const boardFromReducer = useSelector((state: any) => state.boardReducer)
  // const { data: boards } = boardFromReducer?.entities

  // const [boardName, setBoardName] = useState<any>("")

  // useEffect(() => {
  //   if (boards !== undefined) {
  //     let name = boards.find((item: any) => item.board.id === boardIdState)?.board?.name
  //     setBoardName(name)
  //   }
  //   //eslint-disable-next-line
  // }, [boardIdState])


  return (
    <div className='card-header border-0 pt-6'>
      {/* <h4 className='p-2'>Board:
        {boards === undefined ? " N/A" :
          <span className='text-primary fs-4'>{" "}
            {
              boardName || boards[0]?.board.name

            }
          </span>
        }

      </h4> */}
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar setShow={setShow}
        // setBoardIdState={setBoardIdState} 
        />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { UsersListHeader }

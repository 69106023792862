import {useState} from 'react'

const useTogglePassword = (): {
  passwordType: string;
  confirmPasswordType: string;
  newPasswordType: string;
  togglePassword: () => void;
  toggleNewPassword: () => void;
  toggleConfirmPassword: () => void;
}=> {
  const [passwordType, setPasswordType] = useState('password')
  const [confirmPasswordType, setConfirmPasswordType] = useState('password')
  const [newPasswordType, setNewPasswordType] = useState('password')

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text')
      return
    }
    setConfirmPasswordType('password')
  }
  const toggleNewPassword = () => {
    if (newPasswordType === 'password') {
      setNewPasswordType('text')
      return
    }
    setNewPasswordType('password')
  }

  return {
    passwordType,
    confirmPasswordType,
    newPasswordType,
    togglePassword,
    toggleNewPassword,
    toggleConfirmPassword,
  }
}

export default useTogglePassword

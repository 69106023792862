import { useTheme } from '../../context/ThemeContext'
import '../../styles/pricingStyle.css'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Pricing = ({setActiveCard,activeCard}:any) => {
  const {theme} =useTheme()
  
  const handleCardClick = (index:any) => {
    setActiveCard(index)
  }
  return (
    <section id='pricing'>
      <div className='my-5 mx-2'>
        <div className='line m-auto'></div>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-6 my-2 col-sm-12 col-sx-12'>
            <div
              
              className={`col-lg-12 col-md-12 my-2 col-sm-12 pricing-card pe-auto text-center mt-30 wow fadeIn py-2 px-0 ${
                activeCard === 0 ? 'active' : ''
              }`}
              data-wow-duration='1s'
              data-wow-delay='0.2s'
              onClick={() => handleCardClick(0)}
            >
              <div className='pricing-content mt-30'>
                <h3 className={`pricing-title wow fadeInUp ${theme === 'dark' && activeCard === 1  && 'white__text'}`}>Month to Month</h3>
                <div className={`mt-5 wow fadeInUp ${activeCard === 1 && theme !== 'dark' ? 'text-dark' : ''}`}>
                  <span className='mb-2 dollar'>$</span> <span className='price'>5.99</span> /user/month
                </div>
                <div className='price-duration p-3 w-100 mt-3 mb-5'>Billed Monthly</div>
                
  
                {/* <span
                  className='main-btn wow fadeInUp my-4 btn btn-primary'
                  data-wow-duration='1.3s'
                  data-wow-delay='1.1s'
                >
                  Get Started
                </span> */}
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 my-2 col-sm-12 col-sx-12'>
            <div
              className={`col-lg-12 col-md-12 my-2 col-sm-12 pricing-card pe-auto text-center mt-30 wow fadeIn py-2 px-0 ${
                activeCard === 1 ? 'active' : ''
              }`}
              data-wow-duration='1s'
              data-wow-delay='0.2s'
              onClick={() => handleCardClick(1)}
            >
              <div className='pricing-content mt-30'>
              <h3 className={`pricing-title wow fadeInUp ${theme === 'dark' && activeCard === 0  && 'white__text'}`}>12 Month Signup</h3>
              <div className={`mt-5 wow fadeInUp ${activeCard === 0 && theme !== 'dark' ? 'text-dark' : ''}`}>
                  <span className='mb-2 dollar'>$</span> <span className='price'>4.99</span> /user/month
                </div>
                <div className='price-duration p-3 w-100 mt-3 mb-5'>Billed once per year</div>
                {/* <span
                  className='main-btn wow fadeInUp my-4 btn btn-primary'
                  data-wow-duration='1.3s'
                  data-wow-delay='1.1s'
                >
                  Get Started
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing

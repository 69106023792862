import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllBoardByUserId} from '../../services/boards'

// First, create the thunk
export const fetchOtherBoardByUserId = createAsyncThunk(
  'board/fetchOtherBoardByUserId',
  async (data: any) => {
    try {
      const response = await getAllBoardByUserId(data.userId,data.organizationId, data.isAdmin ,  data.limit , data.page)
      return response.data
    } catch (error: any) {
      // if (error.response.status === 401) {
      //   localStorage.clear()
      //   window.location.reload()
      // }
    }
  }
)

const initialState: any = {
  entities: {},
  loading: false,
  errors: {},
}

export const otherBoardSlice = createSlice({
  name: 'fetchOtherBoardByUserId',
  initialState,
  reducers: {
    setOtherBoardsUpdate: (state, action) => {
      state.entities.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOtherBoardByUserId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchOtherBoardByUserId.fulfilled, (state, action) => {
      state.entities = action.payload
      state.loading = false
    })
    builder.addCase(fetchOtherBoardByUserId.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export const {setOtherBoardsUpdate} = otherBoardSlice.actions
export default otherBoardSlice.reducer

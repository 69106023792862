import {Dropdown} from 'react-bootstrap'
import React from 'react'

import {useSelector} from 'react-redux'
import {useTheme} from '../../context/ThemeContext'

interface DropdownCloneProps {
  handleShowModal: (value: any) => void
  cardData: any
  category: string
  boardName?: string | undefined
}

const DropdownClone: React.FC<DropdownCloneProps> = ({handleShowModal, cardData,category}) => {
  const personRole: any = useSelector((state: any) => state.PersonRoleReducer)

  const {theme} = useTheme()

  return (
    <>
      {personRole?.role?.role === 'Guest' ? null : (
        <Dropdown>
          <div className={`d-flex justify-content-center  ${theme === 'dark' ? 'dark-options' : ''}`}>
            <Dropdown.Toggle
              size='sm'
              variant='light'
              className="px-1 py-0 copy-dropdown"
              style={{background: 'none'}}
            >
              <i className='bi bi-three-dots'></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className={`${theme === 'dark' ? 'layout__theme__div' : ''}`}>
              <Dropdown.Item
                className={` ${theme === 'dark' ? 'text-muted' : 'text-dark'}`}
                onClick={handleShowModal}
              >
                Clone
              </Dropdown.Item>
              {cardData?.key && !cardData?.key.includes('undefined') && (
                <Dropdown.Item
                className={` ${theme === 'dark' ? 'text-muted' : 'text-dark'}`}
                onClick={async () => {
                  const textToCopy = category === 'card' ? `/${cardData?.key.trim()}` : `#${cardData?.key.trim()}`
                  try {
                    await navigator.clipboard.writeText(textToCopy)
                  } catch (err) {
                    return err
                  }
                }}
              >
                Copy {category === 'card' ? 'ID': 'Title'}
              </Dropdown.Item>
            )}
            </Dropdown.Menu>
          </div>
        </Dropdown>
      )}
    </>
  )
}

export default DropdownClone

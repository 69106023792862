import Pagination from 'react-bootstrap/Pagination'
import React, {useEffect} from 'react'
import '../../styles/pagination.css'
import {useLocation} from 'react-router-dom'

interface CustomPagination {
  itemsCount: number
  itemsPerPage?: number
  currentPage: number
  setCurrentPage: any
  halfWidth? : boolean | undefined
}
function CustomPagination({
  itemsCount,
  itemsPerPage = 10,
  currentPage,
  setCurrentPage,
  halfWidth
}: CustomPagination): React.JSX.Element {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage)
  const isPaginationShown = pagesCount > 1
  const isCurrentPageFirst = currentPage === 1
  const isCurrentPageLast = currentPage === pagesCount
  const {pathname} = useLocation()

  const changePage = React.useCallback((number: number) => {
    setCurrentPage(number)
  }, [])

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber)
  }

  const onPreviousPageClick = () => {
    changePage(currentPage - 1)
  }

  const onNextPageClick = () => {
    changePage(currentPage + 1)
  }

  const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      setCurrentPage(pagesCount)
    }
  }

  let isPageNumberOutOfRange: boolean
  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1
    const isPageNumberFirst = pageNumber === 1
    const isPageNumberLast = pageNumber === pagesCount
    const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 2

    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
      isPageNumberOutOfRange = false
      return (
        <Pagination.Item
          className='main-pagination'
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      )
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true
      return <Pagination.Ellipsis key={pageNumber} className='muted' />
    }

    return null
  })

  useEffect(setLastPageAsCurrent, [pagesCount])
  return (
    <>
      <div className={halfWidth === true ? '' : 'w-100'}>
        {isPaginationShown &&
          (pathname?.includes('other-boards') ||
            pathname?.includes('my-boards') ||
            pathname?.includes('archived-boards')) && <hr />}

        {isPaginationShown && (
          <Pagination className='main-pagination'>
            <Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst} />
            {pageNumbers}
            <Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast} />
          </Pagination>
        )}
      </div>
    </>
  )
}

export default CustomPagination

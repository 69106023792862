import {useState} from 'react'

const useToggleConfirmPassword = () :any=> {
  const [confirmPasswordType, setConfirmPasswordType] = useState('password')

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text')
      return
    }
    setConfirmPasswordType('password')
  }
  return {confirmPasswordType, toggleConfirmPassword}
}

export default useToggleConfirmPassword

import clsx from 'clsx'
import {FC, useState} from 'react'
import {HeaderUserMenu} from '../../../partials'

import {NovuProvider, PopoverNotificationCenter} from '@novu/notification-center'
import {useAuth} from '../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import {setIsInviteAccepted} from '../../../../app/store/slice/invitation-accepted-slice'
import EditNewCard from '../../../../app/components/UpdateCard/EditBoardCard'
import {acceptInvitation, decodeInvitation} from '../../../../app/services/invitation'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {getAllCardByBoardId} from '../../../../app/services/cards'
import ThemeSwitch from '../../../../app/components/ThemeSwitch'
import {getAllBoardByUserId} from '../../../../app/services/boards'
import ToastComponent from '../../../../app/components/ToastComponent'
import {fetchBoardByUserId} from '../../../../app/store/slice/board-slice'
import {fetchOtherBoardByUserId} from '../../../../app/store/slice/other-board-slice'
import {fetchArchivedBoardByUserId} from '../../../../app/store/slice/archived-board'
import {createOrganizationMember, getOrganizationById} from '../../../../app/services/company'
import { useTheme } from '../../../../app/context/ThemeContext'
import { fetchAllBoards } from '../../../../app/store/slice/all-boards-slice'


const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
// toolbarButtonIconSizeClass = 'svg-icon-1'

const CustomBellIcon = ({unseenCount}: any) => {
  return (
    <>
      <span className='position-relative'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='25'
          fill='currentColor'
          className='bi bi-bell'
          viewBox='0 0 16 16'
        >
          <path d='M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z' />
        </svg>
       {unseenCount > 0 &&  
        <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
          {unseenCount}
        </span>}
      </span>
    </>
  )
}

const Topbar: FC = () => {
  // const { config } = useLayout()
  const {theme} = useTheme()
  const {currentUser} = useAuth()
  const {id, image,isInvitedByOrganization} = currentUser?.data.user
  const organizationId = isInvitedByOrganization && currentUser?.organizationId

  if (id === undefined) {
    localStorage.clear()
    ;(window as any).location.reload()
  }

  const navigate = useNavigate()

  const [cardModalShow, setCardModalShow] = useState(false)
  const [card, setCard] = useState({})
  const [alertMessage, setAlertMessage] = useState('')

  const isAccepted = useSelector((state: any) => state.inviteReducer)

  const {isInviteAccepted} = isAccepted.isInviteAccepted
  const dispatch: Dispatch<any> = useDispatch()

  const openUpdatedCardHandler = async (boardId: string, cardNumber: string) => {
    try {
      const boardRes = await getAllCardByBoardId(boardId, id)

      const getCard = boardRes.data.data.find(
        (item: any) => item.number.trim() === cardNumber.trim()
      )
      if (getCard) {
        setCard(getCard)
        setCardModalShow(true)
      } else {
        setAlertMessage('Card is not available to open!')
        setTimeout(() => {
          setAlertMessage('')
        }, 5000)
        return
      }
    } catch (error) {
      return error
    }
  }

  const decodeTokenHandler = async (token: string) => {
    try {
      const decodedResponse = await decodeInvitation({token, usage: 'boardInvite'})
      const decodedData = decodedResponse.data.decodeToken
      const payload = {
        email: decodedData.email,
        boardId: decodedData.boardId,
        roleId: decodedData.roleId,
        userId: id,
        isAdmin: decodedData.role.isAdmin,
      }
      try {
        await acceptInvitation(payload)
        dispatch(fetchAllBoards({userId:id,organizationId}))
      } catch (error: any) {
        const msg = error.response.data.message
        if (msg === 'jwt expired') {
          setAlertMessage('Invitation link Expired!')
        } else {
          setAlertMessage(msg)
        }

        setTimeout(() => {
          setAlertMessage('')
        }, 5000)

        return error
      }
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg === 'jwt expired') {
        setAlertMessage('Invitation link Expired!')
        setTimeout(() => {
          setAlertMessage('')
        }, 5000)
      }
      return error
    }
  }

  async function gotoBoardScreen(url: string) {
    if (url.includes('welcome')) {
      const urlSplitedWelcome: any = url.split('/welcome')
      const boardIdURL: string = urlSplitedWelcome[0]
      try {
        const res = await getAllBoardByUserId(id,organizationId, false)

        const boards = res.data.data
        if (boards?.length > 0) {
          const splitBoardId = urlSplitedWelcome[0].split('/board/')
          const isBoardExists = boards.find(
            (item: any) => item.boardId === splitBoardId[1]
          )?.boardId
          if (isBoardExists) navigate(boardIdURL)
        } else {
          return
        }
      } catch (error) {
        return error
      }
    }
  }

  function inviteAccepted(url: string) {
    if (url.includes('accept-invitation')) {
      setTimeout(() => {
        dispatch(setIsInviteAccepted({isInviteAccepted: !isInviteAccepted}))
      }, 3000)

      return
    }
  }

  async function organizationAccepted(notification: any) {
    const memberPayload = {
      organizationLicenseId: notification?.organizationLicenseId,
      organizationId: notification?.organizationId,
      email: notification?.email,
      role: notification?.role,
      userId: id,
    }
    const response = await createOrganizationMember(memberPayload)
    if (response.data) {
      const res = await getOrganizationById(notification?.organizationId)
      if (res.data?.data !== null) {
        if (notification?.organizationId) {
          dispatch(fetchBoardByUserId({userId: id,organizationId, isAdmin: true, page: 1}))
          dispatch(fetchOtherBoardByUserId({userId: id, isAdmin: false}))
          dispatch(fetchArchivedBoardByUserId({userId: id,organizationId}))
        }
        navigate('/home')
      }
    }
  }

  async function onNotificationClick(notification: any) {
    if (
      notification.payload?.organizationLicenseId &&
      notification.payload?.organizationLicenseId.length > 0
    ) {
      const {payload} = notification
      if (notification.seen !== true && notification.read !== true) {
        await organizationAccepted(payload)
      }
    }

    const url = notification.cta.data.url
    gotoBoardScreen(url)
    inviteAccepted(url)

    const token: any = url.split('token=')
    const urlSplited: any = url.split('/')
    let boardId = ''
    let cardNumber = ''
    if (urlSplited[1] === 'board') {
      boardId = urlSplited[2]
      cardNumber = urlSplited[3]
      if (cardNumber !== 'welcome') {
        openUpdatedCardHandler(boardId, cardNumber)
      }
    }
    if (token[1]) {
      decodeTokenHandler(token[1])
    } else {
      // navigate(url);
    }
  }

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        <ThemeSwitch />
        {/* NOTIFICATIONS */}

        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom me-3 ',
              toolbarButtonHeightClass
            )}
          >
            <NovuProvider
              i18n={{
                lang: 'eng',

                translations: {
                  poweredBy: 'B4IGO',
                  markAllAsRead: 'Mark all as Read',
                  notifications: 'Notifications',
                  settings: 'Settings',
                },
              }}
              subscriberId={id}
              applicationIdentifier={`${process.env.REACT_APP_APPLICATION_IDENTIFIER}`}
            >
              <PopoverNotificationCenter
                colorScheme={theme === 'dark' ? 'dark': 'light'}
                onNotificationClick={onNotificationClick}
              >
                {({unseenCount}) => <CustomBellIcon unseenCount={unseenCount} />}
              </PopoverNotificationCenter>
            </NovuProvider>
          </div>
        </div>
        <div
          className={clsx(`d-flex align-items-center `, toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx(`cursor-pointer symbol user_profile ${theme === 'dark' ? 'theme__div': ''}`, toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              src={image ? image : '/media/person-fill.svg'}
              alt='profile'
              style={{objectFit: 'contain'}}
            />
          </div>
          <HeaderUserMenu />
        </div>
      </div>

      {alertMessage?.length > 0 && (
        <ToastComponent alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      )}
      {cardModalShow && (
        <EditNewCard
          cardDetails={card}
          cardModalShow={cardModalShow}
          setCardModalShow={setCardModalShow}
        />
      )}
    </>
  )
}

export {Topbar}

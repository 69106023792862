/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'
import { cryptoEncrypt } from "../../app/helper-functions/encryption"

export const forgotPassword = async (email: string) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/auth/forgot/password`, { email })
}

export const resetPassword: any = async (data: any, token: string) => {

  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/auth/reset/password?token=${token}`,
    { data: cryptoEncrypt(data) }
  )
}
export const changeCurrentPassword = async (data: any, userId: string) => {

  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/auth/change/password/${userId}`,
    { data: cryptoEncrypt(data) }
  )
}

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  getAllCardByBoardId,
  getSortedCardByBoardId,
  sortCardByBoardId,
} from '../../../services/cards'
import {useSelector, useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {getBoard} from '../../../services/boards'
import BoardDnD from '../../../components/BoardDnD/BoardDnD'
import ColumnButton from '../../../components/BoardDnD/ColumnButton'
import BoardHeader from '../../../components/Board/BoardHeader/BoardHeader'
import useGetColumns from '../../../hooks/useGetColumns'
import useGetMember from '../../../hooks/useGetMember'
import useBoardTitle from '../../../hooks/useBoardTitle'
import '../../../styles/boardpage.css'
import {useAuth} from '../../auth'
import Skeleton from 'react-loading-skeleton'
import {fetchAllCardsofLoggedInUser, fetchAllCardByBoardId} from '../../../store/slice/card-slice'
import { setShowTour } from '../../../store/slice/tour-slice'
import useGetTags from '../../../hooks/useGetTags'
import { setTag } from '../../../store/slice/selected-tags'
import useCompany from '../../../hooks/useCompany'

// import { fetchAllCardsofLoggedInUser, fetchAllSortedCard } from '../../../store/slice/card-slice'
// import { useAuth } from '../../auth'
// import { fetchBoardByUserId } from '../../../store/slice/board-slice'
// import { useDispatch } from 'react-redux'
// import { Dispatch } from '@reduxjs/toolkit'
// import useGetCards from '../../../hooks/useGetCards'

export function Board(): React.JSX.Element {
  const boardFromReducer = useSelector((state: any) => state.boardReducer)
  const {data: boards} = boardFromReducer?.entities
  const [cards, setCards] = useState<any>([])
  const [filteredCards, setFilteredCards] = useState<any>([])
  const [boardCardFetchLoading , setBoardCardFetchLoading] = useState(false)
  const params: any = useParams()
  const board = boards?.filter((item: any) => {
    return params.boardId === item.board.id
  })
  const {organizationDetailsHandler} = useCompany()
  const { isFirstLogin } = useSelector((state: any) => state.tourSliceReducer);

  const currentBoardName = board?.length > 0 && board[0]?.board.name.trim()
  const [boardTitle, setBoardTitle] = useState((board?.length > 0 && board[0]?.board.name.trim()) || '')
  const [checked, setChecked] = useState(board?.length > 0 && board[0]?.board.sorted)
  const {isEditable, setIsEditable, newTitle, updateBoardTitleHandler} = useBoardTitle(
    params.boardId,
    boardTitle,
    checked,
    currentBoardName,
    setBoardTitle
  )
  const {
    columnResponse,
    setColumnsResponse,
    isColumnLoading,
    isColumnLoadSkeleton,
    fetchColumnHandler,
  } = useGetColumns(params.boardId)
  const {boardPerson, getMembersHandler} = useGetMember(params.boardId)
  const isRenderStore = useSelector((state: any) => state.isRenderReducer)
  const {isRender} = isRenderStore.isRender
  const dispatch: Dispatch<any> = useDispatch()
  const {selectedTags}:any = useSelector((state: any) => state.selectedTagsReducer)
  const selectedTagId = selectedTags?.find((board:any)=>board.boardId === params.boardId)?.card

  const {currentUser} = useAuth()
  const {id} = currentUser?.data.user

  const { getCardTagByBoardHandler,loadingBoardTags } = useGetTags()
  const [selectedTag, setSelectedTag] = useState<any>(selectedTagId || null)
  const { boardTags } = useSelector((state: any) => state.boardTagsReducer)

  const handleTagClick = (tagId: string) => {
    setSelectedTag(selectedTag === tagId ? null : tagId)
    dispatch(setTag({
      boardId: params.boardId,
      type: 'card',
      tagId: tagId
    }));
  }
  
  const getFilteredCards = () => {
    let filteredCards = cards || [];
    if (selectedTag) {
      filteredCards = filteredCards?.filter((page: any) =>
        page?.tags?.some((tag: any) => tag.id === selectedTag)
    )
    // if(filteredCards.length < 1) setSelectedTag(null)
    // return filteredCards;
    setFilteredCards(filteredCards)
    }
    setFilteredCards(filteredCards)

  }
useEffect(() => {
  getFilteredCards()
}, [cards,selectedTag])

  useEffect(() => {
    if (isFirstLogin) organizationDetailsHandler()
  }, [isFirstLogin])

// const filteredCards: any = getFilteredCards();

  const fetchCardHandler = async (boardId: string) => {
    try {
      setBoardCardFetchLoading(true)
      // setCardLoading(true)
      const res = await getAllCardByBoardId(boardId, id)
      if (res.data.data?.length > 0) {
        setCards(res.data.data)
      }
      return res.data?.data
    } catch (error) {
      return error
    }finally{
      setBoardCardFetchLoading(false)
    }
  }
  const fetchSortedCardHandler = async (boardId: string) => {
    try {
      // setCardLoading(true)
      const res = await getSortedCardByBoardId(boardId)
      if (res.data.data?.length > 0) {
        setCards(res.data.data)
      }
    } catch (error) {
      return error
    }
  }

  const changeHandlerSort = async (checked: boolean): Promise<void> => {
    try {
      const payload = {
        sorted: checked,
      }
      await sortCardByBoardId(payload, params.boardId, id)
      setChecked(checked)
    } catch (error) {
      // console.log(error)
    }
  }

  const getBoardHandler = async () => {
    try {
      const res = await getBoard(params.boardId, id)
      // setOtherBoard(res.data.data.board)
      const {board} = res.data.data
      setChecked(board.sorted)
      setBoardTitle(board.name.trim())
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (checked) {
      fetchSortedCardHandler(params.boardId)
    } else if (columnResponse.length > 0) {
      fetchCardHandler(params.boardId)
    }
    //eslint-disable-next-line
  }, [checked, columnResponse, isRender])

  function getAllCardsByUserId() {
    dispatch(fetchAllCardsofLoggedInUser())
    dispatch(fetchAllCardByBoardId({boardId: params.boardId, userId: id}))
  }

  useEffect(() => {
    getBoardHandler()
    getAllCardsByUserId()
    getCardTagByBoardHandler(params.boardId)

    setTimeout(() => {
    dispatch(setShowTour(true))
    }, 5000);

    // dispatch(fetchBoardByUserId({ userId: id, isAdmin: true }))
    //eslint-disable-next-line
  }, [params.boardId])

  return (
    <>
      {/* Board Page Header */}
      {isColumnLoading ? (
        <div className='ms-3 w-25'>
          <Skeleton count={2} height={20} />
        </div>
      ) : (
        <BoardHeader
          personRole={boardPerson?.role}
          setIsEditable={setIsEditable}
          onUpdateTitle={updateBoardTitleHandler}
          isEditableTitle={isEditable}
          setBoardTitle={setBoardTitle}
          boardTitle={boardTitle || (board?.length > 0 && board[0]?.board.name)}
          newTitle={newTitle}
          onChangeSortBtn={changeHandlerSort}
          boardId={params.boardId}
          isSorted={checked}
          cards={cards}
          columnResponse={columnResponse}
          fetchColumnHandler={fetchColumnHandler}
          boardTags={boardTags}
          selectedTag={selectedTag}
          handleTagClick={handleTagClick}
          loadingBoardTags={loadingBoardTags}
        />
      )}
      <div style={{marginRight: 20}}>
        {boardPerson?.role === 'Admin' ? (
          <div className='my-16 pt-1'></div>
        ) : (
          <div className='my-16 pt-1'></div>
        )}

        {/* Add Column Button */}
        {!columnResponse && boardPerson?.role === 'Admin' && (
          <ColumnButton columnResponse={columnResponse} setColumnsResponse={setColumnsResponse} />
        )}

        {/* Drag n Drop columns and card component  */}
        {columnResponse ? (
          <BoardDnD
            isSorted={checked}
            role={boardPerson?.role}
            getMembersHandler={getMembersHandler}
            boardId={params.boardId}
            columnResponse={columnResponse}
            setColumnsResponse={setColumnsResponse}
            isColumnLoading={isColumnLoading}
            cards={filteredCards}
            allCards={cards}
            setCards={setCards}
            fetchSortedCardHandler={fetchSortedCardHandler}
            fetchCardHandler={fetchCardHandler}
            fetchColumnHandler={fetchColumnHandler}
            isColumnLoadSkeleton={isColumnLoadSkeleton}
            boardCardFetchLoading={boardCardFetchLoading}

          />
        ) : (
          <h3 className='d-flex justify-content-center mt-20 fs-6'>Columns are not added yet!</h3>
        )}
      </div>
    </>
  )
}

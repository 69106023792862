import React from 'react'
import { useParams } from "react-router-dom"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useListView } from "../core/ListViewProvider"
// import { useGetAllBoards } from "../../../../hooks"
import { capitalizeEachWord } from "../../../../helper-functions/CapitalizeName"
import { useTheme } from '../../../../context/ThemeContext'
import { useSelector } from 'react-redux'

const UserEditModalHeader: React.FC<any> = ({handleClose}:any) => {
  const { setItemIdForUpdate } = useListView()
  const {theme} = useTheme()
  const params = useParams()
  const {allBoards} = useSelector((state: any) => state.allBoardsReducer)

  // const { allBoards } = useGetAllBoards()
  const board = allBoards?.find((item: any) => item.board.id === params.boardId)

  return (
    <div className='modal-header'>
      <h2 className={`fw-bolder ${theme === 'dark' ? 'white__text' : ''}`}>Invite To {capitalizeEachWord(board?.board.name)}  
      </h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {handleClose()
          setItemIdForUpdate(undefined)
        }}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

export { UserEditModalHeader }
import {useEffect, useState} from 'react'
import {getSubscriptions} from '../services/subscription'
import {useAuth} from '../modules/auth'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { setIsSubscription } from '../store/slice/subscription-slice'

const useGetSubscriptionDetails = (): { subscriptionDetails: unknown, subscriptionHandler: () => Promise<unknown> }  => {
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>()
  const {currentUser} = useAuth()
  const {id} = currentUser?.data.user
  const dispatch: Dispatch<any> = useDispatch()

  const subscriptionHandler = async () => {
    try {
      const response = await getSubscriptions(id)
      setSubscriptionDetails(response.data.data)
      if (response.data.data.isExpire) {
        setTimeout(() => {
         dispatch(setIsSubscription({ isSubscription: 'cancel' }))
        }, 3000);
     }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    subscriptionHandler()
    //eslint-disable-next-line
  }, [])

  return {subscriptionDetails, subscriptionHandler}
}

export default useGetSubscriptionDetails

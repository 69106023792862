import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  redirectUrl: '/home/board',
}

export const RedirectionSlice = createSlice({
  name: 'RedirectionSlice',
  initialState,
  reducers: {
    setRedirectTo: (state, action) => {
      state.redirectUrl = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setRedirectTo} = RedirectionSlice.actions

export default RedirectionSlice.reducer

import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  isSubscription: 'active',
}

export const subscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  initialState,
  reducers: {
    setIsSubscription: (state, action) => {
      state.isSubscription = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setIsSubscription} = subscriptionSlice.actions

export default subscriptionSlice.reducer

import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {db} from '../../../index'
import {collection, query, orderBy, onSnapshot, limit} from 'firebase/firestore'
import SingleComments from './SingleComments'
import ImageModal from './ImageModal'
import Skeleton from 'react-loading-skeleton'
import 'react-photo-view/dist/react-photo-view.css'
import { useTheme } from '../../context/ThemeContext'


const ReadComments = forwardRef(
  (
    {
      sendAlertToBoardPerson,
      optionsForSendAlert,
      setSendAlertToComment,
      sendAlertToComment,
      boardPersonsForSendAlert,
      cardDetails,
      sendComment,
      handleFormSubmission,
    }: any,
    ref: any
  ) => {
    const {boardId, id: cardId} = cardDetails
    const [cardSingleComments, setCardSingleComments] = useState<any>([])
    const [commentsLoading, setCommentsLoading] = useState(true)
    const [latestComments, setLatestComments] = useState<any>('desc')
    const editRef:any = useRef();
    const {theme} =useTheme()

    const childSingleCommentRef: any = useRef()
    const sortCommentHandler = () => {
      if (latestComments === 'asc') {
        setLatestComments('desc')
      } else {
        setLatestComments('asc')
      }
    }
    useEffect(() => {
      // setCommentsLoading(true)
      const q = query(
        collection(
          db,
          `comments-module/${
            process.env.REACT_APP_FIREBASE_ENVIRONMENT
          }/boards/${boardId}/cards/${cardId}/comments`
        ),
        orderBy('createdAt', latestComments),
        limit(50)
      )
      const unsubscribe: any = onSnapshot(q, (QuerySnapshot) => {
        const singleComments: any = []
        QuerySnapshot.forEach((doc) => {
          singleComments.push({...doc.data(), id: doc.id})
        })
        setCardSingleComments(singleComments)
      })
      setCommentsLoading(false)
      return () => unsubscribe
      //eslint-disable-next-line
    }, [sendComment, latestComments])

    useEffect(() => {
      let imageSrc: any
      const editImg: any = document.querySelectorAll(`div.ql-editor[contenteditable="false"] img`)
      // let videoIframe: any = document.querySelectorAll(`div.ql-editor[contenteditable="true"] iframe`)

      // for (let index = 0; index < videoIframe.length; index++) {
      //   videoIframe[index].addEventListener('click', () => {
      //     let videoSrc = videoIframe[index].getAttribute('src')
      //     setVideo(videoSrc)
      //     setShowVideo(true)
      //   })
      // }

      for (let i = 0; i < editImg.length; i++) {
        editImg[i].classList.add('cursor-pointer')
        editImg[i].classList.add('d-block')
        editImg[i].style.maxWidth = '30%'
        //eslint-disable-next-line
        editImg[i].addEventListener('click', () => {
          //eslint-disable-next-line
          imageSrc = editImg[i].getAttribute('src')
          editRef.current?.handleModal(imageSrc)
        })
      }

      //eslint-disable-next-line
    }, [cardSingleComments])

    useImperativeHandle(ref, () => ({
      childRead() {
        if (cardSingleComments.length > 0) {
          childSingleCommentRef.current.updateChildSingleComment()
        }
      },
    }))
    return (
      <div ref={ref}>
        <div className='d-flex justify-content-between mt-15'>
          <h4 className={`d-flex align-items-center mb-0 ${theme === 'dark'?'white__text':''}`}>
            {latestComments === 'asc' ? 'Earliest Comments' : 'Latest Comments'}
          </h4>
          <button type='button' className='btn btn-light' onClick={sortCommentHandler}>
            {latestComments === 'asc' ? (
              <i className='bi bi-sort-down mb-0' style={{fontSize: '2.1rem'}}></i>
            ) : (
              <i className='bi bi-sort-up-alt mb-0' style={{fontSize: '2.1rem'}}></i>
            )}
          </button>
        </div>
        <>
          {commentsLoading === false ? (
            <>
              {cardSingleComments.map((comment: any, index: number) => (
                <SingleComments
                  key={comment.id}
                  index={index}
                  comment={comment}
                  sendComment={sendComment}
                  boardPersonsForSendAlert={boardPersonsForSendAlert}
                  cardDetails={cardDetails}
                  optionsForSendAlert={optionsForSendAlert}
                  setSendAlertToComment={setSendAlertToComment}
                  sendAlertToComment={sendAlertToComment}
                  sendAlertToBoardPerson={sendAlertToBoardPerson}
                  ref={childSingleCommentRef}
                  handleFormSubmission={handleFormSubmission}
                />
              ))}
               <ImageModal ref={editRef} />
              {/* {
                      video.length > 0 &&
                      < VideoShowModal videoSrc={video} setShowVideo={setShowVideo} showVideo={showVideo} />
                    } */}
            </>
          ) : (
            <div>
              {[1, 2, 3].map((item : number) => {
                return (
                  <div key={item} className='row my-4'>
                    <div className='col-1'>
                      <Skeleton count={1} height={40} width={40} circle />
                    </div>
                    <div className='col-11' style={{position: 'relative'}}>
                      <Skeleton count={1} height={60} />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </>
      </div>
    )
  }
)

ReadComments.displayName = 'ReadComments'
export default ReadComments

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getBoardDesign} from '../../services/design-board'

export const fetchBoardDesignByBoardId = createAsyncThunk(
  'board/fetchBoardDesignByBoardId',
  async (data: any) => {
    const {boardId, userId}: any = data
    try {
      const response = await getBoardDesign(boardId, userId)

      return response.data.data.board
    } catch (error: any) {
      // console.log(error)
    }
  }
)

const initialState: any = {
  entities: {},
  loading: false,
  errors: {},
}

export const BoardDesignSlice = createSlice({
  name: 'fetchBoardDesignByBoardId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBoardDesignByBoardId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchBoardDesignByBoardId.fulfilled, (state, action) => {
      state.entities = {...action.payload}
      state.loading = false
    })
    builder.addCase(fetchBoardDesignByBoardId.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export default BoardDesignSlice.reducer

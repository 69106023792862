/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const createPublishableKey = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/stripe/publishableKey`, data)
}

export const createPaymentIntent = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V2}/stripe/paymentIntent`, data)
}


import React, {useState} from 'react'
import LastUpdate from '../../components/LastUpdate/LastUpdate'
import TemplatesTable from '../../components/Tables/TemplatesTable'
import GeneralSearch from '../../components/GeneralSearch'
import '../../styles/generalsearch.css'
import { useTheme } from '../../context/ThemeContext'

export function Templates(): React.JSX.Element {
  const [isListVisible, setIsListVisible] = useState<boolean>(false)
  const {theme} = useTheme()
  return (
    <>
      <div className='row general-search-small-screen pb-8'  
      onMouseLeave={() => {
                //search dropdown list visible
                setIsListVisible(false)
              }}
              >
        <div className='col-12' >
          <div className='mt-3  mx-auto search-input-wrapper-mobile'>
            <GeneralSearch isListVisible={isListVisible} setIsListVisible={setIsListVisible} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3 col-md-3 d-flex  mb-2'>
          <h3 className={`fw-bolder my-2  ${theme === 'dark' ? 'white__text' : ''} `}>Templates</h3>
        </div>
        <div
          className='col-lg-5 col-md-5 '>
        </div>
        <div className='col-lg-4 col-md-4 d-flex justify-content-lg-end'>
          <LastUpdate />
        </div>
      </div>

      <hr />
      <TemplatesTable />
    </>
  )
}

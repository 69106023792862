import React from 'react'
import Select from 'react-select'
import { useTheme } from '../../context/ThemeContext'


const SelectStatus: React.FC<any> = ({ selectedOption, setSelectedOption, role }): any => {
    const { theme: themeDark } = useTheme()

    // const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | null>(null)
    const options = [{ value: 'active', label: 'Active' }, { value: 'hidden', label: 'Hidden' }]
    const colourStyles: any = {
        control: (styles: any) => ({ ...styles, backgroundColor: themeDark === 'dark' ? '' : 'white' }),
        multiValue: (styles: any) => {
            return {
                ...styles,
                backgroundColor: themeDark === 'dark' ? '#141520' : '#E6E6E6',
            }
        },
        multiValueLabel: (styles: any, { data }: any) => ({
            ...styles,
            backgroundColor: data.isDisabled ? '#FF677E' : '',
            color: data.isDisabled ? 'white' : '',
        }),
        multiValueRemove: (styles: any, { data }: any) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: '#FF677E',
                color: 'white',
            },
        }),
        valueContainer: (styles: any) => ({
            ...styles,
            overflow: 'auto',
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            color: themeDark === 'dark' ? '#FF7C00' : 'hsl(0, 0%, 80%)',
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: themeDark === 'dark' ? '#98C6F0' : 'hsl(0, 0%, 80%)',
        }),
    }
    return (
        <Select
            placeholder={options[0].label}
            isDisabled={role === 'Guest'}
            options={options}
            value={selectedOption}
            onChange={(option) => setSelectedOption(option as { value: string; label: string })}
            styles={colourStyles}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral0: themeDark === 'dark' ? "rgb(57,73,89)" : theme.colors.neutral0,
                    neutral80: themeDark === 'dark' ? "#F4FFFD" : theme.colors.neutral80,
                    primary25: themeDark === 'dark' ? "#141520" : theme.colors.primary25
                },
            })}
        />
    )
}

export default SelectStatus
import React, { useEffect, useState } from 'react'

import { getAllCardByBoardId } from '../../services/cards'
import Select from 'react-select'
import { useAuth } from '../../modules/auth'
import { longWordSlicingWithCustomLength } from '../../helper-functions/CheckString'
import { useTheme } from '../../context/ThemeContext'

interface ColumnProps {
  value: string | number
  label: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectBoard = ({
  setCardLength,
  boards,
  setBoardId,
  boardId,
  cardDetails,
  boardIdFromCardDetails,
  role,
  setIsAdmin = undefined
}: any): React.JSX.Element => {
  const [currentBoardState, setCurrentBoardState] = useState<any>()
  const { currentUser } = useAuth()
  const { theme: themeDark } = useTheme()
  const { id } = currentUser?.data.user
  // const { role : checkRole } = currentUser?.data.user

  const selectBoardOptions = boards?.map((item: any) => {
    return { value: item.board.id, label: longWordSlicingWithCustomLength(item.board.name, 40), isAdmin: item.isAdmin }
  })

  selectBoardOptions?.sort((a: ColumnProps, b: ColumnProps) => {
    return a.label.localeCompare(b.label)
  })
  const colourStyles: any = {
    control: (styles: any) => ({ ...styles, backgroundColor: themeDark === 'dark' ? '' : 'white' }),
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: themeDark === 'dark' ? '#141520' : '#E6E6E6',
      }
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: data.isDisabled ? '#FF677E' : '',
      color: data.isDisabled ? 'white' : '',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: '#FF677E',
        color: 'white',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      overflow: 'auto',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#FF7C00' : 'hsl(0, 0%, 80%)',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#98C6F0' : 'hsl(0, 0%, 80%)',
    }),
  }
  useEffect(() => {
    let board
    if (cardDetails) {
      board = selectBoardOptions?.find((item: any) => item.value === boardId)
      setCurrentBoardState(board)
    } else {
      board = selectBoardOptions?.find((item: any) => item.value === boardId)
      if (board) {
        setCurrentBoardState(board)
      }
    }

    //eslint-disable-next-line
  }, [boardId, boards, cardDetails])

  useEffect(() => {
    const fetchCardHandler = async () => {
      try {
        const cardRes: any = await getAllCardByBoardId(boardId, id)
        const allCards = cardRes.data.data.sort(
          (a: any, b: any) => Number(a.number) - Number(b.number)
        )
        const cardsLength = cardRes.data.length
        if (cardsLength === 0) {
          setCardLength(1)
          return
        }
        const boardIdFromCard = cardRes.data.data.find(
          (item: any) => item.boardId === boardId
        )?.boardId
        if (boardIdFromCardDetails !== boardIdFromCard) {
          const cardNumber = allCards[cardsLength - 1].number

          setCardLength(Number(cardNumber) + 1)
        }
      } catch (error: any) {
        // let msg = error.response?.data.message
        // if (msg === 'Card Not found') {
        //     setCardLength(1)
        // }
        return error
      }
    }
    if (cardDetails) {
      fetchCardHandler()
    }
    //eslint-disable-next-line
  }, [boardId])

  return (
    <Select
      isDisabled={role === 'Guest'}
      placeholder={currentBoardState?.label}
      options={selectBoardOptions}
      onChange={(e: any) => {
        setBoardId(e.value)
        if(setIsAdmin){
          setIsAdmin(e.isAdmin)
        }
      }}
      styles={colourStyles}
      // defaultValue={currentBoardState}
      value={currentBoardState}
      theme={(theme) => ({
        ...theme,

        colors: {
          ...theme.colors,

          neutral0: themeDark === 'dark' ? "rgb(57,73,89)" : theme.colors.neutral0,

          neutral80: themeDark === 'dark' ? "#F4FFFD" : theme.colors.neutral80,

          primary25: themeDark === 'dark' ? "#141520" : theme.colors.primary25
        },
      })}
    />
  )
}

export default SelectBoard

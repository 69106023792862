import React, {useEffect, useState} from 'react'
import Select, {components, SingleValueProps} from 'react-select'
import {longWordSlicingWithCustomLength} from '../../helper-functions/CheckString'
import {useTheme} from '../../context/ThemeContext'

// import { useAuth } from '../../modules/auth'

interface ColumnProps {
  value: string | number
  label: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectColumn = ({
  boardId,
  columnId,
  cardDetails,
  columns,
  setColumnId,
  role,
  columnIdProp,
}: any): React.JSX.Element => {
  // const { currentUser } = useAuth()
  // const { role : checkRole } = currentUser?.data.user
  const {theme: themeDark} = useTheme()
  const [column, setColumn] = useState<any>([])
  const options = columns?.map((item: any) => {
    return {value: item.id, label: item.name.trim(), color: item.color}
  })
  options?.sort((a: ColumnProps, b: ColumnProps) => {
    return a.label.localeCompare(b.label)
  })
  let currentCol: any =options?.filter((col: any) => col.value === columnIdProp);
  
  useEffect(() => {
    
    if (cardDetails) {
      currentCol= options?.filter((col: any) => col.value === cardDetails.board_column_id)
      const column = []
      for (let index = 0; index < options.length; index++) {
        if (cardDetails.board_column_id === options[index].value) {
          column.push(options[index])
          break
        }
      }
      setColumn(column)
      if (boardId !== cardDetails.boardId && boardId) {
        setColumnId(options[0]?.value)
      }else {
        setColumnId(currentCol[0]?.value)
      }
    } else {
      setColumnId(currentCol[0]?.value)
    }
    //eslint-disable-next-line
  }, [columns, boardId,cardDetails?.id])


  const colourStyles = {
    control: (styles: any) => ({...styles}),
    option: (styles: any, state: any) => ({
      ...styles,
      padding: '5px',
      display: 'flex',
      alignItems: 'center',
      // color: state.isSelected ? '#000000' : '#000000',
      backgroundColor: state.isSelected ? '#009ef7' : state.isHovered ? '#e0f7ff' : '#ffffff',
    }),
    singleValue: (base: any) => ({
      ...base,
      padding: 5,
      borderRadius: 5,
      display: 'flex',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#FF7C00' : 'hsl(0, 0%, 80%)',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#98C6F0' : '',
    }),
  }

  const SingleValue = ({children, ...props}: SingleValueProps<any, boolean, any>) => {
    const {data} = props

    return (
      <components.SingleValue {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <span>{longWordSlicingWithCustomLength(children as string, 30)}</span>
          <div
            style={{
              width: '60px',
              height: '20px',
              backgroundColor: data?.color || 'transparent',
              display: 'inline-block',
            }}
          />
        </div>
      </components.SingleValue>
    )
  }

  return (
    <>
      <Select
        isDisabled={role === 'Guest'}
        components={{
          Option: ({innerProps, label, data, isSelected, isFocused}: any) => (
            <div
              {...innerProps}
              style={{
                ...innerProps.style,
                backgroundColor: isSelected
                  ? '#009ef7'
                  : isFocused
                  ? themeDark === 'dark'
                    ? '#141520'
                    : '#e0f7ff'
                  : '',
                // color: isSelected ? '#ffffff' : isFocused ? '#000000' : '#000000',
                paddingBlock: '10px',
                paddingInline: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>{longWordSlicingWithCustomLength(label, 30)}</span>
              <span
                style={{
                  width: '60px',
                  height: '20px',
                  backgroundColor: data.color,
                  display: 'inline-block',
                }}
              />
            </div>
          ),
          SingleValue,
        }}
        styles={colourStyles}
        options={options}
        onChange={(e: any) => setColumnId(e.value)}
        value={
          options.find((col: any) => col.value === columnId) ||
          options.find((col: any) => col.value === columnIdProp) ||
          column[0]
        }
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,

            neutral0: themeDark === 'dark' ? 'rgb(57,73,89)' : theme.colors.neutral0,

            neutral80: themeDark === 'dark' ? '#F4FFFD' : theme.colors.neutral80,

            primary25: themeDark === 'dark' ? '#141520' : theme.colors.primary25,
          },
        })}
      />
    </>
  )
}

export default SelectColumn

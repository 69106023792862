import React from 'react'
import { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import VerifyEmailForm from '../../../components/Forms/VerifyEmailForm'
import { forgotPassword } from '../../../services/password'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  email: '',
}
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    // .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword(): React.JSX.Element {
  const [loading, setLoading] = useState(false)
  const [popUpMsg, setPopUpMsg] = useState("")
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true)
      const msg = "If you have an account with B4iGO, you will have received an email with details on how to change your password."
      try {

        await forgotPassword(values.email)
        setPopUpMsg(msg)
      } catch (error:any) {
        if(error.response.status === 400 ){
          setPopUpMsg(error.response.data.message)
        }else{
          setPopUpMsg(msg)
        }
        setLoading(false)
        return error
      } finally {
        setTimeout(() => {
          navigate("/auth")
        }, 5000);
      }

    },
  })

  return (
    <VerifyEmailForm handleSubmit={formik.handleSubmit} title="Forgot Password ?" setPopUpMsg={setPopUpMsg} popUpMsg={popUpMsg} formik={formik} loading={loading} />
  )
}

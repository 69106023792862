/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const createColumn = async (data: any, userId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/board/column?userId=${userId}`,
    data
  )
}

export const getAllColumn = async (boardId: any, userId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL}/board/column/${boardId}?userId=${userId}`
  )
}

export const deleteBoardColumn = async (columnId: string, userId: string, boardId: string) => {
  return await apiService.delete(
    `${process.env.REACT_APP_BACKEND_URL_V3}/board/column/${columnId}?userId=${userId}&boardId=${boardId}`
  )
}

export const updateColumnById = async (data: any, columnId: string, userId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/board/column/${columnId}?userId=${userId}`,
    data
  )
}

export const updateColumnPositions = async (data: any, userId: string, boardId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/board/column/positions?boardId=${boardId}&userId=${userId}`,
    data
  )
}

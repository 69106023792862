/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cryptoEncrypt } from '../../../helper-functions/encryption'
import apiService from './../../../services/index'
import { AuthModel, UserModel } from './_models'

// const API_URL = process.env.REACT_APP_API_URL
const API_URL = process.env.REACT_APP_BACKEND_URL_V3

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/user`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  const payload = {
      email,
      password
    
  }
  return apiService.post<AuthModel>(LOGIN_URL, {data: cryptoEncrypt(payload)})
}

// Server should return AuthModel
export function register(
  boardName:  string | null ,
  pageTitle:  string | null ,
  email: string,
  name: string,
  password: string,
  phone: any,
  image: any,
  roleId: string,
  isInvitedByOrganization: boolean,
  chosenSubscriptionPlan: string,
  pageNumber?: number,
  organizationId?:string | null
) {
  const payload =  {
    boardName,
    pageTitle,
    email,
    fullName: name,
    password,
    phone,
    image,
    roleId,
    isInvitedByOrganization,
    chosenSubscriptionPlan,
    pageNumber,
    organizationId
  }
  return apiService.post(REGISTER_URL,{data: cryptoEncrypt(payload)})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return apiService.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return apiService.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

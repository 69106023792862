import { debounce } from 'lodash';
import React,{ Dispatch, SetStateAction, useRef, useState } from 'react';
// import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTheme } from '../../context/ThemeContext';
import '../../styles/customSearchBar.css'
type Props={
    filterFunction :(fullName: any)=>void
    setFilterTeam?:Dispatch<SetStateAction<any>>
    setFilterOther:Dispatch<SetStateAction<any>>
    setFilterFlag:Dispatch<SetStateAction<boolean>>
    filterTeam?:any
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SearchBar :React.FC<Props> = (props: any) => {
    const { filterFunction, setFilterOther, setFilterFlag, setFilterTeam, filterTeam } = props;
    const {theme} = useTheme()
    const [searchText, setSearchText] = useState('');

    const clearSearch = () => {
        setSearchText('');
        setFilterOther(null)
        if (filterTeam) {
            setFilterTeam(null)
        }
        setFilterFlag(false)
        inputRef.current.value = '';
    };
    const onChange = (e: any) => {
        setSearchText(e.target.value)
        if (e.target.value.length > 0) {
            filterFunction(e.target.value)
        } else {
            if (filterTeam) {
                setFilterTeam(null)
            }
            setFilterOther(null)
            setFilterFlag(false)
        }
    }
    const debouncedOnChange = debounce(onChange, 1000);
    const inputRef: any = useRef('')

    return (
        <div className=' position-relative ' style={{width: 300}}>
        <InputGroup className="mb-3 flex-row-reverse flex-nowrap">
                <InputGroup.Text id="basic-addon1" className='custom_input_group'>
                    <i className="bi bi-search fs-3 " ></i>
                </InputGroup.Text>
                <input
                    type="text"
                    id="searchInput"
                    ref={inputRef}
                    // value={searchText}
                    onChange={debouncedOnChange}
                    placeholder="Search Contacts"
                    className={`form-control  form-control-md form-control-solid italics-placeholder form-control-sm w-lg-350px px-3 custom_searchbar ${theme === 'dark' && 'dark__theme__input'}`}

                />
                {searchText.length > 0 && (
                    <i  className={`bi bi-x cursor-pointer fs-3 position-absolute  translate-middle-y  rounded-circle close_icon  ${theme === 'dark' ? 'theme__div' : 'bg-secondary text-light'}`}
                     onClick={clearSearch}></i>
                )}
            </InputGroup>
        </div>
    );
};

export default SearchBar;
import {useState} from 'react'
import {useAuth} from '../modules/auth'

import {
  createOtherContact,
  deleteOtherContact,
  getOtherContactBoardByContactId,
  getOtherContactByUserAndCompanyId,
  updateContactByContactId,
  updateOtherContactNoteByContactId,
} from '../services/contacts'
import { useLocation } from 'react-router-dom'

function useManageContacts() : any {
  const [otherContactList, setOtherContactList] = useState([])
  const [totalLength, setTotalLength] = useState<any>({
    limit: 0,
    length: 0,
  })
  const [page, setPage] = useState<any>(1)
  const [filterOther, setFilterOther] = useState<any>(null)
  const [members, setMember] = useState<any>([])
  const [filterTeam, setFilterTeam] = useState<any>(null)
  const [contactBoards, setGetContactBoards] = useState([])
  const [contactDetails, setContactDetails] = useState({})
  const [filterFlag, setFilterFlag] = useState(false)
  const [isInvited, setIsInvited] = useState('')
  const [loading, setLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [contactEditedMsg, setContactEditedMsg] = useState('')

  const [removeContactMsg, setRemoveContactMsg] = useState('')
  const {currentUser} = useAuth()
  const {id: userId} = currentUser?.data?.user
  const {organizationId} = currentUser
  const {pathname}=useLocation()
  const limit=pathname.includes('manage-contacts')? 5 : 'all'

  const createOtherContactHandler = async (data: any) => {
    try {
      const res = await createOtherContact(data)
      if (res.status === 200) {
        setIsInvited(res.data.message)
        return res.data.success
      }
    } catch (error: any) {
      setIsInvited(error.response?.data?.message)
    }
  }

  const updateOtherContactHandler = async () => {
    // try {
    // } catch (error) {
    // }
  }

  const getOtherContactHandler = async () => {
    setLoading(true)
    try {
      const res = await getOtherContactByUserAndCompanyId(userId, page,limit)
      setOtherContactList(res.data.data.otherContact)
      setTotalLength({
        limit: res.data?.limit,
        length: res.data?.data?.otherContact_total_length,
      })
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }

  const deleteOtherContactHandler = async (contactId: string) => {
    try {
      setIsDeleteLoading(true)
      const res = await deleteOtherContact(contactId)
      setRemoveContactMsg(res.data.message)

      const deletedContact = otherContactList.filter((item: any) => item.id !== contactId)
      setOtherContactList(deletedContact)
    } catch (error) {
      return error
    } finally {
      setIsDeleteLoading(false)
    }
  }

  const getOtherContactBoardHandler = async (contactId: string) => {
    setLoading(true)
    try {
      const res = await getOtherContactBoardByContactId(contactId)
      setGetContactBoards(res.data.data)
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }

  const filterOtherContactHandler = async (name: any) => {
    try {
      const res = await getOtherContactByUserAndCompanyId(userId, page,limit,name,organizationId)
      setFilterFlag(true)
      setFilterTeam(res.data.data.teamMembers)
      setFilterOther(res.data.data.otherContact)
    } catch (error) {
      return error
    }
  }

  const getOtherContactNoteHandler = async () => {
    try {
      // let res = await getOtherContactNoteByContactId(contactId,organizationId)
    } catch (error) {
      return error
    }
  }

  const updateOtherContactNoteHandler = async (contactId: string, data: any) => {
    try {
      await updateOtherContactNoteByContactId(contactId, data)
    } catch (error) {
      return error
    }
  }
  const updateContactByContactIdHandler = async (contactId: string, data: any) => {
    try {
      const res = await updateContactByContactId(contactId, data)
      setContactEditedMsg(res.data.message)
    } catch (error) {
      return error
    }
  }

  return {
   
  
    totalLength,
    isDeleteLoading,
    updateContactByContactIdHandler,
    isInvited,
    setIsInvited,
    setFilterFlag,
    filterFlag,
    setOtherContactList,
    members,
    setMember,
    loading,
    createOtherContactHandler,
    getOtherContactHandler,
    deleteOtherContactHandler,
    getOtherContactBoardHandler,
    otherContactList,
    filterOtherContactHandler,
    getOtherContactNoteHandler,
    updateOtherContactNoteHandler,
    setContactDetails,
    contactDetails,
    updateOtherContactHandler,
    contactBoards,
    setRemoveContactMsg,
    removeContactMsg,
    filterTeam,
    filterOther,
    setFilterOther,
    setFilterTeam,
    contactEditedMsg,
    setContactEditedMsg,
    page,
    setPage,
  }
}

export default useManageContacts

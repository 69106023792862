import React from 'react'
import clsx from 'clsx'
import {Modal, Button} from 'react-bootstrap'
import ResendCode from '../ResendBtn/ResendCode'
import {useTheme} from '../../context/ThemeContext'

type Props = {
  showModal: boolean
  code: string
  errorMsg2fa: string
  btnVerifyTitle: string
  handleCloseModal: () => void
  verifyCodeHandler: () => void
  handleSubmit: () => void
  setCode: React.Dispatch<React.SetStateAction<string>>
}
const VerifyTwoFACodeModal: React.FC<Props> = ({
  showModal,
  handleCloseModal,
  code,
  setCode,
  handleSubmit,
  verifyCodeHandler,
  errorMsg2fa,
  btnVerifyTitle,
}: Props) => {
  const {theme} = useTheme()
  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className={theme === 'dark' ? 'white__text' : ''}>
          2-Step Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Your account is temporarily deactivated. Want to recover? */}
        <div className='mb-10 mt-7 fv-row' data-kt-password-meter='true'>
          <div className=''>
            <label
              className={`${
                theme === 'dark' ? 'white__text' : `text-dark`
              } form-label fw-bolder  fs-6`}
            >
              Enter your verification code.
            </label>
            <div className='position-relative mb-3'>
              <div className='input-group'>
                <input
                  maxLength={10}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type='text'
                  placeholder='Code'
                  autoComplete='off'
                  className={clsx(
                    `form-control form-control-lg form-control-solid  ${
                      theme === 'dark' && 'dark__theme__input'
                    }`
                  )}
                />
              </div>
            </div>
          </div>
          {errorMsg2fa.length > 0 && (
            <div className='alert alert-danger' role='alert'>
              {errorMsg2fa}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='container row '>
          <div className='col-12 d-flex justify-content-center'>
            <ResendCode onResendCode={handleSubmit} />
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <Button variant='secondary' onClick={verifyCodeHandler} disabled={code.length === 0}>
              {btnVerifyTitle}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default VerifyTwoFACodeModal

import React, {useEffect, useState} from 'react'
import {useTemplates} from '../../../hooks'
import Select from 'react-select'
import '../../../styles/loadtemplate.css'
import {capitalizeEachWord} from '../../../helper-functions/CapitalizeName'
import {useTheme} from '../../../context/ThemeContext'

const LoadTemplate = ({fetchColumnHandler, columns}: any) => {
  const [templateId, setTemplateId] = useState('')
  const {theme: themeDark} = useTheme()
  const {existingTemplates, getTemplateHandler, getTemplateByIdHandler} = useTemplates()
  useEffect(() => {
    getTemplateHandler('all')
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (templateId) {
      getTemplateByIdHandler(templateId, fetchColumnHandler, columns)
    }
    //eslint-disable-next-line
  }, [templateId])

  return (
    <div className='load_template_selector'>
      <Select
        options={existingTemplates?.map((item: any) => ({
          value: item.id,
          label: capitalizeEachWord(item.name.trim()),
        }))}
        onChange={(e: any) => setTemplateId(e.value)}
        placeholder='Load Template'
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,

            neutral0: themeDark === 'dark' ? '#1d1e2c' : theme.colors.neutral0,

            neutral80: themeDark === 'dark' ? '#F4FFFD' : '#17181f',
            neutral50: themeDark === 'dark' ? '#F4FFFD' : '#17181f',
           
            // neutral50: "#F4FFFD",
            primary25: themeDark === 'dark' ? '#141520' : theme.colors.primary25,
          },
        })}
      />
    </div>
  )
}

export default React.memo(LoadTemplate)

import React, {useEffect, useState} from 'react'
import {createSubscription, updateSubscription} from '../../services/subscription'

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import Visa from '../Stripe/visa.png'
import AmericanExpress from '../Stripe/americanexpress.png'
import MasterCard from '../Stripe/mastercard.png'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {checkErrorTypeInStripeHandler} from '../../helper-functions/ErrorHandler'
import CustomAlert from '../CustomAlert'
import useWebSocket, {ReadyState} from 'react-use-websocket'
import {webSocketOperations} from '../../constants'
import Skeleton from 'react-loading-skeleton'
import {useTheme} from '../../context/ThemeContext'
import { getAllRoles } from '../../services/user-lookup'
import { createOrganizationMemberInvite } from '../../services/company'



const CheckoutForm: React.FC = () => {
  const [loading, setLoading] = useState<any>(true)
  const [socketStatus, setSocketStatus] = useState(ReadyState.CONNECTING)
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const [popupMsg, setPopupMsg] = useState<any>(null)
  const [disabledBtn, setDisabledBtn] = useState<any>(false)
  const [name, setName] = useState<any>(null)
  const {theme} = useTheme()
  
  const options = {
  style: {
    base: {
      fontSize: '16px',
      width: '200px',
      padding: '10px',
      color: theme === 'dark' ?'#fff':''
    },
    invalid: {
      color: '#9e2146',
    },
  },
}
  const {planDetails: planUserData, selectedPlanDetails: selectedPlanDetailsData} = useSelector(
    (state: any) => state.paymentReducer
  )
  const [roleList, setRoleList] = useState<any>([])


  const {sendJsonMessage}: any = useWebSocket(
    `${process.env.REACT_APP_WEBSOCKET_URL}?userid=${planUserData?.userId}&token=${planUserData?.loginToken}`,
    {
      onOpen: () => setSocketStatus(ReadyState.OPEN),
      onClose: () => setSocketStatus(ReadyState.CLOSED),
      onError: () => setSocketStatus(ReadyState.CLOSED),
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  )
  

  const stripe = useStripe()
  const elements: any = useElements()
  const navigate = useNavigate()
  useEffect(() => {
    const getRolesHandler = async () => {
    if(planUserData?.isOrgSignup){
        try {
          const roleRes = await getAllRoles()
          setRoleList(roleRes.data.data)
        } catch (error) {
          return error
        }
      }
    }
    getRolesHandler()
  }, [])
  
  const role = roleList.find((roles: any) => roles.name === 'User')?.id

  const subscriptionHandlerPackage = async (stripePaymentMethodId: string) => {
    const data = {
      email: planUserData?.email,
      userId: planUserData?.userId,
      name: planUserData?.name,
      type: selectedPlanDetailsData.selectedPlanDetails?.planType,
      totalUser: planUserData?.isOrgSignup ? 1 : Number(selectedPlanDetailsData.selectedPlanDetails.totalUser),
      stripePaymentMethodId,
      license : planUserData.licenseEmails && {
        totalLicense: planUserData?.licenseEmails.totalLicense,
        organizationId: planUserData?.licenseEmails.organizationId
      }
    }
      const memberDetails = {
          organizationName: planUserData?.licenseEmails && planUserData.licenseEmails.organizationName,
          organizationId: planUserData?.licenseEmails && planUserData.licenseEmails.organizationId,
          organizationLicenseId: '',
          email: '',
          roleId: role,
        }
    try {
      const res = await createSubscription(data)
      const createdLic= res?.data?.data?.license
       if ( planUserData?.licenseEmails) {
          for (let index = 0; index < createdLic.length; index++) {
              memberDetails.email = planUserData.licenseEmails.licenseEmails[index]
              memberDetails.organizationLicenseId = createdLic[index].id
               await createOrganizationMemberInvite(memberDetails)
              // if (inviteRes.data.message.includes('already registered on b4igo')) {
              //   setIsAlreadyMember((prevStatus) => {
              //     const newState = [...prevStatus]
              //     newState[index] = {invite: inviteRes.data.message, index: index + 1}
              //     return newState
              //   })
              // }
            // }
          }
        }

      return res.data
    } catch (error: any) {
      const msg = error.response.data.message
      setErrorMessage(msg)
    }
  }
  const updateSubscriptionHandler = async (payload: any) => {
    const data = {
      email: planUserData.email,
      userId: planUserData.userId,
      type: selectedPlanDetailsData.selectedPlanDetails.planType,
      totalUser: planUserData?.isOrgSignup ? 1 : Number(selectedPlanDetailsData.selectedPlanDetails.totalUser),
      totalPrice: payload.data.totalPrice,
      stripeCustomerId: payload.data.customerId,
      stripeProductId: payload.data.productId,
      stripeSubscriptionId: payload.data.subscriptionId,
      isOrgSignup:planUserData.licenseEmails?true:false,
      license : planUserData.licenseEmails && {
        totalLicense: planUserData?.licenseEmails.totalLicense,
        organizationId: planUserData?.licenseEmails.organizationId
      }
    }

    try {
      await updateSubscription(data)

      onSuccess()
    } catch (error: any) {
      const msg = error.response.data.message
      setErrorMessage(msg)
    }
  }

  const onSuccess = () => {
    sendJsonMessage({
      event: 'publish',
      channel: planUserData?.userId,
      userId: planUserData?.userId,
      command: 'UPDATE',
      operation: webSocketOperations.isUserVerified,
    })

    setPopupMsg('Registration and Subscription is successful! Login Now.')
    localStorage.clear()
    setTimeout(() => {
      navigate('/auth')
      setDisabledBtn(false)
    }, 5000)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setDisabledBtn(true)

    if (!stripe || !elements) {
      setDisabledBtn(false)

      return
    }

    const isCardEntered = await stripe.createToken(elements?.getElement(CardNumberElement))
    const isCVCEntered = await stripe.createToken(elements?.getElement(CardCvcElement))
    const isExpiryEntered = await stripe.createToken(elements?.getElement(CardExpiryElement))

    if (!name) {
      setErrorMessage("Enter Card Holder's Name")
      setDisabledBtn(false)

      return
    } else if (isCardEntered.error) {
      setErrorMessage(isCardEntered.error.message)
      setDisabledBtn(false)

      return
    } else if (isCVCEntered.error) {
      setErrorMessage(isCVCEntered.error.message)
      setDisabledBtn(false)

      return
    } else if (isExpiryEntered.error) {
      setErrorMessage(isExpiryEntered.error.message)
      setDisabledBtn(false)

      return
    } else {
      // const email = planUserData?.email
      try {
        // create a payment method
        const paymentMethod: any = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement(CardNumberElement)!,
          billing_details: {
            email: planUserData?.email,
            name: name,
          },
        })
        
        const subscriptionData: any = await subscriptionHandlerPackage(
          paymentMethod?.paymentMethod?.id
        )
        if (subscriptionData.mode === 'active') {
          onSuccess()
          return
        }
        const confirmPayment: any = await stripe?.confirmCardPayment(
          subscriptionData.data.clientSecret
        )

        if (confirmPayment?.error) {
          const message = checkErrorTypeInStripeHandler(confirmPayment?.error)
          setErrorMessage(message)

          setDisabledBtn(false)
        } else {
          if (subscriptionData.mode === 'active') {
            onSuccess()
            return
          } else {
            updateSubscriptionHandler(subscriptionData)
          }
        }
      } catch (error) {
        setDisabledBtn(false)
        // console.log(error)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  useEffect(() => {
    if (socketStatus === 1 && planUserData?.userId?.length > 0) {
      sendJsonMessage({event: 'subscribe', channel: planUserData?.userId})
    }
    //eslint-disable-next-line
  }, [socketStatus, planUserData?.userId])

  return (
    <>
      {loading ? (
        <div className='h-250px d-flex align-items-center flex-column'>
          <div className='row w-100 mt-5 '>
            <Skeleton count={1} height={35} />
          </div>
          <div className='row w-100 my-3'>
            <Skeleton count={1} height={35} />
          </div>
          <div className='row w-100 my-3 mb-5'>
            <div className='col-6'>
              <Skeleton count={1} height={35} />
            </div>
            <div className='col-6'>
              <Skeleton count={1} height={35} />
            </div>
          </div>
          <div className='row w-100 my-3'>
            <Skeleton count={1} height={35} />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className=' w-100'>
            <div className='my-3 text-center'>
              <h2 className={` ${theme === 'dark' && 'white__text'}`}>
                PAY WITH:
                <img src={Visa} alt='loading' />
                <img src={AmericanExpress} alt='loading' />
                <img src={MasterCard} alt='loading' />
              </h2>
            </div>
            <div className='w-100 mt-3'>
              <div className=''>
                <label htmlFor='cardholder-name mt-3'>
                  <h6  className={` ${theme === 'dark' && 'white__text'}`}>Cardholder&apos;s Name</h6>
                </label>
                <input
                  className={`form-control form-control-lg form-control-solid d-inline-block  ${
                    theme === 'dark' && 'dark__theme__input'
                  }`}
                  type='text'
                  value={name}
                  maxLength={50}
                  onChange={(e) => setName(e.target.value)}
                />
                {/* {!name && (
              <p style={{color: 'red'}} className='mt-2'>
                Enter Card Holder's Name
              </p>
            )} */}
              </div>
              <label     className={`  form-check-label mt-3`}  htmlFor='flexSwitchCheckDefault'>
                <h6 className={` ${theme === 'dark' && 'white__text'}`}>Card Number</h6>
              </label>
              <CardNumberElement
                id='card_num_field'
                className={`form-control form-control-lg form-control-solid d-inline-block  ${
                  theme === 'dark' && 'dark__theme__input'
                }`}
                options={options}
              />
            </div>
            <div className='d-flex mt-3 flex-lg-row  flex-md-row flex-column  '>
              <div className='w-100  mx-1 my-3'>
                <label className='form-check-label mt-2' htmlFor='flexSwitchCheckDefault'>
                  <h6 className={` ${theme === 'dark' && 'white__text'}`}>Expiry Date</h6>
                </label>
                <div className='mb-2 '>
                  <CardExpiryElement
                    id='card_exp_field'
                    className={`form-control form-control-lg form-control-solid d-inline-block  ${
                      theme === 'dark' && 'dark__theme__input'
                    }`}
                    options={options}
                  />
                </div>
              </div>

              <div className='w-100  mx-1 my-3'>
                <label className='form-check-label mt-2' htmlFor='flexSwitchCheckDefault'>
                  <h6 className={` ${theme === 'dark' && 'white__text'} text-nowrap`} >Card Verification Code</h6>
                </label>

                <div className='mb-2'>
                  <CardCvcElement
                    id='card_cvc_field'
                    className={`form-control form-control-lg form-control-solid d-inline-block  ${
                      theme === 'dark' && 'dark__theme__input'
                    }`}
                    options={options}
                  />
                </div>
              </div>
            </div>

            <div className=''>
              <CustomAlert
                variant={'danger'}
                className={'my-2'}
                alertMessage={errorMessage}
                setAlertMessage={setPopupMsg}
              />

              <CustomAlert
                variant={'primary'}
                className={'my-2'}
                alertMessage={popupMsg}
                setAlertMessage={setPopupMsg}
              />

              <div className='text-center my-2'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 mt-2'
                  disabled={!stripe || disabledBtn}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default CheckoutForm

import React from 'react'
import { useAuth } from '../../../modules/auth'

type Props = {
  setCardModalShow: React.Dispatch<React.SetStateAction<boolean>>
  setCardPositionStatus: React.Dispatch<React.SetStateAction<string>>
  columnId: any;
  role : string
  index ?: any
}

const AddCardFromDownBtn: React.FC<Props> = ({
  setCardModalShow,
  setCardPositionStatus,
  role,
  index
}: Props) => {
  const { currentUser } = useAuth()
  const { role : checkRole } = currentUser?.data.user

  return (
    <div className={`py-0 px-6 add_task_btn ${index === 1 && 'bottom_col_tab'}`} 
    style={{ 
      cursor: checkRole?.isGuest ? 'not-allowed' : 'pointer',
    }}
    >
     
        <button
        disabled = {role === 'Guest'}
          onClick={() => {
            setCardModalShow(true)
            setCardPositionStatus('down')
          }}
          type='button'
          className={`btn px-2 fs-6 tag_btn w-100`}
        >
          Add a task
        </button>
    </div>
  )
}

export default AddCardFromDownBtn

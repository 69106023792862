import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
 currentDetail:{ 
  userId: null,
  designation: null,
  companyName: null,
  fullName: '',
  email: '',
  contact: null,
  businessNumber: null,
  website: null,
  streetAddress: null,
  city: null,
  zipCode: null,
  notes: null}
}

export const contactDetailSlice = createSlice({
  name: 'contactDetailSlice',
  initialState,
  reducers: {
    setContactDetailSlice: (state, action) => {
      state.currentDetail = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setContactDetailSlice} = contactDetailSlice.actions

export default contactDetailSlice.reducer

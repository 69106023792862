import React, {useState, useEffect, useRef} from 'react'
import {Dropdown, Button} from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import '../styles/datepicker.css'
import 'react-date-picker/dist/DatePicker.css'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import '../styles/reminder.css'
import {isLocalTimeBeforeUtc} from '../helper-functions/TimeFormate'
import ReactToolTip from '../components/ReactToolTip'
import { useTheme } from '../context/ThemeContext'

interface ReminderProps {
  reminders: any
  setReminders: any
  isDisable: any
  setDeletedReminderIds?: any
  cardDetails?: any
  parentWidth?: number
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Remainder = ({
  cardDetails,
  reminders,
  setReminders,
  isDisable,
  setDeletedReminderIds,
  parentWidth,
}: ReminderProps): React.JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false)
  const {theme} = useTheme()

  const [messageReminder, setMessageReminder] = useState<string>('')

  const hourCheckboxRef: any = useRef()
  const dayCheckboxRef: any = useRef()
  const dateCheckboxRef: any = useRef()
  const everyCheckboxRef: any = useRef()

  const handleButtonClick = () => {
    setShowDropdown((prevState) => !prevState)
  }

  const deleteData = () => {
    const ids = reminders
      ?.filter((reminder: any) => reminder.id !== undefined)
      ?.map((reminder: any) => {
        return {
          cardReminderId: reminder.id,
        }
      })
    if (ids?.length > 0) {
      setDeletedReminderIds([...ids])
    }
  }

  const removeAll = () => {
    if (
      dayCheckboxRef?.current &&
      hourCheckboxRef?.current &&
      dateCheckboxRef?.current &&
      everyCheckboxRef?.current
    ) {
      dayCheckboxRef.current.checked = false
      hourCheckboxRef.current.checked = false
      dateCheckboxRef.current.checked = false
      everyCheckboxRef.current.checked = false
    }
    deleteData()
    setReminders([])
  }

  const getSelectedValues: any = (value: string) => {
    if (value) {
      const isValueExist =
        reminders.length > 0 && reminders?.filter((item: any) => item.reminderType === value)

      if (isValueExist?.length > 0) {
        const ids = isValueExist
          ?.filter((existValue: any) => existValue.id !== undefined)
          ?.map((existValue: any) => {
            return {
              cardReminderId: existValue.id,
            }
          })
        if (setDeletedReminderIds !== undefined && ids?.length > 0) {
          setDeletedReminderIds([...ids])
        }

        setReminders([])
      } else {
        switch (value) {
          case 'days':
            deleteData()
            setReminders([{reminderType: value, number_of_days: 1}])
            if (hourCheckboxRef?.current && dateCheckboxRef?.current && everyCheckboxRef?.current) {
              hourCheckboxRef.current.checked = false
              dateCheckboxRef.current.checked = false
              everyCheckboxRef.current.checked = false
            }

            break
          case 'hours':
            deleteData()
            setReminders([{reminderType: value, number_of_hours: 1}])

            if (dayCheckboxRef?.current && dateCheckboxRef?.current && everyCheckboxRef?.current) {
              dayCheckboxRef.current.checked = false
              dateCheckboxRef.current.checked = false
              everyCheckboxRef.current.checked = false
            }
            break
          case 'date':
            deleteData()
            setReminders([{reminderType: value, date: null}])
            if (dayCheckboxRef?.current && hourCheckboxRef?.current && everyCheckboxRef?.current) {
              dayCheckboxRef.current.checked = false
              hourCheckboxRef.current.checked = false
              everyCheckboxRef.current.checked = false
            }

            break
          default:
            deleteData()
            setReminders([{reminderType: value}])
            if (dayCheckboxRef?.current && hourCheckboxRef?.current && dateCheckboxRef?.current) {
              dayCheckboxRef.current.checked = false
              hourCheckboxRef.current.checked = false
              dateCheckboxRef.current.checked = false
            }
            break
        }
      }
    }
  }

  useEffect(() => {
    let message = ''
    reminders.forEach((reminder: any) => {
      if (
        reminder.reminderType === 'hours' &&
        reminder?.number_of_hours !== null &&
        reminder?.number_of_hours !== undefined
      ) {
        message +=
          reminder?.number_of_hours > 1
            ? `${reminder.number_of_hours} Hrs, `
            : `${reminder.number_of_hours} Hr, `
      }
      if (
        reminder.reminderType === 'days' &&
        reminder?.number_of_days !== null &&
        reminder?.number_of_days !== undefined
      ) {
        message +=
          reminder?.number_of_days > 1
            ? `${reminder?.number_of_days} Days, `
            : `${reminder.number_of_days} Day, `
      }
      if (reminder.reminderType === 'date' && reminder?.date !== null) {
        message += `${reminder?.date?.split('T')[0]}, `
      }
      if (reminder.reminderType === 'everyday') {
        message += `Everyday, `
      }
      if (reminder.length === 0) {
        message += ` `
      }
    })

    message = message.slice(0, -2)
    if (message !== '') {
      setMessageReminder(message)
    } else {
      setMessageReminder('')
    }
  }, [reminders])

  return (
    <Dropdown show={showDropdown} onToggle={handleButtonClick}>
      <Dropdown.Toggle
        variant={
          cardDetails !== undefined &&
          isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
          reminders?.length > 0
            ? 'danger'
            : 'secondary'
        }
        disabled={!isDisable}
        as={Button}
        className={`py-1 me-1  btn-custom btn-custom-secondary d-flex justify-content-end align-items-center`}
        onClick={handleButtonClick}
        style={{
          width: parentWidth && parentWidth <= 209 ? '10rem' : '100%',
          maxWidth: parentWidth && parentWidth <= 209 ? '10rem' : '',
          backgroundColor:
            cardDetails !== undefined &&
            isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
            reminders?.length > 0
              ? '#FC99A3'
              : '',
          color:
            cardDetails !== undefined &&
            isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
            reminders?.length > 0
              ? 'black'
              : '',
        }}
      >
        <>
          <span
            style={{overflow: 'hidden'}}
            data-tooltip-id='tooltip' 
          >
            {messageReminder || null}
          </span>
          {messageReminder && (
            <ReactToolTip id='tooltip' position='top-start'>
              {messageReminder || null}
            </ReactToolTip>
          )}
        </>
        <i
          className='bi bi-calendar4-week ms-2 my-2 '
          style={{
            fontSize: 18,
            color: '#000000',
          }}
        ></i>
      </Dropdown.Toggle>

      <Dropdown.Menu className={`dropdown_menu ${theme === 'dark' ? 'theme__div' : ''}`}>
        <div className='d-flex flex-column justify-content-center gap-2 ms-3 my-2'>
          <div className='d-flex drop-custom  align-items-center gap-3 '>
            <div className='form-check '>
              <input
                type='checkbox'
                ref={hourCheckboxRef}
                id='hours'
                className={`form-check-input `}
                defaultChecked={
                  reminders && reminders.some((item: any) => item.reminderType === 'hours')
                }
                value='hours'
                onChange={(e: any) => {
                  getSelectedValues(e.target.value)
                }}
              />
              <label htmlFor='hours'>Hours</label>
            </div>
            <input
              style={{
                backgroundColor:
                  cardDetails !== undefined &&
                  isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
                  reminders.some((item: any) => item.reminderType === 'hours')
                    ? '#FC99A3'
                    : '',
                color:
                  cardDetails !== undefined &&
                  isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
                  reminders.some((item: any) => item.reminderType === 'hours')
                    ? 'black'
                    : '',
              }}
              type='number'
              min={1}
              disabled={!reminders?.some((item: any) => item.reminderType === 'hours')}
              value={reminders?.find((item: any) => item.reminderType === 'hours')?.number_of_hours}
              onChange={(e) => {
                const newReminders = [...reminders]
                const hoursReminderIndex = reminders.findIndex(
                  (item: any) => item.reminderType === 'hours'
                )

                if (hoursReminderIndex !== -1) {
                  newReminders[hoursReminderIndex].number_of_hours =
                    Math.abs(parseInt(e.target.value)) || null
                  setReminders(newReminders)
                }
              }}
              placeholder='Enter hours'
              className={`form-check-input py-5 form-control-solid placeholder_padded mx-n1  ${theme === 'dark' ? 'dark__theme__input' : ''}`}
            />
          </div>
          <div className='d-flex  align-items-center gap-3'>
            <div className='form-check '>
              <input
                type='checkbox'
                ref={dayCheckboxRef}
                className={`  form-check-input`}
                value='days'
                defaultChecked={
                  reminders?.length > 0 &&
                  reminders?.some((item: any) => item.reminderType === 'days')
                }
                onChange={(e: any) => {
                  getSelectedValues(e.target.value)
                }}
              />
              <label className='form-check-label' htmlFor='days'>
                Days
              </label>
            </div>
            <input
              style={{
                backgroundColor:
                  cardDetails !== undefined &&
                  isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
                  reminders &&
                  reminders.some((item: any) => item.reminderType === 'days')
                    ? '#FC99A3'
                    : '',
                color:
                  cardDetails !== undefined &&
                  isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true &&
                  reminders &&
                  reminders.some((item: any) => item.reminderType === 'days')
                    ? 'black'
                    : '',
              }}
              min={1}
              type='number'
              value={reminders?.find((item: any) => item.reminderType === 'days')?.number_of_days}
              disabled={!reminders?.some((item: any) => item.reminderType === 'days')}
              onChange={(e) => {
                const newReminders = [...reminders]
                const hoursReminderIndex = reminders.findIndex(
                  (item: any) => item.reminderType === 'days'
                )

                if (hoursReminderIndex !== -1) {
                  newReminders[hoursReminderIndex].number_of_days =
                    Math.abs(parseInt(e.target.value)) || null
                  setReminders(newReminders)
                }
              }}
              placeholder='Enter days'
              className={`${theme === 'dark' ? 'dark__theme__input' : ''}  form-check-input py-5 form-control-solid placeholder_padded`}
            />
          </div>
          <div className='d-flex  align-items-center gap-3'>
            <div className={`form-check  ${theme === 'dark' ? 'bg-dark' : ''}`}>
              <input
                ref={dateCheckboxRef}
                type='checkbox'
                className='form-check-input'
                defaultChecked={
                  reminders?.length > 0 &&
                  reminders?.some((item: any) => item.reminderType === 'date')
                }
                id='date'
                value='date'
                onChange={(e: any) => {
                  getSelectedValues(e.target.value)
                }}
              />
              <label htmlFor='date'>Date</label>
              </div>
            <DatePicker
              minDate={new Date()}
              disabled={!reminders?.some((item: any) => item.reminderType === 'date')}
              onChange={(date: any) => {
                const newReminders = [...reminders]
                const hoursReminderIndex = reminders.findIndex(
                  (item: any) => item.reminderType === 'date'
                )
                if (hoursReminderIndex !== -1) {
                  const dateNew = new Date(date)
                  const isoString = dateNew.toISOString()
                  newReminders[hoursReminderIndex].date = isoString || null
                  setReminders(newReminders)
                }
              }}
              value={reminders?.find((item: any) => item.reminderType === 'date')?.date}
              className={` ${theme === 'dark' ? 'react_date_picker_dark react_date_dark' : 'home-search-card-date'}  calender_class ${
                cardDetails !== undefined &&
                isLocalTimeBeforeUtc(cardDetails?.due_date, cardDetails?.due_time) === true
                  ? 'react-date-picker__inputGroup'
                  : ''
              }`}
            />
          </div>

          <div className='form-check '>
            <input
              ref={everyCheckboxRef}
              className='form-check-input'
              value='everyday'
              onChange={(e) => {
                getSelectedValues(e.target.value)
              }}
              type='checkbox'
              id='remindMeCheckbox'
              defaultChecked={
                reminders?.length > 0 &&
                reminders?.some((item: any) => item.reminderType === 'everyday')
              }
            />
            <label className='form-check-label' htmlFor='remindMeCheckbox'>
              Remind me every day include due date
            </label>
          </div>
        </div>
        <div className='d-flex justify-content-end mx-5'>
          <button className='btn btn-primary p-2 px-4 ms-2 mb-1' type='button' onClick={removeAll}>
            Clear
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Remainder

import React, {useState, useEffect} from 'react'
import EditNewCard from '../UpdateCard/EditBoardCard'
import 'react-loading-skeleton/dist/skeleton.css'

import {useEditModal} from '../../context/ShowEditModalQuill'
import EditPageModal from '../Modals/EditPageModal'
import { useTheme } from '../../context/ThemeContext'
import { useSelector } from 'react-redux'

const LinkCardUpdate: React.FC = () => {
  const {card, showEditModalQuill, setShowEditModalQuill} = useEditModal()
  const {theme} =useTheme()
  const { pagesByBoardId:pages } = useSelector((state: any) => state.pageReducer)

  const [modalStates, setModalStates] = useState<{[key: string]: boolean}>({})
  

  useEffect(() => {
    if (showEditModalQuill === true && card) {
      setModalStates((prev) => ({...prev, [card.id]: true}))
      setShowEditModalQuill(false)
    }

    return () => {
     
      setShowEditModalQuill(false)
    }
  }, [showEditModalQuill, card, setShowEditModalQuill])

  const closeModal = (modalId: string) => {
    setModalStates((prev) => ({...prev, [modalId]: false}))
  }
  return (
    <>
      {Object.keys(modalStates).map(
        (modalId) =>
          modalStates[modalId] && (
            card.title ?
            <EditPageModal 
            // boardName={boardName} 
            // sendTocHeading={sendTocHeading} setSendTocHeading={setSendTocHeading} 
            pages={pages}
            page={card} theme={theme} pageModalShow={modalStates[modalId]} setPageModalShow={() => closeModal(modalId)} />
            :
            <EditNewCard
              key={modalId}
              cardTitle='Edit Card'
              isBoard={false}
              cardDetails={card}
              cardModalShow={modalStates[modalId]}
              setCardModalShow={() => closeModal(modalId)}
            />
          )
      )}
    </>
  )
}

export default LinkCardUpdate

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  selectedTags: [],
};

export const selectedTagsSlice = createSlice({
  name: 'selectedTags',
  initialState,
  reducers: {
    setTag: (state, action) => {
      const { boardId, type, tagId } = action.payload;

      // Find the board in the array, or create a new one if it doesn't exist
      const boardIndex = state.selectedTags.findIndex((board: any) => board?.boardId === boardId);

      if (boardIndex !== -1) {
        if (type === 'card') {
          state.selectedTags[boardIndex].card = tagId === null ? null : tagId;
        } else if (type === 'page') {
          state.selectedTags[boardIndex].page = tagId === null ? null : tagId;
        }
      } else {
        state.selectedTags.push({
          boardId: boardId,
          card: type === 'card' ? tagId : null,
          page: type === 'page' ? tagId : null,
        });
      }
    },

  },
});

export const { setTag } = selectedTagsSlice.actions;

export default selectedTagsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  boardTags: null,
}
const sortTagsAlphabetically = (tags: any[]) => {
  return tags.sort((a, b) => a.label.localeCompare(b.label));
};

export const boardTagsSlice = createSlice({
  name: 'boardTags',
  initialState,
  reducers: {
    setBoardTags: (state, action) => {
      state.boardTags = sortTagsAlphabetically(action.payload);
    },
    addBoardTags: (state, action) => {
      action.payload.forEach((newTag: any) => {
        const existingTag = state.boardTags.find((tag: any) => tag.value === newTag.value);

        if (existingTag) {
          const currentCount = Number(existingTag.count || 0);
          existingTag.count = (currentCount + 1).toString();
        } else {
          state.boardTags = state.boardTags
            ? [...state.boardTags, { ...newTag, count: "1" }]
            : [{ ...newTag, count: "1" }];
        }
        state.boardTags = sortTagsAlphabetically(state.boardTags);
      });
    },


    removeBoardTags: (state, action) => {
      action.payload.forEach((removeTag: any) => {
        const existingTag = state.boardTags.find((tag: any) => tag.value === removeTag);

        if (existingTag) {
          const currentCount = Number(existingTag.count || 0);

          if (currentCount > 1) {
            existingTag.count = (currentCount - 1).toString();
          } else {
            state.boardTags = state.boardTags?.filter((tag: any) => tag.value !== removeTag);
          }
        }
        state.boardTags = sortTagsAlphabetically(state.boardTags);
      });
    },

  },
})

export const { setBoardTags, addBoardTags, removeBoardTags } = boardTagsSlice.actions

export default boardTagsSlice.reducer

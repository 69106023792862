import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DeleteInviteeModal = ({ disableBtn, show, handleClose, deleteInviteeHandler, userName }: any) => {
    const {theme}=useTheme()
    return (
        <Modal contentClassName={theme === 'dark' ? 'theme__div' : ''} show={show} onHide={handleClose}>
            <Modal.Header >
                <Modal.Title className={theme === 'dark' ? 'white__text' : ''}>Remove &ldquo;{userName}&ldquo;</Modal.Title>
                <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleClose}></i>
            </Modal.Header>
            <Modal.Body className={` ${theme === 'dark' ? 'white__text' : ''}`}>
                Are you sure you want to remove &ldquo;{userName}&ldquo; from this board ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                    No
                </Button>

                <Button variant='primary' onClick={deleteInviteeHandler} disabled={disableBtn}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteInviteeModal
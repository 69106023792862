/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const sendInvitation = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/people/invite/send`, data)
}

export const decodeInvitation = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL}/people/invite/decode`, data)
}

export const acceptInvitation = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/people/invite/accept`, data)
}
export const revokeInvitation = async (peopleInvitedId: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL_V3}/people/invite/revoke/${peopleInvitedId}`)
}
import {FileUploader} from 'react-drag-drop-files'
import {KTSVG} from '../../../_metronic/helpers'
import FileIcon from '../GetUploadedFileIcons'
import '../../styles/updateanaddform.css'
import {useTheme} from '../../context/ThemeContext'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FileUploadPage({deleteFileHandler, changeHandler, selectedFile}: any) {
  const {theme} = useTheme()

  return (
    <>
      <div className='mb-3 row'>
        <div className='col-md-12 col-sm-12 col-lg-12 mt-2'>
          <FileUploader label='Upload or drop attachment here' handleChange={changeHandler} name='file' classes={`file-upload`} multiple={true} />
        </div>
        {selectedFile.length > 0 && (
          <div className={`col-12 mt-2  my-3 ${theme === 'dark' ? 'theme__div' : 'bg-white'}`}>
            <div className='card-body p-0'>
              <div className='table-responsive' style={{maxHeight: '25vh'}}>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>File</th>
                      <th className='min-w-100px text-end'>Remove</th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedFile?.map((file: any, index: any) => (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-2'>
                              <FileIcon fileName={file.name} showName={file.showName} />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href={file.value}
                                rel='noreferrer'
                                target='_blank'
                                download
                                className={`${
                                  theme === 'dark' ? 'white__text' : ''
                                }  fw-bolder text-hover-primary fs-6`}
                              >
                                {file.name?.toLowerCase() || file.showName?.toLowerCase()}
                              </a>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={() => deleteFileHandler(file?.name, file.key, file.value)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div></div>
          </div>
        )}
      </div>
    </>
  )
}

import { useState } from 'react'
import { getOrganizationById } from '../services/company'
import { useAuth } from '../modules/auth'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { setCompanyDetail } from '../store/slice/company-details-slice'

const useCompany = ():any => {
  const [companyDetails, setCompanyDetails] = useState<any>()
  const [companyName, setCompanyName] = useState('Company / Organisation Name')
  const [logoPic, setLogoPic] = useState('')
  const dispatch: Dispatch<any> = useDispatch()
  const { currentUser } = useAuth()
  const { organizationId } = currentUser

  const organizationDetailsHandler = async () => {
    if(!organizationId){
      setCompanyDetails(null)
      dispatch(setCompanyDetail(null))
      setLogoPic('')
      setCompanyName('Company / Organisation Name')
      return}
    try {
      const res = await getOrganizationById(organizationId)
      
      dispatch(setCompanyDetail(res.data))
    
      if (res.data.data.logo) {
        setLogoPic(res.data.data.logo)
      }
    } catch (error) {
      return error
    }
  }


  return { organizationDetailsHandler, setCompanyDetails, companyDetails, logoPic, setLogoPic, setCompanyName, companyName }
}

export default useCompany


import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import 'react-loading-skeleton/dist/skeleton.css'

import { SkeletonTheme } from 'react-loading-skeleton'
import { useTheme } from './context/ThemeContext'
import VersionUpdateModal from './components/Modals/VersionUpdateModal'
import LoginTutorial from './components/Tutorials/LoginTutorial'




//comment added for testing rollback
const App: React.FC = () => {
  const { theme } = useTheme()

  return (
    <>
      <VersionUpdateModal />
      <LoginTutorial />
      <Suspense fallback={<LayoutSplashScreen />}>
        <SkeletonTheme
          baseColor={theme === 'dark' ? '#202020' : '#f4f4f4'}
          highlightColor={theme === 'dark' ? '#444' : '#fff'}
        >
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </SkeletonTheme>
      </Suspense>
    </>
  )
}

export { App }

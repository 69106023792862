import React from 'react'
import {Tooltip, PlacesType} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import '../../styles/reactTooltip.css'
import {useTheme} from '../../context/ThemeContext'

interface ReactToolTipTypes {
  id: string
  children: React.ReactNode
  position?: PlacesType
}

const ReactToolTip = ({id, children, position}: ReactToolTipTypes): any => {
  const {theme} = useTheme()
  return (
    <Tooltip
      // anchorSelect=".comments-read"
      id={id}
      place={position || 'top'}
      variant={theme === 'dark' ? 'dark' : 'light'}
      className='react-tooltip-custom-class'
      data-tooltip-content={true}
    >
      {children}
    </Tooltip>
  )
}

export default ReactToolTip

export function getFileExtension(url: string): string | null {
    const path = url.split('/');
    const fileName = path[path.length - 1];
    const dotIndex = fileName.lastIndexOf('.');
  
    if (dotIndex === -1) {
      
      return null;
    }
  
    return fileName.slice(dotIndex + 1);
  }


export function generateKey(): string {
  const timestamp = Date.now();
  const date = new Date().toISOString().split('T')[0]; // Get the current date in the format 'YYYY-MM-DD'
  return `${timestamp}-${date}`;
}



import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../../auth'
import DeleteInviteeModal from '../../../../../components/Modals/DeleteInviteeModal'
import CanelInvitationModal from '../../../../../components/Modals/CancelInviteeModal'
import {deletePersonFromBoard} from '../../../../../services/boards'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {sendInvitation, revokeInvitation} from '../../../../../services/invitation'
import {useWebSocketHook} from '../../../../../hooks'
import {setIsInviteAccepted} from '../../../../../store/slice/invitation-accepted-slice'
import {webSocketOperations} from '../../../../../constants'
import {capitalizeEachWord} from '../../../../../helper-functions/CapitalizeName'
import { useTheme } from '../../../../../context/ThemeContext'



// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UserActionsCell = ({rowDetails}: any) => {
  const {allRoles} = useSelector((state: any) => state.PersonRoleReducer)

  const {row} = rowDetails
  const {original: userData} = row

  const [show, setShow] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const [isInviteSend, setIsInviteSend] = useState(false)

  const params = useParams()

  const dispatch: Dispatch<any> = useDispatch()
  const {allBoards} = useSelector((state: any) => state.allBoardsReducer)
  
  const isAccepted = useSelector((state: any) => state.inviteReducer)
  const {isInviteAccepted} = isAccepted.isInviteAccepted
  // const {allBoards} = useGetAllBoards()
  const {currentUser} = useAuth()
  const {sendJsonMessage}: any = useWebSocketHook()
  const {fullName, id: userId, email: currentEmail} = currentUser?.data.user
  const board = allBoards?.find((item: any) => item.board.id === params.boardId)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  const modifyEmailForApi = (email: string) => {
    const encodedEmail = encodeURIComponent(email)
    return encodedEmail
  }

  const deleteInviteeHandler = async () => {
    setDisableBtn(true)
    try {
      const email = userData.email.toString()
      const modifiedEmail = modifyEmailForApi(email)

      await deletePersonFromBoard(params.boardId, userId, modifiedEmail)
      dispatch(setIsInviteAccepted({isInviteAccepted: !isInviteAccepted}))
      const webSocketPayload: any = {
        command: 'DELETE',
        event: 'publish',
        channel: params.boardId,
        userId: userId,
        operation: webSocketOperations.boardDesignInviteeDelete,
        payload: {
          boardId: params.boardId,
          alert: `${capitalizeEachWord(fullName)} removed you from board ${board.name}.`,
        },
      }
      sendJsonMessage(webSocketPayload)
      handleClose()
    } catch (error) {
      return error
    } finally {
      setTimeout(() => {
        setDisableBtn(false)
      }, 5000)
    }
  }
  const resendInvitationHandler = async () => {
    const roleId = allRoles?.length > 0 && allRoles?.find((roles: any) => roles.name === userData.role)?.id


    const inviteeArray = [
      {
        email: userData.email,
        boardId: board?.board.id,
        boardName: board?.board.name.trim(),
        roleId,
        inviteBy: fullName,
        inviteByUserId: userId,
        inviterEmail: currentEmail,
      },
    ]

    try {
      await sendInvitation({inviteeArray})
      setIsInviteSend(true)
    } catch (error) {
      return error
    } finally {
      setTimeout(() => {
        setIsInviteSend(false)
      }, 5000)
    }
  }

  const revokePendingInvitation: any = async () => {
    try {
      setDisableBtn(true)
     await  revokeInvitation(userData.id)
      dispatch(setIsInviteAccepted({isInviteAccepted: !isInviteAccepted}))
    } catch (error) {
      return error
    } finally {
      setShowCancelModal(false)
      setTimeout(() => {
        setDisableBtn(false)
      }, 5000)
    }
  }

  useEffect(() => {
    MenuComponent.reinitialization()
   
    //eslint-disable-next-line
  }, [])
  const {theme} = useTheme()

  return (
    <>
      {isInviteSend && <span className='text-success me-2 fs-5'>Invitation Sent!</span>}

      {userData.role !== 'Owner' && (
        <>
          <span
            className='btn btn-light  btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <i className='bi bi-three-dots'></i>
          </span>
          <div
            className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4 ${theme === 'dark' ? 'theme__div' : ''}`}
            data-kt-menu='true'
          >
            {userData.status === 'pending' && !isInviteSend && (
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3 fs-5'
                  data-kt-users-table-filter='delete_row'
                  onClick={resendInvitationHandler}
                >
                  <i
                    className='bi bi-arrow-90deg-right text-gray-600 mx-1 fs-5'
                    data-bs-toggle='tooltip'
                    title='Resend invitation to this person!'
                  ></i>
                  Resend Invitation
                </span>
              </div>
            )}

            {userData.status === 'pending' && !isInviteSend && (
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3 fs-5'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => setShowCancelModal(true)}
                >
                  <i
                    className='bi bi-x-circle mx-1 fs-5 text-gray-600'
                    data-bs-toggle='tooltip'
                    title='Cancel invitation to this person!'
                  ></i>
                  Cancel Invitation
                </span>
              </div>
            )}
            {userData.status !== 'pending' && (
              <div className='menu-item px-3'>
                <span
                  className='menu-link px-3 fs-5'
                  data-kt-users-table-filter='delete_row'
                  onClick={handleShow}
                >
                  <i
                    className='bi bi-trash mx-1 fs-5 text-gray-600'
                    data-bs-toggle='tooltip'
                    title='Remove user from this board!'
                  ></i>
                  Remove
                </span>
              </div>
            )}
          </div>
        </>
      )}

      <DeleteInviteeModal
        show={show}
        handleClose={handleClose}
        deleteInviteeHandler={deleteInviteeHandler}
        userName={userData.name}
        disableBtn={disableBtn}
      />
      <CanelInvitationModal
        show={showCancelModal}
        handleClose={setShowCancelModal}
        cancelInviteFunc={revokePendingInvitation}
        email={userData.email}
        disableBtn={disableBtn}
      />
    </>
  )
}

export {UserActionsCell}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const convertTime12to24 = (time12h: any) => {
  if (time12h) {
    const [time, modifier] = time12h.split(' ')
    let [hours] = time.split(':')
    const [minutes] = time.split(':')
    if (hours === '12') {
      hours = '00'
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }
    return `${hours}:${minutes}`
  }
}

export const convertTime24to12 = (time24h: any) => {
  if (time24h) {
    let ts: any = time24h
    const H: any = +ts.substr(0, 2)
    let h: any = H % 12 || 12
    h = h < 10 ? '0' + h : h // leading 0 at the left for 1 digit hours
    const ampm: any = H < 12 ? ' AM' : ' PM'
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }
}

export const getTimeDateFormate = (startDate: any) => {
  let utcDate
  let utcTime
  if (startDate) {
    const dateAndTime = startDate && startDate.toISOString()
    utcDate = dateAndTime.split('T')[0].substring(0, 11)
    utcTime = dateAndTime.split('T')[1].substring(0, 8)
  }
  return {utcDate, utcTime}
}

export const convertDateTimeToLocal = (utcDate: string, utcTime: string) => {
  if (utcDate && utcTime) {
    const dateTime = new Date(`${utcDate}T${utcTime}Z`)
    const localDate = dateTime.toLocaleString()
    const parts = localDate?.split(':')
    const localDateTime = `${parts[0]}:${parts[1]} ${parts[2].split(' ')[1] || ''}`
    return localDateTime
  }
  if (utcTime) {
    const dateTime = new Date(`2000-01-01T${utcTime}Z`)
    const localTime = dateTime.toLocaleString().split(',')[1]
    const parts = localTime?.split(':')
    const times = `${parts[0]}:${parts[1]} ${parts[2].split(' ')[1] || ''}`
    return times
  }
}
export const convertDateToLocal = (date: string) => {
  if (date) {
    const localDate = new Date(date)
    const options: any = {dateStyle: 'full'}
    const localString = localDate.toLocaleString('en-US', options)
    return localString
  }
}
export const modifiedDateStringHandler = (isoString: string) => {
  if (isoString) {
    const date = new Date(isoString)
    date.setUTCHours(0)
    date.setUTCMinutes(0)
    date.setUTCSeconds(0)
    date.setUTCMilliseconds(0)

    const modifiedISOString = date.toISOString()
    return modifiedISOString
  }
}
export const modifiedLastTimeStringHandler = (isoString: string) => {
  if (isoString) {
    const date = new Date(isoString)
    date.setUTCHours(23)
    date.setUTCMinutes(59)
    date.setUTCSeconds(59)
    date.setUTCMilliseconds(0)
    const modifiedISOString = date.toISOString()
    return modifiedISOString
  }
}

export const getCurrentDateFormatted = (currentDate: any) => {
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Add 1 to the month and pad with '0'
  const day = String(currentDate.getDate()).padStart(2, '0') // Pad day with '0'
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

// export const isLocalTimeBeforeUtc = (utcDate: string, utcTime: string) => {
//   // eslint-disable-next-line no-debugger

//   if (utcDate && utcTime) {
//     const localDateTime = new Date(`${utcDate}T${utcTime}Z`)
//     const currentLocalDateTime = new Date()

//     const currentLocalDateTimeYear = currentLocalDateTime.getFullYear()
//     const currentLocalDateTimeMonth = currentLocalDateTime.getMonth() + 1
//     const currentLocalDateTimeDay = currentLocalDateTime.getDate()

//     const localDateTimeYear = localDateTime.getFullYear()
//     const localDateTimeMonth = localDateTime.getMonth() + 1
//     const localDateTimeDay = localDateTime.getDate()
//     if (
//       localDateTimeYear < currentLocalDateTimeYear ||
//       (localDateTimeYear === currentLocalDateTimeYear &&
//         (localDateTimeMonth < currentLocalDateTimeMonth ||
//           (localDateTimeMonth === currentLocalDateTimeMonth &&
//             localDateTimeDay <= currentLocalDateTimeDay)))
//     ) {
//       return true
//     }
//     return false
//   }
// }

export const isLocalTimeBeforeUtc = (utcDate: string, utcTime: string) => {
  if (utcDate && utcTime) {
    const utcDateTime = new Date(`${utcDate}T${utcTime}Z`)
    const currentLocalDateTime = new Date()

    return utcDateTime <= currentLocalDateTime
  }
  return false
}

export function getTimeFromString(timeString: string) {
  const date = new Date(timeString)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  const time = `${hours}:${minutes}:${seconds}`

  return time
}

export function removeSeconds(timeDate: string) {
  if (timeDate) {
    const parts = timeDate?.split(':')
    const localDateTime = `${parts[0]}:${parts[1]} ${parts[2].split(' ')[1] || ''}`
    return localDateTime
  } else {
    return '--'
  }
}
export function formatDate(dateString: string) {
  const date = new Date(dateString)
  const options: any = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }
  return new Intl.DateTimeFormat('en-GB', options).format(date)
}

export function formatDateAndTime  (dateString: string): string  {
  const dateObj = new Date(dateString);

  // Extract year, month, day, hour, and minute
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hour24 = dateObj.getHours(); // Get hours in 24-hour format
  const minute = String(dateObj.getMinutes()).padStart(2, '0');

  // Convert to 12-hour format
  const hour12 = hour24 % 12 || 12; // Convert 24-hour to 12-hour (0 should be 12)
  const period = hour24 >= 12 ? 'PM' : 'AM';

  return `${year}-${month}-${day} ${hour12}:${minute}${period}`;// Output: "2024-08-13 12:17PM"
}
import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  dateFilter: {days:null,singleDate:{from:null,to:null},dateRange:{from:null,to:null}},
}

export const DoneFilterSlice = createSlice({
  name: 'DoneFilterSlice',
  initialState,
  reducers: {
    setDoneFilterValues: (state, action:any) => {
      state.dateFilter = action.payload
    },
  },
})

export const {setDoneFilterValues} = DoneFilterSlice.actions

export default DoneFilterSlice.reducer

import React,{ useState} from 'react'
import {getAllCardByBoardId, getSortedCardByBoardId} from '../services/cards'
import {useAuth} from '../modules/auth'

const useGetCards = (): {
  cards: any;
  setCards: React.Dispatch<any>;
  fetchCardHandler: (boardId: string) => Promise<unknown>;
  fetchSortedCardHandler: (boardId: string) => Promise<unknown>;
} => {
  const [cards, setCards] = useState<any>([])
  const {currentUser} = useAuth()
  const {id} = currentUser?.data.user
  const fetchCardHandler = async (boardId: string) => {
    try {
      const res = await getAllCardByBoardId(boardId, id)
      setCards(res.data.data)
      return res.data.data;
    } catch (error) {
      return error
    }
  }
  const fetchSortedCardHandler = async (boardId: string) => {
    try {
      const res = await getSortedCardByBoardId(boardId)
      setCards(res.data.data)
    } catch (error) {
      return error
    }
  }

  return {cards, setCards, fetchCardHandler, fetchSortedCardHandler}
}

export default useGetCards

import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { longWordSlicingWithCustomLength } from '../../helper-functions/CheckString'
import { useTheme } from '../../context/ThemeContext'
import * as Yup from 'yup';
import { useFormik } from 'formik'
import clsx from 'clsx'

const titleSchema = Yup.object({
  customTitle: Yup.string()
    .min(3, 'Minimum 3 Characters')
    .max(300, 'Maximum 300 Characters')
    .required('Page Title is Required')
    .trim(),
});
const ClonePageModal: React.FC<any> = ({ openCloneModal, setOpenCloneModal, page, loading, clonePageHandler }: any) => {
  const { theme } = useTheme()
  const initialValues = { customTitle: page?.title }

  const [formData, setFormData] = useState<any>({
    title: true,
    website_name: true,
    description: true,
    // tags: true,
    // assignees: true,
    // file_attachments: true,
    status: true,
    assignee: true,
  })
  const labelMapping: Record<string, string> = {
    title: 'Title',
    website_name: 'Website/App Name',
    description: 'Description',
    status: 'Status',
    assignee: 'Assignees',
    // assignees: 'Assigned to',
    // file_attachments: 'Attachments',
    // tags: 'Tags',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: titleSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      clonePageHandler(page, values.customTitle)
    }
  })

  const handleCheckboxChange = (key: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [key]: !prevFormData[key],
    }))

    // setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]));
  }
  // const handleSelectAllChange = () => {
  //   setSelectAll((prevSelectAll: any) => !prevSelectAll)
  //   const allChecked = !selectAll
  //   setFormData({
  //     title: true,
  //     website_name: true,
  //     description: true,
  //   //   tags: allChecked,
  //   //   assignees: allChecked,
  //   //   file_attachments: allChecked,
  //     status: allChecked,
  //   })

  //   setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]));
  // }
  //   useEffect(() => {
  //     setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]))
  //     const isAllTrue = Object.values(formData).every((value: any) => value === true)
  //     setSelectAll(isAllTrue)
  //   }, [formData])
  //   }
  //   useEffect(() => {
  //     setSelectedKeys(Object.keys(formData).filter((key: any) => formData[key]))
  //     const isAllTrue = Object.values(formData).every((value: any) => value === true)
  //     setSelectAll(isAllTrue)
  //   }, [formData])

  return (

    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={openCloneModal}
      onHide={() => setOpenCloneModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className={`text-truncate ${theme === 'dark' ? 'white__text ' : 'text-truncate'}`}
        >
          Clone
          <span className='text-muted' style={{ marginLeft: '8px' }}>
            {page?.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={formik.handleSubmit}>

            {Object.keys(formData).map((key: any) => (
              <div key={key} className='form-check mb-3 my-4'>
                <input
                  type='checkbox'
                  className={`form-check-input ${theme === 'dark' ? 'dark__theme__input' : ''} `}
                  id={key}
                  checked={formData[key]}
                  onChange={() => key !== 'title' && handleCheckboxChange(key)}
                  disabled={(key === 'website_name') || key === 'title' || key === 'description' || key === 'status' || key === 'assignee'}
                />
                <label className='form-check-label' htmlFor={key}>
                  {labelMapping[key]}
                </label>
                {key === 'title' && (
                  <div>
                    <input
                      type='text'
                      placeholder='Enter Page Title'
                      {...formik.getFieldProps('customTitle')}
                      className={clsx(
                        `form-control form-control-lg form-control-solid  ${theme === 'dark' ? 'dark__theme__input' : ''
                        }  italics-placeholder`,
                        {
                          'is-invalid': formik.touched.customTitle && formik.errors.customTitle,
                        },
                        {
                          'is-valid': formik.touched.customTitle && !formik.errors.customTitle,
                        }
                      )}
                    />
                    {formik.touched.customTitle && formik.errors.customTitle && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.customTitle as any}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className={`${theme === 'dark' ? 'white__text' : ''
            } d-flex justify-content-between w-100 align-items-center`}
        >
          {loading ? (
            <div className='fs-5'>
              <Spinner
                animation='border'
                variant={theme === 'dark' ? 'light' : 'dark'}
                size='sm'
                className='mx-2'
              />
              Cloning{' '}
              <span
                className={theme === 'dark' ? 'white__text' : 'text-dark'}
                style={{ wordBreak: 'break-all' }}
              >
                &quot;{longWordSlicingWithCustomLength(page.title, 15)}&quot;
              </span>
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <Button variant='secondary mx-1'
              onClick={() => setOpenCloneModal(false)} >
              Cancel
            </Button>

            <Button
              disabled={loading}
              variant='primary mx-1'
              onClick={() => formik.handleSubmit()}
            >
              {loading ? <Spinner animation='border' variant='light' size='sm' /> : 'Clone'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ClonePageModal
import React, {useCallback, useState} from 'react'
import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {User} from '../core/_models'
import {UsersListLoading} from '../components/loading/UsersListLoading'
// import { UsersListPagination } from '../components/pagination/UsersListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import CustomPagination from '../../../../components/Pagination'
import { useTheme } from '../../../../context/ThemeContext'
// import { useAuth } from '../../../../auth'
type Props = {
  membersData: any
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UsersTable: React.FC<Props> = ({membersData}) => {
  // const users = useQueryResponseData()
  const users = membersData ? membersData : [{id: '', name: '', email: '', role: '', status: ''}]
  const {theme} = useTheme()
  const [page, setPage] = useState<number>(1)
  const isLoading = useQueryResponseLoading()
  // const data = useMemo(() => users,
  // eslint-disable-next-line
  // [users])

  const data = users
  const columns = useMemo(() => usersColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const limit = useMemo(() => {
   return 5
  }, [])

  const paginatedFunction = useCallback(
    (array: any, page_size: number, page_number: number) => {
      return array.slice((page_number - 1) * page_size, page_number * page_size)
    },
    [page]
  )

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows?.length > 0 ? (
              (paginatedFunction(rows, limit, page) || []).map((row: Row<User>, i: number) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className={`d-flex text-center w-100 align-content-center justify-content-center ${theme === 'dark' ? 'white__text' : ''} `}>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
       
      </div>
     <div style={{
       float : "right",
       clear: "both"

     }}>
     <CustomPagination
          itemsCount={rows?.length || 1}
          itemsPerPage={limit || 5}
          currentPage={page}
          setCurrentPage={setPage}
        />
     </div>
      {/* <UsersListPagination /> */}
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {UsersTable}

import { FC } from 'react'
import { useTheme } from '../../../context/ThemeContext'

const Error404: FC = () => {
  const {theme} = useTheme()
  return (
    <>
      <h1 className={`fw-bolder fs-4x ${theme === 'dark'? 'white__text' :'text-gray-700'} mb-10`}>Page Not Found</h1>

      <div className='tablet_font fw-bold fs-3 text-gray-400 mb-15'>
        The page you looked not found! You may need to log in with the account created using the same email or the token session is expired.<br />
      </div>
    </>
  )
}

export { Error404 }

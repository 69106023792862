import Select from 'react-select'
import {removeDuplication} from '../../helper-functions/CapitalizeName'
import { useTheme } from '../../context/ThemeContext'
// import { useAuth } from '../../modules/auth'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SendAlertTo = ({options, setSelected, selected, role}: any) => {
  // const { currentUser } = useAuth()
  const {theme : themeDark} = useTheme()
  // const { role : checkRole } = currentUser?.data.user

  const colourStyles: any = {
    control: (styles: any) => ({...styles, backgroundColor: themeDark === 'dark' ? '' : 'white'}),
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: themeDark === 'dark' ? '#141520' : '#E6E6E6',
      }
    },
    multiValueLabel: (styles: any, {data}: any) => ({
      ...styles,
      backgroundColor: data.isDisabled ? '#FF677E' : '',
      color: data.isDisabled ? 'white' : '',
    }),
    multiValueRemove: (styles: any, {data}: any) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: '#FF677E',
        color: 'white',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      overflow: 'auto',
      maxHeight: '60px', 
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#FF7C00' : 'hsl(0, 0%, 80%)',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#98C6F0' : 'hsl(0, 0%, 80%)',
    }),
  }
  return (
    <>
      <Select
        isDisabled={role && role === 'Guest' ? true : false}
        value={removeDuplication(selected)}
        isMulti
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        styles={colourStyles}
        onChange={(e: any) => setSelected(e)}
        theme={(theme) => ({
          ...theme,
          
          colors: {
            ...theme.colors,
           
            neutral0: themeDark === 'dark' ? "#1d1e2c" : theme.colors.neutral0,
           
            neutral80: themeDark === 'dark' ?  "#F4FFFD" : theme.colors.neutral80,
  
            primary25:themeDark === 'dark' ?  "#141520" : theme.colors.primary25
          },
        })}
      />
    </>
  )
}
export default SendAlertTo

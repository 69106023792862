export class ElementStyleUtil {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static set(element: HTMLElement, property: string, value?: any, important?: boolean) {
    if (important) {
      element.style.setProperty(property, value, 'important')
    } else {
      element.style.setProperty(property, value)
    }
  }

  public static get(element: HTMLElement, attributeName: string):string {
    return element.style.getPropertyValue(attributeName)
  }

  public static remove(element: HTMLElement, attibuteName: string):void {
    element.style.removeProperty(attibuteName)
  }
}


import { FC, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import LogoutListener from '../helper-functions/logoutLitsner'


const AppRoutes: FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { currentUser } = useAuth()
  const handleLogout = () => {
    setIsLoggedIn(false);
    // Perform any additional logout tasks
  };

  return (
    <>
      {/* <BrowserRouter basename={PUBLIC_URL}> */}
      <LogoutListener onLogout={handleLogout} />
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {currentUser && isLoggedIn ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/home' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export { AppRoutes }

import React from 'react'
// import { useTemplates } from "../../../hooks"
import Select from 'react-select'
import {capitalizeEachWord} from '../../helper-functions/CapitalizeName'
import { useTemplates } from '../../hooks'
import '../../styles/template.css'
import {useTheme} from '../../context/ThemeContext'
import { useSelector } from 'react-redux'
function BoardTemplate({
  templateId,
  setSuccessMessage,
  setCurrentAssignBoard,
}: {
  templateId: string
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setCurrentAssignBoard: React.Dispatch<React.SetStateAction<string>>
}) {
  const {allBoards} = useSelector((state: any) => state.allBoardsReducer)
  // const {allBoards, fetchBoards} = useGetAllBoards()
  const {theme: themeDark} = useTheme()
  const {getTemplateAndLoad} = useTemplates()

  const options = Array.isArray(allBoards)
    ? allBoards
        .map((item: any) => ({
          value: item?.board?.id,
          label: capitalizeEachWord(item?.board?.name?.trim()),
          isDisabled: item.board.hasCards === true || item.isAdmin === false,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : []


  return (
    <Select
      menuPortalTarget={document.getElementById('template-table') as HTMLElement}
      maxMenuHeight={200}
      styles={{
        control: (provided: any) => ({
          ...provided,
          width: '200px',
          height: '40px',
        }),
        option: (provided, state) => {
          if (themeDark === 'dark') {
            return {
              ...provided,
              backgroundColor: state.isDisabled ? '#141520' : provided.backgroundColor,
              cursor: state.isDisabled ? 'not-allowed' : provided.cursor,
              color: state.isDisabled ? '#9898aa' : '#F4FFFD',
            }
          } else {
            return provided
          }
        },
      }}
      options={options}
      onChange={async (e: any) => {
        const response = await getTemplateAndLoad(templateId, e.value)
        if (response?.success) {
          setCurrentAssignBoard(e.value)
          setSuccessMessage(response?.message)
          setTimeout(() => {
            setCurrentAssignBoard('')
            setSuccessMessage('')
          }, 9000)
        }
      }}
      theme={(theme) => ({
        ...theme,

        colors: {
          ...theme.colors,

          neutral0: themeDark === 'dark' ? '#1d1e2c' : theme.colors.neutral0,
          neutral80: themeDark === 'dark' ? '#F4FFFD' : theme.colors.neutral80,
          primary25: themeDark === 'dark' ? '#141520' : theme.colors.primary25,
        },
      })}
      placeholder='All Boards '
    />
  )
}

export default React.memo(BoardTemplate)

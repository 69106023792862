import React,{ useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { userLookup, userVerification } from '../../../services/user-lookup'
import VerifyEmailForm from '../../../components/Forms/VerifyEmailForm'

const initialValues = {
    email: '',
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        // .min(3, 'Minimum 3 symbols')
        // .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
})

export function VerifyEmail(): React.JSX.Element {
    const [loading, setLoading] = useState(false)
    const [popUpMsg, setPopUpMsg] = useState("")
    const navigate = useNavigate()

    const currentURL = window.location.href;
    const value = currentURL.includes('trial') ? 'trial' : 'monthly-yearly'
    localStorage.setItem('plan', value)

useEffect(() => {
    localStorage.removeItem("planData")
    //eslint-disable-next-line
}, [])


    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values) => {
            setLoading(true)
            const msg = "An email has been sent to the address you entered. Please check your email, to verify your account."
            try {
                const lookupResponse: any = await userLookup(values.email)

                if (lookupResponse.data.success) {
                    setPopUpMsg(msg)
                    return
                }

            } catch (error: any) {
                if (error.response.status === 404) {
                    try {
                        await userVerification(values.email)
                        setPopUpMsg(msg)
                    } catch (error) {
                        setPopUpMsg(msg)
                    }
                }
                setLoading(false)

            } finally {
                setTimeout(() => {
                    navigate("/auth")
                }, 5000);
            }

        },
    })

    return (
        <VerifyEmailForm handleSubmit={formik.handleSubmit} title="Register for your account" setPopUpMsg={setPopUpMsg} popUpMsg={popUpMsg} formik={formik} loading={loading} />

    )
}

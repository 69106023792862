import { FC } from 'react'

const SignupError: FC = () => {
  return (
    <>
      <h3 className='fw-bolder fs-3x text-gray-700 mb-10'>Did you select any Plan from <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE}>
        B4igo.ai</a>?
        </h3>
      <div className='fw-bold fs-2 text-gray-400 mb-15'>
        If yes continue the registration process <b>on same browser where you selected the plan!</b> 
      </div>
    </>
  )
}

export { SignupError }

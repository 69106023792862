import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../core'
import { DrawerComponent } from '../../assets/ts/components'
// import './Content.css'


interface ComponetProps {
  children : React.ReactNode
}
const Content: React.FC<ComponetProps> = ({ children }) => {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='kt_content_container' style ={{maxWidth: "100%"}} className={clsx('content-container', classes.contentContainer.join(' '))}>
  {children}
</div>

  )
}

export { Content }
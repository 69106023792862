import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllRoles} from '../../services/user-lookup'

export const fetchAllRoles = createAsyncThunk('board/fetchAllRoles', async () => {
  try {
    const response = await getAllRoles()

    return response.data.data
  } catch (error: any) {
    return error
  }
})

const initialState: any = {
  role: null,
  allRoles: [],
}

export const PersonRoleSlice = createSlice({
  name: 'person-role',
  initialState,
  reducers: {
    updateRole: (state, action) => {
      state.role = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
      state.allRoles = [...action.payload]
    })
  },
})

export const {updateRole} = PersonRoleSlice.actions

export default PersonRoleSlice.reducer

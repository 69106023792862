
export const getFileIcon = (extension: string | undefined,theme:string): string => {
    const fileIcons: Record<string, string> = {
        png: theme === 'dark' ?'png-icon-white.svg': 'png-file-icon.svg',
        jpeg: theme === 'dark'? 'jpg-icon-white.svg' : 'jpeg-file-icon.svg' ,
        jpg: theme === 'dark'? 'jpg-icon-white.svg' : 'jpeg-file-icon.svg',
        docx: 'old-doc.svg',
        doc: 'old-doc.svg',
        xls: 'old-excel.svg',
        csv: 'old-excel.svg',
        xlsx: 'old-excel.svg',
        pdf: 'a-pdf.svg',
        xd: 'a-xd.svg',
        jam: 'f-figma.svg',
        fig: 'f-figma.svg',
        pptx: 'old-ppt.svg',
        zip: 'zip-icon.svg',
    };

    if (extension) {
        return fileIcons[extension?.toLowerCase()] ||( theme === 'dark' ? 'page-file-icon-white.svg': 'page-file-icon.svg');
    } else {
        return 'bi-file-earmark-medical'
    }
};


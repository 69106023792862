import {useEffect, useState} from 'react'
import {useAuth} from '../modules/auth'
import {getAllBoards} from '../services/boards'

const useGetAllBoards = (): {
  allBoards: any;
  fetchBoards:any
  isBoardLoading: boolean
} => {
  const [allBoards, setAllBoards] = useState<any>([])
  const [isBoardLoading, setIsBoardLoading] = useState<boolean>(false)
  const {currentUser} = useAuth()
  const {id,isInvitedByOrganization} = currentUser?.data.user
  const orgId= isInvitedByOrganization && currentUser?.organizationId
  const fetchBoards = async () => {
    try {
      setIsBoardLoading(true)
      const res = await getAllBoards(id,orgId)
      const boardData = res.data.data.boards
      if(boardData.length>0){
        const filterBoard = boardData.length && boardData.filter((board : any)=> board.isGuest === false )
        setAllBoards(filterBoard)
      }
    } catch (error: any) {
      return error
    }finally{
      setIsBoardLoading(false)
    }
  }

  useEffect(() => {
    if (allBoards?.length > 0) return
    fetchBoards()
    //eslint-disable-next-line
  }, [])

  return {allBoards , fetchBoards , isBoardLoading}
}

export default useGetAllBoards

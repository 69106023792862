
import React,{ useState } from 'react'
import { userVerification } from '../services/user-lookup'

const useEmailVerification = (): {
  emailVerificationHandler: (data: any) => Promise<unknown>;
  isEmailSent: string;
  setIsEmailSent: React.Dispatch<React.SetStateAction<string>>;
  debounce: boolean;
  isLoading: boolean;
} => {
const [isEmailSent, setIsEmailSent] = useState("")
const [debounce, setDebounce] = useState(false)
const [isLoading, setIsLoading] = useState(false)

  const emailVerificationHandler = async (data: any) => {
    setDebounce(true)
    setIsLoading(true)
    try {
      await userVerification(data)
      setIsEmailSent(`Email has been sent!`)
    } catch (error) {
      return error
    }finally{
      setTimeout(() => {
        setDebounce(false)
        setIsLoading(false)
        setIsEmailSent("")
      }, 5000);
    }
  }

  return {emailVerificationHandler,isEmailSent,setIsEmailSent,debounce,isLoading}
}

export default useEmailVerification

import React from 'react'
import {Alert, Button, Modal, Spinner} from 'react-bootstrap'
import {useTheme} from '../../context/ThemeContext'
import {longWordSlicingWithCustomLength} from '../../helper-functions/CheckString'

type Props = {
  showDeleteModal: boolean
  handleCloseDeleteModal: () => void
  title: string
  loading: boolean
  onDeleteHandler: () => void
  message: string
}

const DeleteTemplateModal: React.FC<Props> = ({
  showDeleteModal,
  handleCloseDeleteModal,
  title,
  loading,
  onDeleteHandler,
  message,
}: Props) => {
  const {theme} = useTheme()
 
  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={showDeleteModal}
      onHide={handleCloseDeleteModal}
    >
      <Modal.Header>
        <Modal.Title
          className={`text-truncate ${theme === 'dark' ? 'theme__div' : ''}`}
        >{`Delete "${title?.trim()}"`}</Modal.Title>
        <i
          className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`}
          onClick={handleCloseDeleteModal}
        ></i>
      </Modal.Header>
      <Modal.Body style={{wordBreak: 'break-all'}}>
        <span className={theme === 'dark' ? 'white__text' : ''}>
          Are you sure you want to delete this Template?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div
          className={`${
            theme === 'dark' ? 'white__text' : ''
          } d-flex justify-content-between w-100 align-items-center`}
        >
          {loading ? (
            <div className='fs-5'>
              <Spinner
                animation='border'
                variant={theme === 'dark' ? 'light' : 'dark'}
                size='sm'
                className='mx-2'
              />
              Deleting{' '}
              <span
                className={theme === 'dark' ? 'white__text' : 'text-dark'}
                style={{wordBreak: 'break-all'}}
              >
                &quot;{longWordSlicingWithCustomLength(title, 15)}&quot;
              </span>
            </div>
          ) : (
            <div>
              {message && (
                <Alert variant='success' className='mb-0 w-100'>
                  {message}!
                </Alert>
              )}
            </div>
          )}
          <div>
          {!message && (
            <>
            <Button variant='secondary mx-2' onClick={handleCloseDeleteModal}>
              No
            </Button>
            <Button disabled={loading} variant='primary' onClick={onDeleteHandler}>
              Yes
            </Button>
            </>)}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteTemplateModal



/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const compareItems = (item1:any, item2:any) => {
    const processedItem1 = item1.board.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const processedItem2 = item2.board.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  
    return processedItem1.localeCompare(processedItem2);
  };
  
  export const compareItemsArchived = (item1:any, item2:any) => {
    const processedItem1 = item1.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const processedItem2 = item2.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  
    return processedItem1.localeCompare(processedItem2);
  };
import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Timer({ setSeconds, seconds }: any) {

  useEffect(() => {
    if (seconds > 0) {
      const countdown = setInterval(() => {
        setSeconds((seconds: any) => seconds - 1)
      }, 1000)
      return () => clearInterval(countdown)
    }

    //eslint-disable-next-line
  }, [seconds])

  return <span className='text-primary'>{seconds}</span>
}

import React from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'

const UsersListToolbar :React.FC<any>= ({setShow}:any) => {
  const { setItemIdForUpdate } = useListView()
  const openAddUserModal = () => {
    setShow(true)
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter setBoardIdState={setBoardIdState} /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Invite
      </button>
      {/* end::Add user */}
    </div>
  )
}

export { UsersListToolbar }

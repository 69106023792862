import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllCardByBoardId, getSortedCardByBoardId, getAllCardByUserId , getAllCard } from '../../services/cards'

// First, create the thunk



const initialState: any = {
  cards: [],
  cardsByUser: [],
  allCurrentUserCards : [],
  loading: false,
  errors: {},
  editCardState: false
}

export const fetchAllCardByBoardId: any = createAsyncThunk(
  'cardSlice/fetchAllCardByBoardId',
  async ({ boardId, userId }: { boardId: string, userId: string }) => {
    try {
      const response = await getAllCardByBoardId(boardId, userId) 
      return response.data.data
    } catch (error) {
      return error
    }
  }
)
export const fetchAllCardByUserId = createAsyncThunk(
  'cards/fetchAllCardByUserId',
  async ({ userId, boardStatus, filterOptions }: { userId: any; boardStatus: any; filterOptions: any }, thunkAPI) => {
    try {
      const response = await getAllCardByUserId(userId, boardStatus, filterOptions);
      if (response.data !== null) {
        return response.data; // Assuming response.data is the relevant data in your API response
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllSortedCard = createAsyncThunk(
  'cardSlice/fetchAllSortedCard',
  async (boardId: string) => {
    try {
      const response = await getSortedCardByBoardId(boardId)

      return response.data
    } catch (error) {
      return error
    }
  }
)
export const fetchAllCardsofLoggedInUser = createAsyncThunk(
  'cardSlice/fetchAllCardsofLoggedInUser',
  async () => {
    try {
      const response = await getAllCard()

      return response.data.data
    } catch (error) {
      return error
    }
  }
)


export const cardSlice = createSlice({
  name: 'cardSlice',
  initialState,
  reducers: {
    dropCardPosition: (state, action) => {
      state.entities.data = { ...action.payload }
    },
    showEditCard: (state) => {
      state.editCardState = true
    },
    hideEditCard: (state) => {
      state.editCardState = false
    },
    addHomeCardData: (state, action) => {
      state.cards = action.payload
    },
    updateHomeCardData: (state, action) => {
      state.allCurrentUserCards = action.payload
    },
    addAllCurrentUserCards: (state, action) => {
      state.allCurrentUserCards.push(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCardByUserId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllCardByUserId.fulfilled, (state: any, action) => {
      state.cardsByUser = action.payload
      state.loading = false
    })
    builder.addCase(fetchAllCardByUserId.rejected, (state,) => {
      state.loading = false
      // state.errors = action.payload
    })


    builder.addCase(fetchAllCardByBoardId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllCardByBoardId.fulfilled, (state: any, action) => {
      state.cards = action.payload
      state.loading = false
    })
    builder.addCase(fetchAllCardByBoardId.rejected, (state) => {
      state.loading = false
      // state.errors = action.payload
    })
    builder.addCase(fetchAllSortedCard.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllSortedCard.fulfilled, (state, action) => {
      state.entities = action.payload
      state.loading = false
    })
    builder.addCase(fetchAllSortedCard.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
    builder.addCase(fetchAllCardsofLoggedInUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllCardsofLoggedInUser.fulfilled, (state, action) => {
      state.allCurrentUserCards = action.payload.cards || []
      state.loading = false
    })
    builder.addCase(fetchAllCardsofLoggedInUser.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export const { dropCardPosition, showEditCard, hideEditCard, addHomeCardData,updateHomeCardData,addAllCurrentUserCards } = cardSlice.actions
export default cardSlice.reducer

import * as crypto from 'crypto-js'

export const cryptoEncrypt :any= (data: any) :any =>  {
    const encryptedData = crypto.AES.encrypt(
        JSON.stringify(data),      
        `${process.env.REACT_APP_CRYPTO_KEY}`
    ).toString()
    return encryptedData
}


export const cryptoDecrypt :any = (data: any) :any => {
    const encryptedData = crypto.AES.decrypt(data, `${process.env.REACT_APP_CRYPTO_KEY}`)
    const decryptedData = encryptedData.toString(crypto.enc.Utf8)
   
    return decryptedData
}

import React from 'react'
import clsx from 'clsx'
import {Modal, Button} from 'react-bootstrap'
import CustomAlert from '../CustomAlert'
import { useTheme } from '../../context/ThemeContext'

type Props = {
  loading: boolean
  showModal: boolean
  modalTitle: string
  modalBodyMsg: string
  inputPlaceholder: string
  inputLabel: string
  inputType: string
  inputValue: string
  errorMsg: string
  btnConfirmTitle: string
  btnCloseTitle: string
  handleCloseModal: () => void
  onConfirmation: () => void
  setValue: React.Dispatch<React.SetStateAction<string>>
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
}

const GeneralModal: React.FC<Props> = ({
  showModal,
  handleCloseModal,
  modalTitle,
  modalBodyMsg,
  inputPlaceholder,
  inputLabel,
  inputType,
  setValue,
  inputValue,
  errorMsg,
  setErrorMsg,
  btnConfirmTitle,
  btnCloseTitle,
  onConfirmation,
  loading,
}: Props) => {

    const {theme} = useTheme()
  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ""}
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className={theme === 'dark' ? 'white__text' : ""}>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBodyMsg}
        <div className='mb-10 mt-7 fv-row' data-kt-password-meter='true'>
          <div className=''>
            <label className={`${theme === 'dark' ? 'white__text' : "text-dark"} form-label fw-bolder  fs-6`}>{inputLabel}</label>
            <div className='position-relative mb-3'>
              <div className='input-group'>
                <input
                  value={inputValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                  type={inputType}
                  placeholder={inputPlaceholder}
                  autoComplete='off'
                  className={clsx(
                   `form-control form-control-lg form-control-solid  ${
                      theme === 'dark' && 'dark__theme__input'
                    }`
                  )}
                />
              </div>
            </div>
          </div>
          <CustomAlert
            variant={'info'}
            alertMessage={errorMsg}
            className='alert alert-info'
            setAlertMessage={setErrorMsg}
            time={5000}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseModal}>
          {btnCloseTitle}
        </Button>

        <Button variant='primary' onClick={onConfirmation} disabled={loading}>
          {btnConfirmTitle}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GeneralModal

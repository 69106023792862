import React, { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { Link, useNavigate } from 'react-router-dom'
import LastUpdate from '../../LastUpdate/LastUpdate'
// import LinkCardUpdate from '../../LinkCardEdit'
import ExcelExport from '../../ExcelExport'
import CreateTemplate from './CreateTemplate'
import { useMemo } from 'react'
import LoadTemplate from './LoadTemplate'
import { checkLongWordWithoutSpace } from '../../../helper-functions/CheckString'
import { useSelector } from 'react-redux'
import '../../../styles/boardpage.css'
import { Dropdown, Button } from 'react-bootstrap'
import GeneralSearch from '../../../components/GeneralSearch'
import '../../../styles/generalsearch.css'
import { useTheme } from '../../../context/ThemeContext'
import { useAuth } from '../../../modules/auth'
import Skeleton from 'react-loading-skeleton'

type Props = {
  columnResponse: any
  personRole: string
  boardId: string
  newTitle: string
  boardTitle: string
  isEditableTitle: boolean
  isSorted: boolean
  cards: any
  onChangeSortBtn: any
  fetchColumnHandler: any
  onUpdateTitle: () => void
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
  setBoardTitle: React.Dispatch<React.SetStateAction<string>>
  handleTagClick: any
  boardTags: any
  selectedTag:any
  loadingBoardTags:boolean
}

const BoardHeader: React.FC<Props> = ({
  // newTitle,
  boardId,
  personRole,
  setIsEditable,
  setBoardTitle,
  onChangeSortBtn,
  onUpdateTitle,
  isEditableTitle,
  boardTitle,
  isSorted,
  cards,
  columnResponse,
  fetchColumnHandler,
  boardTags,
  handleTagClick,
  selectedTag,
  loadingBoardTags
}: Props) => {
  // let isPageArchiveBoard = window.location.href.includes('archived')

  //guest -mode : hide design and create templatee
  const navigate = useNavigate()
  const columns = useMemo(() => columnResponse, [columnResponse])
  const { theme } = useTheme()
  const { currentUser } = useAuth()
  const { isVerified, isInvitedByOrganization } =
    currentUser?.data.user
  const allBoardCards = useSelector((state: any) => state.cardReducer.cards)

  const [isListVisible, setIsListVisible] = useState<boolean>(false)

  return (
    <>
      <div className={`board_container ${(!isVerified && !isInvitedByOrganization) && 'board_container_verify'}  ${theme === 'dark' ? 'dark__div' : ''}`}>
        <div className='board_header_heading  mb-2 mt-2  row'>
          <div className='col-lg-3 col-md-3'>
            <EditableBoardTitle
              personRole={personRole}
              isEditableTitle={isEditableTitle}
              setIsEditable={setIsEditable}
              onUpdateTitle={onUpdateTitle}
              boardTitle={boardTitle.length > 0 ? boardTitle?.trim() : ''}
              setBoardTitle={setBoardTitle}
              theme={theme}
            />
          </div>

          {personRole !== 'Admin' ? (
            <div
              className='col-lg-4 col-md-4'
              style={{ marginTop: 2 }}
            >
            </div>
          ) : null}

          <div className='col-lg-5 col-md-5 d-flex justify-content-lg-end  justify-content-md-end'>
            <LastUpdate />
          </div>
        </div>
        <div className='mt-8'>
          {/* {personRole !== 'Guest' ? ( */}
            <div
              className={`board_header_button ${allBoardCards?.length > 0 ? 'board_header_button_align' : ''
                }`}
            >
              <div className='design_and_template_btn'>
                <span className='d-flex align-items-start'>
                  <Button
                    onClick={() => navigate(`/notes/${boardId}`, { state: boardTitle })}
                    className='btn btn-primary btn-md notes_btn'
                    style={{ padding: '8.5px 12px' }}
                  >
                    Notes
                  </Button>
                  {personRole === 'Admin' && (
                    <>
                    <Link
                      to={`/board/${boardId}/design`}
                      // className='me-3'
                      style={{ padding: '8.5px 12px' }}
                    >
                    <i className={`fa fa-cog design_icon fs-1 cursor-pointer ${theme === 'dark' ? 'text-white': ''}`}></i>
                    </Link>
                  {columns?.length > 0 && <CreateTemplate />}
                  </>
                )}
                </span>
                {personRole === 'Admin'?
                  (
                    <span>
                    {allBoardCards?.length === 0 && (
                      <LoadTemplate columns={columns} fetchColumnHandler={fetchColumnHandler} />
                    )}
                  </span>
                  ):null
                }
              </div>

              {/* Board Tags */}
              {(loadingBoardTags) ? <Skeleton className='tag_list_skeleton' count={1} height={80} /> :

                  <div className={`d-sm-flex d-none flex-wrap px-2 py-1  ${theme === 'dark' ? 'card__theme__dark' : ''}  board_tag_list`}>

                  <div className='w-100 d-flex justify-content-between px-2'>
                      <h5 className={`${theme === 'dark' ? 'text-white' : ''}`}>Tags of this board</h5>
                      <div className={`${theme === 'dark' ? 'text-white' : ''}  text-decoration-underline me-2 cursor-pointer`} onClick={() => handleTagClick(null)}>Clear Filter</div>
                  </div>

                  <div className='d-flex column-gap-3 row-gap-0 flex-wrap w-100'>
                      {boardTags?.length > 0 ? boardTags?.map((tag: any) =>
                      <p key={tag?.id} className={`cursor-pointer tags_para mb-0 fs-5 ${selectedTag === tag.value ? (theme === 'dark' ? 'text-white fw-bolder' : 'text-primary fw-bolder') : ''}`}
                      onClick={() => handleTagClick(tag.value)}>
                          #{tag.label}
                      </p>
                      ) :
                      <div className={`${theme === 'dark' ? 'text-white' : ''} text-center w-100`}>No Tags Available</div>
                      }
                  </div>
                  </div>
                   } 

              <div className='sort_and_export_wrapper'>
                {allBoardCards?.length > 0 && (
                  <>
                    <div className='row switch_btn'>
                      <div className='col-6'>
                        <Switch
                          onChange={onChangeSortBtn}
                          className='mt-3'
                          checked={isSorted}
                          width={50}
                          height={25}
                        />
                      </div>
                      <div className='col-6 ps-1'>
                        <p className='mt-3 fs-2 ps-1 sort-label'>Sort</p>
                      </div>
                    </div>
                    <ExcelExport boardName={boardTitle} boardId={boardId} />
                  </>
                )}
              </div>

              <div className=' general-search-small-screen'>
                <div>
                  <div className='mt-3 ' style={{ maxWidth: 280, width: 280 }}>
                    <GeneralSearch
                      isListVisible={isListVisible}
                      setIsListVisible={setIsListVisible}
                    />
                  </div>
                </div>
              </div>
              <div className='mobile_dropdown_sort_export_btn'>
                <Dropdown>
                  <Dropdown.Toggle size='lg' variant='light' className='px-4 py-0'>
                    <i className='bi bi-three-dots ' style={{ fontSize: '25px' }}></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ background: '#f5f8fa', width: '165px' }}>
                    <div className='mobile_menu_dropdown'>
                      <span
                        style={{
                          marginBottom: 10,
                        }}
                      >
                        {columns?.length > 0 && <CreateTemplate />}
                      </span>

                      <Button
                        onClick={() => {
                          navigate(`/notes/${boardId}`, { state: boardTitle })
                        }}
                        className='btn btn-primary btn-sm '
                        style={{
                          paddingInline: 50,
                          marginBottom: 10,
                        }}
                      >
                        Notes
                      </Button>
                      <Link
                      to={`/board/${boardId}/design`}
                      // className='me-3'
                      style={{
                        paddingInline: 50,
                        marginBottom: 10,
                      }}
                    >
                    <i className={`fa fa-cog design_icon fs-1 cursor-pointer`}></i>
                    </Link>

                      {allBoardCards?.length === 0 && (
                        <LoadTemplate columns={columns} fetchColumnHandler={fetchColumnHandler} />
                      )}

                      {allBoardCards?.length > 0 && (
                        <>
                          <div
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <ExcelExport cards={cards} boardName={boardTitle} boardId={boardId} />
                          </div>
                          <label>
                            <Switch
                              onChange={onChangeSortBtn}
                              // className='mt-3'
                              checked={isSorted}
                              width={30}
                              height={18}
                            />
                            <span
                              style={{ position: 'relative', top: '-3px' }}
                              className=' fs-4 ps-2 mb-1'
                            >
                              Sort
                            </span>
                          </label>
                        </>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          {/* ) : null} */}
        </div>
      </div>
    </>
  )
}

export default BoardHeader

interface EditableBoardTitleProps {
  personRole: string
  isEditableTitle: boolean
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
  onUpdateTitle: () => void
  boardTitle: string
  setBoardTitle: React.Dispatch<React.SetStateAction<string>>
  theme: string
}

const EditableBoardTitle: React.FC<EditableBoardTitleProps> = ({
  personRole,
  isEditableTitle,
  setIsEditable,
  onUpdateTitle,
  boardTitle,
  setBoardTitle,
  theme,
}) => {
  const [title, setTitle] = useState(boardTitle)
  useEffect(() => {
    if (title) {
      setBoardTitle(title)

    }
  }, [title])

  const updateBoardTitle = () => {
    onUpdateTitle()
  }

  return (
    <>
      {personRole === 'Admin' && isEditableTitle ? (
        <input
          maxLength={100}
          style={{ backgroundColor: '#F5F8FA' }}
          type='text'
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder={'Enter Title'}
          autoFocus
          className={`css-input w-100  ${theme === 'dark' ? 'dark__theme__input' : ''}`}
          onKeyDown={(event) => event.key === 'Enter' && updateBoardTitle()}
        />
      ) : (
        <h3
          className={`fw-bolder my-2 header-name-elipse ${theme === 'dark' ? 'white__text' : ''}`}
          onClick={() => {
            if (personRole === 'Admin') {
              setIsEditable(true)
            }
          }}
          onBlur={onUpdateTitle}
        >
          {checkLongWordWithoutSpace(boardTitle) ? (
            <span>{boardTitle.slice(0, 50) + '...'}</span>
          ) : (
            <span>{boardTitle}</span>
          )}
        </h3>
      )}
    </>
  )
}

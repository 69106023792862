import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  ReactNode
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel } from './_models'
import * as authHelper from './AuthHelpers'
import { useDispatch } from 'react-redux'
import { setSession } from '../../../store/slice/auth-slice'

type AuthContextProps = any

// type AuthContextProps = {
//   auth: AuthModel | undefined
//   saveAuth: (auth: AuthModel | undefined) => void
//   currentUser: UserModel | undefined
//   setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
//   logout: () => void
// }

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {
    // do nothing 
  },
  currentUser: undefined,
  setCurrentUser: () => {
    // do nothing 
  },
  logout: () => {
    // do nothing 
  },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = (): any => {
  return useContext(AuthContext)
}


const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch()
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())

  const [currentUser, setCurrentUser] = useState<any>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
   
    saveAuth(undefined)
    setCurrentUser(undefined)
    setTimeout(() => {
      localStorage.removeItem("auth")
      localStorage.removeItem('persist:root')
      localStorage.removeItem('planData')
      localStorage.removeItem('widget_user_auth_token')
      sessionStorage.removeItem('isTokenExpire');
       dispatch(setSession(false))
    }, 1000);

  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<{ children: ReactNode }> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = () => {
      try {
        if (!didRequest.current) {
          const authData: any = localStorage.getItem("auth")

          const data = JSON.parse(authData)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }

import React,{ useRef } from 'react';
import { Button, Modal } from 'react-bootstrap'
import Circle from '@uiw/react-color-circle';
import { useTheme } from '../../context/ThemeContext';

type Props = {
    showColorModal: boolean
    colorLoading: boolean
    handleCloseColorModal: () => void
    updateColumnColor: () => void
    backgroundColorState: string
    setBackgroundColorState: React.Dispatch<React.SetStateAction<string>>
    columnsBg:any
}

const UpdateColumnColorModal: React.FC<Props> = ({ colorLoading,showColorModal, handleCloseColorModal, backgroundColorState, setBackgroundColorState, updateColumnColor , columnsBg }: Props) => {
    const colors=['#FF1B00', '#199347', '#8a2be2', '#FFA600', '#0000FF', '#D3D3D3', '#00F12C', '#000000', '#DF00D1', '#800000']
    const {theme} = useTheme()
    const colorPickerRef: any = useRef(null);
    const colorClickHandler = () => {
        colorPickerRef.current.click();


        // setIsDisableColorField(false)
    }
    columnsBg.forEach((color:string)=> {
        const uppercaseColor = color.toUpperCase();
        if (!colors.includes(uppercaseColor)) {
          colors.push(uppercaseColor);
        }
      });

    return (
        <Modal  contentClassName={theme === 'dark' ? 'theme__div' : ''} show={showColorModal} onHide={handleCloseColorModal}>
            <Modal.Header style={{ borderTop: `15px solid ${backgroundColorState}` }}>

                <div className='d-flex align-items-center'>
                <Modal.Title className={theme === 'dark' ? 'white__text' : ''}>Select Color
                </Modal.Title>


                <input ref={colorPickerRef} type="color" className="form-control form-control-color px-2 py-2 ms-2 mb-1 d-inline-block" id="exampleColorInput" value={backgroundColorState.trim()} title="More Colors" onChange={(e) => {
                    setBackgroundColorState(e.target.value);
                    }} />
                <button className='btn-custom btn-custom-secondary py-3 px-4 mx-1 mb-1' onClick={colorClickHandler}>
                    <i className='bi bi-brush-fill pe-0' title="More Colors"></i>
                </button>
                </div>
                <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleCloseColorModal}></i>
            </Modal.Header>
            <Modal.Body>
                {/* <div>
                    <label htmlFor="exampleColorInput" className="form-label">Color picker</label>

                </div> */}
                <div className='d-flex justify-content-center'>

                    <Circle
                        colors={colors}
                        color={backgroundColorState}
                        onChange={(color) => {
                            setBackgroundColorState(color.hex);
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseColorModal}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={colorLoading} onClick={updateColumnColor}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateColumnColorModal
import React, {useState} from 'react'
import {Container, Col, Row} from 'react-bootstrap'
import ReactStars from 'react-rating-stars-component'
import {submitRatings} from '../services/feedback'
import {useTheme} from '../context/ThemeContext'

const FeedBack = (): React.JSX.Element => {
  const {theme} = useTheme()
  const [rate, setRate] = useState(5)
  const [feedback, setFeedBack] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [msg, setMsg] = useState<string>('')
  const [error, setError] = useState<string>('')

  const submitFeedback = async () => {
    try {
      setLoading(true)
      const data: any = {
        text: feedback,
        rating: rate,
      }
      const response: any = await submitRatings(data)
      if (response.data.success) {
        setMsg(
          'Thank you for providing feedback. We have received it and will take it into consideration.'
        )
        setTimeout(() => {
          setMsg('')
        }, 5000)
      }
    } catch (error: any) {
      const erMsg = error.response.data.message
      setError(erMsg)
      setTimeout(() => {
        setError('')
      }, 5000)
    } finally {
      setFeedBack('')
      setLoading(false)
    }
  }

  return (
    <Container className='d-flex justify-content-center'>
      <Row
        className={`w-lg-50 w-md-75 shadow-sm py-8 px-4 rounded  ${
          theme === 'dark' ? 'theme__div' : 'bg-white'
        } `}
      >
        <Col className='col-12'>
          <p
            className={`fw-bold fs-2  mb-3 tablet_font ${
              theme === 'dark' ? 'white__text' : 'text-gray-700'
            }`}
          >
            What would you like to tell us?
          </p>
          <div className='form-group'>
            <textarea
              value={feedback}
              className={`form-control
                         ${theme === 'dark' ? 'dark__theme__input' : ''}
                        `}
              id='exampleFormControlTextarea1'
              rows={6}
              onChange={(e) => {
                setFeedBack(e.target.value)
              }}
            ></textarea>
          </div>
          <p className='tablet_font mt-3'>
            We may notify you when we have updates on your feedback.
          </p>
        </Col>

        <Col className='col-12 my-4'>
          <div className={`fw-bold fs-2  ${theme === 'dark' ? 'white__text' : 'text-gray-700'}`}>
            Rate us:
          </div>
          <ReactStars
            count={5}
            onChange={(rate: number) => {
              setRate(rate)
            }}
            size={50}
            value={rate}
            isHalf={true}
            emptyIcon={<i className='bi bi-star '></i>}
            halfIcon={<i className='bi bi-star-fill '></i>}
            fullIcon={<i className='bi bi-star-fill '></i>}
            activeColor='#ffd700'
          />
        </Col>
        <Col className='col-12'>
          <button
            onKeyDown={(event: any) => event.key === 'Enter' && submitFeedback()}
            disabled={loading || feedback.length === 0 || rate === 0}
            className='btn btn-primary btn-sm  px-8 w-100 rounded'
            style={{float: 'right'}}
            onClick={submitFeedback}
          >
            Submit
          </button>
        </Col>

        <Col className='col-12'>
          {error?.length > 0 && (
            <div className={`alert  alert-danger w-100 mt-3`} role='alert'>
              {error}
            </div>
          )}
        </Col>
        <Col className='col-12'>
          {msg?.length > 0 && (
            <div className={`alert  alert-success w-100 mt-3`} role='alert'>
              {msg}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default FeedBack

import React from 'react'

type Props = {
    role: string
    isEditable: boolean
    columnTitle: string
    isPageArchiveBoard: boolean
    updateColumnTitleHandler: () => void
    columnId: string
    title: string
    newTitle: string
    setColumnIdState: React.Dispatch<React.SetStateAction<string>>
    setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
    setColumnTitle: React.Dispatch<React.SetStateAction<string>>
}

const EditableTitle: React.FC<Props> = ({
    role,
    isEditable,
    columnTitle, title, newTitle,
    setColumnTitle,
    isPageArchiveBoard,
    setIsEditable,
    setColumnIdState,
    updateColumnTitleHandler, columnId }: Props) => {

  

    return (
        <span
            onClick={() => {
                
                if (role === 'Admin' && !isPageArchiveBoard) {
                  setColumnTitle(title.trim() || newTitle.trim())
                    setIsEditable(true)
                    setColumnIdState(columnId)
                }
            }}
            onBlur={updateColumnTitleHandler}
            onKeyPress={(event: any) => event.key === 'Enter' && updateColumnTitleHandler()}
        >
            {isEditable && !isPageArchiveBoard ? (
                <>
                    <input
                        type='text'
                        maxLength={100}
                        onChange={(e) => setColumnTitle(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        value={columnTitle}
                        placeholder={'Enter Title'}
                        autoFocus
                        className='css-input w-100'
                        style={{
                            borderRadius: '3px',
                            height: '30px',
                        }}
                    />
                </>
            ) : (
                newTitle || title
            )}
        </span>
    )
}

export default EditableTitle
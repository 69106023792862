/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import apiService from './index'

export const getAllTags = async () => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/tag`)
}

export const getTagsByBoardId = async (boardId:string,type:string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/tag/${boardId}?type=${type}`)
}

export const createNewTag = async (name:string) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/tag`,{
    name: name,
  })
}

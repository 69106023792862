/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const homeColumnPosition = async (data: any, userId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V2}/board/column/home/${userId}`,
    data
  )
}
export const getHomeColumnPosition = async (userId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V2}/board/column/home/${userId}`)
}

import React, { useEffect, useState } from "react"
import { useTheme } from "../../context/ThemeContext"
import { longWordSlicing } from "../../helper-functions/CheckString"
import { Button, Spinner } from "react-bootstrap"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import EditPageForm from "../Forms/EditPageForm"
import useGetTags from "../../hooks/useGetTags"
import { useBoardPersons, useFileUploader, useGetMember } from "../../hooks"
import useSendAlert from "../../hooks/useSendAlert"
import { deletePageFileAttachment, updatePageById } from "../../services/pages"
import ReactS3Client from 'react-aws-s3-typescript'
import { awsFileUploadConfig } from '../../config/awsFileUpload'
import { useSelector } from "react-redux"
import useGetPages from "../../hooks/useGetPages"
import { setUpdatedItem, updateSearchData } from "../../store/slice/home-searching-slice"
import { useDispatch } from "react-redux"
import { Dispatch } from '@reduxjs/toolkit'
import UserEditModal from "../../modules/user-management/users-list/user-edit-modal/UserEditModal"
import { getBoardPersonById } from "../../services/boards"


const pageUpdateSchema = Yup.object().shape({
    title: Yup.string()
        .min(3, 'Minimum 3 Characters')
        .max(300, 'Maximum 300 Characters')
        .required('Title is Required')
        .trim(),
    webAppName: Yup.string()
        .min(3, 'Minimum 3 Characters')
        .max(300, 'Maximum 300 Characters').nullable()
        .trim(),
})

const EditPageForSearching :  React.FC<any> = ({ page,setSelectedItem}: any)=> {
    const { theme } = useTheme()
    const dispatch: Dispatch<any> = useDispatch()

    const [tocHeading, setTocHeading] = useState<any>(null);


    const { allBoards } = useSelector((state: any) => state.allBoardsReducer)

    const { getPageHandler, pageloading } = useGetPages(page.boardId)

    const s3 = new ReactS3Client({
        ...awsFileUploadConfig,
        dirName: `notes-attachments/${process.env.REACT_APP_ENVIRONMENT}`,
    })
    const [transformedTags, setTransformedTags] = useState<any>(page?.tags.map((tag: any) => ({
        value: tag.id,
        label: tag.name,
    })));
    const [selectedTags, setSelectedTags] = useState(transformedTags)


    const [selectedAssignee, setSelectedAssignee] = useState<any>([]);
    useEffect(() => {
        setSelectedOption(page?.status === 'active' ? { value: 'active', label: 'Active' } : { value: 'hidden', label: 'Hidden' })
        if (page?.tags) {
            const tags = page.tags.map((tag: any) => ({
                value: tag.id,
                label: tag.name,
            }));
            setTransformedTags(tags);
            setSelectedTags(tags)
        }
        if (page?.board_people) {
            const assignees = page.board_people.map((people: any) => ({
                value: people.id,
                label: people.user.fullName,
            }));
            setSelectedAssignee(assignees);
        }

    }, [page?.id]);

    const [boardId, setBoardId] = useState(page.boardId)

    const [tagsToUpdate, setTagsToUpdate] = useState<any>({
        addedTags: [],
        removedTags: [],
    });
    const [assigneeToUpdate, setAssigneeToUpdate] = useState<any>({
        addedAssignee: [],
        removedAssignee: [],
    });

    const [editorTags, setEditorTags] = useState<any>(null)
    const [isPageSaved, setIsPageSaved] = useState(false)
    const [saveBtnProp, setSaveBtnProp] = useState({
        title: 'Save',
        color: 'primary',
    })
    const [disableBtn, setDisableBtn] = useState(false)
    const [editorDescriptionState, setEditorDescriptionState] = useState<any>(null)


    const [socialMediaContent, setsocialMediaContent] = useState<any>(null)
    const [otherLinksContent, setOtherLinksContent] = useState<any>(null)
    const [optionsForSendAlertEdit, setOptionsForSendAlertEdit] = useState<any>(null)
    const [selectedOption, setSelectedOption] = useState<any>(page?.status === 'active' ? { value: 'active', label: 'Active' } : { value: 'hidden', label: 'Hidden' })

    const initialValues = {
        title: page.title,
        webAppName: page.website_name,
    }
    const { tags, loading: tagsLoading } = useGetTags()
    const {
        optionsOfAssignee,
        isUserAssignLoading,
        selectAutoAssigneeForNotes,
        setSelectAutoAssigneeForNotes,
        optionsForSendAlert,
        boardPersonsForSendAlert,
        getBoardPersonHandler,
    } = useBoardPersons(page)
    const {
        sendAlertTo,
        setSendAlertTo,
        sendAlertMsg,
        setSendAlertMsg,
        sendAlertToBoardPerson
    } = useSendAlert(
        boardPersonsForSendAlert,
        page,
        boardId,
        undefined,
        editorDescriptionState
    )
    const { boardPerson } = useGetMember(boardId)
    const [openInviteModal, setOpenInviteModal] = useState(false)
    const { allSearchData } = useSelector((state: any) => state.homeSearchingReducer)


    const { fileErrorMsg, selectedFile, setSelectedFile, selectFileHandler, setFileErrorMsg } =
        useFileUploader()

    const deleteFileHandler = async (name: string, key: string, value: string) => {
        let files: any
        if (key) {
            files = selectedFile.filter((item: any) => item.key !== key)
        } else {
            files = selectedFile.filter((item: any) => item.name !== name)
        }
        try {
            const fileNameForDelete = value || name
            await s3.deleteFile(fileNameForDelete)
            try {
                key && await deletePageFileAttachment(page?.id, key)
                setSelectedFile(files)
            } catch (error) {
                return error
            }
        } catch (error) {
            return error
        }
    }
    const getAddedAndRemovedTags = () => {
        const addedTags = selectedTags?.filter((tag: any) => !transformedTags?.some((t: any) => t.value === tag.value));
        const removedTags = transformedTags?.filter((tag: any) => !selectedTags?.some((t: any) => t.value === tag.value));
        setTagsToUpdate({ addedTags, removedTags });
    };

    const getAddedAndRemovedAssignee = () => {
        const addedAssignee = selectAutoAssigneeForNotes?.filter((assignee: any) => !selectedAssignee.some((a: any) => a.value === assignee.value));
        const removedAssignee = selectedAssignee?.filter((assignee: any) => !selectAutoAssigneeForNotes.some((a: any) => a.value === assignee.value));

        setAssigneeToUpdate({ addedAssignee, removedAssignee });
    };

    const getTableOfContents = () => {
        const selectedPage = allSearchData.pages.pages?.find((p: any) => p.id === page.id)
        if (selectedPage?.description) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(page?.description, 'text/html');

            const headings = doc.querySelectorAll<HTMLHeadingElement>('h1, h2');

            const combinedTags = Array.from(headings).map((tag, index) => ({
                name: tag.textContent,
                tagName: tag.tagName.toLowerCase(),
                index,
            }));
            setEditorTags(combinedTags);
        }
    }
    useEffect(() => {
        if (page.id) getTableOfContents()
    }, [page.id])

    useEffect(() => {
        const urlLinks: any = []
        if (page?.file_attachments) {
            for (const file of page?.file_attachments) {
                for (const [key, value] of Object.entries(file)) {
                    const getFileName = (value as string)
                        .toLowerCase()
                        ?.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
                    urlLinks.push({ key, value: value, showName: getFileName })
                }
            }
            setSelectedFile(urlLinks)
        }
        return () => {
            setSelectedFile([]);
        };
        //eslint-disable-next-line
    }, [page])
    useEffect(() => {
        getAddedAndRemovedTags()
    }, [selectedTags])

    useEffect(() => {
        getAddedAndRemovedAssignee()
    }, [selectAutoAssigneeForNotes])

    useEffect(() => {
        if (page?.board_people?.length) {
            const filteredOptions = optionsForSendAlert.filter((option: any) =>
                page.board_people.some((person: any) => person.user.fullName === option.label)
            );
            setOptionsForSendAlertEdit(filteredOptions);
        }
    }, [optionsForSendAlert, page]);

    useEffect(() => {
        if (boardId) {
            getBoardPersonHandler(boardId)
        }
        //eslint-disable-next-line
    }, [boardId,page])
    useEffect(() => {
        getPageHandler(page?.id)
        setBoardId(page.boardId)
        //eslint-disable-next-line

    }, [page?.id])
    useEffect(() => {
        setEditorDescriptionState(page?.description)
        setsocialMediaContent(page?.socialLinks)
        setOtherLinksContent(page?.otherLinks)
        //eslint-disable-next-line

    }, [page])

    const handleSubmission = async () => {
        const urls = []
        for (let index = 0; index < selectedFile.length; index++) {
            const filename = selectedFile[index].name
            if (filename) {
                const filenameUpdated = filename.substring(0, filename.lastIndexOf('.'))
                try {
                    const uploadFile: any = await s3.uploadFile(selectedFile[index], filenameUpdated)
                    urls.push(uploadFile.location)
                } catch (error) {
                    return error
                }
            }
        }

        return urls
    }
    const getMembersHandler = async () => {
        try {
            const res = await getBoardPersonById(boardId)
            const resData = res.data.data
            const resObject: any = []
            for (let index = 0; index < resData.length; index++) {
                if (resData[index].isDeleted === false) {
                    resObject.push({
                        id: resData[index].id,
                        name: resData[index].user.fullName,
                        email: resData[index].user.email,
                        role: resData[index].role.name,
                        roleId: resData[index].roleId,
                        isOwner: resData[index].isOwner,
                    })
                }
            }
        } catch (error) {
            return error
        }
    }
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: pageUpdateSchema,
        onSubmit: async (values: any) => {
            getAddedAndRemovedAssignee()
            setDisableBtn(true)

            const formData = new FormData();
            const fileLinks: any = await handleSubmission()
            const { title, webAppName } = values
            formData.append('title', title);
            webAppName && formData.append('website_name', webAppName);
            editorDescriptionState && formData.append('description', editorDescriptionState);

            otherLinksContent && formData.append('otherLinks', otherLinksContent);
            socialMediaContent && formData.append('socialLinks', socialMediaContent);

            formData.append('boardId', boardId);
            formData.append('status', selectedOption.value);

            tagsToUpdate.addedTags.forEach((tag: any) => formData.append('new_tagIds[]', tag.value));
            tagsToUpdate.removedTags.forEach((tag: any) => formData.append('remove_tagIds[]', tag.value));

            assigneeToUpdate.addedAssignee.forEach((assignee: any) => formData.append('new_assignee_board_person_ids[]', assignee.value));
            assigneeToUpdate.removedAssignee.forEach((assignee: any) => formData.append('remove_assignee_board_person_ids[]', assignee.value));

            fileLinks.forEach((link: string) => formData.append('new_file_attachments[]', link));
            try {
                const res = await updatePageById(page?.id, formData)
                const updatedPage: any = res.data.data.page
                setSelectedItem(updatedPage)

                if (sendAlertTo.length > 0 && editorDescriptionState !== null)
                    sendAlertToBoardPerson(updatedPage, sendAlertTo, 'page-description')

                const searchedCardData = allSearchData['Pages'.toLowerCase()]['Pages'.toLowerCase()]
                if (searchedCardData?.length > 0) {
                    const data = [...searchedCardData]
                    const index =
                        data?.length > 0 &&
                        (data?.findIndex((data: any) => data?.id === page?.id) as number)
                    if (index !== -1) {
                        data[index as number] = updatedPage
                        dispatch(setUpdatedItem([...data]))

                        dispatch(
                            updateSearchData({
                                tab: 'pages',
                                data: [...data],
                            })
                        )
                    }
                }
            } catch (error) {
                // console.log(error)
            } finally {
                setIsPageSaved(true)
                setDisableBtn(false)
                setSelectedFile([])
                setSaveBtnProp({ color: 'success', title: 'Saved' })

                setTimeout(() => {
                    setIsPageSaved(false)
                }, 3000)
            }
        }
    })
    return (
        <>
            <div className='row d-flex justify-content-between border-bottom fs-2 fw-bold p-8 mb-5'>
                <div className='text-primary col-6'>

                    <span className={`responsive-font ${theme === 'dark' ? 'white__text' : ''} `} style={{ marginRight: 20 }}>

                        {longWordSlicing(page?.title?.trim())}
                    </span>

                </div>
                <div className='col-4 text-end'>
                    <Button
                        disabled={disableBtn || boardPerson?.role === 'Guest'}
                        className={`${disableBtn && 'btn-custom-secondary '} w-75 btn-${saveBtnProp.color
                            } `}
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                    >
                        {
                            disableBtn ? (
                                <Spinner animation='border' variant='light' />
                            ) : (
                                <span>{saveBtnProp.title}</span>
                            )
                        }
                    </Button>
                    {isPageSaved === true && (
                        <i
                            style={{
                                fontSize: 20,
                                color: '#51cd89',
                            }}
                            className='bi bi-check-lg'
                        ></i>
                    )}
                </div>
            </div>
            <div className='container'>
                <EditPageForm
                    theme={theme}
                    formik={formik}
                    editor={editorDescriptionState}
                    setEditor={setEditorDescriptionState}
                    boardId={boardId}
                    setBoardId={setBoardId}
                    allBoards={allBoards}
                    tags={tags}
                    loading={tagsLoading}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    boardPerson={boardPerson}
                    optionsForSendAlert={optionsForSendAlertEdit}
                    setSendAlertTo={setSendAlertTo}
                    sendAlertTo={sendAlertTo}
                    isUserAssignLoading={isUserAssignLoading}
                    setSelected={setSelectAutoAssigneeForNotes}
                    selected={selectAutoAssigneeForNotes}
                    options={optionsOfAssignee}
                    setSendAlertMsg={setSendAlertMsg}
                    sendAlertMsg={sendAlertMsg}
                    selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                    loadingDesc={pageloading}
                    transformedTags={transformedTags}
                    deleteFileHandler={deleteFileHandler}
                    selectedFile={selectedFile}
                    changeHandler={selectFileHandler}
                    fileErrorMsg={fileErrorMsg}
                    setFileErrorMsg={setFileErrorMsg}
                    setOpenInviteModal={setOpenInviteModal}
                    socialMediaContent={socialMediaContent}
                    setsocialMediaContent={setsocialMediaContent}
                    otherLinksContent={otherLinksContent}
                    setOtherLinksContent={setOtherLinksContent}
                    editorTags={editorTags} setEditorTags={setEditorTags}
                    tocHeading={tocHeading} setTocHeading={setTocHeading}
                    updatedDate={page?.updatedAt} />
            </div>
            {openInviteModal && (
                <UserEditModal membersData={optionsOfAssignee} getMembersHandler={getMembersHandler} sendWebSocket={undefined} isPage={true} setShow={setOpenInviteModal} show={openInviteModal} />
            )}
        </>
    )
}
export default EditPageForSearching
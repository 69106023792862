import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
// import { Footer } from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
// import { Toolbar } from './components/toolbar/Toolbar'
// import { RightToolbar } from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {Modal, Button} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {useAuth} from '../../app/modules/auth'
import {useDispatch, useSelector} from 'react-redux'
import {setSession} from '../../app/store/slice/auth-slice'
import LinkCardUpdate from '../../app/components/LinkCardEdit'
import {useTheme} from '../../app/context/ThemeContext'
import { useWebSocketHook } from '../../app/hooks'

const MasterLayout: React.FC = () => {
  
  const navigate = useNavigate()
  const {theme} = useTheme()
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => state.authReducer)
  const {logout,currentUser,setCurrentUser} = useAuth()
  const [isTimeOut, setIsTimeOut] = useState<boolean>(auth.userSessionTimeout)
  const { isVerified, isInvitedByOrganization } =
  currentUser?.data.user
  const [isTokenExpire, setIsTokenExpire] = useState<boolean>(false)

  const {lastJsonMessage}: any = useWebSocketHook()


  const location = useLocation()

  useEffect(() => {
    if (lastJsonMessage) {
      const isVerified: any = lastJsonMessage.response?.payload?.isVerified
      if (isVerified) {
        const updateCurrentUser = {...currentUser}
        updateCurrentUser.data.user.isVerified = true
        localStorage.setItem('auth', JSON.stringify(updateCurrentUser))
        setCurrentUser(updateCurrentUser)
      }
    }
    //eslint-disable-next-line
  }, [lastJsonMessage])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    setIsTimeOut(auth.userSessionTimeout)
  }, [auth.userSessionTimeout])

  useEffect(() => {
    if (window.navigator.onLine === false) {
      navigate('/error/no-internet')
    }
  }, [window.navigator.onLine])

  // const inactivityTimeout = 5000; // 15 minutes (in milliseconds)
  const inactivityTimeout = 20 * 60 * 1000 // 15 minutes (in milliseconds)

  let logoutTimer: any

  const handleUserActivity = () => {
    // setUserIsActive(true);
    clearTimeout(logoutTimer) // Reset the timer
    logoutTimer = setTimeout(() => {
      localStorage.removeItem('auth')
      localStorage.removeItem('persist:root')
      localStorage.removeItem('planData')
      dispatch(setSession(true))
    }, inactivityTimeout)
  }

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll']
    for (const event of events) {
      window.addEventListener(event, handleUserActivity)
    }

    return () => {
      for (const event of events) {
        window.removeEventListener(event, handleUserActivity)
      }
      clearTimeout(logoutTimer)
    }
    //eslint-disable-next-line
  }, [])

  const storedValue = sessionStorage.getItem('isTokenExpire')
  useEffect(() => {
    if (storedValue !== null && storedValue == 'true') {
      setIsTokenExpire(true)
    }
    //eslint-disable-next-line
  })
  const isHomeOrBoard = /^(\/home(\/board)?)$/.test(window.location.pathname);
  const isBoardIdOrView = /^(\/board\/[a-f0-9-]{36}|\/board-view\/board\/[a-f0-9-]{36})$/.test(window.location.pathname);
  

  return (
    <PageDataProvider>
      <div
        className={`page d-flex flex-row flex-column-fluid ${theme === 'dark' ? 'layout__dark__div' : ''}`}
      >
        <AsideDefault />
        <div
          className={`wrapper d-flex flex-column flex-row-fluid ${
            theme === 'dark' ? 'layout__dark__div' : ''
          }`}
          id='kt_wrapper'
        >
        
          <HeaderWrapper />
         
          <div id='kt_content' className={`content d-flex flex-column flex-column-fluid ${!isVerified && !isInvitedByOrganization && 'content_verify' } ${theme === 'dark' ? 'layout__dark__div' : ''} ${isHomeOrBoard ? 'home_view':''} ${isBoardIdOrView ? 'board_view':''}`}>
            <div className={`post d-flex flex-column-fluid ${theme === 'dark' ? 'layout__dark__div' : ''}`} id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
      <LinkCardUpdate />

      <Modal
         contentClassName={theme === 'dark' ? 'layout__theme__div' : ''}
        show={isTimeOut || isTokenExpire}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className={theme === 'dark' ? 'white__text' : ''}>oops!</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-start align-items-center flex-column'>
            <h1 style={{color: 'gray'}}> Your session is expired</h1>
            <div className='align-self-end' style={{marginTop: '40px'}}>
              <Button variant='secondary' className='btn-sm' onClick={logout}>
                Back to Login
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </PageDataProvider>
  )
}

export {MasterLayout}

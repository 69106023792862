/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cryptoEncrypt } from '../helper-functions/encryption'
import apiService from './index'


export const createOtherContact = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact`, { data: cryptoEncrypt(data) })
}

export const updateOtherContact = async (contactId: string,data:any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/${contactId}`, { data: cryptoEncrypt(data) })
}

export const deleteOtherContact = async (contactId: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/${contactId}`)
}



export const getOtherContactByUserAndCompanyId = async (userId:string , page = 1,limit:string | number,name?:string,organizationId?:string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/${userId}?limit=${limit}&page=${page}${name ? `&name=${name}` : ''}${organizationId ? `&organizationId=${organizationId}` : ''}`);
}

export const getOtherContactBoardByContactId = async (contactId:string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/board/${contactId}`)
}

export const filterOtherContact = async (fullName:any,userId:string,companyId:string ) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/filter/${userId}?organizationId=${companyId ?? 'null' }&fullName=${fullName}`)
}

export const getOtherContactNoteByContactId = async (contactId:string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/note/${contactId}`)
}

export const updateOtherContactNoteByContactId = async (contactId:string,data:any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/note/${contactId}`,{ data: cryptoEncrypt(data) })
}

export const updateContactByContactId = async (contactId:string,data:any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V3}/other/contact/${contactId}`,{ data: cryptoEncrypt(data) })
}

import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'

interface CustomAlertProps {
    variant: 'primary'|
    'secondary' |
    'success' |
    'danger' |
    'warning' |
    'info' |
    'light' |
    'dark';
    className?: string;
    alertMessage: string;
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
    time?: number;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ variant='info', className, alertMessage,setAlertMessage, time=5000 }: CustomAlertProps) => {
  // eslint-disable-next-line no-console
  
  useEffect(() => {
   let timeoutId:any;
      if(alertMessage?.length >0){
        timeoutId=setTimeout(() => {
            setAlertMessage('')
          }, time)
      }
      return () => clearTimeout(timeoutId);
    }, [alertMessage])
    
    return (
        <>
        {alertMessage && alertMessage.length > 0 && (
          <Alert variant={variant} className={className || ''} onClose={() => {setAlertMessage("");}} dismissible>
            {alertMessage}
          </Alert>
        )}
      </>
    )
}

export default CustomAlert
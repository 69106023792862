import { useState } from "react"
import Timer from "./Timer";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ResendCode = ({ onResendCode }: any) => {
    const [seconds, setSeconds] = useState<any>(120)
    return (
        <p className='fs-7'>If you didn&apos;t received a code after <span className="text-primary"><Timer setSeconds={setSeconds} seconds={seconds} /></span> sec!
            {" "}<span className={`${seconds === 0 ? "text-primary cursor-pointer" : 'text-muted'} fw-bold`} onClick={() => { seconds === 0 && onResendCode(); setSeconds(120) }}>Resend</span>
        </p >
    )
}

export default ResendCode
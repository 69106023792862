import Joyride, { STATUS } from 'react-joyride';

import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { setIsFirstLogin, setShowTour } from '../../store/slice/tour-slice';
import { FC } from 'react';

const LoginTutorial: FC<any> = () => {

    const dispatch: Dispatch<any> = useDispatch()
    const { isFirstLogin, showTour } = useSelector((state: any) => state.tourSliceReducer);

    const steps: any = [
        {
            target: '.header-name-elipse span',
            content: (
                <>
                    Welcome to bigo and your task view for the board you created at signup.<br />
                    To change the name of the board, click or tap where you see the board name.<br />
                    Here&apos;s a quick guide to help you make the most of the platform.<br />
                    If you have suggestions, please share them via:<br /><br />
                    Help &amp; Feedback:
                    <a href='https://www.linkedin.com/company/b4igo-ai/posts/' target='_blank' rel="noopener noreferrer"><i className="bi bi-linkedin mx-2 fs-2 text-primary cursor-pointer"></i></a>,
                   <a href='https://x.com/b4igoProject' target='_blank' rel="noopener noreferrer"><i className="bi bi-twitter mx-2 fs-2 text-primary cursor-pointer"></i></a>.
                </>
            ),
        },
        {
            target: '.top_task_btn',
            content: (
                <>
                    To add a column on your board, click on the plus sign at the top of the column<br /><br />
                    Hold the task tile to pick it up and move it within the column.<br /><br />
                    Click or tap the task tile once to open and view the task.
                </>
            ),
        },
        {
            target: '.bottom_col_tab',
            content: (
                <>
                    You can also add a column on your board by clicking on &quot;Add a task&quot; button on the bottom.<br /><br />
                </>
            ),
        },
        {
            target: '.head_col_tab',
            content: (
                <>
                    To edit the name, click or tap on the name, or to color it, click on the paintbrush.<br />
                    You can set rules for the column or delete it via the three ellipses.<br /><br />
                    Click or tap and hold to pick up the column and move it to a new position.
                </>
            ),
        },
        {
            target: '.add_col_bar',
            content: (
                <>
                    To add another column to your board, click or tap on the &quot;Add Column&quot; button.<br />
                    Give your column a name and hit enter.<br /><br />
                </>
            ),
        },
        {
            target: '.notes_btn',
            content: (
                <>
                    Click or tap on &quot;Notes&quot; to create and access notes you create.<br /><br />
                    Invite others to join you on a page as contributors and create tags from your notes for faster and easier recall.
                </>
            ),
        },
        {
            target: '.design_icon',
            content: (
                <>
                    To build a team, set the status, or add background images to your board,<br />
                    click or tap on the &quot;Settings&quot; icon.
                </>
            ),
        },
        {
            target: '.user_profile',
            content: (
                <>
                    To add a photo or update your personal details, click<br />
                    on the person icon in the top right.
                </>
            ),
        },
        {
            target: '.manage_account',
            content: (
                <>
                    Need additional licenses or want to set your company profile, click on the<br />
                    &quot;Manage Account&quot; menu option.
                </>
            ),
        },

    ];
    const joyrideLocale: any = {
        next: 'Next',
        skip: 'Skip',
        last: 'End',
    };
    const joyrideStyles: any = {
        options: {
            zIndex: 10000,
            backgroundColor: '#2C3A4D',
            textColor: 'white',
        },
        buttonNext: {
            color: 'yellow',
            background: 'none',
        },
        buttonSkip: {
            color: '#009ef7',
        },
        tooltip: {
            width: '100%',
        },
        spotlight: {
            backgroundColor: '#a8a8a8',
            transition: 'opacity 0.2s',
        },
    };

    const handleJoyrideCallback = (data: any) => {
        const { status, step } = data;
        step.disableBeacon = true;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status)) {
            dispatch(setShowTour(false))
            dispatch(setIsFirstLogin(false));
        }
    };
    return (
        <>
            {showTour && isFirstLogin && (
                <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    disableOverlayClose
                    hideBackButton
                    disableScrolling
                    hideCloseButton
                    run={showTour}
                    scrollToFirstStep
                    showSkipButton
                    steps={steps}
                    locale={joyrideLocale}
                    styles={joyrideStyles}
                />)}
        </>
    )
}

export default LoginTutorial
import { useParams } from "react-router-dom"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getBoardPersonInvitees } from "../../../services/boards"
import { capitalizeEachWord } from "../../../helper-functions/CapitalizeName"
import { KTCard } from "../../../../_metronic/helpers"
import { UsersTable } from "./table/UsersTable"
import { UsersListHeader } from "./components/header/UsersListHeader"
import  UserEditModal  from "./user-edit-modal/UserEditModal"
// import { QueryRequestProvider } from "./core/QueryRequestProvider"
// import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { NovuProvider, useNotifications } from "@novu/notification-center"
import { useAuth } from "../../auth"
import { webSocketOperations } from "../../../constants"
import { useTheme } from "../../../context/ThemeContext"



const UsersList = ({manageTeamHandler, setWebsocketAlert, isRoleChanged, sendWebSocket, lastJsonMessage }: any) => {
  const { itemIdForUpdate } = useListView()
  const params = useParams()
  const {theme} = useTheme()
  const [boardIdState] = useState<any>(params.boardId)
  const [membersData, setMembersData] = useState<any>()
  const isAccepted = useSelector((state: any) => state.inviteReducer)
  const { isInviteAccepted } = isAccepted.isInviteAccepted
  const isLastUpdated = useSelector((state: any) => state.lastUpdateReducer)
  const { isCardUpdated } = isLastUpdated.isCardUpdated
  const { notifications }: any = useNotifications();
  const [show, setShow] = useState(true)



  const getMembersHandler = async () => {
    try {
      const res = await getBoardPersonInvitees(boardIdState)
      const resObject = res.data.data?.map((item: any) => { return { id: item.id, name: capitalizeEachWord(item.userName), email: item.email, role: item.role.name, status: item.status === "accept" ? "accepted" : item.status } })
      setMembersData(resObject)
      manageTeamHandler()
    } catch (error) {
      return error
    }
  }
  useEffect(() => {
    if (boardIdState) {
      setTimeout(() => {
        getMembersHandler()
      }, 1000);
    }
    //eslint-disable-next-line
  }, [boardIdState, isRoleChanged, isInviteAccepted, isCardUpdated])

  useEffect(() => {
    const isInvitationAccepted = () => {

      if (notifications) {

        const url = notifications[0]?.cta.data.url
        if (url === "/invitation-accepted") {
          getMembersHandler()
        }
      }
    }
    isInvitationAccepted()
    //eslint-disable-next-line
  }, [notifications])

  useEffect(() => {
    if (lastJsonMessage) {
      const operation: any = lastJsonMessage.response?.output.operation
      if (operation === webSocketOperations.boardDesignInviteeUpdate) {
        setTimeout(() => {
          getMembersHandler()
        }, 1000);
        const alertMessage: any = lastJsonMessage.response?.output.payload.alert
        setWebsocketAlert(alertMessage)
      }

    }
//eslint-disable-next-line
  }, [lastJsonMessage]);


  return (
    <>
      <KTCard className={`${theme === 'dark' ? 'theme__div' : ''}`}>
        <UsersListHeader setShow={setShow} />
        <UsersTable membersData={membersData} />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal show={show} setShow={setShow} membersData={membersData} getMembersHandler={getMembersHandler} sendWebSocket={sendWebSocket} />}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UsersListWrapper = ({ manageTeamHandler,setWebsocketAlert, sendWebSocket, lastJsonMessage, isRoleChanged }: any) => {
 
  const { currentUser } = useAuth()
  const { id: userId } = currentUser?.data.user

  return (
    <>
      <ListViewProvider>
        <NovuProvider
          initialFetchingStrategy={{ fetchNotifications: true, fetchUserPreferences: true }}
          subscriberId={userId} applicationIdentifier={`${process.env.REACT_APP_APPLICATION_IDENTIFIER}`}
        >
          <UsersList manageTeamHandler={manageTeamHandler} isRoleChanged={isRoleChanged} setWebsocketAlert={setWebsocketAlert} sendWebSocket={sendWebSocket} lastJsonMessage={lastJsonMessage} />
        </NovuProvider>
      </ListViewProvider>

      {/* // <QueryRequestProvider>
    //   <QueryResponseProvider>
    
    //   </QueryResponseProvider>
    // </QueryRequestProvider> */}
    </>
  )

}
export { UsersListWrapper }

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const capitalizeName = (name: string): any => {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }
}

export const capitalizeInitialOnly=(str:string) :any =>{
  return str.split(' ').map((word:any) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}


export const capitalizeEachWord = (name: any): any => {
  if (name) {
    const capitalizedStr = name
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    return capitalizedStr
  }
}

export const capitalizeEachWordInitials = (name: any) => {
  if (name) {
    const arr = name.split(' ')

    for (let i = 0; i < arr.length; i++) {
      if (i < 2) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }
    }

    let initials = ''
    for (let i = 0; i < arr.length; i++) {
      if (i < 2) {
        initials += arr[i].charAt(0)
      }
    }

    return initials
  }
}

export function removeDuplication(data: any) {
  const uniqueData = data.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.value === current.value)
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])

  return uniqueData || []
}

export function getFileName(url: string) {
  const parts = url.split('/')
  const fileName = parts[parts.length - 1]
  return {fileName, url}
}

export function downloadfile(url: string, fileName: string) {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.blob()
    })
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error) => {
      return error
    })
}

export function printDocument(url: string) {
  // Open the file in a new window or tab
  const fileWindow = window.open(url)
  if (fileWindow !== null) {
    fileWindow.onload = () => {
      // Call the print method of the window object
      fileWindow?.print()
    }
  }
}

export const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms))


export const getSkeletonHeight = () => {
   if (window.innerWidth > 2300) {
    return 300
  }
  else if (window.innerWidth > 1400) {
    return '32.3%'
  }
   else if (window.innerWidth > 900 && window.innerWidth < 1400) {
    return 130
  } else if (window.innerWidth > 500 && window.innerWidth < 900) {
    return 100
  } else {
    return 70
  }
}

export function removeSpaces(str: string) {
  return str.replace(/\s/g, '');
}
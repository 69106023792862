import {useEffect, useState} from 'react'
import {updateBoardNameInAllCards} from '../services/cards'
import {fetchAllCardByBoardId, fetchAllCardsofLoggedInUser, fetchAllSortedCard} from '../store/slice/card-slice'
import {useAuth} from '../modules/auth'
import {fetchBoardByUserId} from '../store/slice/board-slice'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {setIsRender} from '../store/slice/isRender-slice'
import {updateBoardName} from '../services/boards'
import {fetchArchivedBoardByUserId} from '../store/slice/archived-board'
import useWebSocketHook from './useWebSocketHook'
import {webSocketOperations} from '../constants'
import {capitalizeEachWord} from '../helper-functions/CapitalizeName'
import { fetchAllBoards } from '../store/slice/all-boards-slice'

const useBoardTitle = (
  boardId: string,
  boardTitle: string,
  isSortingOn: boolean,
  currentBoardName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setBoardTitle: any
):any => {
  const [isEditable, setIsEditable] = useState(false)
  const [newTitle, setNewTitle] = useState('')
  const dispatch: Dispatch<any> = useDispatch()
  const {currentUser} = useAuth()
  const {id, fullName,isInvitedByOrganization} = currentUser?.data.user
  const organizationId =isInvitedByOrganization && currentUser?.organizationId

  const isRenderStore = useSelector((state: any) => state.isRenderReducer)
  const {isRender} = isRenderStore.isRender
  const isPageArchiveBoard = window.location.href.includes('archived')
  const {lastJsonMessage, sendJsonMessage,readyState}: any = useWebSocketHook()
  const updateTitle = async (newBoardTitle: string) => {
    if (newBoardTitle !== currentBoardName && newBoardTitle !== '' && boardTitle.length >=3) {

      try {
        await updateBoardName({name: newBoardTitle}, boardId, id)

        setNewTitle(newBoardTitle)
        setBoardTitle(newBoardTitle)
        await updateBoardNameInAllCards({
          board_name: newBoardTitle,
          boardId: boardId,
        })
        if (isSortingOn) {
          dispatch(fetchAllSortedCard(boardId))
        } else {
          dispatch(fetchAllCardsofLoggedInUser())
          dispatch(fetchAllCardByBoardId({boardId, userId: id}))
        }
        dispatch(setIsRender({isRender: !isRender}))
        dispatch(fetchAllBoards({userId:id,organizationId}))
      } catch (error) {
        return error
      } finally {
        if (isPageArchiveBoard) {
          dispatch(fetchArchivedBoardByUserId({userId: id,organizationId}))
        } else {
          dispatch(fetchBoardByUserId({userId: id,organizationId,isAdmin: true}))
        }
      }
    }else{
      setBoardTitle(currentBoardName)
    }
  }
  const updateBoardTitleHandler = () => {
    setIsEditable(false)
    updateTitle(boardTitle)
    const webSocketPayload: any = {
      event: 'publish',
      channel: boardId,
      userId:id,
      command: 'UPDATE',
      operation: webSocketOperations.boardNameUpdate,
      payload: {
        newBoardTitle: boardTitle,
        boardId,
        alert: `${capitalizeEachWord(fullName)} changed board name to ${boardTitle}.`,
      },
    }
    sendJsonMessage(webSocketPayload)
  }

  useEffect(() => {
    if (lastJsonMessage) {
      const operation: any = lastJsonMessage.response?.output.operation
      const payload: any = lastJsonMessage.response?.output.payload
      if (operation === webSocketOperations.boardNameUpdate) {
        const {newBoardTitle} = payload
        setTimeout(() => {
          updateTitle(newBoardTitle)
        }, 1000)
      }
    }
    //eslint-disable-next-line
  }, [lastJsonMessage])
  
  useEffect(() => {
    if(readyState===1){
        sendJsonMessage({event:"subscribe",channel:boardId})   
      }
      //eslint-disable-next-line
}, [readyState])
  return {isEditable, setIsEditable, newTitle, updateBoardTitleHandler}
}
export default useBoardTitle

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Route, Link, Routes, Outlet} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {AlreadyLogin} from './components/AlreadyLogin'
import {SignupError} from './components/SignupError'
import {NoInternet} from './components/NoInternet'
import { useTheme } from '../../context/ThemeContext'


const ErrorsLayout = () => {
  const {theme} =useTheme()
  return (
    <div className='d-flex flex-column flex-root justify-content-center'>
      <div
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 '>
          <a href='/home' className='mb-10 '>
            <img
              alt='Logo'
              src={theme === 'dark' ? toAbsoluteUrl('/media/logos/b4igo-logo-white.png'):toAbsoluteUrl('/media/logos/b4igo-logo.png')}
              className='h-50px mb-5'
            />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            {window.navigator.onLine && (
              <div className='text-center'>
                <Link to='/home' className='btn btn-lg btn-primary fw-bolder'>
                  Go to homepage
                </Link>
              </div>
            )}
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-md-200px min-h-lg-350px
          min-h-100px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>

        {/* <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='https://keenthemes.com' className='text-muted text-hover-primary px-2'>
              About
            </a>
            <a href='mailto:support@keenthemes.com' className='text-muted text-hover-primary px-2'>
              Contact
            </a>
            <a href='https://1.envato.market/EA4JP' className='text-muted text-hover-primary px-2'>
              Contact Us
            </a>
          </div>
        </div> */}
      </div>
    </div>
  )
}

const ErrorsPage: React.FC = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='AlreadyLogin' element={<AlreadyLogin />} />
      <Route path='signup-error' element={<SignupError />} />
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
      <Route path='no-internet' element={<NoInternet />} />
    </Route>
  </Routes>
)

export {ErrorsPage}

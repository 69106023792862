import Aes from 'crypto-js/aes.js';
import CryptoJsCore from 'crypto-js/core.js';
import stringify from 'json-stringify-safe';
import { createTransform,Transform } from 'redux-persist';
import type { TransformConfig } from 'redux-persist/lib/createTransform';
const makeError = (message : string) => new Error(`redux-persist-transform-encrypt: ${message}`);


 interface EncryptTransformConfig {
    secretKey: string;
    onError?: (err: Error) => void;
}

export const EncryptTransform = (config:EncryptTransformConfig, transformConfig?:TransformConfig) : Transform<any , any> => {
    if (typeof config === 'undefined') {
        throw makeError('No configuration provided.');
    }
    const { secretKey } = config;
    if (!secretKey) {
        throw makeError('No secret key provided.');
    }
     // eslint-disable-next-line no-console
    const onError = typeof config.onError === 'function' ? config.onError : console.warn;
    return createTransform(
        (inboundState) => Aes.encrypt(stringify(inboundState), secretKey).toString(), 
        (outboundState) => {
        if (typeof outboundState !== 'string') {
            return onError(makeError('Expected outbound state to be a string.'));
        }
        try {
            const decryptedString = Aes.decrypt(outboundState, secretKey).toString(CryptoJsCore.enc.Utf8);
            if (!decryptedString) {
                throw new Error('Decrypted string is empty.');
            }
            try {
                return JSON.parse(decryptedString);
            }
            catch {
                return onError(makeError('Failed to parse state as JSON.'));
            }
        }
        catch {
            return onError(makeError('Could not decrypt state. Please verify that you are using the correct secret key.'));
        }
    }, transformConfig);
};

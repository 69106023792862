
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationforMembrs } from "../../services/company"



const initialState: any = {
  companyDetail: null,
  organizationMembersData: null,
  status: false,
}





export const getOrganizationDetail = createAsyncThunk(
  `companyDetailSlice/getOrganizationDetail`,
  async (token:any) => {
    const organizationDetaail = await getOrganizationforMembrs(token);
    return organizationDetaail;
  }
);

export const companyDetailSlice = createSlice({
  name: 'companyDetailSlice',
  initialState,
  reducers: {
    setCompanyDetail: (state, action) => {

      state.companyDetail = action.payload
    },
    setCompanyLogo: (state, action) => {

      state.companyDetail = {
        ...state.companyDetail,
        data: {
          ...state.companyDetail.data,
          logo: action.payload,
        },
      };


    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationDetail.pending, (state) => {
        state.status = true;
      })
      .addCase(getOrganizationDetail.fulfilled, (state, action) => {
        state.status = false;
        state.organizationMembersData = action.payload.data.data.memberOrganization
      })


  },
})

// Action creators are generated for each case reducer function
export const { setCompanyDetail, setCompanyLogo } = companyDetailSlice.actions

export default companyDetailSlice.reducer

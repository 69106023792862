import React, {useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import '../../styles/inputStyle.css'
import {
  createOrganization,
  // createOrganizationLicense,
  // createOrganizationMemberInvite,
} from '../../services/company'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'

// interface Roles {
//   id: string
//   name: string
//   description: string
//   isSuperAdmin: boolean
//   isAdmin: boolean
//   createdAt: string
//   updatedAt: string
// }

// const nameReg = /^[a-zA-Z ._-]*$/
const registrationCompanySchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Maximum 40 characters are allowed.')
    .required('Name is required')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),

  companyName: Yup.string()
    .max(100, 'Maximum 100 characters are allowed.')
    .required('Company/Organisation name is required.')
    .trim(),
  companyEmail: Yup.string()
    .email('Invalid email address')
    .required('Company/Organisation email is required.'),
  licenseEmail1: Yup.string().email('Invalid email address'),
  licenseEmail2: Yup.string().email('Invalid email address'),
  licenseEmail3: Yup.string().email('Invalid email address'),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OrganizationForm = ({
  // roleList,
  loading,
  setLoading,
  setIsNavigate,
}: any): React.JSX.Element => {
  setIsNavigate(false)
  const {theme} = useTheme()
  const [popUpMsg, setPopUpMsg] = useState('')
  const [isAlreadyMember, setIsAlreadyMember] = useState([
    {invite: '', index: 1},
    {invite: '', index: 2},
    {invite: '', index: 3},
  ])
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useAuth()
  const initialValues = {
    name: currentUser.data.user.fullName,
    email: currentUser.data.user.email,
    password: null,
    image: null,
    roleId: '',
    companyName: '',
    companyEmail: currentUser.data.user.email,
    licenseEmail1: '',
    licenseEmail2: '',
    licenseEmail3: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationCompanySchema,
    enableReinitialize: true,
    onSubmit: async (
      values: any,
      {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}
    ) => {
      setLoading(true)
      const {
        companyName,
        companyEmail,
        password,
        email,
        name,
        // licenseEmail1,
        // licenseEmail2,
        // licenseEmail3,
      } = values
      // if(licenseEmail1===licenseEmail2|| licenseEmail3===licenseEmail1|| licenseEmail3===licenseEmail2){
      //   setPopUpMsg("Emails should be unique!")
      //   setLoading(false)
      //   return
      // }
      // const role = roleList.find((roles: Roles) => roles.name === 'User')?.id

      const organizationDetails = {
        organizationName: companyName,
        organizationEmail: companyEmail,
        logo: null,
        usedLicense: 0,
        unusedLicense: 0,
        totalLicense: 3,
        password: password,
        personalEmail: email,
        fullName: name,
        chosenSubscriptionPlan: currentUser.data.user.chosenSubscriptionPlan,
      }
      // const freeTrialLicenseSubscription = {
      //   organizationId: '',
      //   type: 'Introductory',
      //   organizationEmail: companyEmail,
      //   totalLicense: 0,
      //   fullName: name,
      // }

      // const licenseEmails = [licenseEmail1, licenseEmail2, licenseEmail3]
      // const memberDetails = {
      //   organizationName: companyName,
      //   organizationLicenseId: '',
      //   organizationId: '',
      //   email: '',
      //   roleId: role,
      // }
      try {
        const orgRes = await createOrganization(organizationDetails)
        // freeTrialLicenseSubscription.organizationId = orgRes.data.data.id

        // for (let index = 0; index < licenseEmails?.length; index++) {
        //   if (licenseEmails[index].length > 0) {
        //     freeTrialLicenseSubscription.totalLicense++
        //   }
        // }
        // let timeoutDuration = 5000
        // if (freeTrialLicenseSubscription.totalLicense > 0) {
        //   const licenseResponse = await createOrganizationLicense(freeTrialLicenseSubscription)
        //   memberDetails.organizationId = orgRes.data.data.id
        //   for (let index = 0; index < licenseEmails.length; index++) {
        //     if (licenseEmails[index].length > 0) {
        //       memberDetails.email = licenseEmails[index]
        //       memberDetails.organizationLicenseId = licenseResponse.data.data[index].id
        //       const inviteRes = await createOrganizationMemberInvite(memberDetails)
        //       if (inviteRes.data.message.includes('already registered on b4igo')) {
        //         timeoutDuration = 10000
        //         setIsNavigate(false)
        //         setIsAlreadyMember((prevStatus) => {
        //           const newState = [...prevStatus]
        //           newState[index] = {invite: inviteRes.data.message, index: index + 1}
        //           return newState
        //         })
        //       }
        //     }
        //   }
        // }
        // emailVerificationHandler({
        //   userId: orgRes.data.data.userId,
        //   fullName: name,
        //   email: email,
        // })

        // handleShow()
        // setTimeout(() => {
        //   handleClose()
        //   loginHandler(email, password)
        // }, 5000)

        setPopUpMsg('Organisation is created!')

        setTimeout(() => {
          const updateCurrentUser = {...currentUser}
          updateCurrentUser.hasOrganization = true
          updateCurrentUser.organizationId = orgRes.data.data.id
          localStorage.setItem('auth', JSON.stringify(updateCurrentUser))
          setCurrentUser(updateCurrentUser)
          setIsNavigate(true)

          navigate('/manage-account/licenses')
        }, 3000)
      } catch (error: any) {
        const msg = error.response.data.message
        setErrorMsgRegistration(msg)
      } finally {
        setSubmitting(false)
        setLoading(false)
        // formik.resetForm()
      }
    },
  })

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      className='form row w-100 m-auto fv-plugins-bootstrap5 fv-plugins-framework mt-7'
    >
      {/* <Tab.Container id='left-tabs-example' activeKey={tabName}> */}

      {/* <Tab.Content> */}
      {/* <Tab.Pane className='bg-white' eventKey='info'> */}
      <div className='col-lg-6 fv-row mt-5'>
        <div className='col-xl-12 my-7'>
          <label
            className={` ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } form-label fw-bold  fs-6`}
          >
            Your name
          </label>
          <input
            disabled
            placeholder='Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder`,
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.name as any}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-12 my-7'>
          <label
            className={` ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } form-label fw-bold  fs-6 mb-0`}
          >
            Your email address
          </label>
          <input
            disabled
            placeholder='Email Address'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder`,
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.email as any}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* </Tab.Pane> */}

      {/* <Tab.Pane className='bg-white' eventKey='company'> */}
      <div className='col-lg-6 fv-row mt-5'>
        <div className='col-xl-12 my-7'>
          <label
            className={` ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } form-label fw-bold  fs-6 `}
          >
            The name of your company / organisation
          </label>
          <input
            placeholder='Company / Organisation name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyName')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder`,
              {
                'is-invalid': formik.touched.companyName && formik.errors.companyName,
              },
              {
                'is-valid': formik.touched.companyName && !formik.errors.companyName,
              }
            )}
          />
          {formik.touched.name && formik.errors.companyName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.companyName as string}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='col-xl-12 my-7'>
          <label
            className={` ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } form-label fw-bold  fs-6 `}
          >
            Company / Organisation Email
          </label>
          <input
            placeholder='Company / Organisation email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('companyEmail')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder`,
              {'is-invalid': formik.touched.companyEmail && formik.errors.companyEmail},
              {
                'is-valid': formik.touched.companyEmail && !formik.errors.companyEmail,
              }
            )}
          />
          {formik.touched.companyEmail && formik.errors.companyEmail && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.companyEmail as any}
                </span>
              </div>
            </div>
          )}
        </div>

          {/* <div className='fv-plugins-message-container mt-2'>
            <div className='fv-help-block'>
              <span role='alert' className='font-italic' style={{color: 'gray'}}>
                Already have a team, or people that you would like to create a team with and get
                started right away? Invite three of them to join you right from the start? If not,
                or wish to add more people you can do so in the Manage Account section.
              </span>
            </div>
          </div>

        <div className='col-xl-12 my-2'>
          <input
            placeholder='Email address'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('licenseEmail1')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder mb-2`
            )}
          />
          {formik.touched.licenseEmail1 && formik.errors.licenseEmail1 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.licenseEmail1 as string}
                </span>
              </div>
            </div>
          )}
          <input
            placeholder='Email address'
            disabled={!formik.errors.licenseEmail1 ? false : true}
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('licenseEmail2')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder mb-2`
            )}
          />
          {formik.touched.licenseEmail2 && formik.errors.licenseEmail2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.licenseEmail2 as string}
                </span>
              </div>
            </div>
          )}
          <input
            placeholder='Email address'
            type='email'
            autoComplete='off'
            disabled={!formik.errors.licenseEmail1 && !formik.errors.licenseEmail2 ? false : true}
            {...formik.getFieldProps('licenseEmail3')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder mb-2`
            )}
          />
          {formik.touched.licenseEmail3 && formik.errors.licenseEmail3 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.licenseEmail3 as string}
                </span>
              </div>
            </div>
          )}
        </div> */}
        <div className='my-3'>
          <CustomAlert variant={'info'} alertMessage={popUpMsg} setAlertMessage={setPopUpMsg} />
        </div>

        <div className='my-3'>
          <CustomAlert
            variant={'danger'}
            alertMessage={errorMsgRegistration}
            setAlertMessage={setErrorMsgRegistration}
            time={5000}
          />
        </div>
        {isAlreadyMember.map((item, index) => (
          <CustomAlert
            key={index}
            variant={'danger'}
            alertMessage={item.invite}
            setAlertMessage={(newMessage) => {
              setIsAlreadyMember((prevStatus) => {
                const newState: any = [...prevStatus]
                newState[index].invite = newMessage
                return newState
              })
            }}
            time={10000}
          />
        ))}

        <div className='text-end'>
          <button
            type='submit'
            //  id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-50 my-2'
            //  disabled={formik.isSubmitting || !formik.isValid}
            disabled={loading}
          >
            {!loading && <span className='indicator-label'>Create Organization</span>}
            {loading && <span className='indicator-label'>Loading...</span>}
          </button>
        </div>

        {/* <div className='d-flex justify-content-between mt-3'>
                <span
                  className='cursor-pointer text-primary border-bottom border-primary fs-4'
                  onClick={() => setTabName('info')}
                >
                  Back
                </span>
                <span
                  className={`${
                    !formik.errors.companyName && !formik.errors.companyEmail && !formik.errors.licenseEmail1
                      ? 'cursor-pointer border-primary text-primary'
                      : 'border-secondary text-secondary'
                  } border-bottom fs-3`}
                  onClick={() => {
                    if (!formik.errors.companyName && !formik.errors.companyEmail && !formik.errors.licenseEmail1)
                      handleNextBtn('plan')
                  }}
                >
                  Invite More?
                </span>
              </div> */}
      </div>
      {/* </Tab.Pane> */}

      {/* </Tab.Content> */}

      {/* </Tab.Container> */}
    </form>
  )
}

export default OrganizationForm

const dbName = 'searchesDB'
const dbVersion = 1
let db: IDBDatabase

export const createDatabase = (): void => {
  try{
  const openRequest: IDBOpenDBRequest = indexedDB.open(dbName, dbVersion)

  openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
    const db: IDBDatabase = (event.target as IDBOpenDBRequest).result

    if (!db.objectStoreNames.contains('searches')) {
      db.createObjectStore('searches', {keyPath: 'type'})
    }
  }

  openRequest.onsuccess = (event: Event) => {
    try{
    db = (event.target as IDBOpenDBRequest).result as IDBDatabase

    const transaction: IDBTransaction = db?.transaction(['searches'], 'readwrite')
    const store: IDBObjectStore = transaction.objectStore('searches')

    const searches = {
      Cards: [],
      Boards: [],
      Templates: [],
      Contacts: [],
      Home: []
    }

    const getRequest = store.getAllKeys()
    getRequest.onsuccess = () => {
      const keys = getRequest.result
      if (keys.length === 0) {
        Object.entries(searches).forEach(([type, data]) => {
          store.put({type, data})
        })
      }
    }

  }catch(error: any){
    return error
  }
  }

  openRequest.onerror = (event: Event) => {
    return event
  }
} catch(error: any){return  error}
}
                                    // eslint-disable-next-line
export const updateSearches = (type: string, updatedData: any): void => {
  try{
  const openRequest: IDBOpenDBRequest = indexedDB.open(dbName, dbVersion)

  openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
    const db: IDBDatabase = (event.target as IDBOpenDBRequest).result

    if (!db.objectStoreNames.contains('searches')) {
      db.createObjectStore('searches', {keyPath: 'type'})
    }
  }

  openRequest.onsuccess = (event: Event) => {
    db = (event.target as IDBOpenDBRequest).result as IDBDatabase

    const transaction: IDBTransaction = db?.transaction(['searches'], 'readwrite')
    const store: IDBObjectStore = transaction.objectStore('searches')

    store.put({type, data: updatedData})
  }
  openRequest.onerror = function(event:any) {
  return  event
  }

 } catch(error: any){return  error}

}

export const getSearches = (key: string): Promise<Record<string, any>> => {
  return new Promise((resolve, reject) => {
    if (!key) {
      resolve({});
      return; // Exit early if key is not provided
    }

    const openRequest: IDBOpenDBRequest = indexedDB.open(dbName, dbVersion);

    openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;

      if (!db.objectStoreNames.contains('searches')) {
        db.createObjectStore('searches', { keyPath: 'type' });
      }
    };

    openRequest.onsuccess = (event: Event) => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
      const transaction: IDBTransaction = db.transaction(['searches'], 'readonly');
      const store: IDBObjectStore = transaction.objectStore('searches');
      const getRequest: IDBRequest = store.getAll();
 // eslint-disable-next-line
      getRequest.onsuccess = (_: Event) => {
        const searches: Record<string, any> = getRequest.result?.find(
          (result: any) => result.type === key
        );

        resolve(searches);
      };

      getRequest.onerror = (event: Event) => {
        reject(event);
      };
    };

    openRequest.onerror = (event: Event) => {
      reject(event);
    };
  });
};


// export const getSearches = (key: string): Promise<Record<string, any>> => {
//   try{
//   if (!key) return Promise.resolve({});
  
//   return new Promise((resolve, reject) => {
    
//     const openRequest: IDBOpenDBRequest = indexedDB.open(dbName, dbVersion);
//     let db: IDBDatabase | null = null;

//     openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
//       const db: IDBDatabase = (event.target as IDBOpenDBRequest).result
  
//       if (!db.objectStoreNames.contains('searches')) {
//         db.createObjectStore('searches', {keyPath: 'type'})
//       }
//     }

//     openRequest.onsuccess = (event: Event) => {
//       db = (event.target as IDBOpenDBRequest).result as IDBDatabase;
//       const transaction: IDBTransaction = db?.transaction(['searches'], 'readonly');
//       const store: IDBObjectStore = transaction.objectStore('searches');
//       const getRequest: IDBRequest = store.getAll();

//      // eslint-disable-next-line
//       getRequest.onsuccess = (_: Event) => {
//         const searches: Record<string, any> = getRequest.result?.find(
//           (result: any) => result.type === key
//         );

//         resolve(searches);
//       };

//       getRequest.onerror = (event: Event) => {
//         reject(event);
//       };
//     };

//     openRequest.onerror = (event: Event) => {
//       reject(event);
//     };
//   })
// } catch(error: any){return  error}
// };


import {createSlice} from '@reduxjs/toolkit'

const initialState: any = {
  planDetails: {},
  selectedPlanDetails: {},
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPlanDetails: (state, action) => {
      state.planDetails = action.payload
    },
    setSelectedPlanDetails: (state, action) => {
      state.selectedPlanDetails = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setPlanDetails,setSelectedPlanDetails} = paymentSlice.actions

export default paymentSlice.reducer

import {Button, Modal} from 'react-bootstrap'
import {TextEditor} from '../TextEditor'
import React, {useEffect, useState, useRef} from 'react'
import ReactQuill from 'react-quill'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {setUpdatedItem} from '../../store/slice/home-searching-slice'
import ImageModal from '../Comments/ImageModal'
import {useTheme} from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ContactNotes =  ({
  setOtherContactList,
  otherContactList,
  handleClose,
  show,
  contactDetails,
  updateOtherContactNoteHandler,
  isHomeSearching,
}: any) : React.JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch()
  const {theme} = useTheme()
  const [editor, setEditor] = useState<string|null>('')
  const [allowEdit, setAllowEdit] = useState(false)

  const editRef: any = useRef()
  const handleAllowEdit = () => {
    setAllowEdit(true)
    if (contactDetails.notes) {
      setEditor(contactDetails.notes)
    }
  }

  const saveNotesHandler = () => {
    const updatedNotes = otherContactList.map((item: any) =>
      item.id === contactDetails.id ? {...item, notes: editor} : item
    )

    if (isHomeSearching) {
      dispatch(setUpdatedItem([...updatedNotes]))
    } else {
      setOtherContactList(updatedNotes)
    }
  }

  useEffect(() => {
    if (contactDetails?.notes === null) {
      setAllowEdit(true)
    }

    let imageSrc: any
    const editImg: any = document.querySelectorAll(`div.ql-editor[contenteditable="false"] img`)

    for (let i = 0; i < editImg.length; i++) {
      editImg[i].classList.add('cursor-pointer')
      editImg[i].classList.add('d-block')
      editImg[i].style.maxWidth = '30%'
      //eslint-disable-next-line
      editImg[i].addEventListener('click', () => {
        //eslint-disable-next-line
        imageSrc = editImg[i].getAttribute('src')
        editRef.current?.handleModal(imageSrc)
      })
    }
  }, [contactDetails?.notes])

  return (
    <Modal
      contentClassName={theme === 'dark' ? 'theme__div' : ''}
      show={show}
      onHide={handleClose}
      size='lg'
    >
      <Modal.Header>
        <Modal.Title
          className={`text-center w-100 fw-bold ${theme === 'dark' ? 'white__text' : ''}`}
        >
          Notes for {contactDetails?.fullName}
          {contactDetails?.companyName && ' at ' + contactDetails?.companyName}
        </Modal.Title>
        <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={() => {
            handleClose()
            setAllowEdit(false)
          }}></i>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '500px', overflowY: 'scroll',overflowX: 'hidden'}} className={theme === 'dark' ?'dark-mode-placeholder' :''}>
        {!allowEdit ? (
          <>
            <ReactQuill
              className='image-resize'
              value={contactDetails?.notes}
              readOnly={true}
              theme={'bubble'}
            />
            <ImageModal ref={editRef} />
          </>
        ) : (
          <TextEditor
            placeholder={'Enter template description'}
            setEditor={setEditor}
            editor={editor}
          />
        )}
      </Modal.Body>
      <Modal.Footer className='my-1'>
        <Button
          variant='secondary'
          onClick={() => {
            handleClose()
            setAllowEdit(false)
          }}
        >
          Cancel
        </Button>
        {!allowEdit && (
          <Button variant='primary' onClick={handleAllowEdit}>
            Edit
          </Button>
        )}
        {allowEdit && (
          <Button
            variant='primary'
            onClick={() => {
              updateOtherContactNoteHandler(contactDetails?.id, {content: editor})
              setAllowEdit(false)
              handleClose()
              saveNotesHandler()
              // getOtherContactHandler()
            }}
          >
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ContactNotes

import { useEffect, useState } from 'react'
import { getAllTags, getTagsByBoardId } from '../services/tags'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { setBoardTags } from '../store/slice/board-tags-slice'


const useGetTags: any = () => {
    const [tags, setTags] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingBoardTags, setLoadingBoardTags] = useState<boolean>(false)
    const dispatch: Dispatch<any> = useDispatch()



    const getTagsHandler = async () => {
        try {
            setLoading(true)
            const tagRes = await getAllTags()
            const tagsData = tagRes?.data?.data?.tags?.map((item: any) => {
                return { label: item.name, value: item.id }
            })

            setTags(tagsData)
        } catch (error) {
            return error
        } finally {
            setLoading(false)
        }
    }
    const getCardTagByBoardHandler = async (boardId: string) => {
        if (boardId) {
            try {
                setLoadingBoardTags(true)
                const tags = await getTagsByBoardId(boardId,'card')
                const tagsData = tags?.data?.data?.tags?.map((item: any) => {
                    return { label: item.name, value: item.id, count:item.matchingTagsCount }
                })
                dispatch(setBoardTags(tagsData))
            } catch (error) {
                return error
            } finally {
                setLoadingBoardTags(false)
            }
        }
    }
    const getPageTagByBoardHandler = async (boardId: string) => {
        if (boardId) {
            try {
                setLoadingBoardTags(true)
                const tags = await getTagsByBoardId(boardId,'page')
                const tagsData = tags?.data?.data?.tags?.map((item: any) => {
                    return { label: item.name, value: item.id, count:item.matchingTagsCount }
                })
                dispatch(setBoardTags(tagsData))
            } catch (error) {
                return error
            } finally {
                setLoadingBoardTags(false)
            }
        }
    }
    useEffect(() => {
        getTagsHandler()
    }, [])

    return { tags, loading, getPageTagByBoardHandler, getCardTagByBoardHandler,loadingBoardTags }
}

export default useGetTags
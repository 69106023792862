import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {colors} from '@atlaskit/theme'
import {useTemplates} from '../../hooks'
import SpinnerContainer from '../../utils/SpinnerContainer'
import Skeleton from 'react-loading-skeleton'
import {deleteTemplate, updateTemplate} from '../../services/templates'
import {Alert, Dropdown} from 'react-bootstrap'
import BoardTemplate from '../Select/BoardTemplate'
import {borderRadius} from '../../components/BoardDnD/constants'
import TemplateModal from '../Modals/TemplateModal'
import {Template, TemplateColumn} from '../../models/template'
import ReactToolTip from '../ReactToolTip'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'

import styled from '@emotion/styled'
import '../../styles/template.css'
import {setUpdatedItem, updateSearchData} from '../../store/slice/home-searching-slice'

import {useNavigate} from 'react-router-dom'
import {compareItemsArchived} from '../../helper-functions/CompareAndSort'
import CustomPagination from '../Pagination'
import {useTheme} from '../../context/ThemeContext'
import DeleteTemplateModal from '../Modals/DeleteTemplateModal'
type Props = {
  isHomeSearching?: boolean
  searchTemplates?: any
  setSearchesTemplate?: any
}

const CustomContainer = styled.div`
  over-flow: auto;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;

  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${colors.G50};
  }
`

const Title = styled.h6`
  padding: ${5}px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid ${colors.P100};
    outline-offset: 2px;
  }
`

const TemplatesTable: React.FC<Props> = ({
  isHomeSearching,
  searchTemplates,
  setSearchesTemplate,
}) => {
  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate()
  const {theme} = useTheme()
  const [showModal, setShowModal] = useState(false)
  const [toBeDeleteTemplate, setToBeDeleteTemplate] = useState<any>(null)
  const [editor, setEditor] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateId, setTemplateId] = useState('')
  const [debounceBtn, setDebounceBtn] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentTemplateImage, setCurrentTemplateImage] = useState<Template | null>(null)
  const [showTemplateImage, setShowTemplateImage] = useState<boolean>(false)
  const [currentAssignBoard, setCurrentAssignBoard] = useState('')
  const {allSearchData} = useSelector((state: any) => state.homeSearchingReducer)

  const [msg, setMsg] = useState('')

  const {existingTemplates, getTemplateHandler, page, setPage, totalLength, setExistingTemplates} =
    useTemplates()
  const closeModal = () => {
    setShowModal(false)
    setEditor('')
    setTemplateName('')
    setMsg('')
  }

  const showTemplateImageHandler = (item: Template) => {
    setCurrentTemplateImage(item)
    if (currentTemplateImage && currentTemplateImage.id === item?.id) {
      setCurrentTemplateImage(null)
      setShowTemplateImage(false)
    } else {
      setShowTemplateImage(true)
    }
  }
  const handleEditTemplate: any = (template: any) => {
    setEditor(template?.description)
    setShowModal(true)
    setTemplateName(template?.name?.trim())
    setTemplateId(template?.id)
  }

  const onNameChangeHandler = (event: any) => {
    const inputValue = event.target.value
    setTemplateName(inputValue)
  }

  const onUpdateTemplate = async () => {
    if (templateName === '' || templateName.length < 2 || templateName.length > 100) return
    setDebounceBtn(true)
    function hasOnlySpaces(str: string) {
      const regex = /<p class="spacer">\s*<\/p>/
      return !regex.test(str)
    }

    const hasSpacesOnly = hasOnlySpaces(editor)
    try {
      const res = await updateTemplate(
        {
          name: templateName,
          description: !hasSpacesOnly ? null : editor,
        },
        templateId
      )
      setMsg(res.data.message)

      if (isHomeSearching && searchTemplates !== null) {
        const updatedTemplate = {...res.data.data.template}
        setSearchesTemplate(updatedTemplate)
        setExistingTemplates([updatedTemplate])

        const searchedTemplateData =
          allSearchData['Templates'.toLowerCase()]['Templates'.toLowerCase()]
        if (searchedTemplateData?.length > 0) {
          const data = [...searchedTemplateData]
          const index =
            data?.length > 0 &&
            (data?.findIndex((data: any) => data?.id === searchTemplates?.id) as number)
          if (index !== -1) {
            data[index as number] = updatedTemplate
            dispatch(setUpdatedItem([...data]))

            dispatch(
              updateSearchData({
                tab: 'templates',
                data: [...data],
              })
            )
          }
        }
      }
    } catch (error) {
      return error
    } finally {
      setTimeout(() => {
        closeModal()
        if (!isHomeSearching && !searchTemplates) {
          getTemplateHandler(5)
        }
        setDebounceBtn(false)
      }, 5000)
    }
  }

  useEffect(() => {
    if (isHomeSearching && searchTemplates !== null) {
      setExistingTemplates([searchTemplates])
      setCurrentTemplateImage(null)
    } else {
      getTemplateHandler(5)
    }

    // return () => setShowTemplateImage(false)
  }, [page, isHomeSearching, searchTemplates])

  useEffect(() => {
    if (existingTemplates?.length > 0) {
      const sortedTemplates = [...existingTemplates].sort(compareItemsArchived); 
      showTemplateImageHandler(sortedTemplates[0]);
    }
  }, [existingTemplates]);
  


  if (existingTemplates === null) {
    return (
      <SpinnerContainer>
        <Skeleton count={5} height={55} />
      </SpinnerContainer>
    )
  }

  const handleClose = () => {
    setDeleteModalShow(false)
    setToBeDeleteTemplate(null)
  }
  const handleShow = (template: any) => {
    setDeleteModalShow(true)
    setToBeDeleteTemplate(template)
    setSuccessMessage('')
  }

  const deleteTemplateHandler = async () => {
    try {
      if (toBeDeleteTemplate) {
        setLoading(true)
        const res = await deleteTemplate(toBeDeleteTemplate?.id)

        if (res?.data?.success === true) {
          setSuccessMessage(res?.data?.message)
          setLoading(false)
          setShowTemplateImage(false)
          getTemplateHandler(5)
        }
      }
    } catch (error) {
      return error
    } finally {
      setTimeout(() => {
        handleClose()
        setSuccessMessage('')
      }, 3000)
    }
  }
  return (
    <React.Fragment>
      {existingTemplates && existingTemplates?.length > 0 ? (
        <div>
          <div
            style={{
              position: 'relative',
            }}
          >
            <div
              className={`card mb-5 mb-xl-8 ${theme === 'dark' ? 'theme__div' : ''}`}
              style={{
                height: isHomeSearching && isHomeSearching === true ? 100 : 380,
                maxHeight: isHomeSearching && isHomeSearching === true ? 100 : 380,
                overflow: isHomeSearching && isHomeSearching === true ? 'visible' : 'auto',
              }}
            >
              <div className='card-body py-3'>
                <table
                  className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
                  style={{zIndex: -10000}}
                >
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-140px'>Name</th>
                      <th className='min-w-160px'>Description</th>
                      <th className='min-w-100px text-start px-2'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {existingTemplates?.length > 0 &&
                      [...existingTemplates].sort(compareItemsArchived)?.map((item: any) => (
                        <tr key={item?.id}>
                          <td style={{width: '150px'}}>
                            <span
                              className={` ${
                                theme === 'dark' ? 'white__text' : 'text-dark'
                              } fw-bolder text-hover-primary fs-6 cursor-pointer ellipsis-template-name`}
                              onClick={() => {
                                showTemplateImageHandler(item)
                              }}
                            >
                              {item?.name}
                            </span>
                          </td>
                          <td className='template-desc'>
                            <span className='text-muted fw-bold text-muted  fs-7 template-description'>
                              {item?.description
                                ? item?.description.replace(/<[^>]+>/g, '').length > 100
                                  ? item?.description.replace(/<[^>]+>/g, '').slice(0, 100) + '...'
                                  : item?.description.replace(/<[^>]+>/g, '')
                                : 'Empty'}
                            </span>
                          </td>

                          <td className='text-start px-2' style={{width: '200px',minWidth:'200px'}}>
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                              onClick={() => handleEditTemplate(item)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </span>

                            <button
                              onClick={() => {
                                handleShow(item)
                              }}
                              className={`btn-custom btn-custom-secondary px-2 py-2 `}
                              style={{marginRight: 6}}
                            >
                              <i
                                style={{color: '#a0a5b5'}}
                                className={`bi bi-trash-fill p-1 mx-1 `}
                              ></i>
                            </button>
                            <span
                              className=''
                              data-tooltip-id={
                                isHomeSearching
                                  ? 'my-tooltip-children-template-upload_search'
                                  : 'my-tooltip-children-template-upload'
                              }
                            >
                              <Dropdown drop='up' className='d-inline'>
                                <Dropdown.Toggle
                                  variant='secondary'
                                  size='sm'
                                  id='dropdown-basic'
                                ></Dropdown.Toggle>

                                <Dropdown.Menu className='custom p-0'>
                                  <BoardTemplate
                                    templateId={item?.id}
                                    setCurrentAssignBoard={setCurrentAssignBoard}
                                    setSuccessMessage={setSuccessMessage}
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {!isHomeSearching && (
              <div className={`pagination-template ${theme === 'dark' ? 'theme__div' : ''}`}>
                <CustomPagination
                  itemsCount={totalLength?.length || 1}
                  itemsPerPage={totalLength?.limit || 10}
                  currentPage={page}
                  setCurrentPage={setPage}
                  halfWidth={true}
                />
              </div>
            )}
          </div>
          <div>
            {successMessage && currentAssignBoard && (
              <Alert variant='success' className='mb-0 w-100'>
                {successMessage}!
                <span
                  style={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    borderBottom: '2px solid',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    navigate(`/board-view/board/${currentAssignBoard}`)
                  }}
                >
                  Go To Tasks
                </span>
              </Alert>
            )}

            {}
          </div>

          <hr />

          <div className='d-flex flex-wrap flex-stack mb-5'>
            <h3 className={`fw-bolder my-2 ${theme === 'dark' ? 'white__text' : ''}`}>
              Template Preview
            </h3>
          </div>

          <div
            className={`d-flex overflow-auto gap-3 px-2 ${theme === 'dark' ? 'theme__div' : ''}`}
            style={{backgroundColor: '#ffffff'}}
          >
            {showTemplateImage && (
              <React.Fragment>
                {currentTemplateImage &&
                  currentTemplateImage?.templateColumns?.length > 0 &&
                  currentTemplateImage?.templateColumns
                    ?.slice()
                    ?.sort((a: TemplateColumn, b: TemplateColumn) => a.position - b.position)
                    ?.map((item: TemplateColumn) => (
                      <CustomContainer
                        className={`position-relative mb-3 mt-3   ${
                          theme === 'dark' ? 'dark__div' : 'card-column-img'
                        } template-image`}
                        key={item.id}
                      >
                        <Header style={{width: '230px', backgroundColor: `${item?.color}`}}>
                          <Title
                            className='text-white text-truncate mt-1'
                            style={{maxWidth: '140px'}}
                          >
                            {item?.columnName}
                          </Title>
                          <div className='text-nowrap'>
                            <button
                              className={`btn-custom btn-custom-secondary ${
                                theme === 'dark' ? 'theme__div' : ''
                              } px-2 py-1 mx-1 `}
                            >
                              <i
                                className={`p-1 mx-0 bi bi-plus ${
                                  theme === 'dark' ? 'white__text' : ''
                                }`}
                              ></i>
                            </button>
                            <button
                              className={`btn-custom btn-custom-secondary ${
                                theme === 'dark' ? 'theme__div' : ''
                              } px-2 py-1 mx-1 `}
                            >
                              <i
                                className={`bi bi-brush-fill ${
                                  theme === 'dark' ? 'white__text' : ''
                                } pe-0`}
                              ></i>
                            </button>
                            <button
                              className={`btn-custom btn-custom-secondary ${
                                theme === 'dark' ? 'theme__div' : ''
                              } px-2 py-1 mx-1 `}
                            >
                              <i
                                className={`bi bi-trash-fill p-1 mx-1 ${
                                  theme === 'dark' ? 'white__text' : ''
                                }`}
                              ></i>
                            </button>
                          </div>
                        </Header>
                        <div
                          className={`${
                            theme === 'dark' ? 'theme__div' : 'bg-secondary'
                          } w-100 py-0 px-6 position-absolute bottom-0 left-0 right-0 `}
                        >
                          <button
                            onClick={() => {}}
                            type='button'
                            className='btn px-2 fs-6 text-muted w-100'
                          >
                            Add a task
                          </button>
                        </div>
                      </CustomContainer>
                    ))}
              </React.Fragment>
            )}
            <TemplateModal
              modalTitle='Update Template'
              onNameChangeHandler={onNameChangeHandler}
              isTemplateExists={false}
              msg={msg}
              setMsg={setMsg}
              showModal={showModal}
              closeModal={closeModal}
              onSaveHandler={onUpdateTemplate}
              setTemplateName={setTemplateName}
              templateName={templateName}
              setEditor={setEditor}
              editor={editor}
              saveButtonTitle='Update'
              debounceBtn={debounceBtn}
            />
          </div>
          <ReactToolTip
            id={
              isHomeSearching
                ? 'my-tooltip-children-template-upload_search'
                : 'my-tooltip-children-template-upload'
            }
            position='top-start'
          >
            <span>Upload Template to Your Board</span>
          </ReactToolTip>
        </div>
      ) : (
        <h3
          className={`d-flex justify-content-center mt-20 fs-6  ${
            theme === 'dark' ? 'white__text' : ''
          }`}
        >
          Templates are not available!
        </h3>
      )}

      {deleteModalShow && toBeDeleteTemplate && (
        <DeleteTemplateModal
          showDeleteModal={deleteModalShow}
          handleCloseDeleteModal={handleClose}
          title={toBeDeleteTemplate?.name}
          onDeleteHandler={deleteTemplateHandler}
          loading={loading}
          message={successMessage}
        />
      )}
    </React.Fragment>
  )
}

export default TemplatesTable

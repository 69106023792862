/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import apiService from './index'

export const createPage = async (data: any) => {
  return await apiService.post(`${process.env.REACT_APP_BACKEND_URL_V3}/page`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getAllPagesByBoardId = async (boardId: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/page/by/board/${boardId}`)
}

export const getPagesByUserId = async (id: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/page/getby/user/${id}`)
}

export const getPageById = async (id: string) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/page/${id}`)
}

export const updatePageById = async (id: string, data: any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL_V3}/page/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const deletePageFileAttachment = async (
  pageId: string,
  fileId: string
) => {
  return await apiService.delete(
    `${process.env.REACT_APP_BACKEND_URL_V3}/page/${pageId}/file-attachments/${fileId}`
  )
}
export const clonePage = async (data:any) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/page/clone`,data
  )
}
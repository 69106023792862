import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useTheme} from '../../context/ThemeContext'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CommentsAttachmentTable = ({attachments, isEditable, deleteFileHandler}: any) => {
  const {theme} = useTheme()
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-150px'>Files</th>
            {isEditable && <th className='min-w-100px text-end'>Remove</th>}
          </tr>
        </thead>
        <tbody>
          {attachments.length > 0 &&
            attachments?.map((item: any, index: number) => {
              const getFileName = item
                .toLowerCase()
                ?.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
              return (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-2'>
                        {
                          item &&
                          getFileName?.toLowerCase().split('.')[
                            getFileName?.toLowerCase().split('.').length - 1
                          ] === 'png' ? (
                            
                            <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/${theme === 'dark' ?'png-icon-white.svg': 'png-file-icon.svg'}`)} />
                          ) : getFileName?.toLowerCase().split('.')[
                              getFileName?.toLowerCase().split('.').length - 1
                            ] === 'docx' ? (
                              <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/old-doc.svg`)} />
                          ) : getFileName?.toLowerCase().split('.')[
                              getFileName?.toLowerCase().split('.').length - 1
                            ] === 'xls' ? (
                              <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/old-excel.svg`)} />
                          ) : getFileName?.toLowerCase().split('.')[
                              getFileName?.toLowerCase().split('.').length - 1
                            ] === 'xlsx' ? (
                              <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/old-excel.svg`)} />
                          ) : getFileName?.toLowerCase().split('.')[
                            getFileName?.toLowerCase().split('.').length - 1
                          ] === 'csv' ? (
                            <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/old-excel.svg`)} />
                        ) :
                           getFileName?.toLowerCase().split('.')[
                              getFileName?.toLowerCase().split('.').length - 1
                            ] === 'pdf' ? (
                              <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/a-pdf.svg`)} />
                          ) :  getFileName?.toLowerCase().split('.')[
                            getFileName?.toLowerCase().split('.').length - 1
                          ] === 'jpeg' ? (
                            <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/${theme === 'dark'? 'jpg-icon-white.svg' : 'jpeg-file-icon.svg'}`)} />
                        ) :
                           getFileName?.toLowerCase().split('.')[
                              getFileName?.toLowerCase().split('.').length - 1
                            ] === 'jpg' ? (
                              <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/${theme === 'dark'? 'jpg-icon-white.svg' : 'jpeg-file-icon.svg'}`)} />
                          ) : getFileName?.toLowerCase().split('.')[
                              getFileName?.toLowerCase().split('.').length - 1
                            ] === 'txt' ? (
                              <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/${theme === 'dark' ? 'page-file-icon-white.svg': 'page-file-icon.svg'}`)} />
                          ) : getFileName?.toLowerCase().split('.')[
                            getFileName?.toLowerCase().split('.').length - 1
                          ] === 'pdf' ? (
                            <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/a-pdf.svg`)} />
                        ) :  getFileName?.toLowerCase().split('.')[
                          getFileName?.toLowerCase().split('.').length - 1
                        ] === 'xd' ? (
                          <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/a-xd.svg`)} />
                      ) : getFileName?.toLowerCase().split('.')[
                        getFileName?.toLowerCase().split('.').length - 1
                      ] === 'jam' ? (
                        <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/f-figma.svg`)} />
                    ) : getFileName?.toLowerCase().split('.')[
                      getFileName?.toLowerCase().split('.').length - 1
                    ] === 'fig' ? (
                      <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/f-figma.svg`)} />
                  ) : getFileName?.toLowerCase().split('.')[
                    getFileName?.toLowerCase().split('.').length - 1
                  ] === 'pptx' ? (
                    <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/old-ppt.svg`)} />
                ) : getFileName?.toLowerCase().split('.')[
                  getFileName?.toLowerCase().split('.').length - 1
                ] === 'zip' ? (
                  <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/zip-icon.svg`)} />
              ) :
                      (
                            <img className={theme === 'dark' ? 'white__text' : 'text-dark'} src={toAbsoluteUrl(`/media/icons/files/${theme === 'dark' ? 'page-file-icon-white.svg': 'page-file-icon.svg'}`)} />
                          )

                          // file.name?.toLowerCase().split(".")[file.name?.toLowerCase().split(".").length - 1] === "png" ? <i className="bi bi-file-image"></i> : file.name?.toLowerCase().split(".")[file.name?.toLowerCase().split(".").length - 1] === "docx" ? <i className="bi bi-filetype-docx"></i> :
                          //     file.name?.toLowerCase().split(".")[file.name?.toLowerCase().split(".").length - 1] === "xls" ? <i className="bi bi-filetype-xls"></i> : file.name?.toLowerCase().split(".")[file.name?.toLowerCase().split(".").length - 1] === "xlsx" ? <i className="bi bi-filetype-xlsx"></i> : file.name?.toLowerCase().split(".")[file.name?.toLowerCase().split(".").length - 1] === "pdf" ? <i className="bi bi-filetype-pdf"></i> : file.name?.toLowerCase().split(".")[file.name?.toLowerCase().split(".").length - 1] === "jpg" ? <i className="bi bi-filetype-jpg"></i> : <i className="bi bi-file-earmark-medical"></i>
                        }
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a
                          href={item}
                          target='_blank'
                          rel='noreferrer'
                          download
                          className={`${
                            theme === 'dark' ? 'white__text' : 'text-dark'
                          }  fw-bolder text-hover-primary fs-6`}
                        >
                          {getFileName}
                        </a>
                      </div>
                    </div>
                  </td>
                  {isEditable && (
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <span
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={() => deleteFileHandler(item)}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default CommentsAttachmentTable
